import React from "react"
import * as Yup from 'yup'
import {useFormik} from "formik"
import {Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap"
import _ from "lodash"
import {Spinner} from "react-bootstrap"

const PhasesForm = ({
  initValues,
  handleSubmit,
  isLoading,
  changeTab,
  resetForm
}) => {

  const phaseInfo = {
    phases_cd: '',
    phases_name: '',
    phases_abbr: '',
  }
  const initialValues = _.isEmpty(initValues) ? phaseInfo : initValues

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      phases_cd: Yup.string().required('Phases code is required.'),
      phases_name: Yup.string().required('Phases name is required.'),
      phases_abbr: Yup.string().required('Abbreviation is required.'),
    }),
    onSubmit: async values => await handleSubmit(values)
  })

  return <Card className="border-top-0 shadow-none">
    <CardHeader style={{
      fontSize: 20,
      color: '#fff',
      textAlign: 'center',
      padding: 10,
    }}>
      Phases Information
    </CardHeader>

    <CardBody>
      {isLoading ?
        <Spinner animation="border" /> :
        <Form onSubmit={formik.handleSubmit}>

          <FormGroup row>
            <Col sm={1}>
              <Label for="phasesCode">Code</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                name="phases_cd"
                id="phasesCode"
                value={formik.values?.phases_cd}
                bsSize="sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched?.phases_cd &&
                  formik.errors?.phases_cd
                }
              />
              <FormFeedback invalid>
                {formik.errors?.phases_cd}
              </FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={1}>
              <Label for="phasesName">Name</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                name="phases_name"
                id="phasesName"
                value={formik.values?.phases_name}
                bsSize="sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched?.phases_name &&
                  formik.errors?.phases_name
                }
              />
              <FormFeedback invalid>
                {formik.errors?.phases_name}
              </FormFeedback>
            </Col>
          </FormGroup>


          <FormGroup row>
            <Col sm={1}>
              <Label for="phasesAbbr">Abbreviation</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                name="phases_abbr"
                id="phasesAbbr"
                value={formik.values?.phases_abbr}
                bsSize="sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched?.phases_abbr &&
                  formik.errors?.phases_abbr
                }
              />
              <FormFeedback invalid>
                {formik.errors?.phases_abbr}
              </FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col xs={{offset: 4}} className="text-right">
              <Button
                type="submit"
                color="primary"
                className="fixedWidthButton"
                >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                className="fixedWidthButton"
                color="danger"
                onClick={() => {
                  resetForm()
                  changeTab('list')
                }}
                >
                Cancel
              </Button>
            </Col>
          </FormGroup>

        </Form>
      }
    </CardBody>
  </Card>
}

export default PhasesForm