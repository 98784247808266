import 'assets2/button.scss';
import { theme } from 'utils/Theme';
import Loading from 'components/Loading';
import ClientTypeDetailList from 'components/ClientTypeDetailList';

import React, { useState } from "react";
import _ from 'lodash';
import { MuiThemeProvider, Tooltip, IconButton } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { Button } from 'reactstrap';
import MaterialTable from 'material-table';


const ClientTypeTable = ({
    dataList,
    handleEdit,
    handleDelete,
    isLoading,
    isActionAllowed,
    changeTab,
    onStatusIconClick,
    onApprovedIconClick
}) => {
    const cellStyle = {
        paddingTop: 0,
        paddingBottom: 0
    };

    const [filter, setFilter] = useState(false);
    let clientTypeList = _.cloneDeep(dataList);
    clientTypeList = _.orderBy(clientTypeList, ['status', 'approved'], ['asc', 'desc']);

    clientTypeList = clientTypeList.map(row => {
        const rowString = JSON.stringify(row);
        row['status_icon'] = (
            <IconButton
                onClick={(e) => onStatusIconClick(e)}
                data-client-type={rowString}
                disabled={!isActionAllowed('status')}
            >
                {row['status'] === 'I' ? (
                    <Tooltip title="Inactive">
                        <Cancel style={{ color: 'red' }} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Active">
                        <CheckCircle style={{ color: 'green' }} />
                    </Tooltip>
                )}
            </IconButton>
        );

        row['approved_icon'] = (
            <IconButton
                onClick={(e) => onApprovedIconClick(e)}
                data-client-type={rowString}
                disabled={!isActionAllowed('approve')}
            >
                {row['approved'] === 'N' ? (
                    <Tooltip title="Unapproved">
                        <Cancel style={{ color: 'red' }} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Approved">
                        <CheckCircle style={{ color: 'green' }} />
                    </Tooltip>
                )}
            </IconButton>
        );

        return row;
    });

    const Title = () => {
        return (
            <>
                <div style={{ width: '500px' }}>
                    <Button
                        type="button"
                        title="Add New Client Type"
                        color="primary"
                        style={{
                            margin: '0px 10px 5px 0px',
                            borderRadius: '50%',
                            padding: '0px',
                            width: '30px',
                            height: '30px',
                            float: 'left',
                        }}
                        onClick={() => {
                            changeTab('add');
                        }}
                        hidden={!isActionAllowed('add')}
                    >
                        <i class="fas fa-plus fa-sm"></i>
                    </Button>

                    <h2 style={{ color: '#00948B' }}>Client Type</h2>
                </div>
            </>
        );
    }

    const columns = [
        {
            title: 'Code',
            field: 'client_type_cd',
            cellStyle: cellStyle
        },
        {
            title: 'Name',
            field: 'client_type_name',
            cellStyle: cellStyle
        },
        {
            title: 'Abbreviation',
            field: 'client_type_abbr',
            cellStyle: cellStyle
        },
        {
            title: 'Status',
            field: 'status_icon',
            cellStyle: cellStyle,
            customFilterAndSearch: (text, rowData) => {
                const searchText = text.toLowerCase().trim();
                const word = rowData.status === 'I' ? 'inactive' : 'active';
                if (word.startsWith(searchText)) {
                    return true;
                }
                return false;
            }
        },
        {
            title: 'Approved',
            field: 'approved_icon',
            cellStyle: cellStyle,
            customFilterAndSearch: (text, rowData) => {
                const searchText = text.toLowerCase().trim();
                const word = rowData.approved === 'Y' ? 'yes' : 'no';
                if (word.startsWith(searchText)) {
                    return true;
                }
                return false;
            }
        }
    ];


    return (
        <>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    localization={{
                        body: {
                            emptyDataSourceMessage: isLoading ? 'No records to display' : '',
                        },
                    }}
                    components={{
                        OverlayLoading: (props) => <Loading />,
                    }}
                    isLoading={isLoading}
                    title={<Title />}
                    columns={columns}
                    data={clientTypeList}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
                            disabled: !isActionAllowed('edit'),
                            onClick: (event, rowData) => {
                                handleEdit(rowData);
                            },
                        },
                        {
                            icon: 'delete',
                            tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
                            disabled: !isActionAllowed('delete'),
                            onClick: (event, rowData) => {
                                handleDelete(rowData);
                            },
                        },
                        {
                            icon: 'filter_alt',
                            tooltip: 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => {
                                setFilter((prev) => !prev);
                            },
                        }
                    ]}
                    options={{
                        headerStyle: {
                            color: '#00948B',
                            fontWeight: 'bold',
                            paddingTop: '0px',
                            paddingBottom: '5px',
                        },

                        actionsColumnIndex: -1,
                        detailPanelColumnAlignment: 'right',
                        pageSize: 10,
                        filtering: filter,
                    }}
                    detailPanel={[
                        {
                            icon: 'toc',
                            disabled: !isActionAllowed('detail'),
                            render: (rowData) => {
                                const clientTypeId = rowData['client_type_id'];
                                return <ClientTypeDetailList clientTypeId={clientTypeId} />;
                            },
                        },
                    ]}
                />
            </MuiThemeProvider>
        </>
    );
}

export default ClientTypeTable;
