import React from "react"
import {IconButton, MuiThemeProvider} from "@material-ui/core";
import {theme} from "../../utils/Theme";
import _ from "lodash";
import MaterialTable from "material-table";
import Loading from "../../components/Loading";
import {Button} from "reactstrap";
import ConfigDetailList from "../../components/ConfigDetailList";
import Tooltip from "@material-ui/core/Tooltip";
import {Cancel, CheckCircle, ListAlt, Visibility} from "@material-ui/icons";

const RoleTable = ({
  roleListData,
  handleEdit,
  handleDelete,
  onStatusIconClick,
  onApprovedIconClick,
  onPermissionIconClick,
  onMenuIconClick,
  isLoading,
  isActionAllowed,
  changeTab
}) => {

  let roleList = _.cloneDeep(roleListData)
  roleList = roleList.map(row => {
    const rowString = JSON.stringify(row)

    row['status_icon'] = <IconButton
      onClick={onStatusIconClick}
      data-config={rowString}
      disabled={!isActionAllowed('status')}
    >
      {row['status'] === 'I' ? (
        <Tooltip title="Inactive">
          <Cancel style={{ color: 'red' }} />
        </Tooltip>
      ) : (
        <Tooltip title="Active">
          <CheckCircle style={{ color: 'green' }} />
        </Tooltip>
      )}
    </IconButton>


    row['approved_icon'] = <IconButton
      onClick={onApprovedIconClick}
      data-config={rowString}
      disabled={!isActionAllowed('approve')}
    >
      {row['approved'] === 'N' ? (
        <Tooltip title="Unapproved">
          <Cancel style={{ color: 'red' }} />
        </Tooltip>
      ) : (
        <Tooltip title="Approved">
          <CheckCircle style={{ color: 'green' }} />
        </Tooltip>
      )}
    </IconButton>

    row['permission_icon'] = <IconButton
      onClick={onPermissionIconClick}
      data-config={rowString}
      disabled={!isActionAllowed('permission')}
    >
      <Tooltip title="Edit permissions">
        <Visibility style={{color: 'black'}} />
      </Tooltip>
    </IconButton>

    row['menu_icon'] = <IconButton
      onClick={onMenuIconClick}
      data-config={rowString}
      disabled={!isActionAllowed('menu')}
    >
      <Tooltip title="Edit menus">
        <ListAlt style={{color: 'black'}} />
      </Tooltip>
    </IconButton>

    return row
  })

  const cellStyle = {
    paddingTop: '0px',
    paddingBottom: '0px',
  }
  const columns = [
    { title: 'Role', field: 'role_name', cellStyle: cellStyle },
    { title: 'Status', field: 'status_icon', cellStyle: cellStyle },
    { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle },
    { title: 'Permission', field: 'permission_icon', cellStyle: cellStyle },
    { title: 'Menu', field: 'menu_icon', cellStyle: cellStyle },
  ]

  return <MuiThemeProvider theme={theme}>
    <MaterialTable
      components={{
        OverlayLoading: props => <Loading />
      }}
      localization={{
        body: {
          emptyDataSourceMessage: !isLoading ? 'No records to display!' : ''
        }
      }}
      isLoading={isLoading}
      title={
        <div style={{width: '500px'}}>
          <Button
            type="button"
            title="Add new Role"
            color="primary"
            style={{
              margin: '0px 10px 5px 0px',
              borderRadius: '50%',
              padding: '0px',
              width: '30px',
              height: '30px',
              float: 'left',
            }}
            onClick={() => {
              changeTab('add')
            }}
            hidden={!isActionAllowed('add')}
          >
            <i className="fas fa-plus fa-sm" />
          </Button>
          <h2 style={{color: '#00948B'}}>Role List</h2>
        </div>
      }
      columns={columns}
      data={roleList}
      options={{
        headerStyle: {
          color: '#00948B',
          fontWeight: 'bold',
          paddingTop: '0px',
          paddingBottom: '5px',
        },
        rowStyle: {
          hover: true,
        },
        actionsColumnIndex: -1,
        detailPanelColumnAlignment: 'right'
      }}
      actions={[
        {
          icon: 'edit',
          tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
          disabled: !isActionAllowed('edit'),
          onClick: (event, rowData) => {handleEdit(rowData)}
        },
        {
          icon: 'delete',
          tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
          disabled: !isActionAllowed('delete'),
          onClick: (event, rowData) => {handleDelete(rowData)}
        }
      ]}
      detailPanel={[
        {
          icon: 'toc',
          disabled: !isActionAllowed('detail'),
          render: rowData => {
            const roleId = rowData['role_id']
            return <ConfigDetailList id={roleId} type={'role'} />
          }
        }
      ]}
    />
  </MuiThemeProvider>
}

export default RoleTable