import React, { Fragment } from 'react';
import {getDetail} from '../../actions/PriorityFunctions';
import { Card, CardBody, CardTitle, CardSubtitle, CardColumns } from 'reactstrap';

class PriorityDetailList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      detail: {}
    }
  }

  componentDidMount() {
    getDetail(this.props.priorityId).then((detail) => {
      this.setState({
        detail: detail[0],
      })
    });
  }

  notEmpty = (value) => {
    return value ? value : '';
  }

  render() {
    const {detail} = this.state;
    const map = {
      'priority_cd': 'Code',
      'priority_name': 'Name',
      'priority_abbr': 'Abbreviation',      
      'status': 'Status',
      'approved': 'Approved',
      'approved_by': 'Approved By',
      'approved_dt': 'Approved Date',
      'updated_by': 'Updated By',
      'updated_dt': 'Updated Date',
      'created_by': 'Created By',
      'created_dt': 'Created Date',
    }
    return (
      <CardColumns className="lisColor1 pt-2">
        {Object.keys(map).map((key) => <Card className="border-0 lisColor1">
          <CardBody className="p-0 pl-3">
            <CardTitle>{`${map[key]}`}</CardTitle>
            <CardSubtitle className="font-weight-bold">{`${this.notEmpty(detail[key])}`}</CardSubtitle>
          </CardBody>
        </Card>)}
      </CardColumns>
    );
  }
}

export default PriorityDetailList;