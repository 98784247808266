import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Cancel, CheckCircle } from '@material-ui/icons';
import 'assets2/button.scss';
import Loading from 'components/Loading';
import ProjectDetailList from 'components/ProjectDetailList';
import _ from 'lodash';
import MaterialTable from 'material-table';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { theme } from 'utils/Theme';

const projectStateMap = {
  'I': 'In Progress',
  'H': 'Halted',
  'C': 'Closed',
  'In Progress': 'I',
  'Halted': 'H',
  'Closed': 'C',
}

const ProjectInfoTable = ({
  project_list,
  handleEdit,
  handleDelete,
  handleProjectStateModal,
  isLoading,
  isActionAllowed,
  changeTab,
  onStatusIconClick,
  onApprovedIconClick,
}) => {
  const cellStyle = {
    paddingTop: 0,
    paddingBottom: 0,
    // paddingRight: 0,
    // paddingLeft: 0,
    // fontSize: 'inherit',
  };

  const [filter, setFilter] = useState(false);

  let projectList = _.cloneDeep(project_list);

  projectList = _.orderBy(projectList, ['status', 'approved'], ['asc', 'desc']);
  projectList = projectList.map((row) => {
    const rowString = JSON.stringify(row);
    row['status_icon'] = (
      <IconButton
        onClick={(e) => onStatusIconClick(e)}
        data-project={rowString}
        disabled={!isActionAllowed('status')}
      >
        {row['status'] === 'I' ? (
          <Tooltip title="Inactive">
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title="Active">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    );
    row['approved_icon'] = (
      <IconButton
        onClick={(e) => onApprovedIconClick(e)}
        data-project={rowString}
        disabled={!isActionAllowed('approve')}
      >
        {row['approved'] === 'N' ? (
          <Tooltip title="Unapproved">
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title="Approved">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    );
    row['disabled_edit'] = !(isActionAllowed('edit') && row['approved'] === 'N')
    row['disabled_state'] = !(isActionAllowed('state') && row['approved'] === 'N')
    return row;
  });

  const Title = () => {
    return (
      <>
        <div style={{ width: '500px' }}>
          <Button
            type="button"
            title="Add new project"
            color="primary"
            style={{
              margin: '0px 10px 5px 0px',
              borderRadius: '50%',
              padding: '0px',
              width: '30px',
              height: '30px',
              float: 'left',
            }}
            onClick={() => {
              //resetState();
              changeTab('add');
            }}
            hidden={!isActionAllowed('add')}
          >
            <i class="fas fa-plus fa-sm"></i>
            {/* {props.editMode
            ? formatCurrentTabName(activeTab, 'Edit')
            : formatCurrentTabName(activeTab, 'Add')} */}
          </Button>

          <h2 style={{ color: '#00948B' }}>Project Information</h2>
        </div>
      </>
    );
  };

  const columns = [
    {
      title: 'Code',
      field: 'project_cd',
      cellStyle: cellStyle,
    },
    {
      title: 'Name',
      field: 'project_name',
      cellStyle: cellStyle,
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
      render: (rowData) => (
        <Link to={'/project/' + rowData.project_id}>{rowData.project_name} </Link>
      ),
    },
    {
      title: 'Type',
      field: 'project_type',
      cellStyle: cellStyle,
    },
    {
      title: 'Stream',
      field: 'stream_id',
      cellStyle: cellStyle,
    },
    {
      title: 'Category',
      field: 'project_category',
      cellStyle: cellStyle,
    },
    //{
    //  title: 'Start-Date',
    //  field: 'project_st_dt',
    //  type: 'date',
    //},
    //{
    //  title: 'End-Date',
    //  field: 'project_end_dt',
    //  type: 'date',
    //},
    {
      title: 'State',
      field: 'state_project',
      render: ({state_project}) => {
        switch(state_project) {
          case 'I':
            return 'In Progress';
          case 'C':
            return 'Closed';
          case 'H':
            return 'Halted';
          default:
            return '';
        }
      },
      cellStyle: cellStyle,
      customFilterAndSearch: (text, rowData) => {
        const searchText = text.toLowerCase().trim();
        const word = projectStateMap[rowData?.state_project];
        if (word?.toLowerCase().indexOf(searchText) > -1) {
          return true;
        }
        return false;
      },
     },
    {
      title: 'Status',
      field: 'status_icon',
      cellStyle: cellStyle,
      // filtering: false,
      customFilterAndSearch: (text, rowData) => {
        const searchText = text.toLowerCase().trim();
        const word = rowData.status === 'I' ? 'inactive' : 'active';
        if (word.startsWith(searchText)) {
          return true;
        }
        return false;
      },
    },
    {
      title: 'Approved',
      field: 'approved_icon',
      cellStyle: cellStyle,
      filtering: false,
      customFilterAndSearch: (text, rowData) => {
        const searchText = text.toLowerCase().trim();
        const word = rowData.approved === 'Y' ? 'yes' : 'no';
        if (word.startsWith(searchText)) {
          return true;
        }
        return false;
      },
    },
  ];

  return (
    <>
      <Fragment>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: isLoading ? 'No records to display' : '',
              },
            }}
            components={{
              OverlayLoading: (props) => <Loading />,
            }}
            isLoading={isLoading}
            title={<Title />}
            columns={columns}
            data={projectList}
            //onRowClick={}
            actions={[
              (rowData) => ({
                icon: 'edit',
                tooltip: rowData['disabled_edit'] ? 'Can\'t edit this item' : 'Edit',
                disabled: rowData['disabled_edit'],
                onClick: () => handleEdit(rowData),
              }),
              // {
              //   icon: 'delete',
              //   tooltip: isActionAllowed('delete') ? 'Delete Project' : 'You don\'t have permission to perform this action.',
              //   disabled: !isActionAllowed('delete'),
              //   onClick: (event, rowData) => {
              //     handleDelete(rowData);
              //   },
              // },
              (rowData) => ({
                icon: 'note',
                tooltip: rowData['disabled_state'] ? 'Cannot perform this action' : 'State',
                disabled: rowData['disabled_state'],
                onClick: (event, rowData) => {
                  handleProjectStateModal(rowData);
                },
              }),
              {
                icon: 'filter_alt',
                tooltip: 'Enable Filter',
                isFreeAction: true,
                onClick: (event) => {
                  setFilter((prev) => !prev);
                },
              },
            ]}
            options={{
              headerStyle: {
                color: '#00948B',
                fontWeight: 'bold',
                paddingTop: '0px',
                paddingBottom: '5px',
              },

              actionsColumnIndex: -1,
              detailPanelColumnAlignment: 'right',
              pageSize: 10,
              filtering: filter,
            }}
            detailPanel={[
              {
                icon: 'toc',
                disabled: !isActionAllowed('detail'),
                render: (rowData) => {
                  const projectId = rowData['project_id'];
                  return <ProjectDetailList projectId={projectId} />;
                },
              },
            ]}
          />
        </MuiThemeProvider>
      </Fragment>
    </>
  );
};

export default ProjectInfoTable;
