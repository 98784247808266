import React, { Fragment } from "react";
import {
  Container,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";

import {
  NavLink as RouterNavLink,
  Route,
  Switch,
  Redirect,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import Loading from "../Loading";
import RoadmapContainer from "../../container/RoadmapContainer";

const EffortEstimateContainer = React.lazy(() =>
  import("../../container/EffortEstimateContainer")
);
const ResourceInfoContainer = React.lazy(() =>
  import("../../container/ResourceInfoContainer")
);
const SprintContainer = React.lazy(() =>
  import("../../container/SprintContainer2")
);
const ProjectDetailTab = React.lazy(() =>
  import("../../components/ProjectDetailTab")
);
const ProjectRelease = React.lazy(() =>
  import("../../container/ProjectRelease")
);
const ProjectTask = React.lazy(() =>
  import("../../container/ProjectTaskContainer")
);
const SprintStatus = React.lazy(() =>
  import("../../container/SprintStatusContainer")
);
const SearchCriteria = React.lazy(() =>
  import("../../container/SearchCriteriaContainer")
);
const ProjectHealthForm = React.lazy(() =>
  import("../../container/ProjectHealthFormContainer")
);
const TimeSheetApproval = React.lazy(() =>
  import("../../container/TimeSheetApproval")
);
const TaskImport = React.lazy(() =>
  import("../../container/TaskImportContainer")
);
const WeeklyReport = React.lazy(() => import("../WeeklyReport"));
const IPPContainer = React.lazy(() => import("../../container/IPPContainer"));

const MembersView = React.lazy(() => import("../MembersView"));

const ProjectWorkFlowContainer = React.lazy(() =>
  import("../../container/ProjectWorkflowContainer/ProjectWorkflowContainer")
);

const formatCurrentTabName = (activeTab, tabId, label) => {
  return activeTab.toLowerCase() === tabId.toLowerCase() ? (
    <b>{label}</b>
  ) : (
    `${label}`
  );
};

const ProjectDetailPage = (props) => {
  const activeTab = props.currentTab;
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  if (!pathname.includes(activeTab)) {
    // TODO: Extremely fragile hack!! Find a better way to do this
    // if activeTab points to a different tab than the tab present in the URL
    const names = pathname.split("/");
    names[3] && props.changeTab(names[3]); // names[3] contains the required tab id
  }
  return (
    props.project && (
      <Fragment>
        <Container
          className="mt-3 ml-0"
          fluid
          style={{ maxWidth: "100%", margin: "auto auto" }}
        >
          <div>
            <h2 style={{ color: "#00948B" }}>
              {props.project["project_name"]}
            </h2>
          </div>
          <Nav tabs className="border-bottom-0">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "projectDetail",
                })}
                onClick={() => {
                  props.changeTab("projectDetail")
                }}
                style={{
                  backgroundColor:
                    activeTab === "projectDetail" ? "white" : null,
                  cursor: "pointer",
                }}
                tag={RouterNavLink}
                to={`${url}/projectDetail`}
              >
                {formatCurrentTabName(
                  activeTab,
                  "projectDetail",
                  "Project Detail"
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "resourceList" })}
                onClick={() => {
                  props.changeTab("resourceList")
                }}
                style={{
                  backgroundColor:
                    activeTab === "resourceList" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("resource")}
                tag={RouterNavLink}
                to={`${url}/resourceList`}
              >
                {formatCurrentTabName(activeTab, "resourceList", "Resource")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "effort" })}
                onClick={() => {
                  props.changeTab("effort")
                }}
                style={{
                  backgroundColor: activeTab === "effort" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("effort")}
                tag={RouterNavLink}
                to={`${url}/effort`}
              >
                {formatCurrentTabName(activeTab, "effort", "Effort")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "workflow" })}
                onClick={() => {
                  props.changeTab("workflow")
                }}
                style={{
                  backgroundColor: activeTab === "workflow" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("workflow")}
                tag={RouterNavLink}
                to={`${url}/workflow`}
              >
                {formatCurrentTabName(activeTab, "workflow", "Work Flow")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                // disabled={props.project['approved'] === 'No' ? true : false}
                className={classnames({ active: activeTab === "taskPlan" })}
                onClick={() => {
                  props.changeTab("taskPlan")
                }}
                style={{
                  backgroundColor: activeTab === "taskPlan" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("taskPlan")}
                tag={RouterNavLink}
                to={`${url}/taskPlan`}
              >
                {formatCurrentTabName(activeTab, "taskPlan", "Task Plan")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "release" })}
                onClick={() => {
                  props.changeTab("release")
                }}
                style={{
                  backgroundColor: activeTab === "release" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("release")}
                tag={RouterNavLink}
                to={`${url}/release`}
              >
                {formatCurrentTabName(activeTab, "release", "Release")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "workItems" })}
                onClick={() => {
                  props.changeTab("workItems")
                }}
                style={{
                  backgroundColor: activeTab === "workItems" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("workItems")}
                tag={RouterNavLink}
                to={`${url}/workItems`}
              >
                {formatCurrentTabName(activeTab, "workItems", "Work Items")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "sprint" })}
                onClick={() => {
                  props.changeTab("sprint")
                }}
                style={{
                  backgroundColor: activeTab === "sprint" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("sprint")}
                tag={RouterNavLink}
                to={`${url}/sprint`}
              >
                {formatCurrentTabName(activeTab, "sprint", "Sprint")}
              </NavLink>
            </NavItem>
{/* 
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "search" })}
                onClick={() => {
                  props.changeTab("search")
                }}
                style={{
                  backgroundColor: activeTab === "search" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("search")}
                tag={RouterNavLink}
                to={`${url}/search`}
              >
                {formatCurrentTabName(activeTab, "search", "Search")}
              </NavLink>
            </NavItem> */}

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "health" })}
                onClick={() => {
                  props.changeTab("health")
                }}
                style={{
                  backgroundColor: activeTab === "health" ? "white" : null,
                  cursor: "pointer",
                }}
                tag={RouterNavLink}
                to={`${url}/health`}
                hidden={!props.isActionAllowed("health")}
              >
                {formatCurrentTabName(activeTab, "health", "Health")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "timesheet" })}
                onClick={() => {
                  props.changeTab("timesheet")
                }}
                style={{
                  backgroundColor: activeTab === "timesheet" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("timesheet")}
                tag={RouterNavLink}
                to={`${url}/timesheet`}
              >
                {formatCurrentTabName(activeTab, "timesheet", "Timesheet")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "import" })}
                onClick={() => {
                  props.changeTab("import")
                }}
                style={{
                  backgroundColor: activeTab === "import" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("import")}
                tag={RouterNavLink}
                to={`${url}/import`}
              >
                {formatCurrentTabName(activeTab, "import", "Import")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "report" })}
                onClick={() => {
                  props.changeTab("report")
                }}
                style={{
                  backgroundColor: activeTab === "report" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("report")}
                tag={RouterNavLink}
                to={`${url}/report`}
              >
                {formatCurrentTabName(activeTab, "report", "Report")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "ipp" })}
                onClick={() => {
                  props.changeTab("ipp")
                }}
                style={{
                  backgroundColor: activeTab === "ipp" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("ipp")}
                tag={RouterNavLink}
                to={`${url}/ipp`}
              >
                {formatCurrentTabName(activeTab, "ipp", "IPP")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "roadmap" })}
                onClick={() => {
                  props.changeTab("roadmap")
                }}
                style={{
                  backgroundColor: activeTab === "roadmap" ? "white" : null,
                  cursor: "pointer",
                }}
                hidden={!props.isActionAllowed("sprint")}
                tag={RouterNavLink}
                to={`${url}/roadmap`}
              >
                {formatCurrentTabName(activeTab, "roadmap", "Roadmap")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <React.Suspense fallback={<Loading />}>
              <Switch>
                <Route path={`${path}/projectDetail`} exact>
                  <TabPane tabId="projectDetail">
                    <ProjectDetailTab project={props.project} />
                  </TabPane>
                </Route>

                <Route path={`${path}/resourceList`} exact>
                  <TabPane tabId="resourceList">
                    <ResourceInfoContainer
                      project={props.project}
                      projectId={props.projectId}
                      projectName={props.project["project_name"]}
                      projectCode={props.project["project_cd"]}
                      changeTab={props.changeTab}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/effort`} exact>
                  <TabPane tabId="effort">
                    <EffortEstimateContainer
                      projectId={props.projectId}
                      projectName={props.project["project_name"]}
                      projectCode={props.project["project_cd"]}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/workflow`} exact>
                  <TabPane tabId="workflow">
                    <ProjectWorkFlowContainer
                      projectId={props.projectId}
                      project={props.project}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/taskPlan`} exact>
                  {/* sprint is renamed to task plan */}
                  <TabPane tabId="taskPlan">
                    <SprintContainer
                      //projectId={props.projectId}
                      project={props.project}
                      // projectName={props.project['project_name']}
                      // projectCode={props.project['project_cd']}
                      // modelTrack={props.project['model_track']}
                      // workPlan={props.project['work_plan']}
                      // workDays={props.project['work_days']}
                      // capacity_hrs={props.project['capacity_hrs']}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                      // projectEndDt={props.project['project_end_dt']}
                      // projectStartDt={props.project['project_st_dt']}
                    />
                  </TabPane>
                </Route>
{/* 
                <Route path={`${path}/release`} exact>
                  <TabPane tabId="release">
                    <ProjectRelease
                      projectId={props.projectId}
                      projectName={props.project["project_name"]}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                    />
                  </TabPane>
                </Route> */}

                <Route path={`${path}/workItems`} exact>
                  {/* backlog is renamed to work items */}
                  <TabPane tabId="workItems">
                    <ProjectTask
                      projectId={props.projectId}
                      projectName={props.project["project_name"]}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                      projectStDt={props.project["project_st_dt"]}
                      projectEndDt={props.project["project_end_dt"]}
                      modelId={props.project.model_id}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/sprint`} exact>
                  {/* sprint status is renamed to sprint */}
                  <TabPane tabId="sprint">
                    <SprintStatus
                      projectId={props.projectId}
                      projectCode={props.project["project_cd"]}
                      projectName={props.project["project_name"]}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                      projectStDt={props.project["project_st_dt"]}
                      projectEndDt={props.project["project_end_dt"]}
                      modelId={props.project.model_id}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/search`} exact>
                  <TabPane tabId="search">
                    <SearchCriteria
                      projectId={props.projectId}
                      projectName={props.project["project_name"]}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                      projectStDt={props.project["project_st_dt"]}
                      projectEndDt={props.project["project_end_dt"]}
                      modelId={props.project.model_id}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/health`} exact>
                  <TabPane tabId="health">
                    <ProjectHealthForm
                      projectId={props.projectId}
                      projectName={props.project["project_name"]}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                      projectStDt={props.project["project_st_dt"]}
                      projectEndDt={props.project["project_end_dt"]}
                      modelId={props.project.model_id}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/timesheet`} exact>
                  <TabPane tabId="timesheet">
                    <TimeSheetApproval
                      projectId={props.projectId}
                      projectName={props.project["project_name"]}
                      changeTab={props.changeTab}
                      currentTab={props.currentTab}
                      projectStDt={props.project["project_st_dt"]}
                      projectEndDt={props.project["project_end_dt"]}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/import`} exact>
                  <TabPane tabId="import">
                    <TaskImport
                      projectId={props.projectId}
                      modelId={props.project.model_id}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/report`} exact>
                  <TabPane tabId="report">
                    <WeeklyReport
                      projectId={props.projectId}
                      project={props.project}
                    />
                  </TabPane>
                </Route>

                <Route path={`${path}/ipp`} exact>
                  <TabPane tabId="ipp">
                    <IPPContainer
                      projectId={props.projectId}
                      project={props.project}
                    />
                  </TabPane>
                </Route>
                <Route path={`${path}/roadmap`} exact>
                  <TabPane tabId="roadmap">
                    <RoadmapContainer projectId ={props.projectId}/>
                  </TabPane>
                </Route>

                <Redirect from={`${path}`} to={`${path}/projectDetail`} exact />
              </Switch>
            </React.Suspense>
          </TabContent>
        </Container>
      </Fragment>
    )
  )
};

export default ProjectDetailPage;
