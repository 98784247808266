import React from "react";

import ProjectDetailPage from "components/ProjectDetailPage";
import { getPermissionsFor } from "../../actions/UtilFunctions";
import { getDetail } from "actions/ProjectFunctions";

export default class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentId: this.props.match.params.id,
      currentTab: "projectDetail",
      project: "",
      allowedPermissions: getPermissionsFor("ProjectDetail"),
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    getDetail(id).then((response) => {
      if (response) {
        this.setState({ project: response });
      }
    });
  }

  changeTab = (tabName) => {
    this.setState({
      currentTab: tabName,
    });
  };

  isActionAllowed = (action) => {
    const { allowedPermissions } = this.state;
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true;
    }
    return false;
  };

  render() {
    // const project = this.state.project
    // console.log(this.state.project)
    // const projectCode = project['project_code']
    // const projectName = project['project_name']
    return (
      <ProjectDetailPage
        currentTab={this.state.currentTab}
        changeTab={this.changeTab}
        projectId={this.state.currentId}
        projectName={this.state.project["project_name"]}
        projectCode={this.state.project["project_code"]}
        project={this.state.project}
        isActionAllowed={this.isActionAllowed}
      />
    );
  }
}
