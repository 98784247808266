import React from "react";
import { loginCall } from "../actions/UserFunctions";
import decoder from "jwt-decode";

import ValidateField from "../utils/ValidateField.js";
import "@fortawesome/fontawesome-free";
import { Redirect, Link } from "react-router-dom";
import Error from "../components/Toaster";
import _ from "lodash";
import "./Index.css";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardText,
} from "reactstrap";
import { getMenuByUser } from "actions/MenuFunctions";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: { email: "", password: "" },
      errors: { isValidForm: false },
      isPasswordVisible: false,
      loading: false,
      redirect: false,
      redirectUrl: "",

      errorMessage: "",
    };
  }

  toggleIcon = (e) => {
    this.setState({
      isPasswordVisible: this.state.isPasswordVisible ? false : true,
    });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let fields = this.state.fields;
    fields[name] = value;
    this.setState({ fields });
    this.setState({ [name]: value });
    let newState = JSON.parse(JSON.stringify(this.state.errors));
    newState.email = undefined;
    newState.password = undefined;
    newState.form = undefined;

    this.setState(() => ({
      errors: newState,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const error = ValidateField(this.state.fields);
    this.setState({ errors: error });
    this.redirectUrl = "/project";
    // console.log(error['isValidForm'])
    if (error["isValidForm"]) {
      const user = {
        email: this.state.fields.email,
        password: this.state.fields.password,
      };
      loginCall(user)
        .then((response) => {
          if (response === true) {
            this.setState({ redirect: true });
          }
          if (
            response &&
            response.response &&
            response.response.data &&
            (response.response.data.error || response.response.statusText) &&
            response.response.data.error.password === undefined
          ) {
            let errors = {};
            console.log(response.response.data.error.password);
            errors["form"] =
              response.response.data.error || response.response.statusText;
            this.setState({ errors, redirect: false });
          }
          if (
            response &&
            response.response &&
            response.response.data &&
            (response.response.data.error || response.response.statusText) &&
            response.response.data.error.password !== undefined
          ) {
            let errors = {};
            console.log(response.response.data.error.password);
            errors["form"] = response.response.data.error.password[0];
            this.setState({ errors, redirect: false });
          }
        })
        // .catch((error) => console.log(error))
        .catch((error) => {
          console.log(error);
          let errors = {};
          errors["form"] = error;
          this.setState({ errors, redirect: false });
        });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.getdata !== this.props.getdata) {
      if (this.props.getdata.res.success === false) {
        console.log("error", this.props.getdata.res);
        let errors = {};
        errors["form"] = `${this.props.getdata.res.error}`;
        errors["isValidForm"] = false;
        this.setState({ errors, redirect: false });
      }
    }
  }

  render() {
    let notify;
    if (this.props.location.state) {
      notify = this.props.location.state.value;
    }
    const { fields, errors, isPasswordVisible, loading, redirect, allMenus } =
      this.state;

    if (redirect === true) {
      return (
        <Redirect
          to={{
            pathname: this.redirectUrl,
          }}
        />
      );
    }
    return (
      <>
        <Col lg="5" md="5">
          {/* <Card className="bg-secondary shadow border-0"> */}
          <Card className="lisColor1 shadow border-0 pb-5">
            <CardTitle style={{ margin: "0" }}>
              <img
                className="w-50 mx-auto d-block mt-5 mb-4"
                alt="LIS Nepal Logo"
                src={require("assets2/img/vesatransparent.png")}
              />
            </CardTitle>
            <CardBody className="px-lg-5" style={{ paddingBottom: "0px" }}>
              {/* <div className="text-center text-muted mb-4">
                <big>Log In</big>
              </div> */}
              <Form role="form" noValidate onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="shadow" size="md">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        {/* <i className="ni ni-email-83" /> */}
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      label={"Email"}
                      name={"email"}
                      type={"text"}
                      value={fields["email"]}
                      onChange={this.handleUserInput}
                      // className={'normal'}
                      className="pl-2"
                      placeholder={"Email"}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="shadow" size="md">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i
                          className={
                            "fas fa-eye"
                            // this.isPasswordVisible
                            //   ? 'fas fa-eye'
                            //   : 'fas fa-eye-slash'
                          }
                          onClick={this.toggleIcon}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      label={"Password"}
                      name={"password"}
                      type={isPasswordVisible ? "text" : "password"}
                      value={fields["password"]}
                      onChange={this.handleUserInput}
                      className="pl-2"
                      placeholder={"Password"}
                    ></Input>
                  </InputGroup>
                </FormGroup>

                {/* <div className='custom-control custom-control-alternative custom-checkbox d-flex flex-row justify-content-between'>
                  <div>
                    <input
                      className='custom-control-input checkboxBorder'
                      id=' customCheckLogin'
                      type='checkbox'
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='customCheckLogin'
                    >
                      <span className='text-muted'>Remember me</span>
                    </label>
                  </div>
                  <div>
                    <span className='text-muted text-right'>
                      <small>Forgot password?</small>
                    </span>
                  </div>
                </div> */}

                <div className="d-flex justify-content-between align-items-center">
                  <p style={{ color: "#adb5bd", marginBottom: "0" }}>
                    {" "}
                    Forgot Password?{" "}
                    <Link
                      className="forgot-pass"
                      // style={{ color: '#4fa2b5', fontWeight: 'bold' }}
                      to="forgot"
                    >
                      Reset
                    </Link>
                  </p>
                  <Button className="my-4" color="primary" type="submit" style={{width: '120px'}}>
                    Log in
                  </Button>
                </div>
                <div className="error text-danger">
                  {this.state.errors.form && (
                    <Error
                      errorMessage={`${this.state.errors.form}`}
                      className={"Warning"}
                    />
                  )}
                </div>
              </Form>
            </CardBody>
            <div className="row">
              <img
                class="w-50 mx-auto d-block"
                alt="LIS Nepal Logo"
                src={require("assets2/img/LIS_Nepal_new_logo.png")}
              />
            </div>
          </Card>

          {/* <Row className="mt-3">
            <Col xs="6">
              <a
                // className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
        </Col>
      </>
    );
  }
}

export default Login;
