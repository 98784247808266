import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    "&$selected": {
      backgroundColor: '#4FA2B4',
    }
  },
  paper: {
    width: 200,
    height: 350,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

// this is set operation (a - b)
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

// this is set operation (a n b)
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferList = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  // update the permissions/menus whenever the props from higher component updates
  useEffect(() => {
    setLeft(props.unassigned)
  }, [props.unassigned]);
  useEffect(() => {
    setRight(props.assigned)
  }, [props.assigned]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  props.setSelected(right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    const updatedRight = right.concat(left);
    props.setSelected(updatedRight);
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    const updatedRight = right.concat(leftChecked);
    props.setSelected(updatedRight);
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const updateRight = not(right, rightChecked);
    props.setSelected(updateRight);
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    props.setSelected([]);
  };

  const customList = (items) => {
    if (!items) return;
    return (<Paper className={classes.paper} variant={'outlined'}>
      <List component="div" role="list" disablePadding={true} dense={true} >
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value['perm_id']} role="listitem" button onClick={handleToggle(value)}
              selected={checked.indexOf(value) !== -1}>
              <ListItemText id={labelId} primary={`${value[props.typeKey]}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
    );
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item className="text-center text-lisColor2">
        {props.titleLeft}
        {customList(left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item className="text-center text-lisColor2">
        {props.titleRight}
        {customList(right)}
      </Grid>
    </Grid>
  );
}

export default TransferList;