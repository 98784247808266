import React from "react";
import ClientInformation from "../../components/ClientInformation";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  fetchAllClient,
  createClient,
  deleteClient,
  updateClient,
  updateApproved,
  updateStatus,
  getDetail,
  getClientTypes,
} from "../../actions/ClientFunctions";
import { getPermissionsFor } from "../../actions/UtilFunctions";

import _ from "lodash";

const cellStyle = {
  paddingTop: "0px",
  paddingBottom: "0px",
};

export default class ClientInformationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // state for the form fields
      fields: {
        client_cd: "",
        client_name: "",
        client_abbr: "",
        address: "",
        telephone_no: "",
        fax_no: "",
        po_box: "",
        email: "",
        remarks: "",
        client_type_id: "",
      },
      // state for the list of tables
      clientListColumns: [
        { title: "Code", field: "client_cd", cellStyle: cellStyle },
        { title: "Name", field: "client_name", cellStyle: cellStyle },
        {
          title: "Client type",
          field: "client_type_abbr",
          cellStyle: cellStyle,
        },
        { title: "Status", field: "status_icon", cellStyle: cellStyle },
        { title: "Approved", field: "approved_icon", cellStyle: cellStyle },
      ],
      clientListData: [],
      // state for edit mode
      editMode: false,
      currentTab: "list",
      currentId: "", // id is valid only for clients being updated
      actionFeedbackMessage: "",
      isValid: {},

      allowedPermissions: getPermissionsFor("client"),
      isFetchComplete: false,

      clientTypes: [],
    };
  }

  componentDidMount() {
    // fetch all the clients as soon as the component mounts
    this.refreshClientsList();
    getClientTypes().then((response) => {
      this.setState({
        clientTypes: response,
      });
    });
  }

  refreshClientsList = () => {
    fetchAllClient().then((response) => {
      const listOfClients = response;
      const sortedListOfClients = _.sortBy(
        listOfClients,
        (obj) => obj.order_no
      );
      this.setState({
        clientListData: sortedListOfClients,
        isFetchComplete: true,
      });
    });
  };

  changeTab = (tabName) => {
    this.setState({
      currentTab: tabName,
    });
  };

  handleEdit = (rowData) => {
    const clientId = rowData.client_id;
    const currentState = { ...this.state.fields };
    for (const key of Object.keys(currentState)) {
      currentState[key] = rowData[key];
    }
    this.setState({
      fields: currentState,
      currentId: clientId,
      editMode: true,
      currentTab: "add",
    });
  };

  handleApproved = (rowData) => {
    const clientId = rowData.client_id;
    const isApproved = rowData["approved"];
    rowData["approved"] = isApproved === "Y" ? "N" : "Y";
    updateApproved(clientId, rowData).then((response) => {
      console.log(
        `Client successfully approved with client id: ${clientId} `,
        response
      );
      if (response) {
        this.refreshClientsList();
        this.showActionFeedback(response.message);
      }
    });
  };

  handleDelete = (rowData) => {
    // careful while updating the state;
    // create a new copy of original state &
    // always replace the original state
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const clientId = rowData.client_id;
            const clientListData = this.state.clientListData.filter(
              (client) => client["client_id"] !== clientId
            );
            this.setState({
              clientListData: clientListData,
            });
            deleteClient(clientId).then((response) => {
              console.log(
                `Client successfully deleted with client id: ${clientId} `,
                response
              );
              if (response) {
                this.showActionFeedback(response.message);
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  resetState = () => {
    this.setState({
      fields: {
        client_cd: "",
        client_name: "",
        client_abbr: "",
        address: "",
        telephone_no: "",
        fax_no: "",
        po_box: "",
        email: "",
        remarks: "",
        client_type_id: "",
      },
      editMode: false,
      isValid: {},
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Confirm Submission",
      message: "Are you sure you want to submit?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.validateFields();

            if (!this.isFormValid()) {
              this.showActionFeedback("Invalid or missing form data!");
              return;
            }

            if (this.state.editMode) {
              // we are in the edit mode
              updateClient(this.state.currentId, this.state.fields).then(
                (response) => {
                  this.refreshClientsList();
                  console.log(
                    `Success updating the client with id: ${this.state.currentId} `,
                    response
                  );
                  if (response) {
                    this.showActionFeedback(response.message);
                  }
                }
              );

              this.setState({
                currentId: "",
              });
            } else {
              createClient(this.state.fields).then((response) => {
                this.refreshClientsList();
                console.log("Success: ", response);
                if (response) {
                  this.showActionFeedback(response.message);
                  // TODO: if no response is received show the failed here
                }
              });
            }
            this.resetState();
            this.changeTab("list");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  // check if all the form value are valid
  isFormValid = () => {
    // TODO: ignore these fields when database table changes
    const notRequiredFields = ["fax_no", "po_box", "remarks"];
    //const notRequiredFields = []
    const { isValid, fields } = this.state;
    notRequiredFields.forEach((field) => delete fields[field]);
    for (const key in fields) {
      if (isValid[key] === false || isValid[key] === undefined) return false;
    }
    return true;
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let fields = this.state.fields;
    fields[name] = value;
    this.setState({ fields });
    this.setState({ [name]: value });
    this.validateFields();
  };

  validateEmail = (key, value) => {
    const { isValid } = this.state;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid[key] = false;
    if (value && value.match(emailRegex)) {
      isValid[key] = true;
    }
    this.setState({ isValid });
  };

  validateNonEmpty = (key, value) => {
    const { isValid } = this.state;
    if (
      value &&
      typeof value === "string" &&
      (value === "" || value.trim() === "")
    ) {
      isValid[key] = false;
    } else {
      isValid[key] = true;
    }
    this.setState({ isValid });
  };

  validatePhoneNumber = (key, value) => {
    const { isValid } = this.state;
    const phoneRegex = /^\d{7,15}$/;
    // const phoneRegex = /^\+?(?:[0-9] ?){6,14}[0-9]$/;
    // const phoneRegexNorthAmerican = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    isValid[key] = false;
    if (value && value.match(phoneRegex)) {
      isValid[key] = true;
    }
    this.setState({ isValid });
  };

  validateZipCode = (key, value) => {
    const { isValid } = this.state;
    const zipCodeRegex = /^\d{5}$/;
    isValid[key] = false;
    if (value && typeof value === "string" && value.match(zipCodeRegex)) {
      isValid[key] = true;
    }
    this.setState({ isValid });
  };

  validateFields = () => {
    for (const key of Object.keys(this.state.fields)) {
      const value = this.state.fields[key];
      switch (key) {
        case "email":
          this.validateEmail(key, value);
          break;

        case "telephone_no":
        case "fax_no":
          this.validatePhoneNumber(key, value);
          break;

        case "po_box":
          this.validateZipCode(key, value);
          break;

        default:
          this.validateNonEmpty(key, value);
          break;
      }
    }
  };

  handleBlur = (e) => {
    this.validateFields();
  };

  showActionFeedback = (message) => {
    this.setState({
      actionFeedbackMessage: message,
    });
    setTimeout(() => this.setState({ actionFeedbackMessage: "" }), 5000);
  };

  isActionAllowed = (action) => {
    const { allowedPermissions } = this.state;
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase()))
        return true;
    }
    return false;
  };

  // container fetches the data and passes it to the ClientInformation component
  // when the container loads do the data loading here
  render() {
    return (
      <ClientInformation
        fields={this.state.fields}
        handleUserInput={this.handleUserInput}
        handleSubmit={this.handleSubmit}
        clientListColumns={this.state.clientListColumns}
        clientListData={this.state.clientListData}
        handleDelete={this.handleDelete}
        handleEdit={this.handleEdit}
        editMode={this.state.editMode}
        currentTab={this.state.currentTab}
        currentId={this.state.currentId}
        changeTab={this.changeTab}
        resetState={this.resetState}
        message={this.state.actionFeedbackMessage}
        isValid={this.state.isValid}
        handleBlur={this.handleBlur}
        onApprovedIconClick={this.onApprovedIconClick}
        onStatusIconClick={this.onStatusIconClick}
        isActionAllowed={this.isActionAllowed}
        isFetchComplete={this.state.isFetchComplete}
        getClientTypeOptions={this.getClientTypeOptions}
      />
    );
  }

  getClientTypeOptions = () => {
    return (
      this.state.clientTypes &&
      this.state.clientTypes.map((clientType) => (
        <option
          key={`clientTypeId-${clientType.client_type_id}`}
          value={clientType.client_type_id}
        >
          {clientType.client_type_abbr}
        </option>
      ))
    );
  };

  onStatusIconClick = (e) => {
    const button = e.currentTarget;
    const client = button.getAttribute("data-client");
    const clientObj = JSON.parse(client);
    this.handleStatus(clientObj);
  };

  onApprovedIconClick = (e) => {
    const button = e.currentTarget;
    const client = button.getAttribute("data-client");
    const clientObj = JSON.parse(client);
    this.handleApproved(clientObj);
  };

  handleStatus = (rowData) => {
    const clientId = rowData.client_id;
    const status = rowData["status"];
    rowData["status"] = status === "A" ? "I" : "A";
    updateStatus(clientId, rowData).then((response) => {
      console.log(
        `Status successfully updated for client with client id: ${clientId} `,
        response
      );
      if (response) {
        this.refreshClientsList();
        this.showActionFeedback(response.message);
      }
    });
  };
}
