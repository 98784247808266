import React from "react";
import { Table } from "reactstrap";

export const EffortEstimationTable = ({ estimation }) => {
  let estimate = {};
  if (estimation) {
    estimate = [
      { name: "Total Resource", value: estimation.total_resource },
      {
        name: "Velocity per Resource",
        value:
          estimation.model_track === "S"
            ? estimation.capacity_points
            : estimation.capacity_hrs,
      },
      { name: "Total Working Days", value: estimation.work_days },
      { name: "Velocity per Sprint", value: estimation.velocity },
    ];
  }
  return (
    <Table size="sm" bordered>
      <thead className="lisColor1">
        <tr>
          <th colSpan={2} className="text-center" style={{ ...headerStyles }}>
            Estimation in{" "}
            {estimation && estimation.model_track === "S" ? "Story Points" : "Hours"}
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {estimation &&
          estimate.map((row, idx) => (
            <tr key={`estimate-report-${idx}`}>
              <td>{row.name}</td>
              <td className="text-right">{row.value}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export const ResourceHoursTable = ({ actual, billable, dayoff }) => {
  const data = {};
  actual &&
    actual.map((row) => {
      data[row.resource_id] = { ...row };
    });
  billable &&
    billable.map((row) => {
      data[row.resource_id] = { ...data[row.resource_id], ...row };
    });

  dayoff && dayoff.map((row) => {
    data[row.resource_id] = { ...data[row.resource_id], ...row }
  })
  var totalBillable = 0;
  var totalNonBillable = 0;
  var totalActual = 0;
  var totalDayoff = 0;
  return (
    <Table size="sm" bordered>
      <thead className="lisColor1">
        <tr>
          <th className="text-center" style={{ ...headerStyles, width: "5%" }}>
            #
          </th>
          <th style={{ ...headerStyles }}>Resource Name</th>
          <th className="text-center" style={{ ...headerStyles }}>
            Billable
          </th>
          <th className="text-center" style={{ ...headerStyles }}>
            Non-Billable
          </th>
          <th className="text-center" style={{ ...headerStyles }}>
            Actual
          </th>
          <th className="text-center" style={{ ...headerStyles }}>
            Leave
          </th>
          <th className="text-center" style={{ ...headerStyles }}>
            Total
          </th>
          <th className="text-left"  style={{ ...headerStyles }}>Comments</th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {Object.keys(data).map((key, idx) => {
          const row = data[key];
          const billable = row.billable || 0;
          const actual = row.actual || 0;
          const nonbillable = actual - billable;
          totalBillable += Number(billable) || 0;
          totalNonBillable += Number(nonbillable) || 0;
          totalActual += Number(actual) || 0;
          totalDayoff += Number(row.dayoff) || 0;
          return (
            <tr key={`week-resource-stat-${idx}`}>
              <td className='text-center' style={{ width: '5%' }}>
                {idx + 1}
              </td>
              <td>{`${row?.first_name} ${row?.last_name}`}</td>
              <td className='text-center'>{billable}</td>
              <td className='text-center'>
                {nonbillable < 0
                  ? '(' + Math.abs(nonbillable) + ')'
                  : nonbillable}
              </td>
              <td className='text-center'>{actual}</td>
              <td className='text-center'>{row.dayoff}</td>
              <td className='text-center'>
                {Number(actual) + Number(row?.dayoff) || 0}
              </td>
              <td className='text-left' style={{ whiteSpace: 'break-spaces' }}>
                {row?.remarks?.slice(0, row.remarks.length - 2)}
              </td>
            </tr>
          )
        })}
        <tr key={`grand-total`}>
          <td></td>
          <td className="text-left font-weight-bold">Total</td>
          <td className="text-center font-weight-bold">{totalBillable}</td>
          <td className="text-center font-weight-bold">
            {totalNonBillable < 0
              ? "(" + Math.abs(totalNonBillable) + ")"
              : totalNonBillable}
          </td>
          <td className="text-center font-weight-bold">{totalActual}</td>
          <td className="text-center font-weight-bold">{totalDayoff}</td>
          <td className="text-center font-weight-bold">{totalActual + totalDayoff}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const headerStyles = {
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
};

export const StatusTable = ({ type, data }) => {
  let filteredData =
    data &&
    data.filter((row) => {
      if (type === "accomplishment" && row.status_type === "A") {
        return true;
      }
      if (type === "plan" && row.status_type === "N") {
        return true;
      }
      if (type === "news" && row.status_type === "H") {
        return true;
      }
    });
  return (
    <Table size="sm" bordered>
      <thead className="lisColor1">
        <tr>
          <th colSpan={2} style={{ ...headerStyles }}>
            {type === "plan"
              ? "Plans Next Week"
              : type === "news"
              ? "News/Announcements/Highlights"
              : "Accomplishments"}
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {filteredData &&
          filteredData.map((row, idx) => {
            return (
              <tr key={`week-${type}-status-${idx}`}>
                <td style={{ width: "5%", textAlign: "right" }}>{idx + 1}</td>
                <td style={{ whiteSpace: "break-spaces" }}>{row?.remarks}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export const RaidTable = ({ type, data }) => {
  let filteredData =
    data &&
    data.filter((row) => {
      if (type === "risk" && row.raid_type === "R") {
        return true;
      }
      if (type === "issue" && row.raid_type === "I") {
        return true;
      }
    });

  return (
    <Table size='sm' bordered>
      <thead className='lisColor1'>
        <tr>
          <th colSpan={2} style={headerStyles}>
            {type === 'risk' ? 'Risks' : 'Issues'}
          </th>
          <th style={{ ...headerStyles }}>Severity</th>
          <th style={{ ...headerStyles }}>Phase</th>
          <th style={{ ...headerStyles }}>Status</th>
          <th style={{ ...headerStyles }}>Owner</th>
          <th style={{ ...headerStyles }}>
            {type === 'risk' ? 'Prevention Plan' : 'Resolution Plan'}
          </th>
          <th style={{ ...headerStyles }}>Created Date</th>
          <th style={{ ...headerStyles, width: '10px'}}>No. of Days</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {filteredData &&
          filteredData.map((row, idx) => {
            return (
              <tr key={`week-${type}-raid-${idx}`}>
                <td style={{ textAlign: 'right' }}>{idx + 1}</td>
                <td
                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                >
                  {row.remarks}
                </td>
                <td
                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                >
                  {row.priority_name}
                </td>
                <td
                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                >
                  {row.phases_name}
                </td>
                <td
                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                >
                  {row.status === 'A' ? 'Open' : 'Closed'}
                </td>
                <td
                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                >
                  {row.raid_owner}
                </td>
                <td
                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                >
                  {row.con_miti_plan}
                </td>
                <td
                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                >
                  {row.created_date}
                </td>
                <td
                  style={{ textAlign: 'right'}}
                >
                  {row.number_of_days}
                </td>
              </tr>
            )
          })}
      </tbody>
    </Table>
  )
};
