import { getAllSprintRaids } from 'actions/SprintStatusRaidFunctions'
import RaidDetail from 'components/SprintWiseRAID/RaidDetail'
import MaterialTable from 'material-table'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap"

const RisksAndIssues = ({riskSlide, issueSlide}) => {
 const [risks,setRisksList] = useState([])
 const [issues,setIssuesList] = useState([])
  const [modal, setModal] = useState(false) 
  const [modalRowData, setModalRowData] = useState()
  const [modalTitle, setModalTitle] = useState()
  const handleClick = (rowData) => {
    setModalRowData(rowData)
    setModalTitle(rowData.project_name)
    setModal(true)
  }
  const toggle = () => {
    setModal(!modal)
  }
  useEffect(() => {
    getAllSprintRaids().then((response) => {
      if (response) {
        let risks = response.filter((raid) => raid.raid_type === 'R' && raid.status==='Open' )
        let issues = response.filter((raid) => raid.raid_type === 'I' && raid.status === 'Open' )
        setRisksList(risks)
        setIssuesList(issues)
      }
    })
  }, [])
  const cellStyle = {
    padding: '8px',
    whiteSpace: 'nowarp'
  }
  const columns = [
    {
      title: 'Stream',
      field: 'stream_name',
      // cellStyle: { width: '10%' },
      // width: '10%',
      // headerStyle: { width: '10%' },
    },
    {
      title: 'Project',
      field: 'project_name',
      // cellStyle: cellStyle,
      // cellStyle: { width: '20%' },
    },
    {
      title: 'Created Date',
      field: 'created_dt',
      cellStyle: {
        width: '200px'
      }
    },
    {
      title: 'Description',
      field: 'remarks',
      cellStyle: {
        width: '500px'
      }
    },
    { title: 'Severity', field: 'priority' },
    { title: 'Owner', field: 'raid_owner' },
    { title: 'Tenure', field: 'number_of_days', render: rowData => <p>{`${rowData.number_of_days} days`}</p> },

  ]
  return (
    <div>
      <div ref={riskSlide}>
        <div className="row d-flex justify-content-center">
          <h2>Risks</h2>
        </div>
        <MaterialTable
          data={risks}
          columns={columns}
          onRowClick={(e, rowData) => handleClick(rowData)}
          options={{
            toolbar: false,
            paging: false,
            headerStyle: {
              color: "#00948B",
              backgroundColor: "#e1ebf0",
              filtering: true,
              fontSize: "14px",
              fontWeight: "bold",
              borderCollapse: "collapse",
            },
            rowStyle: (rowData) => ({
              backgroundColor: rowData.number_of_days >= 15 ? "#e02d39" : "",
            }),
          }}
        />
      </div>
      <div ref={issueSlide} className="mt-4">
        <div className="row d-flex justify-content-center">
          <h2>Issues</h2>
        </div>
        <MaterialTable
          data={issues}
          columns={columns}
          onRowClick={(e, rowData) => handleClick(rowData)}
          options={{
            toolbar: false,
            paging: false,
            headerStyle: {
              color: "#00948B",
              backgroundColor: "#e1ebf0",
              fontSize: "14px",
              fontWeight: "bold",
              borderCollapse: "collapse",
            },
            search: false,
            emptyRowsWhenPaging: false,
            padding: "dense",
            rowStyle: (rowData) => ({
              backgroundColor: rowData.number_of_days >= 15 ? "#e02d39" : "",
            }),
          }}
        />
      </div>
      <Modal size="lg" style={{ width: "100%" }} isOpen={modal} centered>
        <ModalHeader toggle={toggle}>{`${modalTitle}`}</ModalHeader>
        <ModalBody>
          <RaidDetail
            title="Raid Details"
            raid_id={modalRowData?.raid_id}
            isViewMode={true}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default RisksAndIssues
