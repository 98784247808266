import React from "react";
import { emailCall, loginCall } from "../actions/UserFunctions";
import decoder from "jwt-decode";

import ValidateEmail from "../utils/ValidateEmail.js";
import "@fortawesome/fontawesome-free";
import { Redirect, Link } from "react-router-dom";
import Error from "../components/Toaster";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import { theme } from "../utils/Theme";
import { MuiThemeProvider } from "@material-ui/core/styles";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardText,
} from "reactstrap";
import { getMenuByUser } from "actions/MenuFunctions";
import { changePassword } from "utils/ResetPassword";

class Reset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // fields: { email: '', password: '' },
      email: null,
      password: "",
      confirmPassword: "",
      errors: { isValidForm: false },
      isPasswordVisible: false,
      loading: false,
      redirect: false,
      redirectUrl: "",
      resetToken: null,
      errorMessage: "",
      successMessage: "",
      success: false,
    };
  }

  toggleIcon = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    console.log("target name", name);

    const value = e.target.value;

    if (name === "password") {
      this.setState({ password: value });
    }

    if (name === "confirmPassword") {
      this.setState({ confirmPassword: value });
    }

    // this.setState({ [name]: value })
    // let newState = JSON.parse(JSON.stringify(this.state.errors))
    // newState.email = undefined
    // newState.form = undefined

    // this.setState(() => ({
    //   errors: newState,
    // }))
  };

  useQuery() {
    return new URLSearchParams(this.props.location.search);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.password.length < 8) {
      return this.setState({
        errorMessage: "Passwords must have minimum length 8",
      });
    }

    if (this.state.password !== this.state.confirmPassword) {
      return this.setState({ errorMessage: "Passwords do not match!" });
    }

    this.setState({ errorMessage: "", loading: true });
    changePassword(
      this.state.email,
      this.state.resetToken,
      this.state.password,
      this.state.confirmPassword
    )
      .then((response) => {
        this.setState({ loading: false });
        if ([200, 201].includes(response.status)) {
          this.setState({ success: true });
          this.setState({
            successMessage: response.data.data,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: "Password Reset Failed",
            successMessage: "",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    // alert('log', queryString.parse(window.location.search))
    let query = this.useQuery();

    const token = query.get("token");
    const email = query.get("email");

    if (token === null || email === null) {
      return this.setState({ redirect: true });
    }
    this.setState({ resetToken: token });
    this.setState({ email: email });
  }

  render() {
    const { fields, errors, isPasswordVisible, loading, redirect, allMenus } =
      this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectUrl,
          }}
        />
      );
    }
    return (
      <>
        <Col lg="5" md="5">
          {/* <Card className="bg-secondary shadow border-0"> */}
          <Card className="lisColor1 shadow border-0">
            <CardTitle>
              <img
                class="w-50 mx-auto d-block mt-5"
                alt="LIS Nepal Logo"
                src={require("assets2/img/LIS_LOGO.png")}
              />
            </CardTitle>
            <CardBody className="px-lg-5 py-lg-4">
              <div className="text-center text-muted mb-4">
                <big>Reset Password</big>
              </div>
              {!this.state.success && (
                <Form role="form" noValidate onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <InputGroup className="shadow" size="md">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i
                            className={
                              this.isPasswordVisible
                                ? "fas fa-eye"
                                : "fas fa-eye-slash"
                            }
                            onClick={this.toggleIcon}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        label={"Password"}
                        name={"password"}
                        type={isPasswordVisible ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handleUserInput}
                        className="pl-2"
                        placeholder={"Enter New Password"}
                      ></Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="shadow" size="md">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i
                            className={
                              this.isPasswordVisible
                                ? "fas fa-eye"
                                : "fas fa-eye-slash"
                            }
                            onClick={this.toggleIcon}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        label={"confirmPassword"}
                        name={"confirmPassword"}
                        type={isPasswordVisible ? "text" : "password"}
                        value={this.state.confirmPassword}
                        onChange={this.handleUserInput}
                        className="pl-2"
                        placeholder={"Confirm New Password"}
                      ></Input>
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      disabled={loading}
                      type="submit"
                    >
                      Set New Password
                    </Button>
                  </div>

                  <div className="error text-center text-danger">
                    {this.state.errorMessage && (
                      <Error
                        errorMessage={`${this.state.errorMessage}`}
                        className={"Warning"}
                      />
                    )}
                  </div>
                </Form>
              )}
              {this.state.success && (
                <>
                  <div className="text-center">
                    <Link className="my-4 btn btn-primary" to="/auth/login">
                      Go to Login
                    </Link>
                  </div>
                  <div className="success text-center text-success">
                    {this.state.successMessage && (
                      <div>
                        <p>{this.state.successMessage}</p>
                      </div>
                    )}
                  </div>
                </>
              )}
              {loading && (
                <div className="d-flex justify-content-center ">
                  <MuiThemeProvider theme={theme}>
                    <CircularProgress color="secondary" />
                  </MuiThemeProvider>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Reset;
