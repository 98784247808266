import { theme } from 'utils/Theme'
import {IconButton, MuiThemeProvider} from "@material-ui/core"
import React from "react"
import _ from 'lodash'
import MaterialTable from 'material-table'
import Loading from 'components/Loading'
import { Button } from 'reactstrap'
import Tooltip from "@material-ui/core/Tooltip"
import {Cancel, CheckCircle} from "@material-ui/icons"
import UserDetailList from "../../components/UserDetailList"

const UserTable = ({
    userTableList,
    employeeMap,
    handleEdit,
    handleDelete,
    isLoading,
    isActionAllowed,
    changeTab,
    onStatusIconClick,
    onApprovedIconClick
}) => {

    const cellStyle = {
        paddingTop: '0px',
        paddingBottom: '0px',
    }

    let userList = _.cloneDeep(userTableList)

    userList = userList.map((row) => {
        const rowString = JSON.stringify(row)

        row['status_icon'] = (
            <IconButton
                onClick={(e) => onStatusIconClick(e)}
                data-user={rowString}
                disabled={!isActionAllowed('status')}
            >
                {row['status'] === 'Inactive' ? (
                    <Tooltip title="Inactive">
                        <Cancel style={{ color: 'red' }} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Active">
                        <CheckCircle style={{ color: 'green' }} />
                    </Tooltip>
                )}
            </IconButton>
        )

        row['approved_icon'] = (
            <IconButton
                onClick={(e) => onApprovedIconClick(e)}
                data-user={rowString}
                disabled={!isActionAllowed('status')}
            >
                {row['approved'] === 'No' ? (
                    <Tooltip title="Unapproved">
                        <Cancel style={{ color: 'red' }} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Approved">
                        <CheckCircle style={{ color: 'green' }} />
                    </Tooltip>
                )}
            </IconButton>
        )

        const employee = employeeMap[row['employee_id']]
        if (employee) {
            row['email'] = employee['email']
        }

        return row
    })

    const Title = () => {
        return (
            <>
                <div style={{ width: '500px' }}>
                    <Button
                        type="button"
                        title="Add new user"
                        color="primary"
                        style={{
                            margin: '0px 10px 5px 0px',
                            borderRadius: '50%',
                            padding: '0px',
                            width: '30px',
                            height: '30px',
                            float: 'left',
                        }}
                        onClick={() => {
                            changeTab('add');
                        }}
                        hidden={!isActionAllowed('add')}
                    >
                        <i className="fas fa-plus fa-sm"/>
                    </Button>

                    <h2 style={{ color: '#00948B' }}>User List</h2>
                </div>
            </>
        )
    }

    const columns = [
        { title: 'Name', field: 'employee_name', cellStyle: cellStyle},
        { title: 'Email', field: 'email', cellStyle: cellStyle},
        { title: 'Role', field: 'role_name', cellStyle: cellStyle},
        { title: 'Status', field: 'status_icon', cellStyle: cellStyle},
        { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle},
    ]

    const materialTableOptions = {
        headerStyle: {
            color: '#00948B',
            fontWeight: 'bold',
            paddingTop: '0px',
            paddingBottom: '5px',
        },
        rowStyle: {
            hover: true,
        },
        actionsColumnIndex: -1,
        detailPanelColumnAlignment: 'right',
    }

    return <MuiThemeProvider theme={theme}>
        <MaterialTable
            components={{
                OverlayLoading: (props) => <Loading />
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: !isLoading ? 'No records to display!' : ''
                }
            }}
            isLoading={isLoading}
            title={<Title />}
            columns={columns}
            data={userList}
            actions={[
                {
                    icon: 'edit',
                    tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
                    disabled: !isActionAllowed('edit'),
                    onClick: (event, rowData) => handleEdit(rowData)
                },
                {
                    icon: 'delete',
                    tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
                    disabled: !isActionAllowed('delete'),
                    onClick: (event, rowData) => handleDelete(rowData)
                }
            ]}
            options={materialTableOptions}
            detailPanel={[
                {
                    icon: 'toc',
                    tooltip: 'Details',
                    disabled: !isActionAllowed('detail'),
                    render: (rowData) => {
                        const userId = rowData['usr_id']
                        return <UserDetailList userId={userId} />
                    }
                }
            ]}
        />
    </MuiThemeProvider>
}

export default UserTable