import React, { createContext, useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios';

import * as serviceWorker from "./serviceWorker";

import "assets2/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets2/scss/argon-dashboard-react.scss";
import "index.css";
import "./App.css";

// import App from './App'
import UserLayout from "./layouts/User";
import AuthLayout from "./layouts/Auth";
import AuthManager from "./utils/AuthManager";
import { getUserToken } from "actions/UtilFunctions";

axios.defaults.baseURL = 'https://vesa-api.lis.com.np';
const token = getUserToken();
if(token && token!== 'undefined') {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000,
    },
  },
})

const PrivateRoute = ({ children, ...rest }) => {
  return AuthManager.isAuthenticated() ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect
      to={{
        pathname: "/auth/login",
      }}
    />
  );
};

const AuthRoute = ({ ...rest }) => {
  return AuthManager.isAuthenticated() ? (
    <Redirect
      to={{
        pathname: "/project",
      }}
    />
  ) : (
    <Route {...rest} />
  );
};

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
  <BrowserRouter>
    <Switch>
      <AuthRoute path="/auth" render={(props) => <AuthLayout {...props} />} />

      <PrivateRoute
        path="/user"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/project"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/stream"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/client"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/menu"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/efforttype"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/phases"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/permissions"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/roles"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/userGroups"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/taskstatus"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/tasktype"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/dayofftype"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/priority"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/model"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/timesheet"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/healthCalculation"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/clientType"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/employee"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/resourceHistory"
        render={(props) => <UserLayout {...props} />}
      />
      <PrivateRoute
        path="/healthDetail"
        render={(props) => <UserLayout {...props} />}
      />

      <Redirect from="/" to="/auth/login" />
    </Switch>
  </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
