import MaterialTable from "material-table";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const RAIDTable = ({ data, tabID }) => {
  const cellStyle = {
    padding: "8px",
  };
  const columns = [
    { title: 'Stream', field: 'stream_name', cellStyle: cellStyle },
    { title: 'Project', field: 'project_name', cellStyle: cellStyle },
    { title: 'Sprint', field: 'sprint_st_dt', cellStyle: cellStyle },
    {
      title: 'Description',
      field: 'remarks',
      cellStyle: cellStyle,
      render: (rowData) => {
        return (
          <div
            style={{
              maxWidth: 280,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <span id={`tooltip-${rowData.raid_id}`} style={{}}>
              {rowData.remarks}
            </span>
            <UncontrolledTooltip
              target={`tooltip-${rowData.raid_id}`}
              placement='bottom-start'
              style={{ minWidth: '240px' }}
            >
              {rowData.remarks}
            </UncontrolledTooltip>
          </div>
        )
      },
    },
    { title: 'Severity', field: 'priority', cellStyle: cellStyle },
    { title: 'Status', field: 'status', cellStyle: cellStyle },
    { title: 'Owner', field: 'raid_owner', cellStyle: cellStyle },
    {
      title: 'Mitigation Plan',
      field: 'con_miti_plan',
      cellStyle: cellStyle,
      render: (rowData) => {
        return (
          <div
            style={{
              maxWidth: 280,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <span id={`tooltip-m-${rowData.raid_id}`}>
              {rowData.con_miti_plan}
            </span>
            <UncontrolledTooltip
              target={`tooltip-m-${rowData.raid_id}`}
              placement='bottom-start'
              style={{ minWidth: '240px' }}
            >
              {rowData.con_miti_plan}
            </UncontrolledTooltip>
          </div>
        )
      },
    },
    {
      title: 'Created Date',
      field: 'created_dt',
      cellStyle: { padding: '8px', textAlign: 'center' },
    },
    {
      title: 'Number of Days',
      field: 'no_of_days',
      cellStyle: { padding: '8px', textAlign: 'center' },
      render: (rowData) => {
        let date = new Date(`${rowData.created_dt}`)
        let currDate = new Date()
        var diffInTime = currDate.getTime() - date.getTime()
        var diffInDays = Math.round(diffInTime / (1000 * 3600 * 24))
        return diffInDays
      },
    },
    { title: 'Closed Date', field: 'closed_dt', cellStyle: cellStyle },
    // { title: 'Date Difference', field: 'diff', cellStyle: cellStyle },
  ]

  return (
    <MaterialTable
      columns={columns}
      data={data}
      options={{
        toolbar: false,
        headerStyle: {
          color: "#00948B",
          backgroundColor: "#e1ebf0",
          filtering: true,
          fontSize: "14px",
          fontWeight: "bold",
          borderCollapse: "collapse",
        },
        search: false,
        pageSize: 10,
        padding: "dense",
      }}
    />
  );
};

export default RAIDTable;
