import { getDashboardProjectRisksIssues } from "actions/DashboardFunctions";
import { RaidTable } from "components/WeeklyReport/ReportTables";
import React from "react";
import { useEffect, useState } from "react";
import NestedToggleButton from "../../../components/ProjectHealth/NestedToggleButton";
import axios from "axios";

function RisksAndIssues(props) {
  const [risksIssuesData, setRisksIssuesData] = useState([]);
  const cancelToken = axios.CancelToken.source();
  useEffect(() => {
    let isMounted = true;
    getDashboardProjectRisksIssues(
      props.projectId,
      props.sprintId,
      cancelToken.token
    ).then((res) => {
      if (isMounted) {
        setRisksIssuesData(res.data);
      }
    });

    return () => {
      isMounted = false;
      cancelToken.cancel();
    };
  }, [props.projectId, props.calcDate]);

  return (
    <div>
      <div className="row">
        {risksIssuesData && (
          <>
            <div className="col-12">
              <RaidTable data={risksIssuesData} type="risk" />
            </div>
            <div className="col-12">
              <RaidTable data={risksIssuesData} type="issue" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default RisksAndIssues;
