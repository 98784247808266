import Axios from 'axios';
import axios from 'axios';
import { getUserId, getUserToken } from './UtilFunctions';

export const listTaskstatus = (cancelToken) => {
  const token = getUserToken();
  return axios
    .get('/taskstatus/listTaskstatus', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const activeTaskstatus = () => {
  const token = getUserToken();
  return axios
    .get('/taskstatus/activeTaskstatus', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createTaskstatus = (fields) => {
  const token = getUserToken();
  const id = getUserId();
  fields['created_by'] = id;
  fields['created_dt'] = new Date().toISOString();
  return axios
    .post('/taskstatus/createTaskstatus', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteTaskstatus = (taskstatusId) => {
  const token = getUserToken();
  return axios
    .delete(`/taskstatus/deleteTaskstatus/${taskstatusId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while deleting effort type with id ${taskstatusId}`, error);
    });
};

export const updateTaskstatus = (taskstatusId, rowData) => {
  const token = getUserToken();
  const id = getUserId();
  rowData['updated_by'] = id;
  rowData['updated_dt'] = new Date().toISOString();
  return axios
    .put(`/taskstatus/updateTaskstatus/${taskstatusId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating Effort type): ', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while updating Effort type with id ${taskstatusId}`, error);
    });
};

export const updateApproved = (taskstatusId, rowData) => {
  const id = getUserId();
  const token = getUserToken();
  rowData['approved_by'] = id;
  rowData['approved_dt'] = new Date().toISOString();
  return axios
    .put(`/taskstatus/updateApproved/${taskstatusId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating approved status of Effort type): ', response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating Effort type's approved status with id ${taskstatusId}`,
        error,
      );
    });
};

export const updateStatus = (taskstatusId, rowData) => {
  const id = getUserId();
  const token = getUserToken();
  rowData['updated_by'] = id;
  rowData['updated_dt'] = new Date().toISOString();
  return axios
    .put(`/taskstatus/updateStatus/${taskstatusId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating status field of Effort type): ', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while updating Effort type's status with Id ${taskstatusId}`, error);
    });
};

export const getDetail = (taskstatusId) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/taskstatus/getDetail/${taskstatusId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting detail of Effort type): ', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting Effort type's detail with Id ${taskstatusId}`, error);
    });
};

export const listProjectTaskstatus = (projectId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/taskstatus/listProjectTaskstatus/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server (Fetch Project Task Status)', response);
      return response.data;
    })
    .catch((error) => {
      if (Axios.isCancel(error)){
        console.log("Cancelling request for Fetch project task status");
      } else {
        console.log(error);
      }
    });
};

export const listMultipleProjectTaskstatus = (projectIds, cancelToken) => {
  const token = getUserToken();
  return axios
    .post(`/taskstatus/listMultipleProjectTaskstatus`, projectIds, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateProjectTaskstatusMapping = (projectId, request, cancelToken) => {
  const token = getUserToken();
  return axios
    .post(`/taskstatus/updateProjectTaskstatusMapping/${projectId}`, request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
