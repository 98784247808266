import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const getActiveEmployees = (cancelToken) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/employee/activeEmployee`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Response from server(Getting Name of employee): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting active employee names`, error);
    });
};

export const getUnregisteredEmployees = () => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/employee/unregisteredEmployee`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        `Response from server(Getting a list of unregistered employees): `,
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while fetching the list of unregistered employees`,
        error
      );
    });
};

export const viewEmployee = (empId) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/employee/viewEmployee/${empId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting Name of employee): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting active employee names`, error);
    });
};

export const getRegisteredEmployees = (cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/employee/registered`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get registered employees ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get registered employees `, error);
    });
};

export const createEmployee = (employee) => {
  const token = getUserToken();
  const id = getUserId();
  employee["created_by"] = id;
  employee["created_dt"] = new Date().toISOString();
  employee["approved"] = "Y";
  employee["status"] = "A";
  return axios
    .post(`/employee/createEmployee`, employee, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(`Response from server(Creating a user): `, response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while creating user`, employee);
    });
};

export const fetchGenders = () => {
  return axios
    .get(`/employee/fetchGenders`)
    .then((response) => {
      console.log(`Response from server(fetching genders): `, response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while fetching genders`, error);
    });
};
