import React from "react"
import _ from "lodash"
import * as Yup from 'yup'
import {useFormik} from "formik"
import {Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap"
import {Spinner} from "react-bootstrap"

const StreamInfoForm = ({
  initValues,
  handleSubmit,
  isLoading,
  changeTab,
  resetForm
}) => {

  const streamInfo = {
    stream_cd: '',
    stream_name: '',
    stream_abbr: '',
  }
  const initialValues = _.isEmpty(initValues) ? streamInfo : initValues

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      stream_cd: Yup.string().required('Stream code is required'),
      stream_name: Yup.string().required('Stream name is required'),
      stream_abbr: Yup.string().required('Stream abbreviation is required'),
    }),
    onSubmit: async values => await handleSubmit(values)
  })

  return <Card className="border-top-0 shadow-none">
    <CardHeader style={{
      fontSize: 20,
      color: '#fff',
      textAlign: 'center',
      padding: 10,
    }}>
      Stream Information
    </CardHeader>

    <CardBody>
      {isLoading ?
        <Spinner animation="border"/> :
        <Form onSubmit={formik.handleSubmit}>

          <FormGroup row>
            <Col sm={2}>
              <Label for="streamCode">Code</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                name="stream_cd"
                id="streamCode"
                value={formik.values?.stream_cd}
                bsSize="sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched?.stream_cd &&
                  formik.errors.stream_cd
                }
              />
              <FormFeedback invalid>
                {formik.errors?.stream_cd}
              </FormFeedback>
            </Col>
          </FormGroup>


          <FormGroup row>
            <Col sm={2}>
              <Label for="streamName">Name</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                name="stream_name"
                id="streamName"
                value={formik.values?.stream_name}
                bsSize="sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched?.stream_name &&
                  formik.errors.stream_name
                }
              />
              <FormFeedback invalid>
                {formik.errors?.stream_name}
              </FormFeedback>
            </Col>
          </FormGroup>


          <FormGroup row>
            <Col sm={2}>
              <Label for="streamAbbr">Abbreviation</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                name="stream_abbr"
                id="streamAbbr"
                value={formik.values?.stream_abbr}
                bsSize="sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched?.stream_abbr &&
                  formik.errors.stream_abbr
                }
              />
              <FormFeedback invalid>
                {formik.errors?.stream_abbr}
              </FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col className="text-right" xs={{offset: 4}}>
              <Button
                type="submit"
                color="primary"
                className="fixedWidthButton"
                >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                className="fixedWidthButton"
                color="danger"
                onClick={() => {
                  resetForm()
                  changeTab('list')
                }}
                >
                Cancel
              </Button>
            </Col>
          </FormGroup>

        </Form>
      }
    </CardBody>
  </Card>
}

export default StreamInfoForm