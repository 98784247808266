import React, {useEffect, useState} from "react"
import {Container, TabContent, TabPane} from "reactstrap"
import PermissionTable from "./PermissionTable"
import PermissionForm from "./PermissionForm"
import {getPermissionsFor} from "../../actions/UtilFunctions"
import {
  createPermission, deletePermission, getDetail,
  listPermissions,
  updateApproved,
  updatePermission,
  updateStatus
} from "../../actions/PermissionFunctions"
import ActionFeedback from "../../components/ActionFeedbackAlert"
import {confirmAlert} from "react-confirm-alert"

const PermissionContainer = () => {

  const fields = {
    perm_name: ''
  }
  const [initialValues, setInitialValues] = useState({})
  const [permissionList, setPermissionList] = useState([])

  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [activeTab, setActiveTab] = useState('list')
  const [actionFeedbackMsg, setActionFeedbackMsg] = useState('')

  const [allowedPermissions] = useState(getPermissionsFor('permission'))

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        await refreshProject()
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }) ()
  }, [])

  const refreshProject = async () => {
    let permissions = await listPermissions()
    if (permissions) {
      setPermissionList(permissions)
    }
  }

  const changeTab = tabName => setActiveTab(tabName)

  const populateFormData = async rowData => {
    const permId = rowData.perm_id
    try {
      if (permId > 0) {
        setLoading(true)
        const detail = (await getDetail(permId))[0]
        setInitialValues(detail)
      }
    } catch (error) {
      console.error(false)
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = async rowData => {
    setEditMode(true)
    setActiveTab('add')
    await populateFormData(rowData)
  }

  const handleDelete = rowData => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const permId = rowData.perm_id
            const permListData = permissionList.filter(perm => perm['perm_id'] !== permId)
            setPermissionList(permListData)
            deletePermission(permId).then(response => {
              console.log(
                `Permission successfully deleted with permission id: ${permId} `,
                response,
              )
              if (response) {
                showActionFeedback(response.message)
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const handleStatus = rowData => {
    const permId = rowData.perm_id
    const status = rowData['status']
    rowData['status'] = status === 'A' ? 'I' : 'A'
    updateStatus(permId, rowData).then((response) => {
      console.log(
        `Status successfully updated for permission with perm id: ${permId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })
  }

  const handleApproved = rowData => {
    const permId = rowData.perm_id
    const isApproved = rowData['approved']
    rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
    updateApproved(permId, rowData).then((response) => {
      console.log(
        `Permission successfully approved with permId: ${permId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })

  }

  const onStatusIconChange = e => {
    e.preventDefault()
    const permData = e.currentTarget.getAttribute('data-config')
    handleStatus(JSON.parse(permData))
  }

  const onApprovedIconChange = e => {
    e.preventDefault()
    const permData = e.currentTarget.getAttribute('data-config')
    handleApproved(JSON.parse(permData))
  }

  const handleSubmit = values => {
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let response

            if (editMode) {
              if (values?.perm_id && values?.perm_id > 0) {
                for (const key of Object.keys(fields)) {
                  fields[key] = values[key]
                }
                response = await updatePermission(values.perm_id, fields)
              }
            } else {
              response = await createPermission(values)
            }

            if (response) {
              showActionFeedback(response.message)
              changeTab('list')
              refreshProject()
            }
          }
        },

        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const isActionAllowed = action => {
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true
    }
    return false
  }

  const showActionFeedback = message => {
    setActionFeedbackMsg(message)
    setTimeout(() => setActionFeedbackMsg(''), 5000)
  }

  const resetForm = () => {
    setInitialValues({})
    setEditMode(false)
  }

  return <div className="ipp-page">
    {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}
    <Container className="mt-3" fluid style={{maxWidth: '100%', margin: 'auto'}}>
      <TabContent activeTab={activeTab}>

        <TabPane tabId="list">
          <PermissionTable
            permissionListData={permissionList}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onStatusIconClick={onStatusIconChange}
            onApprovedIconClick={onApprovedIconChange}
            isLoading={loading}
            isActionAllowed={isActionAllowed}
            changeTab={changeTab}
          />
        </TabPane>

        <TabPane tabId="add">
          <PermissionForm
            initValues={initialValues}
            handleSubmit={handleSubmit}
            isLoading={loading}
            changeTab={changeTab}
            resetForm={resetForm}
          />
        </TabPane>

      </TabContent>
    </Container>
  </div>
}

export default PermissionContainer