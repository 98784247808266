import React from 'react'

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap'

class Header extends React.Component {
  render() {
    return (
      <>
        {/* <div className="header bg-gradient-info pb-12 pt-5 pt-md-12"></div> */}
        {/* <div className="header border-bottom border-light pb-12 pt-5 pt-md-12"></div> */}
        {/* <div className="header pt-5 pt-md-12 border-bottom"></div> */}
      </>
    )
  }
}

export default Header
