import { getDashboardProjectNewsAccomplishmentsPlans } from "actions/DashboardFunctions";
import { StatusTable } from "components/WeeklyReport/ReportTables";
import React, { useState, useEffect } from "react";
import NestedToggleButton from "../../../components/ProjectHealth/NestedToggleButton";
import axios from "axios";

function ProjectStatus(props) {
  const [statusData, setStatusData] = useState([]);
  const cancelToken = axios.CancelToken.source();
  useEffect(() => {
    let isMounted = true;

    getDashboardProjectNewsAccomplishmentsPlans(
      props.projectId,
      props.calcDate,
      cancelToken.token
    ).then((res) => {
      if (isMounted) {
        setStatusData(res.data);
      }
    });

    return () => {
      isMounted = false;
      cancelToken.cancel();
    };
  }, [props.projectId, props.calcDate]);
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <StatusTable data={statusData} type="news" />
        </div>
        <div className="col-12">
          <StatusTable data={statusData} type="accomplishment" />
        </div>
        <div className="col-12">
          <StatusTable data={statusData} type="plan" />
        </div>
      </div>
    </div>
  );
}

export default ProjectStatus;
