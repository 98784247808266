import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const fetchAllClient = () => {
  const token = getUserToken()
  return axios
    .get('/client/listClient', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createClient = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id // TODO: big todo: find some users or create new ones
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/client/createClient', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteClient = (clientId) => {
  // TODO: manage this token at a central store
  const token = getUserToken()
  return axios
    .delete(`/client/deleteClient/${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting client with clientId ${clientId}`,
        error,
      )
    })
}

export const updateClient = (clientId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/client/updateClient/${clientId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating client): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating client with clientId ${clientId}`,
        error,
      )
    })
}

export const updateApproved = (clientId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  // TODO: do we need to changed the updated_by field as well here ?
  rowData['approved_by'] = id // TODO: big todo: find some users or create new ones
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/client/updateApproved/${clientId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of client): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating client's approved status with clientId ${clientId}`,
        error,
      )
    })
}

export const updateStatus = (clientId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/client/updateStatus/${clientId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of client): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating client's status with clientId ${clientId}`,
        error,
      )
    })
}

export const getDetail = (clientId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/client/getDetail/${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting detail of client): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting client's detail with clientId ${clientId}`,
        error,
      )
    })
}

export const getClientTypes = () => {
  const token = getUserToken();
  return axios
    .get(`/client/types`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Client types: ', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error client types`, error);
    });
}
