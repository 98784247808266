import { getPermissionsFor } from 'actions/UtilFunctions';
import { listEfforttype, createEfforttype, deleteEfforttype, updateEfforttype, updateApproved, updateStatus } from 'actions/EfforttypeFunctions';
import ActionFeedback from 'components/ActionFeedbackAlert';
import EffortTypeTable from './EffortTypeTable';

import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { Container, TabContent, TabPane } from 'reactstrap';
import EffortTypeForm from './EffortTypeForm';
import { confirmAlert } from 'react-confirm-alert';


const EffortTypeContainer = () => {
    const fields = {
        effort_type_abbr: '',
        effort_type_cd: '',
        effort_type_name: ''
    };

    const [initValues, setInitValues] = useState({});
    const [effortTypeList, setEffortTypeList] = useState([]);

    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('list');
    const [actionFeedbackMsg, setActionFeedbackMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [allowedPermission, setAllowedPermission] = useState(getPermissionsFor('EffortType'));
    const [submitSuccess, setSubmitSuccess] = useState({
        success: false,
        loading: false,
        message: '',
    });

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                refresh();
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })()
    }, []);

    useEffect(() => {
        if (submitSuccess.success) {
            refresh();
            showActionFeedback(submitSuccess.message || 'Success');
        }
    }, [submitSuccess])

    const refresh = async () => {
        await listEfforttype().then(res => {
            const sortedList = _.sortBy(res, obj => obj.order_no);
            setEffortTypeList(sortedList);
        });
    }

    const showActionFeedback = (message) => {
        setActionFeedbackMsg(message);
        setTimeout(() => setActionFeedbackMsg(''), 5000);
    }

    const changeTab = (tabName) => {
        setActiveTab(tabName);
    }

    const isActionAllowed = (action) => {
        for (let permission of allowedPermission) {
            if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true;
        }
        return false;
    }

    const handleEdit = async (rowData) => {
        try {
            const effortId = rowData['effort_type_id'];
            if (effortId > 0) {
                setEditMode(true);
                setActiveTab('add');
                setLoading(true);
                setInitValues(rowData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = (rowData) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const effortId = rowData.effort_type_id;
                        deleteEfforttype(effortId)
                            .then((response) => {
                                if (response) {
                                    const effort_list = effortTypeList.filter(
                                        (effort) => effort.effort_type_id !== effortId,
                                    );
                                    setEffortTypeList(effort_list);
                                    showActionFeedback(response.message);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });

    }

    const handleStatus = (rowData) => {
        const effortId = rowData.effort_type_id;
        const status = rowData['status'];
        rowData['status'] = status === 'A' ? 'I' : 'A';
        updateStatus(effortId, rowData).then((response) => {
            if (response) {
                const updatedList = effortTypeList.map(effortType => {
                    if (effortType.effort_type_id === effortId) {
                        return { ...effortType, status: (status === 'A' ? 'I' : 'A') };
                    }
                    return effortType;
                });

                setEffortTypeList(updatedList);
                showActionFeedback(response.message);
            }
        });
    }

    const onStatusIconClick = (e) => {
        e.preventDefault();
        const effortData = e.currentTarget.getAttribute('data-effort');
        handleStatus(JSON.parse(effortData));
    }

    const onApprovedIconClick = (e) => {
        e.preventDefault();
        const effortData = e.currentTarget.getAttribute('data-effort');
        handleApprove(JSON.parse(effortData))
    }

    const handleApprove = (rowData) => {
        const effortId = rowData.effort_type_id
        const isApproved = rowData['approved']
        rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
        updateApproved(effortId, rowData).then((response) => {
            if (response) {
                const updatedList = effortTypeList.map(effortType => {
                    if (effortType.effort_type_id === effortId) {
                        return { ...effortType, approved: (effortType.approved === 'Y' ? 'N' : 'Y') }
                    }
                    return effortType;
                });

                setEffortTypeList(updatedList);
                showActionFeedback(response.message);
            }
        });
    }

    const resetForm = () => {
        setInitValues({});
        setEditMode(false);
    }

    const handleSubmit = async (values) => {
        confirmAlert({
            title: 'Confirm Submission',
            message: 'Are you sure you want to submit?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            let response;

                            if (editMode) {
                                if (values?.effort_type_id && values?.effort_type_id > 0) {
                                    for (const key of Object.keys(fields)) {
                                        fields[key] = values[key]
                                    }

                                    response = await updateEfforttype(values.effort_type_id, fields);
                                }
                            } else {
                                response = await createEfforttype(values);
                            }

                            if (response) {
                                setSubmitSuccess({
                                    success: true,
                                    message: response.message,
                                    loading: false,
                                });
                                resetForm();
                                changeTab("list");
                            }
                        } catch (error) {
                            console.error(error);
                            setSubmitSuccess({
                                success: false,
                                message: error.message,
                                loading: false,
                            });
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    }


    return (
        <>
            <div className="ipp-page">
                {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}

                <Container className="mt-3 ml-0" fluid style={{ maxWidth: '100%', margin: 'auto auto' }}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="list">
                            <EffortTypeTable
                                dataList={effortTypeList}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                isLoading={loading}
                                isActionAllowed={isActionAllowed}
                                changeTab={changeTab}
                                onStatusIconClick={onStatusIconClick}
                                onApprovedIconClick={onApprovedIconClick}
                            />
                        </TabPane>
                        <TabPane tabId="add">
                            <EffortTypeForm
                                initValues={initValues}
                                changeTab={changeTab}
                                loading={loading}
                                resetForm={resetForm}
                                handleSubmit={handleSubmit}
                            />
                        </TabPane>
                    </TabContent>
                </Container>
            </div>
        </>
    );
}

export default EffortTypeContainer;