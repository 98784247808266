import React, { Component } from "react"
import { gantt } from "dhtmlx-gantt"
import "dhtmlx-gantt/codebase/dhtmlxgantt.css"
import "./Gantt.css"

export default class Gantt extends Component {
  initZoom() {
    gantt.ext.zoom.init({
      levels: [
        {
          name: "Hours",
          scale_height: 60,
          min_column_width: 30,
          scales: [
            { unit: "day", step: 1, format: "%d %M" },
            { unit: "hour", step: 1, format: "%H" },
          ],
        },
        {
          name: "Days",
          scale_height: 60,
          min_column_width: 70,
          scales: [
            { unit: "week", step: 1, format: "Week #%W" },
            { unit: "day", step: 1, format: "%d %M" },
          ],
        },
        {
          name: "Months",
          scale_height: 60,
          min_column_width: 70,
          scales: [
            { unit: "month", step: 1, format: "%F" },
            { unit: "week", step: 1, format: "#%W" },
          ],
        },
      ],
    })
  }
  setZoom(value) {
    if (!gantt.ext.zoom.getLevels()) {
      this.initZoom()
    }
    gantt.ext.zoom.setLevel(value)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.zoom !== nextProps.zoom
  }
  initGanttDataProcessor() {
    const onDataUpdated = this.props.onDataUpdated
    this.dataProcessor = gantt.createDataProcessor(
      (entityType, action, item, id) => {
        return new Promise((resolve, reject) => {
          if (onDataUpdated) {
            onDataUpdated(entityType, action, item, id)
          }
          return resolve()
        })
      }
    )
  }

  componentWillUnmount() {
    gantt.clearAll()
    if (this.dataProcessor) {
      this.dataProcessor.destructor()
      this.dataProcessor = null
    }
  }

  componentDidMount() {
    gantt.config.date_format = "%Y-%m-%d %H:%i"
    gantt.config.scroll_size = 32
    this.initGanttDataProcessor()
    const { tasks } = this.props
    gantt.init(this.ganttContainer)
    gantt.parse(tasks)
    gantt.config.columns = [
      { name: "text", label: "Task name", width: "*", tree: true },
    ]
    gantt.config.readonly = true;
  }

  render() {
    const { zoom } = this.props
    this.setZoom(zoom)
    return (
      <div
        ref={(input) => {
          this.ganttContainer = input
        }}
        style={{ width: "100%", height: "100%" }}
      ></div>
    )
  }
}


