import React from 'react';
import UserGroupComponent from '../../components/ConfigComponent';
import _ from 'lodash';
import {
  listUserGroup,
  createUserGroup,
  deleteUserGroup,
  updateUserGroup,
  updateApproved,
  updateStatus,
} from '../../actions/UserGroupFunctions';
import { getPermissionsFor } from '../../actions/UtilFunctions';
import ConfigDetailList from '../../components/ConfigDetailList';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from 'reactstrap'

const cellStyle = {
  paddingTop: '0px', paddingBottom: '0px',
}

export default class UserGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        grp_name: ''
      },
      userGroupListColumn: [
        { title: 'User Group', field: 'grp_name', cellStyle: cellStyle},
        { title: 'Status', field: 'status_icon', cellStyle: cellStyle},
        { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle},
      ],
      userGroupListData: [],
      editMode: false,
      currentTab: 'list',
      currentId: '',
      actionFeedbackMessage: '',
      isValid: {},

      allowedPermissions: getPermissionsFor('usergroup'),
      isFetchComplete: false,
    }
  }

  resetState = () => {
    this.setState({
      fields: {
        grp_name: ''
      },
      editMode: false,
      isValid: {},
    })
  }

  componentDidMount() {
    this.refreshUserGroupList();
  }

  refreshUserGroupList = () => {
    listUserGroup().then((response) => {
      const listOfUserGroups = response;
      const sortedListOfUserGroups = _.sortBy(listOfUserGroups, (obj) => obj.grp_name);
      this.setState({
        userGroupListData: sortedListOfUserGroups,
        isFetchComplete: true,
      });
    })
  }

  changeTab = (tabName) => {
    this.setState({
      currentTab: tabName,
    })
  }

  onStatusIconClick = (e) => {
    const button = e.currentTarget
    const userGroup = button.getAttribute('data-config')
    const userGroupObj = JSON.parse(userGroup)
    this.handleStatus(userGroupObj)
  }

  onApprovedIconClick = (e) => {
    const button = e.currentTarget
    const userGroup = button.getAttribute('data-config')
    const userGroupObj = JSON.parse(userGroup)
    this.handleApproved(userGroupObj)
  }

  handleEdit = (rowData) => {
    const grpId = rowData.grp_id
    const currentState = { ...this.state.fields }
    for (const key of Object.keys(currentState)) {
      currentState[key] = rowData[key]
    }
    this.setState({
      fields: currentState,
      currentId: grpId,
      editMode: true,
      currentTab: 'add',
    })
  }

  handleDelete = (rowData) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const grpId = rowData.grp_id
    const userGroupListData = this.state.userGroupListData.filter(
      (userGroup) => userGroup['grp_id'] !== grpId,
    )
    this.setState({
      userGroupListData: userGroupListData,
    })
    deleteUserGroup(grpId).then((response) => {
      console.log(
        `User Group successfully deleted with user group id: ${grpId} `,
        response,
      )
      if (response) {
        this.showActionFeedback(response.message)
      }
    })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleApproved = (rowData) => {
    const grpId = rowData.grp_id
    const isApproved = rowData['approved']
    rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
    updateApproved(grpId, rowData).then((response) => {
      console.log(
        `User Group successfully approved with grpId: ${grpId} `,
        response,
      )
      if (response) {
        this.refreshUserGroupList();
        this.showActionFeedback(response.message)
      }
    })
  }

  formGroup = () => (
    <FormGroup row>
      <Col sm={2}>
        <Label for="userGroupName">User Group Name</Label>
      </Col>
      <Col sm={5}>
        <Input
          type="text"
          name="grp_name"
          id="userGroupName"
          value={this.state.fields['grp_name']}              
          bsSize="sm"
          onChange={this.handleUserInput}
          invalid={this.state.isValid['grp_name'] === false}
          onBlur={this.handleBlur}
        />
        <FormFeedback invalid>
          User Group name cannot be empty.
        </FormFeedback>              
      </Col>
    </FormGroup>
  )

  showActionFeedback = (message) => {
    this.setState({
      actionFeedbackMessage: message,
    });
    setTimeout(() => this.setState({ actionFeedbackMessage: '' }), 5000);
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let fields = this.state.fields;
    fields[name] = value;
    this.setState({ fields });
    this.setState({ [name]: value });
    this.validateFields();
  }

  validateNonEmpty = (key, value) => {
    const { isValid } = this.state
    if (value === '' || value.trim() === '') {
      isValid[key] = false
    } else {
      isValid[key] = true
    }
    this.setState({ isValid })
  }

  validateFields = () => {
    for (const key of Object.keys(this.state.fields)) {
      const value = this.state.fields[key]
      this.validateNonEmpty(key, value)
    }
  }

  handleBlur = (e) => {
    this.validateFields()
  }

  isFormValid = () => {
    const { isValid, fields } = this.state
    for (const key in fields) {
      if (isValid[key] === false || isValid[key] === undefined) return false
    }
    return true
  }

  handleSubmit = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            //e.preventDefault()
            this.validateFields();

            if (!this.isFormValid()) {
              this.showActionFeedback('Invalid or missing form data!')
              return
            }

            if (this.state.editMode) {
              // we are in the edit mode
              updateUserGroup(this.state.currentId, this.state.fields).then((response) => {
                this.refreshUserGroupList();
                console.log(
                  `Success updating the user group with id: ${this.state.currentId} `,
                  response,
                )
                if (response) {
                  this.showActionFeedback(response.message)
                }
              })

              this.setState({
                currentId: '',
              })
            } else {
              createUserGroup(this.state.fields).then((response) => {
                this.refreshUserGroupList();
                console.log('Success: ', response)
                if (response) {
                  this.showActionFeedback(response.message)
                  // TODO: if no response is received show the failed here
                }
              })
            }
            this.resetState();
            this.changeTab('list');
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleStatus = (rowData) => {
    const grpId = rowData.grp_id
    const status = rowData['status']
    rowData['status'] = status === 'A' ? 'I' : 'A'
    updateStatus(grpId, rowData).then((response) => {
      console.log(
        `Status successfully updated for user group with user group id: ${grpId} `,
        response,
      )
      if (response) {
        this.refreshUserGroupList();
        this.showActionFeedback(response.message)
      }
    })
  }

  getDetailPanelView = () => {
    return [{
      icon: 'toc',
      tooltip: 'Details',
      disabled: !this.isActionAllowed('detail'),
      render: (rowData) => {
        const grpId = rowData['grp_id'];
        return (<ConfigDetailList id={grpId} type={'userGroup'} />);
      },
    }]
  }

  isActionAllowed = (action) => {
    const { allowedPermissions } = this.state;
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase()))
        return true;
    }
    return false;
  }

  render() {
    return (
      <UserGroupComponent
        formGroup={this.formGroup}
        formTitle={'User Group Information'}
        fields={this.state.fields}
        columns={this.state.userGroupListColumn}
        data={this.state.userGroupListData}
        editMode={this.state.editMode}
        currentTab={this.state.currentTab}
        currentId={this.state.currentId}
        changeTab={this.changeTab}
        resetState={this.resetState}
        message={this.state.actionFeedbackMessage}
        onApprovedIconClick={this.onApprovedIconClick}
        onStatusIconClick={this.onStatusIconClick}
        tableTitle={'User Group List'}
        handleDelete={this.handleDelete}
        handleEdit={this.handleEdit}
        handleSubmit={this.handleSubmit} 
        getDetailPanelView={this.getDetailPanelView}
        isActionAllowed={this.isActionAllowed}
        isFetchComplete={this.state.isFetchComplete}
      />
    )
  }
}
