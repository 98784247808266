import React from "react"
import _ from "lodash"
import {Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap"
import {useFormik} from "formik"
import * as Yup from 'yup'
import {Spinner} from "react-bootstrap"

const UserForm = ({
    initValues,
    changeTab,
    loading,
    resetForm,
    handleSubmit,
    editMode,
    userList,
    roleList,
    employeeList,
    employeeMap
}) => {

    const userInfo = {
        email: '',
        password: '',
        role_id: '',
        employee_id: '',
    }
    const initialValues = _.isEmpty(initValues) ? userInfo : initValues

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            password: !editMode ? Yup.string().required('Password is required') : null,
            role_id: Yup.string().required('Role is required'),
            employee_id: Yup.string().required('Employee is required'),
        }),
        onSubmit: async (value) => {
            await handleSubmit(value)
        }
    })

    const handleUserInput = (e) => {
        const value = e.target.value
        let email = employeeMap[value]['email']
        if (email === null) {email = 'No email found'}
        formik.setFieldValue('employee_id', value)
        formik.setFieldValue('email', email)
    }

    const getEmployeeNames = () => {
        const excludedEmployeeIds = userList.map((user) => user['employee_id'])
        const filteredEmployees = employeeList.filter(
            (employee) => excludedEmployeeIds.indexOf(employee['employee_id'])  === -1
        )
        console.log(filteredEmployees)
        return filteredEmployees.map((employee) => (
            <option value={employee['employee_id']} key={employee['employee_id']}>
                {`${employee['first_name']} ${employee['last_name']}`}
            </option>
        ))
    }

    const getEmployeeName = (employeeId) => {
        const employee = employeeMap[employeeId]
        return `${employee['first_name']} ${employee['last_name']}`
    }

    const getRoleNames = () => {
        return roleList.map((role) => (
            <option value={role['role_id']} key={role['role_id']}>
                {role['role_name']}
            </option>
        ))
    }

    const formHeaderStyle = {
        fontSize: 20,
        color: '#fff',
        textAlign: 'center',
        padding: 10,
    }

    return <Card className="border-top-0 shadow-none">
        <CardHeader style={formHeaderStyle}>User Information</CardHeader>

        <CardBody>
            {loading ? (
                <Spinner animation="border" />
            ) : (
                <Form onSubmit={formik.handleSubmit}>
                    <FormGroup row>
                        <Col sm={2}>
                            <Label for="employeeId">Employee</Label>
                        </Col>
                        <Col sm={6}>
                            <Input
                                type="select"
                                name="employee_id"
                                id="employeeId"
                                value={formik.values?.employee_id}
                                bsSize="sm"
                                onChange={handleUserInput}
                                onBlur={formik.handleBlur}
                                disabled={editMode}
                            >
                                <option value={editMode ? formik.values?.employee_id : ''}>
                                    {editMode ? getEmployeeName(formik.values?.employee_id) : ''}
                                </option>
                                {getEmployeeNames()}
                            </Input>
                            <FormFeedback invalid>
                                {formik.errors?.employee_id}
                            </FormFeedback>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col sm={2}>
                            <Label for="email">Email</Label>
                        </Col>
                        <Col sm={6}>
                            <Input
                                type="select"
                                name="email"
                                id="email"
                                value={formik.values?.email}
                                bsSize="sm"
                                disabled
                            >
                                <option>{formik.values?.email}</option>
                            </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col sm={2}>
                            <Label for="roleId">Role</Label>
                        </Col>
                        <Col sm={6}>
                            <Input
                                type="select"
                                name="role_id"
                                id="roleId"
                                value={formik.values?.role_id}
                                bsSize="sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={
                                    formik.touched?.role_id &&
                                    formik.errors?.role_id
                                }
                            >
                                <option value=""/>
                                {getRoleNames()}
                            </Input>
                            <FormFeedback invalid>
                                {formik.errors?.role_id}
                            </FormFeedback>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col sm={2}>
                            <Label for="password">Password</Label>
                        </Col>
                        <Col sm={6}>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={formik.values?.password}
                                bsSize="sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={
                                    !editMode &&
                                    formik.touched?.password &&
                                    formik.errors?.password
                                }
                            />
                            <FormFeedback invalid>
                                {formik.errors?.password}
                            </FormFeedback>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col xs={{ offset: 6 }} className="text-right">
                            <Button
                                type="submit"
                                color="primary"
                                className="fixedWidthButton"
                            >
                                Save
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="fixedWidthButton"
                                color="danger"
                                onClick={() => {
                                    resetForm()
                                    changeTab('list')
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            )}
        </CardBody>
    </Card>
}

export default UserForm