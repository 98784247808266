import Register from 'views/Register.js'
import Login from 'views/Login.js'
import Forgot from 'views/Forgot'
import Reset from 'views/Reset'

var routes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'ni ni-circle-08 text-pink',
    component: Register,
    layout: '/auth',
  },
  {
    path: '/forgot',
    name: 'Forgot',
    icon: 'ni ni-circle-08 text-pink',
    component: Forgot,
    layout: '/auth',
  },
  {
    path: '/reset',
    name: 'Reset',
    icon: 'ni ni-circle-08 text-pink',
    component: Reset,
    layout: '/auth',
  },
]
export default routes
