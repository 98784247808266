import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const updateTimeSheet = async (request) => {
  const token = getUserToken();
  return axios
    .post("/timeSheet/update", request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Response from server updating time sheet", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error while updating time sheet", error);
      return error;
    });
};

export const getTimeSheet = async (request) => {
  const token = getUserToken();
  return axios
    .post("/timeSheet/list", request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Response from server retrieving time sheet", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error while retrieving time sheet", error);
      return error;
    });
};

export const getBillableTotals = async (request) => {
  const token = getUserToken();
  return axios
    .post("/timeSheet/billable/list", request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(
        "Response from server retrieving billable totals for time sheet",
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log(
        "Error while retrieving billable totals for time sheet",
        error
      );
      return error;
    });
};

export const updateBillableTotals = async (request) => {
  const token = getUserToken();
  return axios
    .post("/timeSheet/billable/update", request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(
        "Response from server updating billable totals for time sheet",
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log("Error while updating billable totals for time sheet", error);
      return error;
    });
};

export const getActualHrs = async (projectId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/timeSheet/actual/hours/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get actual hours", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error get actual hours", error);
      return error;
    });
};

export const getTimeSheetByProject = async (
  projectId,
  startDate,
  endDate,
  cancelToken
) => {
  const token = getUserToken();
  return axios
    .get(`/timeSheet/project/${projectId}/${startDate}/${endDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get time sheet by project", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error get time sheet by project", error);
      return error;
    });
};

export const getBillableByProject = async (
  projectId,
  startDate,
  endDate,
  cancelToken
) => {
  const token = getUserToken();
  return axios
    .get(`/timeSheet/billable/${projectId}/${startDate}/${endDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get billable hours by project", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error get billable hours by project", error);
      return error;
    });
};

export const approveTimeSheetResource = async (row) => {
  const token = getUserToken();
  row["approved_by"] = getUserId();
  return axios
    .post("/timeSheet/approve", row, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      //console.log("Post approve time sheet", response);
      return {
        message: response.data.message,
        success: response.status === 200,
      };
    })
    .catch((error) => {
      //console.log("Error approve time sheet", error.response.data.message);
      throw error.response.data.message;
    });
};

export const rejectTimeSheetResource = async (row) => {
  const token = getUserToken();
  row["approved_by"] = getUserId();
  return axios
    .post("/timeSheet/reject", row, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      //console.log("Post reject time sheet", response);
      return {
        message: response.data.message,
        success: response.status === 200,
      };
    })
    .catch((error) => {
      //console.log("Error reject time sheet", error);
      throw error.response.data.message;
    });
};

export const submitTimeSheet = async (row) => {
  const token = getUserToken();
  row["updated_by"] = getUserId();
  return axios
    .post("/timeSheet/submit", row, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Submit time sheet", response);
      return { data: response.data, success: response.status === 200 };
    })
    .catch((error) => {
      console.log("Error submit time sheet", error);
      return error;
    });
};

export const getTimeSheetByWeekProjectResource = async (request) => {
  const token = getUserToken();
  return axios
    .post("/timeSheet/week", request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Get time sheet by week project resource", response);
      return { data: response.data, success: response.status === 200 };
    })
    .catch((error) => {
      console.log("Error time sheet by week project resource", error);
      return error;
    });
};

export const getTimeSheetWeekStat = async (
  projectId,
  startOfWeek,
  endOfWeek,
  cancelToken
) => {
  const token = getUserToken();
  return axios
    .get(`/timeSheet/week/stat/${projectId}/${startOfWeek}/${endOfWeek}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get time sheet stat by week", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error time sheet stat by week", error);
      return error;
    });
};

export const getDefaultProjects = async (cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/timeSheet/getDefaultProjects`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get time sheet stat by week", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error time sheet stat by week", error);
      throw error;
    });
};

export const getTimesheetDayoff = async (request, cancelToken) => {
  const token = getUserToken();
  return axios
    .post(`/timeSheet/getTimesheetDayoff`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get time sheet stat by week", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error time sheet stat by week", error);
      throw error;
    });
};

export const saveTimesheetDayoff = async (request, cancelToken) => {
  const token = getUserToken();
  return axios
    .post(`/timeSheet/saveTimesheetDayoff`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get time sheet stat by week", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error time sheet stat by week", error);
      throw error;
    });
};
