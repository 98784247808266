import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { date } from "yup";

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

const GenericBarChart = ({ data, dataKey, keyset, colors, height, stackId }) => {
  return (
    <ResponsiveContainer width={"100%"} height={height ? height : 250}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        // TODO: we may need to set a fixed barSize if the data there are a lot of data
        // barSize={25}
      >
        <XAxis
          dataKey={dataKey}
          interval={0}
          height={55}
          tick={<CustomizedAxisTick />}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* TODO: if we are unable to show a scrollbar maybe a Brush would be okay */}
        {data.length > 6 && (
          <Brush
            dataKey={dataKey}
            height={20}
            stroke="#8884d8"
            startIndex={0}
            endIndex={data.length > 6 ? 6 : null}
          />
        )}
        {keyset &&
          keyset.map((entry, index) => (
            <Bar
              key={`${index}-${entry}`}
              stackId={stackId}
              dataKey={`${keyset[index]}`}
              fill={colors[index]}
              label={{ position: "top" }}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GenericBarChart;
