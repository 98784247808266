import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label} from "reactstrap"
import React, {useEffect, useState} from "react"
import {useFormik} from "formik"
import {listPriority} from "../../../actions/PriorityFunctions"
import {fetchAllStream} from "../../../actions/StreamFunctions"
import {fetchAllProject} from "../../../actions/ProjectFunctions"
import _ from 'lodash'

const RAIDFilter = ({onFilter}) => {

  const [priorityList, setPriorityList] = useState([])
  const [streamList, setStreamList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [filteredProjectList, setFilteredProjectList] = useState([])

  const generateOptions = (datalist, key) => {
    return datalist.map(data => (
      <option value={data[key]} key={data[key]}>{data[key]}</option>
    ))
  }

  useEffect(() => {
    listPriority().then(response => setPriorityList(response))
    fetchAllStream().then(response => setStreamList(_.sortBy(response, ['stream_name'])))
    fetchAllProject().then(response => {
      let projects = response.filter(project => project.status === 'A')
      projects = _.sortBy(projects, 'project_name')
      setProjectList(projects)
      setFilteredProjectList(projects)
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      stream: '',
      project: '',
      status: '',
      severity: '',
      till_date: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => onFilter(values)
  })

  useEffect(() => {
    if (formik.values.stream === '') setFilteredProjectList(projectList)
    else {
      let stream = streamList.find(stream => stream.stream_name === formik.values.stream)
      setFilteredProjectList(projectList.filter(project => project.stream_id === stream.stream_id))
    }
  }, [formik.values.stream])

  return <Card className="mt-3">
    <CardBody>
      <Form onSubmit={formik.handleSubmit} className="row">
        <div className="col-md-4">
          <FormGroup row>
            <Col sm={3}>
              <Label for="stream">Stream</Label>
            </Col>
            <Col sm={9}>
              <Input
                type="select"
                name="stream"
                id="stream"
                bsSize="sm"
                placeholder="All"
                value={formik.values?.stream}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">All</option>
                {generateOptions(streamList, 'stream_name')}
              </Input>
            </Col>
          </FormGroup>
        </div>

        <div className="col-md-4">
          <FormGroup row>
            <Col sm={3}>
              <Label for="status">Status</Label>
            </Col>
            <Col sm={9}>
              <Input
                type="select"
                name="status"
                id="status"
                bsSize="sm"
                value={formik.values?.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="" key="null">All</option>
                <option value="Open" key="Open">Open</option>
                <option value="Closed" key="Closed">Closed</option>
              </Input>
            </Col>
          </FormGroup>
        </div>

        <div className="col-md-4">
          <FormGroup row>
            <Col sm={3}>
              <Label for="till_date">Till Date</Label>
            </Col>
            <Col sm={9}>
              <Input
                type="date"
                name="till_date"
                id="till_date"
                bsSize="sm"
                value={formik.values?.till_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
          </FormGroup>
        </div>

        <div className="col-md-4">
          <FormGroup row className="mb-0">
            <Col sm={3}>
              <Label for="project">Project</Label>
            </Col>
            <Col sm={9}>
              <Input
                type="select"
                name="project"
                id="project"
                bsSize="sm"
                placeholder="All"
                value={formik.values?.project}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">All</option>
                {generateOptions(filteredProjectList, 'project_name')}
              </Input>
            </Col>
          </FormGroup>
        </div>

        <div className="col-md-4">
          <FormGroup row className="mb-0">
            <Col sm={3}>
              <Label for="severity">Severity</Label>
            </Col>
            <Col sm={9}>
              <Input
                type="select"
                name="severity"
                id="severity"
                bsSize="sm"
                value={formik.values?.severity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">All</option>
                {generateOptions(priorityList, 'priority_name')}
              </Input>
            </Col>
          </FormGroup>
        </div>

        <div className="col-md-4">
          <Button
            type="submit"
            color="primary"
            className="fixedWidthButton float-right"
          >
            Filter
          </Button>
        </div>

      </Form>
    </CardBody>
  </Card>
}

export default RAIDFilter