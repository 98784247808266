import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listMenu = () => {
  const token = getUserToken()
  return axios
    .get(`/menu/listMenu`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log(`Response from server(Fetching all the menus)`, response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createMenu = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id // TODO: big todo: find some users or create new ones
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post(`/menu/createMenu`, fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Creating menu)', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const updateMenu = (menuId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/menu/updateMenu/${menuId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating menu): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteMenu = (menuId) => {
  const token = getUserToken()
  return axios
    .delete(`/menu/deleteMenu/${menuId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Deleting menu): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while deleting menu with menuId ${menuId}`, error)
    })
}

export const viewMenu = (menuId) => {
  const token = getUserToken()
  return axios
    .get(`/menu/viewMenu/${menuId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Fetching menu): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while fetching menu with menuId ${menuId}`, error)
    })
}

export const updateStatus = (menuId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id // TODO: Root user
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/menu/updateStatus/${menuId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of menu): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating status field of menu with menuId ${menuId}`,
        error,
      )
    })
}

export const updateApproved = (menuId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['approved_by'] = id // TODO: Root user
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/menu/updateApproved/${menuId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of menu): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating menu's approved status with menuId ${menuId}`,
        error,
      )
    })
}

export const getDetail = (menuId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/menu/getDetail/${menuId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting detail of menu): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting menu's detail with menuId ${menuId}`,
        error,
      )
    })
}

export const activeMenu = () => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/menu/activeMenu`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while getting active menu`, error)
    })
}

export const getMenuByUser = () => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/menu/getMenuByUser/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Get menu by user ', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get menu by user `, error);
    })
}
