import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const listProjectTask = (projectId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/projecttask/listProjectTask/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(`Response from server(Fetching all the project tasks)`, response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const listProjectTaskRoadmap = (projectId, cancelToken) => {
  const token = getUserToken()
  return axios
    .get(`/projecttask/listProjectTaskRoadmap/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(
        `Response from server(Fetching all the project tasks)`,
        response
      )
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createProjectTask = (fields) => {
  const token = getUserToken();
  const id = getUserId();
  fields["created_by"] = id;
  fields["created_dt"] = new Date().toISOString();
  return axios
    .post(`/projecttask/createProjectTask`, fields, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Creating project task)", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return new Error(error.response.data);
    });
};

export const createProjectTaskBulk = (fields, all_req_tasks) => {
  const token = getUserToken();
  const id = getUserId();
  fields.forEach((element) => {
    element["created_by"] = id;
    element["created_dt"] = new Date().toISOString();
  });
  all_req_tasks.forEach((element) => {
    element["created_by"] = id;
    element["created_dt"] = new Date().toISOString();
  });
  let all_data = [];
  all_data.push({ upper_tasks: fields });
  all_data.push({ all_req_tasks: all_req_tasks });
  //fields['created_by'] = id
  //fields['created_dt'] = new Date().toISOString()
  return axios
    .post(`/projecttask/createProjectTaskBulk`, all_data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Creating project task)", response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return new Error(error.response.data);
    });
};

export const updateProjectTask = (taskId, rowData) => {
  const token = getUserToken();
  const id = getUserId();
  rowData["updated_by"] = id;
  rowData["updated_dt"] = new Date().toISOString();
  return axios
    .put(`/projecttask/updateProjectTask/${taskId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Updating project task): ", response);
      return response.data;
    })
    .catch((error) => {
      // throw new Error("Bad Request", { cause: error });
      throw error;
    });
};

export const deleteProjectTask = (taskId) => {
  const token = getUserToken();
  return axios
    .delete(`/projecttask/deleteProjectTask/${taskId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Deleting project task): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while deleting project task with taskId ${taskId}`, error);
    });
};

export const deleteProjectTasks = (array) => {
  const token = getUserToken();
  return axios
    .delete(
      `/projecttask/deleteProjectTasks`,
      {
        projectTaskIds: array,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      console.log("Response from server(Deleting project tasks): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while deleting multiple project tasks `, error);
    });
};

export const updateStatus = (taskId, rowData) => {
  const token = getUserToken();
  const id = getUserId();
  rowData["updated_by"] = id;
  rowData["updated_dt"] = new Date().toISOString();
  return axios
    .put(`/projecttask/updateStatus/${taskId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Updating status field of task): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating status field of task with taskId ${taskId}`,
        error
      );
    });
};

export const updateApproved = (taskId, rowData) => {
  const id = getUserId();
  const token = getUserToken();
  rowData["approved_by"] = id;
  rowData["approved_dt"] = new Date().toISOString();
  return axios
    .put(`/projecttask/updateApproved/${taskId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Updating approved status of task): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating task's approved status with taskId ${taskId}`,
        error
      );
    });
};

export const getDetail = (taskId) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/projecttask/getDetail/${taskId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting detail of task): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting task's detail with taskId ${taskId}`, error);
    });
};

export const activeProjectTask = (projectId) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/projecttask/activeProjectTask/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server: ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting active task`, error);
    });
};

export const getProjectReleaseSize = (projectId, sprintId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(
      `/projecttask/getProjectReleaseSize/${projectId}/${sprintId ? sprintId : null}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken,
      }
    )
    .then((response) => {
      console.log("Response from server, Project release size: ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting the project release size `, error);
    });
};

export const copyProjectGroup = (groupTaskId, upperTaskId) => {
  const token = getUserToken();
  const id = getUserId();
  const date = new Date().toISOString();
  return axios
    .post(
      `/projecttask/copyProjectGroup`,
      {
        upper_task_id: upperTaskId,
        group_task_id: groupTaskId,
        created_by: id,
        created_dt: date,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      console.log("Response from server, creating project group: ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while copying project group `, error);
    });
};

export const updateTaskResources = (request) => {
  const token = getUserToken();
  const id = getUserId();
  request["created_by"] = id;
  return axios
    .post(`/projecttask/resource`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Update task resources", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error update task resources`, error);
    });
};

export const getTaskResources = (projectId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/projecttask/resource/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get task resources", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get task resources`, error);
    });
};
