import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Axios from "axios"
import Loading from "components/Loading"
import React from "react"
import StyledTable from "./StyledTable"
import { Table } from "react-bootstrap"

const cellStyle = {
  paddingTop: "0px",
  paddingBottom: "0px",
  whiteSpace: "nowrap",
}

const TextArea = (props) => (
  <div className="py-1">
    <input
      type="textarea"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  </div>
)

const DateInput = (props) => (
  <div className="py-1">
    <input
      type="date"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  </div>
)
const raidDetailColumns = [
  {
    title: "Status Date",
    field: "status_dt",
    cellStyle: cellStyle,
    editComponent: (props) => (
      <DateInput value={props.value} onChange={props.onChange} />
    ),
  },
  {
    title: "Status Owner",
    field: "status_owner",
    cellStyle: cellStyle,
  },
  {
    title: "Action Plan",
    field: "action_plan",
    cellStyle: cellStyle,
    editComponent: (props) => (
      <TextArea value={props.value} onChange={props.onChange} />
    ),
  },
]
export default function RaidDetail({ raid_id, title, isViewMode }) {
  const queryClient = useQueryClient()

  const { data, isError, error, isLoading } = useQuery(
    ["retailDetail", raid_id],
    () => {
      return Axios.get(`/raid/detail/${raid_id}`)
    }
  )
  const deleteRaidDetail = useMutation(
    (raidDetail) => {
      return Axios.delete(`/raid/detail/${raidDetail?.raid_dtl_id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["retailDetail", raid_id])
      },
    }
  )

  const addRaidDetail = useMutation(
    (raidDetail) => {
      return Axios.post(`/raid/detail`, { ...raidDetail, raid_id })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["retailDetail", raid_id])
      },
    }
  )

  const updateRaidDetail = useMutation(
    (raidDetail) => {
      return Axios.put(`/raid/detail/${raidDetail?.raid_dtl_id}`, {
        ...raidDetail,
        raid_id,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["retailDetail", raid_id])
      },
      onError: (e) => {
        console.error(e)
      },
    }
  )

  if (isLoading) return <Loading />
  if (isError) {
    console.error(error)
    return <h5 className="pl-4">Error fetching raid data</h5>
  }
  const sortedData = data?.data.sort((a, b) => {
    if (a.status_dt > b.status_dt) {
      return -1
    }
    if (a.status_dt < b.status_dt) {
      return 1
    }
    return 0
  })
  return (
    <>
      {!isViewMode ? (
      <div
        style={{
          width: "100%",
          padding: 5,
          marginBottom: 10,
          backgroundColor: "whitesmoke",
        }}
      >
        {deleteRaidDetail.isLoading && <>Deleting</>}
        {updateRaidDetail.isLoading && <>Updating</>}
        {addRaidDetail.isLoading && <>Adding</>}
        <StyledTable
            isViewMode={isViewMode}
          data={data?.data}
          columns={raidDetailColumns}
            titleName={title}
          isFetchComplete={!isLoading}
          onRowDelete={(row) => deleteRaidDetail.mutate(row)}
          onRowUpdate={(row) => updateRaidDetail.mutate(row)}
            onRowAdd={(row) => {
              addRaidDetail.mutate(row)
            }}
        />
      </div>
      ) : (
        <Table>
          <thead>
            <tr>
              <th className="w-10">Status Date</th>
              <th className="w-20">Status Owner</th>
              <th className="w-70">Action Plan</th>
            </tr>
          </thead>

          <tbody>
            {sortedData.length == 0 ? (
              <tr>
                <td colSpan={3} style={{textAlign: "center"}}>No records to display</td>
              </tr>
            ) : (
              sortedData?.map((row, idx) => {
                return (
                  <tr key={idx}>
                    <td>{row.status_dt.split(" ")[0]}</td>
                    <td>{row.status_owner}</td>
                    <td>{row.action_plan}</td>
                  </tr>
                )
              })
            )}
          </tbody>
        </Table>
      )}
    </>
  )
}
