import React from "react"
import {Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap"
import _ from "lodash";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Spinner} from "react-bootstrap";

const RoleForm = ({
  initValues,
  handleSubmit,
  isLoading,
  changeTab,
  resetForm
}) => {

  const roleInfo = {
    role_name: ''
  }
  const initialValues = _.isEmpty(initValues) ? roleInfo : initValues

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      role_name: Yup.string().required('Role name is required.')
    }),
    onSubmit: async values => await handleSubmit(values)
  })

  return <Card className="border-top-0 shadow-none">
    <CardHeader style={{
      fontSize: 20,
      color: '#fff',
      textAlign: 'center',
      padding: 10,
    }}>
      Role Information
    </CardHeader>


    <CardBody>
      {isLoading ?
        <Spinner animation="border" /> :
        <Form onSubmit={formik.handleSubmit}>

          <FormGroup row>
            <Col sm={2}>
              <Label for="roleName">Role Name</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                name="role_name"
                id="roleName"
                value={formik.values?.role_name}
                bsSize="sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched?.role_name &&
                  formik.errors?.role_name
                }
              />
              <FormFeedback invalid>
                {formik.errors?.role_name}
              </FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col className="text-right" xs={{offset: 5}}>
              <Button
                type="submit"
                color="primary"
                className="fixedWidthButton"
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                className="fixedWidthButton"
                color="danger"
                onClick={() => {
                  resetForm()
                  changeTab('list')
                }}
              >
                Cancel
              </Button>
            </Col>
          </FormGroup>

        </Form>
      }
    </CardBody>
  </Card>
}

export default RoleForm