import React, {useEffect, useState} from "react"
import PhasesTable from "./PhasesTable"
import PhasesForm from "./PhasesForm"
import {getPermissionsFor} from "../../actions/UtilFunctions"
import {
  createPhases,
  deletePhases,
  getDetail,
  listPhases, updateApproved,
  updatePhases,
  updateStatus
} from "../../actions/PhasesFunctions"
import ActionFeedback from "../../components/ActionFeedbackAlert"
import {Container, TabContent, TabPane} from "reactstrap"
import {confirmAlert} from "react-confirm-alert"

const PhasesContainer = () => {

  const fields = {
    phases_cd: '',
    phases_name: '',
    phases_abbr: '',
  }
  const [initialValues, setInitialValues] = useState([])

  const [phasesList, setPhasesList] = useState([])

  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [activeTab, setActiveTab] = useState('list')
  const [actionFeedbackMsg, setActionFeedbackMsg] = useState('')

  const [allowedPermissions] = useState(getPermissionsFor('phases'))

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        await refreshProject()
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }) ()
  }, [])

  const refreshProject = async () => {
    const phases = await listPhases()
    if (phases) {
      setPhasesList(phases)
    }
  }

  const changeTab = tabName => setActiveTab(tabName)

  const populateFormData = async rowData => {
    const phasesId = rowData.phases_id
    try {
      if (phasesId > 0) {
        setLoading(true)
        const detail = (await getDetail(phasesId))[0]
        setInitialValues(detail)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = async rowData => {
    setEditMode(true)
    setActiveTab('add')
    await populateFormData(rowData)
  }

  const handelDelete = async rowData => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const phasesId = rowData.phases_id
            const phasesListData = phasesList.filter(phases => phases['phases_id'] !== phasesId)
            setPhasesList(phasesListData)
            deletePhases(phasesId).then(response => {
              console.log(
                `Phases successfully deleted with id: ${phasesId} `,
                response,
              )
              if (response) {
                showActionFeedback(response.message)
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const handleStatus = rowData => {
    const phasesId = rowData.phases_id
    const status = rowData['status']
    rowData['status'] = status === 'A' ? 'I' : 'A'
    updateStatus(phasesId, rowData).then(response => {
      console.log(
        `Status successfully updated for Phases with id: ${phasesId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })
  }

  const handleApproved = rowData => {
    const phasesId = rowData.phases_id
    const isApproved = rowData['approved']
    rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
    updateApproved(phasesId, rowData).then(response => {
      console.log(
        `Phases successfully approved with id: ${phasesId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })
  }

  const onStatusIconClick = e => {
    e.preventDefault()
    const phaseData = e.currentTarget.getAttribute('data-phases')
    handleStatus(JSON.parse(phaseData))
  }

  const onApprovedIconClick = e => {
    e.preventDefault()
    const phaseData = e.currentTarget.getAttribute('data-phases')
    handleApproved(JSON.parse(phaseData))
  }

  const handleSubmit = values => {
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let response

            if (editMode) {
              if (values.phases_id && values.phases_id > 0) {
                for (const key of Object.keys(fields)) {
                  fields[key] = values[key]
                }
                response = await updatePhases(values.phases_id, fields)
              }
            } else {
              response = await createPhases(values)
            }

            if (response) {
              showActionFeedback(response.message)
              changeTab('list')
              refreshProject()
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const isActionAllowed = action => {
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true
    }
    return false
  }

  const showActionFeedback = message => {
    setActionFeedbackMsg(message)
    setTimeout(() => setActionFeedbackMsg(''), 5000)
  }

  const resetForm = () => {
    setInitialValues({})
    setEditMode(false)
  }

  return <div className="ipp-page">
    {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}
    <Container className="mt-2" fluid style={{maxWidth: '100%', margin: 'auto'}}>
      <TabContent activeTab={activeTab}>

        <TabPane tabId="list">
          <PhasesTable
            phasesListData={phasesList}
            handleEdit={handleEdit}
            handleDelete={handelDelete}
            onStatusIconClick={onStatusIconClick}
            onApprovedIconClick={onApprovedIconClick}
            isLoading={loading}
            isActionAllowed={isActionAllowed}
            changeTab={changeTab}
          />
        </TabPane>

        <TabPane tabId="add">
          <PhasesForm
            initValues={initialValues}
            handleSubmit={handleSubmit}
            isLoading={loading}
            changeTab={changeTab}
            resetForm={resetForm}
          />
        </TabPane>

      </TabContent>
    </Container>
  </div>
}

export default PhasesContainer