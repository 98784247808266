import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listPriority = (cancelToken) => {
  const token = getUserToken()
  return axios
    .get('/priority/listPriority', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}
export const activePriority = () => {
  const token = getUserToken()
  return axios
    .get('/priority/activePriority', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createPriority = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id // TODO: big todo: find some users or create new ones
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/priority/createPriority', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deletePriority = (priorityId) => {
  // TODO: manage this token at a central store
  const token = getUserToken()
  return axios
    .delete(`/priority/deletePriority/${priorityId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting Priority with id ${priorityId}`,
        error,
      )
    })
}

export const updatePriority = (priorityId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/priority/updatePriority/${priorityId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating Priority): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Priority with id ${priorityId}`,
        error,
      )
    })
}

export const updateApproved = (priorityId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  // TODO: do we need to changed the updated_by field as well here ?
  rowData['approved_by'] = id // TODO: big todo: find some users or create new ones
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/priority/updateApproved/${priorityId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of Priority): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Priority's approved status with id ${priorityId}`,
        error,
      )
    })
}

export const updateStatus = (priorityId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/priority/updateStatus/${priorityId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of Priority): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Priority's status with Id ${priorityId}`,
        error,
      )
    })
}

export const getDetail = (priorityId) => {
  const token = getUserToken()
  return axios
    .get(`/priority/getDetail/${priorityId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of Priority): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting Priority's detail with Id ${priorityId}`,
        error,
      )
    })
}
