import React from "react";
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';

import {
    Card,
    Button,
    FormFeedback,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    CardHeader,
    CardBody,
    Row,
    CardFooter,
} from 'reactstrap';


const TaskTypeForm = ({
    initValues,
    changeTab,
    loading,
    resetForm,
    handleSubmit
}) => {
    const formFields = {
        task_type_cd: '',
        task_type_name: '',
        task_type_abbr: ''
    };
    const initialValues = _.isEmpty(initValues) ? formFields : initValues;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            task_type_cd: Yup.string().required('Code is required'),
            task_type_name: Yup.string().required('Name is required'),
            task_type_abbr: Yup.string().required('Abbreviation is required')
        }),
        onSubmit: async (values) => {
            await handleSubmit(values);
        }
    });

    return (
        <>
            <Card className="border-top-0 shadow-none">
                <CardHeader
                    style={{
                        fontSize: 20,
                        color: '#fff',
                        textAlign: 'center',
                        padding: 10,
                    }}
                >
                    Task Type
                </CardHeader>

                <CardBody style={{ maxHeight: 'none' }}>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <Form onSubmit={formik.handleSubmit}>
                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="taskTypeCode">Code</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="task_type_cd"
                                        id="taskTypeCode"
                                        value={formik.values?.task_type_cd}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.task_type_cd &&
                                            formik.errors?.task_type_cd
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.task_type_cd}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="taskTypeName">Name</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="task_type_name"
                                        id="taskTypeName"
                                        value={formik.values?.task_type_name}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.task_type_name &&
                                            formik.errors?.task_type_name
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.task_type_name}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="taskTypeAbbr">Abbreviation</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="task_type_abbr"
                                        id="taskTypeAbbr"
                                        value={formik.values?.task_type_abbr}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.task_type_abbr &&
                                            formik.errors?.task_type_abbr
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.task_type_abbr}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </CardBody>
                <CardFooter>
                    <Row style={{ float: 'right' }}>
                        <Button
                            type="submit"
                            color="primary"
                            className="fixedWidthButton"
                            onClick={formik.handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            color="danger"
                            className="fixedWidthButton"
                            onClick={() => {
                                resetForm();
                                changeTab('list');
                            }}
                        >
                            Cancel
                        </Button>
                    </Row>
                </CardFooter>
            </Card>
        </>
    );
}

export default TaskTypeForm;