import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listPermissions = () => {
  const token = getUserToken()
  return axios
    .get('/permission/listPermission', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server(List of permissions)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while getting the list of permissions', error)
    })
}

export const createPermission = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/permission/createPermission', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Creating permission)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while creating permission ', error)
    })
}

export const deletePermission = (permId) => {
  const token = getUserToken()
  return axios
    .delete(`/permission/deletePermission/${permId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        `Response from server(Deleting permission with id: ${permId})`,
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting permission with permId ${permId}`,
        error,
      )
    })
}

export const updatePermission = (permId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/permission/updatePermission/${permId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating permission): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating permission with permId ${permId}`,
        error,
      )
    })
}

export const updateApproved = (permId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['approved_by'] = id
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/permission/updateApproved/${permId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of permission): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating permission's approved status with permId ${permId}`,
        error,
      )
    })
}

export const updateStatus = (permId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/permission/updateStatus/${permId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of permission): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating permission's status with permId ${permId}`,
        error,
      )
    })
}

export const viewPermission = (permId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/permission/viewPermission/${permId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting a view of permission): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting a permission with permId ${permId}`,
        error,
      )
    })
}

export const getDetail = (permId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/permission/getDetail/${permId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of permission): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting permission's detail with permId ${permId}`,
        error,
      )
    })
}
