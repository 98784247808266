import React, { Fragment } from 'react';
import {getDetail} from '../../actions/MenuFunctions';
import { Card, CardBody, CardTitle, CardSubtitle, CardColumns } from 'reactstrap';

class MenuDetailList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      detail: {}
    }
  }

  componentDidMount() {
    getDetail(this.props.menuId).then((detail) => {
      this.setState({
        detail: detail[0],
      })
    });
  }

  notEmpty = (value) => {
    return value ? value : '';
  }

  render() {
    const {detail} = this.state;
    const map = {
      'menu_name': 'Menu Name',
      'upper_menu_name': 'Upper Menu',
      'link_url': 'Link URL',
      'icon_url': 'Icon URL',
      'approved': 'Approved',
      'approved_by': 'Approved By',
      'approved_dt': 'Approved Date',
      'updated_by': 'Updated By',
      'updated_dt': 'Updated Date',
      'created_by': 'Created By',
      'created_dt': 'Created Date',
    };
    if (detail['upper_menu_id'] === 0) {
      detail['upper_menu_name'] = 'Root';
    }
    return (
      <CardColumns className="lisColor1 pt-2">
        {Object.keys(map).map((key) => <Card className="border-0 lisColor1">
          <CardBody className="p-0 pl-3">
            <CardTitle>{`${map[key]}`}</CardTitle>
            <CardSubtitle className="font-weight-bold">{`${this.notEmpty(detail[key])}`}</CardSubtitle>
          </CardBody>
        </Card>)}
      </CardColumns>
    );
  }
}

export default MenuDetailList;