import React from "react";
import axios from "axios";
import { Card, Button, CardFooter } from "reactstrap";
import moment from "moment";
import {
  HealthTable,
  HealthDetailTable,
  ProjectNameBox as SprintNameBox,
  ProjectTaskPlan,
} from "../ProjectHealth/Table";
import { getRecentSprints } from "../../actions/ProjectSprintFunctions";
import { getTimeSheetWeekStat } from "../../actions/TimeSheetFunctions";
import { getAllStatusRaid } from "../../actions/SprintStatusRaidFunctions";
import {
  EffortEstimationTable,
  ResourceHoursTable,
  StatusTable,
  RaidTable,
} from "./ReportTables";
import pptxgen from "pptxgenjs";
import { LIS_LOGO, RETAIL } from "./images";
import domtoimage from "dom-to-image";
import { getReportRaid } from "actions/ProjectFunctions";
import { getDashboardProjectRisksIssues } from "actions/DashboardFunctions";

class WeeklyReport extends React.Component {
  constructor(props) {
    super(props);
    this.axiosCancelSource = axios.CancelToken.source();
    this.sprintSlide = React.createRef();
    this.resourceSlide = React.createRef();
    this.statusSlide = React.createRef();
    this.raidSlide = React.createRef();

    this.state = {
      currentSprint: {},
      prevSprint: {},
      capacity: {},
      capacityList: {},
      exportDisabled: true,
      estimation: {},
    };
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Cancel requests from Weekly Report");
  }

  componentDidMount() {
    const currentDate = formatDate(new Date());
    getRecentSprints(
      this.props.projectId,
      currentDate,
      this.axiosCancelSource.token
    ).then((data) => {
      if (data) {
        this.setState({
          currentSprint: data.sprints[0],
          prevSprint: data.sprints[1],
          capacity: data.capacity[0] ? data.capacity[0] : {},
          capacityList: data.capacityList,
          estimation: data.estimation[0],
          status: [],
          raid: [],
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentSprint !== prevState.currentSprint) {
      const currentSprint = this.state.currentSprint;
      const prevSprint = this.state.prevSprint;
      const today = moment();
      const currentSprintStartDate = moment(currentSprint.sprint_st_dt);
      const prevSprintEndDate = prevSprint && moment(prevSprint.sprint_end_dt);
      const diff = today.diff(currentSprintStartDate, "days");
      let startOfWeek = "",
        endOfWeek = "";
      if (diff >= 7 || !prevSprint) {
        startOfWeek = currentSprintStartDate.clone().format("YYYY-MM-DD");
        endOfWeek = currentSprintStartDate.clone().add(6, "days").format("YYYY-MM-DD");
      } else {
        startOfWeek = prevSprintEndDate.clone().subtract(6, "days").format("YYYY-MM-DD");
        endOfWeek = prevSprintEndDate.clone().format("YYYY-MM-DD");
      }

      //for resource table
      const curr = new Date;
      const prevWeekFirstDay = new Date(curr.setDate(curr.getDate() - curr.getDay()-7)).toISOString().split('T')[0];
      const prevWeekLastDay = new Date(curr.setDate(curr.getDate() - curr.getDay()+6)).toISOString().split('T')[0];
      getTimeSheetWeekStat(
        this.props.projectId,
        prevWeekFirstDay,
        prevWeekLastDay,
        this.axiosCancelSource.token
      ).then((data) => {
        if (data) {
          this.setState({
            //nonBillable: data.nonBillable,
            actual: data.actual,
            billable: data.billable,
            exportDisabled: false,
            dayoff: data.dayoff
          });
        }
      });

      // NOTE: Report is generated on a weekly basis
      // so if it's been more than 7 days since the start of current sprint // changed from > 7 to >=7
      // show the data from current sprint
      // else show the data from the previous sprint
      const sprint =
        diff >= 7 || !prevSprint ? this.state.currentSprint : this.state.prevSprint;
      getAllStatusRaid(
        this.props.projectId,
        sprint.sprint_id,
        this.axiosCancelSource.token
      ).then((response) => {
        if (response) {
          // commented as it is using next function to update raid state
          // if (response.raid) {
          //   this.setState({ raid: response.raid });
          // }

          if (response.status) {
            this.setState({ status: response.status });
          }
        }
      });
      getDashboardProjectRisksIssues(
        this.props.projectId,
        sprint.sprint_id,
        this.axiosCancelSource.token
      ).then((res) => {
        if (res) {
          this.setState({ raid: res.data });
        }
      });
    }
  }
  getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  getImageHeight(height) {
    if (height < 100) return "10%";
    else if (height > 100 && height < 200) return "20%";
    else if (height > 200 && height < 300) return "28%";
    else if (height > 300 && height < 350) return "35%";
    else if (height > 350 && height < 400) return "45%";
    else if (height > 400 && height < 450) return "50%";
    else if (height > 450 && height < 500) return "55%";
    else if (height > 500 && height < 600) return "65%";
    else return "75%";
  }

  export = async () => {
    this.setState({
      exportDisabled: true,
    });

    Promise.allSettled([
      await domtoimage.toPng(this.sprintSlide.current),
      await domtoimage.toPng(this.resourceSlide.current),
      await domtoimage.toPng(this.statusSlide.current),
      await domtoimage.toPng(this.raidSlide.current),
      // await domtoimage.toPng(this.issueSlide.current),
    ]).then(
      ([
        sprintImgPro,
        resourceImgPro,
        statusImgPro,
        raidImgPro,
        // issueImgPro,
      ]) => {
        const sprintSvgDataUrl = sprintImgPro.value;
        const resourceSvgDataUrl = resourceImgPro.value;
        const statusSvgDataUrl = statusImgPro.value;
        const raidSvgDataUrl = raidImgPro.value;
        // const issueSvgDataUrl = issueImgPro.value;

        Promise.allSettled([
          this.getImageDimensions(sprintSvgDataUrl),
          this.getImageDimensions(resourceSvgDataUrl),
          this.getImageDimensions(statusSvgDataUrl),
          this.getImageDimensions(raidSvgDataUrl),
          // this.getImageDimensions(issueSvgDataUrl),
        ]).then(
          ([
            sprintImgDims,
            resourceImgDims,
            statusImgDims,
            raidImgDims,
            // issueImgDims,
          ]) => {
            const projectCategory = this.props.project.project_category;
            const projectName = this.props.project.project_name;
            const currentDate = moment().clone().format("DD/MMM/YYYY");

            const pres = new pptxgen();
            const slideNumberProps = {
              x: "94%",
              y: "92%",
              color: "#818181",
              fontFace: "Calibri",
              fontSize: 12,
            };
            const projectMeta = `${projectName} | ${projectCategory}`;
            const projectMetaProps = {
              color: "#009999",
              fontFace: "Verdana",
              align: "right",
              x: 2.8,
              y: "10%",
              h: "10%",
              fontSize: 28,
            };
            pres.layout = "LAYOUT_WIDE";

            pres.defineSlideMaster({
              title: "LIS_TEMPLATE",
              background: { color: "#FFFFFF" },
              margin: [0.5, 0.25, 1.0, 1.0],
              objects: [
                { image: { data: LIS_LOGO, x: 0.5, y: 0.1, w: "20%", h: "10%" } },
              ],
            });

            pres
              .addSlide({ masterName: "LIS_TEMPLATE" })
              .addImage({ data: RETAIL, x: 0.5, y: 1.8, w: "92%", h: "45%" })
              .addText(`Weekly Project Status\n${projectName}\n${currentDate}`, {
                color: "#009999",
                fontSize: 28,
                align: "right",
                y: "80%",
                x: 2.8,
                h: "15%",
                fontFace: "Verdana",
              });

            pres
              .addSlide({ masterName: "LIS_TEMPLATE" })
              .addText(projectMeta, { ...projectMetaProps })
              .addImage({
                data: sprintSvgDataUrl,
                x: 0.5,
                y: "22%",
                w: "92%",
                h: this.getImageHeight(sprintImgDims.value.h),
              }).slideNumber = { ...slideNumberProps };

            pres
              .addSlide({ masterName: "LIS_TEMPLATE" })
              .addText(projectMeta, { ...projectMetaProps })
              .addImage({
                data: resourceSvgDataUrl,
                x: 0.5,
                y: "22%",
                w: "92%",
                h: this.getImageHeight(resourceImgDims.value.h),
              }).slideNumber = { ...slideNumberProps };

            pres
              .addSlide({ masterName: "LIS_TEMPLATE" })
              .addText(projectMeta, { ...projectMetaProps })
              .addImage({
                data: statusSvgDataUrl,
                x: 0.5,
                y: "22%",
                w: "92%",
                h: this.getImageHeight(statusImgDims.value.h),
              }).slideNumber = { ...slideNumberProps };

            pres
              .addSlide({ masterName: "LIS_TEMPLATE" })
              .addText(projectMeta, { ...projectMetaProps })
              .addImage({
                data: raidSvgDataUrl,
                x: 0.5,
                y: "22%",
                w: "92%",
                h: this.getImageHeight(raidImgDims.value.h),
              }).slideNumber = { ...slideNumberProps };

            pres.writeFile(`${this.props.project["project_name"]} - Weekly Report - ${currentDate}.pptx`);
            this.setState({ exportDisabled: false });
          }
        );
        // TODO: currently there are only two project categories
      }
    );
  };

  render() {
    const currentSprint = this.state.currentSprint;
    const prevSprint = this.state.prevSprint;
    const today = moment();
    const currentSprintStartDate =
      currentSprint.sprint_st_dt && moment(currentSprint.sprint_st_dt);
    const diff = currentSprintStartDate && today.diff(currentSprintStartDate, "days");
    let sprintName = "";
    if (diff >= 7 || !prevSprint) {
      sprintName = currentSprint.sprint_name;
    } else {
      sprintName = prevSprint.sprint_name;
    }

    return (
      <Card className="border-top-0 shadow-none p-2 pl-3">
        <div>
          <div id="sprint-slide" ref={this.sprintSlide}>
            <div className="row">
              <div className="col">
                <SprintNameBox name={sprintName} />
              </div>
              <div className="col">
                <HealthTable projectId={this.props.projectId} />
              </div>
              <div className="col">
                <HealthDetailTable projectId={this.props.projectId} />
              </div>
              <div className="col">
                <EffortEstimationTable
                  capacityList={this.state.capacityList}
                  capacity={this.state.capacity}
                  project={this.props.project}
                  estimation={this.state.estimation}
                />
              </div>
            </div>
            <div className="row pt-4">
              <div className="col">
                <ProjectTaskPlan projectId={this.props.projectId} />
              </div>
            </div>
          </div>
          <div className="row pt-4" id="resource-slide" ref={this.resourceSlide}>
            <div className="col">
              <ResourceHoursTable
                billable={this.state.billable}
                actual={this.state.actual}
                dayoff={this.state.dayoff}

              />
            </div>
          </div>
          <div id="status-slide" ref={this.statusSlide}>
            <div className="row pt-4">
              <div className="col">
                <StatusTable data={this.state.status} type="news" />
              </div>
            </div>
            <div className="row pt-4">
              <div className="col">
                <StatusTable data={this.state.status} type="accomplishment" />
              </div>
            </div>
            <div className="row pt-4">
              <div className="col">
                <StatusTable data={this.state.status} type="plan" />
              </div>
            </div>
          </div>

          <div id="raid-slide" ref={this.raidSlide}>
            <div className="row pt-4">
              <div className="col">
                <RaidTable data={this.state.raid} type="risk" />
              </div>
            </div>
            <div className="row pt-4">
              <div className="col">
                <RaidTable data={this.state.raid} type="issue" />
              </div>
            </div>
          </div>
        </div>
        <CardFooter>
          <Button
            color="primary"
            type="button"
            onClick={this.export}
            style={{ float: "right" }}
            disabled={this.state.exportDisabled}
          >
            Export
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

export const formatDate = (date) => {
  if (date) {
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  return "";
};

export default WeeklyReport;
