import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const fetchAllStream = () => {
  const token = getUserToken()
  return axios
    .get('/stream/listStream', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createStream = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/stream/createStream', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteStream = (streamId) => {
  const token = getUserToken()
  return axios
    .delete(`/stream/deleteStream/${streamId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting stream with streamId ${streamId}`,
        error,
      )
    })
}

export const updateStream = (streamId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/stream/updateStream/${streamId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating stream): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating stream with streamId ${streamId}`,
        error,
      )
    })
}

export const updateApproved = (streamId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['approved_by'] = id
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/stream/updateApproved/${streamId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of stream): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating stream's approved status with streamid ${streamId}`,
        error,
      )
    })
}

export const updateStatus = (streamId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/stream/updateStatus/${streamId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of stream): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating stream's status with streamid ${streamId}`,
        error,
      )
    })
}

export const getDetail = (id) => {
  return axios
    .get(`/stream/getDetail/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log(response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}
