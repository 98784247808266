import React from "react"
import {IconButton, MuiThemeProvider} from "@material-ui/core"
import {theme} from "../../utils/Theme"
import _ from "lodash"
import Tooltip from "@material-ui/core/Tooltip"
import {Cancel, CheckCircle} from "@material-ui/icons"
import MaterialTable from "material-table"
import Loading from "../../components/Loading"
import {Button} from "reactstrap"
import ConfigDetailList from "../../components/ConfigDetailList"

const PermissionTable = ({
  permissionListData,
  handleEdit,
  handleDelete,
  onStatusIconClick,
  onApprovedIconClick,
  isLoading,
  isActionAllowed,
  changeTab
}) => {

  let permissionList = _.cloneDeep(permissionListData)
  permissionList = permissionList.map(row => {
    const rowString = JSON.stringify(row)

    row['status_icon'] = <IconButton
      onClick={onStatusIconClick}
      data-config={rowString}
      disabled={!isActionAllowed('status')}
    >
      {row['status'] === 'I' ? (
        <Tooltip title="Inactive">
          <Cancel style={{ color: 'red' }} />
        </Tooltip>
      ) : (
        <Tooltip title="Active">
          <CheckCircle style={{ color: 'green' }} />
        </Tooltip>
      )}
    </IconButton>


    row['approved_icon'] = <IconButton
      onClick={onApprovedIconClick}
      data-config={rowString}
      disabled={!isActionAllowed('approve')}
    >
      {row['approved'] === 'N' ? (
        <Tooltip title="Unapproved">
          <Cancel style={{ color: 'red' }} />
        </Tooltip>
      ) : (
        <Tooltip title="Approved">
          <CheckCircle style={{ color: 'green' }} />
        </Tooltip>
      )}
    </IconButton>

    return row
  })

  const cellStyle = {
    paddingTop: '0px',
    paddingBottom: '0px',
  }

  const columns = [
    { title: 'Permission', field: 'perm_name', cellStyle: cellStyle},
    { title: 'Status', field: 'status_icon', cellStyle: cellStyle},
    { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle},
  ]

  return <MuiThemeProvider theme={theme}>
    <MaterialTable
      components={{
        OverlayLoading: props => <Loading />
      }}
      localization={{
        body: {
          emptyDataSourceMessage: !isLoading ? 'No records to display!' : ''
        }
      }}
      isLoading={isLoading}
      title={
        <div style={{width: '500px'}}>
          <Button
            type="button"
            title="Add"
            color="primary"
            style={{
              margin: '0px 10px 5px 0px',
              borderRadius: '50%',
              padding: '0px',
              width: '30px',
              height: '30px',
              float: 'left',
            }}
            onClick={() => {
              changeTab('add')
            }}
            hidden={!isActionAllowed('add')}
          >
            <i className="fas fa-plus fa-sm" />
          </Button>
          <h2 style={{color: '#00948B'}}>Permission List</h2>
        </div>
      }
      columns={columns}
      data={permissionList}
      options={{
        headerStyle: {
          color: '#00948B',
          fontWeight: 'bold',
          paddingTop: '0px',
          paddingBottom: '5px',
        },
        rowStyle: {
          hover: true,
        },
        actionsColumnIndex: -1,
        detailPanelColumnAlignment: 'right'
      }}
      actions={[
        {
          icon: 'edit',
          tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
          fontSize: 'small',
          disabled: !isActionAllowed('edit'),
          onClick: (event, rowData) => handleEdit(rowData),
        },
        {
          icon: 'delete',
          tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
          fontSize: 'small',
          disabled: !isActionAllowed('delete'),
          onClick: (event, rowData) => handleDelete(rowData),
        }
      ]}
      detailPanel={[
        {
          icon: 'toc',
          tooltip: 'Details',
          disabled: !isActionAllowed('detail'),
          render: rowData => {
            const permId = rowData['perm_id']
            return <ConfigDetailList id={permId} type={'permission'} />
          }
        }
      ]}
    />
  </MuiThemeProvider>
}

export default PermissionTable