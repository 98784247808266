import React from "react";
import MaterialTable from "material-table";
import Loading from "../Loading";

const headerStyle = {
  fontWeight: "bold",
  paddingTop: "0px",
  paddingBottom: "5px",
};

const StyledTitle = ({ title }) => <h3 className='text-lisColor1'>{title}</h3>;

export default function StyledTable({
  isLoading,
  detailPanel,
  data,
  columns,
  options,
  titleName,
  onRowAdd,
  onRowUpdate,
  onRowDelete,
}) {
  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: !isLoading ? "No records to display" : "",
        },
      }}
      detailPanel={detailPanel}
      components={{
        OverlayLoading: () => <Loading />,
      }}
      isLoading={isLoading}
      columns={columns}
      data={data}
      options={{
        headerStyle: headerStyle,
        actionsColumnIndex: -1,
        detailPanelColumnAlignment: "right",
        paging: !isLoading ? false : true,
        search: false,
        ...options,
      }}
      title={titleName && <StyledTitle title={titleName} />}
      editable={{
        onRowAdd: (row) =>
          new Promise((resolve) => {
            onRowAdd(row);
            resolve();
          }),
        onRowUpdate: (row) =>
          new Promise((resolve) => {
            onRowUpdate(row);
            resolve();
          }),
        onRowDelete: (row) =>
          new Promise((resolve) => {
            onRowDelete(row);
            resolve();
          }),
      }}
    />
  );
}
