import React, { Fragment, useState } from 'react'
import Header from 'components/Headers/Header'

import Container from '@material-ui/core/Container'
import MaterialTable, { MTableHeader, MTableToolbar } from 'material-table'
import classnames from 'classnames'
import ActionFeedback from '../ActionFeedbackAlert'

import { Cancel, CheckCircle } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import ProjectInfoCard from '../InfoCard'
import ProjectStateModal from '../ProjectStateModal'
import Loading from '../Loading'
import 'assets2/button.scss'

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  CardHeader,
  CardBody,
  Table,
  InputGroup,
} from 'reactstrap'
import { TableRow, Tooltip, Chip } from '@material-ui/core'
import _ from 'lodash'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../utils/Theme'
import ProjectDetailList from 'components/ProjectDetailList'
import { Link } from 'react-router-dom'

const formatCurrentTabName = (activeTab, label) => {
  return activeTab === label.toLowerCase() ? <b>{label}</b> : `${label}`
}

const ProjectInformation = (props) => {
  const [filter, setFilter] = useState(false)
  const activeTab = props.currentTab

  let project_list = _.cloneDeep(props.project_list)
  // project_list = project_list.filter(
  //   (project) => project.status == "A" && project.approved == "Y"
  // );
  project_list = _.orderBy(
    project_list,
    ['status', 'approved'],
    ['asc', 'desc']
  )
  project_list = project_list.map((row) => {
    const rowString = JSON.stringify(row)
    row['status_icon'] = (
      <IconButton
        onClick={props.onStatusIconClick}
        data-project={rowString}
        disabled={!props.isActionAllowed('status')}
      >
        {row['status'] === 'I' ? (
          <Tooltip title='Inactive'>
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title='Active'>
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    )
    row['approved_icon'] = (
      <IconButton
        onClick={props.onApprovedIconClick}
        data-project={rowString}
        disabled={!props.isActionAllowed('approve')}
      >
        {row['approved'] === 'N' ? (
          <Tooltip title='Unapproved'>
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title='Approved'>
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    )
    return row
  })

  return (
    <Fragment>
      <Header />
      {props.message && <ActionFeedback message={props.message} />}
      {props.isModal && (
        <ProjectStateModal
          isModal={props.isModal}
          resetState={props.resetState}
          selectedRowData={props.selectedRowData}
          handleStateChange={props.handleStateChange}
          handleUserInput={props.handleUserInput}
          handleSubmit={props.handleSubmit}
          handleSubmitState={props.handleSubmitState}
          handleBlur={props.handleBlur}
          state_list={props.state_list}
          fields={props.fields}
          isValid={props.isValid}
        />
      )}
      <Container
        className='mt-3 ml-0'
        fluid
        style={{ maxWidth: '100%', margin: 'auto auto' }}
        // style={{ maxWidth: '100%', margin: 'auto auto'}}
      >
        <Nav tabs className='border-bottom-0'>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'list' })}
              onClick={() => {
                props.resetState()
                props.changeTab('list')
              }}
              style={{ backgroundColor: activeTab === 'list' ? 'white' : null }}
            >
              {formatCurrentTabName(activeTab, 'List')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'add' })}
              onClick={() => {
                props.resetState()
                props.changeTab('add')
              }}
              style={{ backgroundColor: activeTab === 'add' ? 'white' : null }}
            >
              {props.editMode
                ? formatCurrentTabName(activeTab, 'Edit')
                : formatCurrentTabName(activeTab, 'Add')}
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='list'>
            {/* <Link to={`/project/create`}>
          <Button
            type="button"
            title="Add new project"
            color="primary"
            style={{
              marginBottom: '5px',
              borderRadius: '50%',
              padding: '0',
              width: '30px',
              height: '30px',
            }}
          > 
            <i class="fas fa-plus fa-sm"></i>
          </Button>
        </Link> */}

            <Fragment>
              <MuiThemeProvider theme={theme}>
                <MaterialTable
                  localization={{
                    body: {
                      emptyDataSourceMessage: props.isFetchComplete
                        ? 'No records to display'
                        : '',
                    },
                  }}
                  components={{
                    OverlayLoading: (props) => <Loading />,
                  }}
                  isLoading={!props.isFetchComplete}
                  title={
                    <div style={{ width: '500px' }}>
                      <Button
                        type='button'
                        title='Add new project'
                        color='primary'
                        style={{
                          margin: '0px 10px 5px 0px',
                          borderRadius: '50%',
                          padding: '0px',
                          width: '30px',
                          height: '30px',
                          float: 'left',
                        }}
                        onClick={() => {
                          props.resetState()
                          props.changeTab('add')
                        }}
                        hidden={!props.isActionAllowed('add')}
                      >
                        <i class='fas fa-plus fa-sm'></i>
                        {/* {props.editMode
                          ? formatCurrentTabName(activeTab, 'Edit')
                          : formatCurrentTabName(activeTab, 'Add')} */}
                      </Button>

                      <h2 style={{ color: '#00948B' }}>Project Information</h2>
                    </div>
                  }
                  columns={props.project_list_columns}
                  // columns={[
                  //   { title: "Code", field: "project_cd" },
                  //   { title: "Name", field: "project_name" },
                  //   { title: "Type", field: "project_type" },
                  //   { title: "Stream", field: "project_category" },
                  //   { title: "Category", field: "project_category" },
                  //   { title: "Status", field: "status_icon", filtering: false },
                  //   {
                  //     title: "Approved",
                  //     field: "approved_icon",
                  //     filtering: false,
                  //   },
                  // ]}
                  data={project_list}
                  onRowClick={props.handleRowClick}
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Edit',
                      disabled: !props.isActionAllowed('edit'),
                      onClick: (event, rowData) => {
                        props.handleEdit(rowData)
                      },
                    },

                    {
                      icon: 'delete',
                      tooltip: 'Delete Project',
                      disabled: !props.isActionAllowed('delete'),
                      onClick: (event, rowData) => {
                        props.handleDelete(rowData)
                      },
                    },
                    {
                      icon: 'note',
                      tooltip: 'State',
                      disabled: !props.isActionAllowed('state'),
                      onClick: (event, rowData) => {
                        props.handleProjectStateModal(rowData)
                      },
                    },
                    {
                      icon: 'filter_alt',
                      tooltip: 'Enable Filter',
                      isFreeAction: true,
                      onClick: (event) => {
                        setFilter(!filter)
                      },
                    },
                  ]}
                  options={{
                    headerStyle: {
                      color: '#00948B',
                      fontWeight: 'bold',
                      paddingTop: '0px',
                      paddingBottom: '5px',
                    },

                    actionsColumnIndex: -1,
                    detailPanelColumnAlignment: 'right',
                    pageSize: 10,
                    filtering: filter,
                  }}
                  detailPanel={[
                    {
                      icon: 'toc',
                      disabled: !props.isActionAllowed('detail'),
                      render: (rowData) => {
                        const projectId = rowData['project_id']
                        return <ProjectDetailList projectId={projectId} />
                      },
                    },
                  ]}
                />
              </MuiThemeProvider>
            </Fragment>
          </TabPane>

          <TabPane tabId='add'>
            <Fragment>
              {props.message && <ActionFeedback message={props.message} />}
              <Card className='border-top-0 shadow-none'>
                <CardHeader
                  style={{
                    fontSize: 20,
                    color: '#00948B',
                    // fontWeight: 'bold',
                    textAlign: 'center',
                    padding: 10,
                  }}
                >
                  Project Information
                </CardHeader>
                {props.fields && (
                  <CardBody>
                    <Form
                      // onSubmit={(e) => {
                      //   e.preventDefault()
                      // }}
                      onSubmit={props.handleSubmit}
                    >
                      <fieldset
                        disabled={
                          props.fields['approved'] === 'Y' ? true : false
                        }
                      >
                        <Row form>
                          <Col md={2}>
                            <FormGroup>
                              <Label for='projectCode'>Code</Label>
                              <Input
                                type='text'
                                name='project_cd'
                                value={props.fields['project_cd']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={props.isValid['project_cd'] === false}
                                onBlur={props.handleBlur}
                              />
                              <FormFeedback invalid>
                                Project code cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={8}>
                            <FormGroup>
                              <Label for='projectName'>Name</Label>
                              <Input
                                type='text'
                                name='project_name'
                                value={props.fields['project_name']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={
                                  props.isValid['project_name'] === false
                                }
                                onBlur={props.handleBlur}
                              />
                              <FormFeedback invalid>
                                Project name cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <Label for='projectAbbr'>Abbr.</Label>
                              <Input
                                type='text'
                                name='project_abbr'
                                value={props.fields['project_abbr']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={
                                  props.isValid['project_abbr'] === false
                                }
                                onBlur={props.handleBlur}
                              />
                              <FormFeedback invalid>
                                Project Abbr cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={4}>
                            <FormGroup>
                              <Label for='projectType'>Type</Label>
                              <Input
                                type='select'
                                name='project_type'
                                value={props.fields['project_type']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={
                                  props.isValid['project_type'] === false
                                }
                                onBlur={props.handleBlur}
                              >
                                <option value=''></option>
                                {props.createProjectType()}
                              </Input>
                              <FormFeedback invalid>
                                Project Type cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>

                          <Col md={4}>
                            <FormGroup>
                              <Label for='projectCategory'>Category</Label>
                              <Input
                                type='select'
                                name='project_category'
                                value={props.fields['project_category']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={
                                  props.isValid['project_category'] === false
                                }
                                onBlur={props.handleBlur}
                              >
                                <option value=''></option>
                                {props.createProjectCategory()}
                              </Input>
                              <FormFeedback invalid>
                                Project Category cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>

                          <Col md={4}>
                            <FormGroup>
                              <Label for='projectStream'>Stream</Label>
                              <Input
                                type='select'
                                name='stream_id'
                                value={props.fields['stream_id']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={props.isValid['stream_id'] === false}
                                onBlur={props.handleBlur}
                              >
                                <option value=''></option>
                                {props.createProjectStream()}
                              </Input>
                              <FormFeedback invalid>
                                Project Stream cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <FormGroup>
                              <Label for='clientName'>Client Name</Label>
                              <Input
                                type='select'
                                name='client_id'
                                value={props.fields['client_id']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={props.isValid['client_id'] === false}
                                onBlur={props.handleBlur}
                              >
                                <option value=''></option>
                                {props.createClientList()}
                              </Input>
                              <FormFeedback invalid>
                                Client name cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label for='clientType'>Client Type</Label>
                              <Input
                                type='select'
                                name='client_type_id'
                                id='clientType'
                                value={props.fields['client_type_id']}
                                bsSize='sm'
                                // onChange={props.handleUserInput}
                                // invalid={props.isValid['client_type_id'] === false}
                                // onBlur={props.handleBlur}
                                disabled
                              >
                                <option value=''></option>
                                {props.createClientTypeList()}
                              </Input>
                              <FormFeedback invalid>
                                Client type cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for='lead'>PMO Manager/Lead</Label>
                              <Input
                                type='select'
                                name='lead_id'
                                value={props.fields['lead_id']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={props.isValid['lead_id'] === false}
                                onBlur={props.handleBlur}
                              >
                                <option value=''></option>
                                {props.createEmployeeList()}
                              </Input>
                              <FormFeedback invalid>
                              PMO Manager/Lead cannot be empty.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for='exampleCity'>Start Date</Label>
                              <Input
                                type='date'
                                name='project_st_dt'
                                value={props.fields['project_st_dt']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={
                                  props.isValid['project_st_dt'] === false
                                }
                                onBlur={props.handleBlur}
                              />
                              <FormFeedback invalid>
                                Start date must be valid
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for='exampleState'>End Date</Label>
                              <Input
                                type='date'
                                name='project_end_dt'
                                value={props.fields['project_end_dt']}
                                bsSize='sm'
                                onChange={props.handleUserInput}
                                invalid={
                                  props.isValid['project_end_dt'] === false
                                }
                                onBlur={props.handleBlur}
                              />
                              <FormFeedback invalid>
                                End date must be valid.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup row>
                          <Col md={2}>
                            <Label for='model_id'>Project Model</Label>
                            <Input
                              type='select'
                              name='model_id'
                              value={props.fields['model_id']}
                              bsSize='sm'
                              onChange={props.handleUserInput}
                              invalid={props.isValid['model_id'] === false}
                              onBlur={props.handleBlur}
                            >
                              <option value=''></option>
                              {props.createProjectModel()}
                            </Input>

                            <FormFeedback invalid>
                              Project Model cannot be empty
                            </FormFeedback>
                          </Col>
                          <Col md={2}>
                            <Label for='model_track'>Model Track</Label>
                            <Input
                              type='select'
                              name='model_track'
                              value={props.fields['model_track']}
                              bsSize='sm'
                              onChange={props.handleUserInput}
                              invalid={props.isValid['model_track'] === false}
                              onBlur={props.handleBlur}
                            >
                              <option value=''></option>
                              <option value='S' key={`story-point-s`}>
                                Story Point
                              </option>
                              <option value='H' key={`hours-h`}>
                                Hours
                              </option>
                            </Input>

                            <FormFeedback invalid>
                              Model Track cannot be empty
                            </FormFeedback>
                          </Col>
                          <Col md={2}>
                            <Label for='velocity'>Velocity per resource</Label>
                            <Input
                              type='number'
                              name='capacity_hrs'
                              value={props.fields['capacity_hrs']}
                              bsSize='sm'
                              onChange={props.handleUserInput}
                              invalid={props.isValid['capacity_hrs'] === false}
                              onBlur={props.handleBlur}
                            />
                            Hrs
                            <FormFeedback invalid>
                              Velocity should be a positive number
                            </FormFeedback>
                          </Col>
                          <Col md={2}>
                            {/* very very bad aproach but works */}
                            <Label for='points' style={{ color: 'white' }}>
                              Points
                            </Label>
                            <Input
                              type='number'
                              name='capacity_points'
                              value={props.fields['capacity_points']}
                              bsSize='sm'
                              onChange={props.handleUserInput}
                              invalid={
                                props.isValid['capacity_points'] === false
                              }
                              onBlur={props.handleBlur}
                            />
                            Points
                            <FormFeedback invalid>
                              Points should be a positive number.
                            </FormFeedback>
                          </Col>

                          <Col md={2}>
                            <Label for='taskPlan'>Task plan</Label>

                            <Input
                              type='select'
                              name='work_plan'
                              value={props.fields['work_plan']}
                              bsSize='sm'
                              onChange={props.handleUserInput}
                              invalid={props.isValid['work_plan'] === false}
                              onBlur={props.handleBlur}
                            >
                              <option value=''></option>
                              {props.createTaskPlan()}
                            </Input>
                            <FormFeedback invalid>
                              Task plan should not be empty
                            </FormFeedback>
                          </Col>
                          <Col md={2}>
                            <Label for='days'> Work days</Label>
                            <Input
                              type='number'
                              name='work_days'
                              value={props.fields['work_days']}
                              bsSize='sm'
                              onChange={props.handleUserInput}
                              invalid={props.isValid['work_days'] === false}
                              onBlur={props.handleBlur}
                            />
                            <FormFeedback invalid>
                              Work day should be a positive number.
                            </FormFeedback>
                          </Col>
                        </FormGroup>

                        <FormGroup>
                          <Label for='remarks'>Remarks</Label>
                          <Input
                            type='textarea'
                            rows='5'
                            name='remarks'
                            value={props.fields['remarks']}
                            bsSize='sm'
                            onChange={props.handleUserInput}
                            invalid={props.isValid['remarks'] === false}
                            onBlur={props.handleBlur}
                          />
                          <FormFeedback invalid>
                            Remarks should not be empty
                          </FormFeedback>
                        </FormGroup>
                        <div
                          style={{
                            maxHeight: 'auto',
                          }}
                        >
                          <Table
                            name='risks'
                            responsive
                            size='sm'
                            style={{ maxHeight: '50px' }}
                          >
                            <thead
                              style={{
                                fontWeight: 'bold',
                                color: '#00948B',
                                backgroundColor: '#E1EBF0',
                                overflowY: 'scroll',
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    padding: 5,
                                    width: '95%',
                                  }}
                                >
                                  Risks and challenges
                                </th>
                                <th>
                                  <Button
                                    size='sm'
                                    style={{
                                      float: 'right',
                                      padding: '0px 5px 0px 5px',
                                      backgroundColor: '#00948B',
                                      color: 'white',
                                    }}
                                    onClick={() => props.addRiskRow()}
                                  >
                                    <i className='fas fa-plus fa-xs'></i>
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.risks.map((row) => (
                                <tr>
                                  <td>
                                    <Input
                                      type='text'
                                      bsSize='sm'
                                      id={row.id}
                                      value={row.risk_name}
                                      onChange={props.handleChangeRiskRow}
                                      disabled={!props.editRow}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      size='sm'
                                      style={{
                                        float: 'right',
                                        padding: '0px 5px 0px 5px',
                                        backgroundColor: '#00948B',
                                        color: 'white',
                                      }}
                                      onClick={(e) =>
                                        props.deleteRiskRow(row, e)
                                      }
                                    >
                                      <i className='fas fa-minus fa-xs'></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div style={{ maxHeight: '20px' }}>&nbsp; </div>
                        <div style={{ maxHeight: 'auto' }}>
                          <Table
                            name='assumption'
                            responsive
                            size='sm'
                            style={{ maxHeight: '50px' }}
                            // onChange={props.handleChangeTable}
                          >
                            <thead
                              style={{
                                fontWeight: 'bold',
                                color: '#00948B',
                                backgroundColor: '#E1EBF0',
                                overflowY: 'scroll',
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    padding: 5,
                                    width: '95%',
                                  }}
                                >
                                  Assumptions
                                </th>
                                <th>
                                  <Button
                                    size='sm'
                                    style={{
                                      float: 'right',
                                      padding: '0px 5px 0px 5px',
                                      backgroundColor: '#00948B',
                                      color: 'white',
                                    }}
                                    onClick={() => props.addAssumptionRow()}
                                  >
                                    <i className='fas fa-plus fa-xs'></i>
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.assumption.map((row) => (
                                <TableRow key={row.id}>
                                  <td>
                                    <Input
                                      type='text'
                                      bsSize='sm'
                                      id={row.id}
                                      value={row.assumption_name}
                                      onChange={props.handleChangeAssumptionRow}
                                      disabled={!props.editRow}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      size='sm'
                                      style={{
                                        float: 'right',
                                        padding: '0px 5px 0px 5px',
                                        backgroundColor: '#00948B',
                                        color: 'white',
                                      }}
                                      onClick={(e) =>
                                        props.deleteAssumptionRow(row, e)
                                      }
                                    >
                                      <i className='fas fa-minus fa-xs'></i>
                                    </Button>
                                  </td>
                                </TableRow>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div style={{ maxHeight: '20px' }}>&nbsp; </div>
                        <div
                          style={{
                            maxHeight: 'auto',
                          }}
                        >
                          <Table
                            name='issues'
                            responsive
                            size='sm'
                            style={{ maxHeight: '50px' }}
                          >
                            <thead
                              style={{
                                fontWeight: 'bold',
                                color: '#00948B',
                                backgroundColor: '#E1EBF0',
                                overflowY: 'scroll',
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    padding: 5,
                                    width: '95%',
                                  }}
                                >
                                  Issues
                                </th>
                                <th>
                                  <Button
                                    size='sm'
                                    style={{
                                      float: 'right',
                                      padding: '0px 5px 0px 5px',
                                      backgroundColor: '#00948B',
                                      color: 'white',
                                    }}
                                    onClick={() => props.addIssueRow()}
                                  >
                                    <i className='fas fa-plus fa-xs'></i>
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.issues.map((row) => (
                                <TableRow key={row.id}>
                                  <td>
                                    <Input
                                      type='text'
                                      bsSize='sm'
                                      id={row.id}
                                      value={row.issue_name}
                                      onChange={props.handleChangeIssueRow}
                                      disabled={!props.editRow}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      size='sm'
                                      style={{
                                        float: 'right',
                                        padding: '0px 5px 0px 5px',
                                        backgroundColor: '#00948B',
                                        color: 'white',
                                      }}
                                      onClick={(e) =>
                                        props.deleteIssueRow(row, e)
                                      }
                                    >
                                      <i className='fas fa-minus fa-xs'></i>
                                    </Button>
                                  </td>
                                </TableRow>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div style={{ maxHeight: '20px' }}>&nbsp; </div>
                        <div style={{ maxHeight: 'auto' }}>
                          <Table
                            name='dependencies'
                            responsive
                            size='sm'
                            style={{ maxHeight: '30px' }}
                            // onChange={props.handleChangeTable}
                          >
                            <thead
                              style={{
                                fontWeight: 'bold',
                                color: '#00948B',
                                backgroundColor: '#E1EBF0',
                                overflowY: 'scroll',
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    padding: 5,
                                    width: '95%',
                                  }}
                                >
                                  Dependency
                                </th>
                                <th>
                                  <Button
                                    size='sm'
                                    style={{
                                      float: 'right',
                                      padding: '0px 5px 0px 5px',
                                      backgroundColor: '#00948B',
                                      color: 'white',
                                    }}
                                    onClick={() => props.addDependencyRow()}
                                  >
                                    <i className='fas fa-plus fa-xs'></i>
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.dependency.map((row) => (
                                <TableRow key={row.id}>
                                  <td>
                                    <Input
                                      type='text'
                                      bsSize='sm'
                                      id={row.id}
                                      value={row.dependency_name}
                                      onChange={props.handleChangeDependencyRow}
                                      disabled={!props.editRow}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      size='sm'
                                      style={{
                                        float: 'right',
                                        padding: '0px 5px 0px 5px',
                                        backgroundColor: '#00948B',
                                        color: 'white',
                                      }}
                                      onClick={(e) =>
                                        props.deleteDependencyRow(row, e)
                                      }
                                    >
                                      <i className='fas fa-minus fa-xs'></i>
                                    </Button>
                                  </td>
                                </TableRow>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </fieldset>
                      <div style={{ maxHeight: '20px' }}>&nbsp; </div>
                      <Row style={{ float: 'right' }}>
                        <Button
                          type='submit'
                          color='primary'
                          className='fixedWidthButton'
                          hidden={
                            props.fields['approved'] === 'Y' ? true : false
                          }
                        >
                          Save
                        </Button>
                        <Link to={`/project`}>
                          <Button
                            color='danger'
                            type='button'
                            className='fixedWidthButton'
                            onClick={() => {
                              props.resetState()
                              props.changeTab('list')
                            }}
                          >
                            Cancel
                          </Button>
                        </Link>
                      </Row>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Fragment>
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  )
}

export default ProjectInformation
