import React, { Fragment, useState, useEffect } from 'react'
import Header from 'components/Headers/Header'
import ActionFeedback from '../ActionFeedbackAlert'

import Container from '@material-ui/core/Container'
import MaterialTable, { MTableHeader } from 'material-table'
import classnames from 'classnames'
import { Cancel, CheckCircle, Toc } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ClientInfoCard from '../InfoCard'
import Loading from '../Loading';
import _ from 'lodash'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../utils/Theme'

import ClientDetailList from '../ClientDetailList'

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  FormFeedback,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody,
  Row,
} from 'reactstrap'

const formatCurrentTabName = (activeTab, label) => {
  return activeTab === label.toLowerCase() ? <b>{label}</b> : `${label}`
}

const ClientInformation = (props) => {
  const activeTab = props.currentTab
  let clientListData = _.cloneDeep(props.clientListData)
  clientListData = clientListData.map((row) => {
    const rowString = JSON.stringify(row)
    row['status_icon'] = (
      <IconButton onClick={props.onStatusIconClick} data-client={rowString}
        disabled={!props.isActionAllowed('status')}>
        {row['status'] === 'I' ? (
          <Tooltip title="Inactive">
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title="Active">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    )
    row['approved_icon'] = (
      <IconButton onClick={props.onApprovedIconClick} data-client={rowString}
        disabled={!props.isActionAllowed('approve')}>
        {row['approved'] === 'N' ? (
          <Tooltip title="Unapproved">
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title="Approved">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    )
    return row
  })

  return (
    <Fragment>
      <Header />
      {props.message && <ActionFeedback message={props.message} />}
      <Container
        className="mt-3"
        style={{ maxWidth: '100%', margin: 'auto auto' }}
      >
        {/* <Nav tabs className="border-bottom-0">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'list' })}
              onClick={() => {
                props.resetState();
                props.changeTab('list');
              }}
              style={{backgroundColor: activeTab === 'list' ? 'white' : null}}
            >
              {formatCurrentTabName(activeTab, 'List')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'add' })}
              onClick={() => {
                props.resetState();
                props.changeTab('add');
              }}
              style={{backgroundColor: activeTab === 'add' ? 'white' : null}}
            >
              {props.editMode ? formatCurrentTabName(activeTab, 'Edit') : formatCurrentTabName(activeTab, 'Add')}
            </NavLink>
          </NavItem>
        </Nav> */}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="list">
            <Card className="border-top-0 shadow-none">
              <MuiThemeProvider theme={theme}>
                <MaterialTable
                  components={{
                    OverlayLoading: props => (<Loading />)
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: 
                        props.isFetchComplete ? 'No records to display' : '',
                    }
                  }}
                  isLoading={!props.isFetchComplete}
                  title={
                    <div style={{ width: '500px' }}>
                      <Button
                        type="button"
                        title="Add new client"
                        color="primary"
                        style={{
                          margin: '0px 10px 5px 0px',
                          borderRadius: '50%',
                          padding: '0px',
                          width: '30px',
                          height: '30px',
                          float: 'left',
                        }}
                        onClick={() => {
                          props.resetState()
                          props.changeTab('add')
                        }}
                        hidden={!props.isActionAllowed('add')}
                      >
                        <i class="fas fa-plus fa-sm"></i>
                        {/* {props.editMode
                          ? formatCurrentTabName(activeTab, 'Edit')
                          : formatCurrentTabName(activeTab, 'Add')} */}
                      </Button>

                      <h2 style={{ color: '#00948B' }}>Client Information</h2>
                    </div>
                  }
                  columns={props.clientListColumns}
                  data={clientListData}
                  // TODO: refactor the styles somewhere else
                  options={{
                    headerStyle: {
                      color: '#00948B',
                      fontWeight: 'bold',
                      paddingTop: '0px',
                      paddingBottom: '5px',
                    },
                    rowStyle: {
                      hover: true,
                    },
                    actionsColumnIndex: -1,
                    detailPanelColumnAlignment: 'right',
                    pageSize: 10,
                  }}
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Edit',
                      fontSize: 'small',
                      disabled: !props.isActionAllowed('edit'),
                      onClick: (event, rowData) => {
                        props.handleEdit(rowData)
                      },
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Delete',
                      fontSize: 'small',
                      disabled: !props.isActionAllowed('delete'),
                      onClick: (event, rowData) => {
                        props.handleDelete(rowData)
                      },
                    },
                  ]}
                  detailPanel={[
                    {
                      icon: () => <Toc />,
                      tooltip: 'Details',
                      disabled: !props.isActionAllowed('detail'),
                      render: (rowData) => {
                        const clientId = rowData['client_id']
                        return <ClientDetailList clientId={clientId} />
                      },
                    },
                  ]}
                />
              </MuiThemeProvider>
            </Card>
          </TabPane>

          <TabPane tabId="add">
            <Card className="border-top-0 shadow-none">
              <CardHeader
                style={{
                  fontSize: 20,
                  color: '#00948B',
                  // fontWeight: 'bold',
                  textAlign: 'center',
                  padding: 10,
                }}
              >
                Client Information
              </CardHeader>

              <CardBody>
                <Form onSubmit={props.handleSubmit}>
                  <FormGroup row>
                    <Col sm={1}>
                      <Label for="clientCode">Code</Label>
                    </Col>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="client_cd"
                        id="clientCode"
                        value={props.fields['client_cd']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['client_cd'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Client code cannot be empty.
                      </FormFeedback>
                    </Col>

                    <Col sm={2}>
                      <Label for="clientType">Client Type</Label>
                    </Col>
                    <Col sm={4}>
                      <Input
                        type="select"
                        name="client_type_id"
                        id="clientType"
                        value={props.fields['client_type_id']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['client_type_id'] === false}
                        onBlur={props.handleBlur}
                      >
                        <option value=""></option>
                        {props.getClientTypeOptions()}
                      </Input>
                      <FormFeedback invalid>
                        Client type cannot be empty.
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={1}>
                      <Label for="clientName">Name</Label>
                    </Col>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="client_name"
                        id="clientName"
                        value={props.fields['client_name']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['client_name'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Client name cannot be empty.
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={1}>
                      <Label for="clientAbbr">Abbreviation</Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="text"
                        name="client_abbr"
                        id="clientAbbr"
                        value={props.fields['client_abbr']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['client_abbr'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Client abbreviation cannot be empty.
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={1}>
                      <Label for="clientAddress">Address</Label>
                    </Col>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="address"
                        id="clientAddress"
                        value={props.fields['address']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['address'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Address cannot be empty.
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={1}>
                      <Label for="telephoneNumber">Telephone</Label>
                    </Col>
                    <Col sm={3}>
                      <Input
                        type="text"
                        name="telephone_no"
                        id="telephoneNumber"
                        value={props.fields['telephone_no']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['telephone_no'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Telephone number should be between 7 and 15 digits. 
                      </FormFeedback>
                    </Col>

                    <Label for="faxNumber">Fax</Label>
                    <Col sm={3}>
                      <Input
                        type="text"
                        name="fax_no"
                        id="faxNumber"
                        value={props.fields['fax_no']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                      />
                    </Col>

                    <Label for="poBox">PO Box</Label>
                    <Col sm={3}>
                      <Input
                        type="text"
                        name="po_box"
                        id="poBox"
                        value={props.fields['po_box']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={1}>
                      <Label for="clientEmail">Email</Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="email"
                        name="email"
                        id="clientEmail"
                        value={props.fields['email']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['email'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>Email is invalid.</FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={1}>
                      <Label for="remarks">Remarks</Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="textarea"
                        rows="5"
                        name="remarks"
                        id="remarks"
                        value={props.fields['remarks']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                      />
                    </Col>
                  </FormGroup>

                  <Row style={{ float: 'right' }}>
                    <Button
                      type="submit"
                      color="primary"
                      className="fixedWidthButton"
                    >
                      Save
                    </Button>
                    <Button
                      color="danger"
                      className="fixedWidthButton"
                      onClick={() => {
                        props.resetState()
                        props.changeTab('list')
                      }}
                    >
                      Cancel
                    </Button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  )
}

export default ClientInformation
