import ClientInfoContainer from "container/ClientInfoContainer";
import ClientTypeContainer from "container/ClientTypeContainer2";
import DayoffTypeContainer from "container/DayoffTypeContainer2";
import EffortTypeContainer from "container/EffortTypeContainer2";
import HealthCalculatorContainer from "container/HealthCalculatorContainer2";
import PriorityContainer from "container/PriorityContainer2";
import ProjectDetail from "container/ProjectDetailContainer";
import ProjectInfoContainer2 from "container/ProjectInfoContainer2";
import ProjectModelContainer from "container/ProjectModelContainer2";
import TaskStatusContainer from "container/TaskStatusContainer2";
import TaskTypeContainer from "container/TaskTypeContainer2";
import Index from "views/Index.js";
import MenuContainer from "../container/MenuContainer";
import PermissionContainer from "../container/PermissionContainer";
import PhasesContainer from "../container/PhasesContainer";
import RoleContainer from "../container/RoleContainer";
import StreamInfoContainer from "../container/StreamInfoContainer";
import TimeSheet from "../container/TimeSheetContainer";
import UserContainer from "../container/UserContainer";
import UserGroup from "../container/UserGroupContainer";
import EmployeeForm from "../forms/Employee/EmployeeForm";
import MembersView from "../components/MembersView";
import HealthDetail from "container/DashboardContainer/PMOReport/HealthDetail";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/user",
    invisible: false,
  },

  {
    path: "",
    name: "Timesheet",
    icon: "ni ni-tv-2 text-primary",
    component: TimeSheet,
    layout: "/timesheet",
    invisible: false,
  },

  {
    // path: '/client',
    path: "",
    name: "Clients",
    icon: "ni ni-circle-08 text-pink",
    component: ClientInfoContainer,
    layout: "/client",
    invisible: false,
  },
  {
    // path: '/menu',
    path: "",
    name: "Menu",
    icon: "ni ni-circle-08 text-pink",
    component: MenuContainer,
    layout: "/menu",
    invisible: false,
  },

  {
    path: "/:id",
    name: "Resources",
    icon: "ni ni-circle-08 text-pink",
    component: ProjectDetail,
    layout: "/project",
    invisible: true,
  },

  {
    path: "",
    name: "Projects",
    icon: "ni ni-circle-08 text-pink",
    component: ProjectInfoContainer2,
    layout: "/project",
    invisible: false,
  },

  {
    path: "",
    name: "Stream",
    icon: "ni ni-circle-08 text-pink",
    component: StreamInfoContainer,
    layout: "/stream",
    invisible: false,
  },
  {
    // path: '/efforttype',
    path: "",
    name: "Effort Type",
    icon: "ni ni-circle-08 text-pink",
    component: EffortTypeContainer,
    // layout: '/user',
    layout: "/efforttype",
  },
  {
    // path: '/phases',
    path: "",
    name: "Phases",
    icon: "ni ni-circle-08 text-pink",
    component: PhasesContainer,
    // layout: '/user',
    layout: "/phases",
  },
  {
    path: "",
    name: "Permissions",
    icon: "ni ni-circle-08 text-pink",
    component: PermissionContainer,
    // layout: '/user',
    layout: "/permissions",
  },
  {
    path: "",
    name: "Roles",
    icon: "ni ni-circle-08 text-pink",
    component: RoleContainer,
    // layout: '/user',
    layout: "/roles",
  },
  {
    path: "",
    name: "User Groups",
    icon: "ni ni-circle-08 text-pink",
    component: UserGroup,
    // layout: '/user',
    layout: "/userGroups",
  },
  {
    path: "",
    name: "Task Type",
    icon: "ni ni-circle-08 text-pink",
    component: TaskTypeContainer,
    layout: "/tasktype",
  },
  {
    path: "",
    name: "Task Status",
    icon: "ni ni-circle-08 text-pink",
    component: TaskStatusContainer,
    layout: "/taskstatus",
  },
  {
    path: "",
    name: "Dayoff Type",
    icon: "ni ni-circle-08 text-pink",
    component: DayoffTypeContainer,
    layout: "/dayofftype",
  },
  {
    path: "",
    name: "Priority",
    icon: "ni ni-circle-08 text-pink",
    component: PriorityContainer,
    layout: "/priority",
  },
  {
    path: "",
    name: "User",
    icon: "ni ni-tv-2 text-primary",
    component: UserContainer,
    layout: "/user",
    invisible: false,
  },
  {
    path: "",
    name: "Project Model",
    icon: "ni ni-tv-2 text-primary",
    component: ProjectModelContainer,
    layout: "/model",
    invisible: false,
  },
  {
    path: "",
    name: "Health Calculation",
    icon: "ni ni-tv-2 text-primary",
    component: HealthCalculatorContainer,
    layout: "/healthCalculation",
    invisible: false,
  },
  {
    path: "",
    name: "Client Type",
    icon: "ni ni-tv-2 text-primary",
    component: ClientTypeContainer,
    layout: "/clientType",
    invisible: false,
  },
  {
    path: "",
    name: "Employee",
    icon: "ni ni-tv-2 text-primary",
    component: EmployeeForm,
    layout: "/employee",
    invisible: false,
  },
  {
    path: "",
    name: "Resource",
    icon: "ni ni-tv-2 text-primary",
    component: MembersView,
    layout: "/resourceHistory",
    invisible: false,
  },
  {
    path: "",
    name: "Health",
    icon: "ni ni-tv-2 text-primary",
    component: HealthDetail,
    layout: "/healthDetail/:id/:date/:projectName/:category/:sprintId/:sprintName",
    invisible: true,
  },
]
export default routes;
