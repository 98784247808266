import React, { Fragment } from 'react'
import classnames from 'classnames'
import ActionFeedback from '../ActionFeedbackAlert'
import Container from '@material-ui/core/Container'
import MaterialTable from 'material-table'
import TablePagination from 'material-table'
import { Cancel, CheckCircle } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Header from '../Headers/Header'
import Tree from '../Tree'
import _ from 'lodash'
import Loading from '../Loading';

import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../utils/Theme'
import MenuDetailList from '../MenuDetailList'

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  FormFeedback,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody,
  Row,
} from 'reactstrap'
import { TableFooter, TableHead } from '@material-ui/core'

const formatCurrentTabName = (activeTab, label) => {
  return activeTab === label.toLowerCase() ? <b>{label}</b> : `${label}`
}

const getIndentation = (num) =>
  [...Array(num)].map(
    (e, i) => String.fromCharCode(127), // no idea what code 127 represents
  )

const getDFSOrder = (subMenuMap) => {
  const stack = []
  const result = []
  stack.push('0')
  while (stack.length !== 0) {
    const elem = stack.pop()
    result.push(elem)
    if (subMenuMap[elem]) {
      subMenuMap[elem].forEach((menu) => {
        stack.push(menu['menu_id'])
      })
    }
  }
  return result
}

const generateTabbedMenu = (menuListData) => {
  let menuMap = {}
  let subMenuMap = {}
  menuListData.forEach((menu) => {
    menuMap[menu['menu_id']] = menu
    if (!subMenuMap[menu['upper_menu_id']]) {
      subMenuMap[menu['upper_menu_id']] = []
    }
    subMenuMap[menu['upper_menu_id']].push(menu)
  })

  let stack = []
  let result = []
  let tab = 0
  stack.push({ menu_id: '0', tab: tab })
  while (stack.length !== 0) {
    let elem = stack.pop()
    if (subMenuMap[elem.menu_id]) {
      tab = elem.tab + 1
      subMenuMap[elem.menu_id].forEach((subMenu) => {
        result.push({
          menu_name: `${subMenu['menu_name']}`,
          menu_id: subMenu['menu_id'],
          tab: tab,
        })
        stack.push({ menu_id: `${subMenu['menu_id']}`, tab: tab })
      })
    }
  }
  const dfsOrder = getDFSOrder(subMenuMap)
  return { result: result, dfsOrder: dfsOrder }
}

const getAllMenu = (menuListData) => {
  const { result, dfsOrder } = generateTabbedMenu(menuListData)
  return dfsOrder.map((order) => {
    const menu = result.find((menu) => menu['menu_id'] === order)
    if (menu)
      return (
        <option value={order} key={order}>
          {getIndentation(menu.tab)}
          {`${menu.menu_name}`}
        </option>
      )
  })
}

const Menu = (props) => {
  const activeTab = props.currentTab
  let menuListData = _.cloneDeep(props.menuListData)
  menuListData = menuListData.map((row) => {
    const rowString = JSON.stringify(row);
    row['status_icon'] = ( 
      <IconButton onClick={props.onStatusIconClick} data-menu={rowString} 
        disabled={!props.isActionAllowed('status')}>
        {row['status'] === 'I' ? 
          <Tooltip title="Inactive" >
            <Cancel style={{color: 'red'}} />
          </Tooltip> : 
          <Tooltip title="Active" >
            <CheckCircle style={{color: 'green'}} />
          </Tooltip>}
      </IconButton>);
    row['approved_icon'] = ( 
      <IconButton onClick={props.onApprovedIconClick} data-menu={rowString} 
        disabled={!props.isActionAllowed('approve')}>
        {row['approved'] === 'N' ? 
          <Tooltip title="Unapproved" >
            <Cancel style={{color: 'red'}} />
          </Tooltip> : 
          <Tooltip title="Approved" >
            <CheckCircle style={{color: 'green'}} />
          </Tooltip>}
      </IconButton>);
    if (row['upper_menu_id'] === 0) {
      row['upper_menu_name'] = 'Root'
    }
    return row
  })

  return (
    <Fragment>
      <Header />
      {props.message && <ActionFeedback message={props.message} />}
      <Container
        className="mt-3"
        style={{ maxWidth: '100%', margin: 'auto auto' }}
      >
        {/* <Nav tabs className="border-bottom-0">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'list' })}
              onClick={() => {
                props.resetState();
                props.changeTab('list');
              }}
              style={{backgroundColor: activeTab === 'list' ? 'white' : null}}
            >
              {formatCurrentTabName(activeTab, 'List')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              disabled={true}
              className={classnames({ active: activeTab === 'add' })}
              onClick={() => {
                props.resetState();
                props.changeTab('add');
              }}
              style={{backgroundColor: activeTab === 'add' ? 'white' : null}}
            >
              {props.editMode ? formatCurrentTabName(activeTab, 'Edit') : formatCurrentTabName(activeTab, 'Add')}
            </NavLink>
          </NavItem>

        </Nav> */}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="list">
            <Card className="border-top-0 shadow-none">
              <MuiThemeProvider theme={theme}>
                <MaterialTable
                  components={{
                    OverlayLoading: props => (<Loading />)
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage:
                        props.isFetchComplete ? 'No records to display' : '',
                    }
                  }}
                  isLoading={!props.isFetchComplete}
                  title={
                    <div style={{ width: '500px' }}>
                      <Button
                        type="button"
                        title="Add new Menu"
                        color="primary"
                        style={{
                          margin: '0px 10px 5px 0px',
                          borderRadius: '50%',
                          padding: '0px',
                          width: '30px',
                          height: '30px',
                          float: 'left',
                        }}
                        onClick={() => {
                          props.resetState()
                          props.changeTab('add')
                        }}
                        hidden={!props.isActionAllowed('add')}
                      >
                        <i class="fas fa-plus fa-sm"></i>
                        {/* {props.editMode
                        ? formatCurrentTabName(activeTab, 'Edit')
                        : formatCurrentTabName(activeTab, 'Add')} */}
                      </Button>

                      <h2 style={{ color: '#00948B' }}>Menu List</h2>
                    </div>
                  }
                  columns={props.menuListColumns}
                  data={menuListData}
                  // TODO: refactor the styles somewhere else
                  options={{
                    headerStyle: {
                      color: '#00948B',
                      fontWeight: 'bold',
                      paddingTop: '0px',
                      paddingBottom: '5px',
                    },
                    rowStyle: {
                      hover: true,
                    },
                    actionsColumnIndex: -1,
                    detailPanelColumnAlignment: 'right',
                  }}
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Edit',
                      disabled: !props.isActionAllowed('edit'),
                      onClick: (event, rowData) => {
                        props.handleEdit(rowData)
                      },
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Delete',
                      disabled: !props.isActionAllowed('delete'),
                      onClick: (event, rowData) => {
                        props.handleDelete(rowData)
                      },
                    },
                  ]}
                  detailPanel={[
                    {
                      icon: 'toc',
                      disabled: !props.isActionAllowed('detail'),
                      render: (rowData) => {
                        const menuId = rowData['menu_id']
                        return <MenuDetailList menuId={menuId} />
                      },
                    },
                  ]}
                />
              </MuiThemeProvider>
            </Card>
          </TabPane>

          <TabPane tabId="add">
            <Card className="border-top-0 shadow-none">
              <CardHeader
                style={{
                  fontSize: 20,
                  color: '#00948B',
                  // fontWeight: 'bold',
                  textAlign: 'center',
                  padding: 10,
                }}
              >
                Menu Information
              </CardHeader>

              <CardBody>
                <Form onSubmit={props.handleSubmit}>
                  <FormGroup row>
                    <Col sm={2}>
                      <Label for="menuName">Menu</Label>
                    </Col>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="menu_name"
                        id="menuName"
                        value={props.fields['menu_name']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['menu_name'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Menu name cannot be empty.
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={2}>
                      <Label for="upperMenuId">Upper Menu Id</Label>
                    </Col>
                    <Col sm={5}>
                      <Input
                        type="select"
                        name="upper_menu_id"
                        id="upperMenuId"
                        value={props.fields['upper_menu_id']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        // invalid={props.isValid['upper_menu_id'] === false}
                        // onBlur={props.handleBlur}
                      >
                        <option value={0} selected>
                          {'Root'}
                        </option>
                        {getAllMenu(props.menuListData)}
                      </Input>
                      {/* <FormFeedback invalid>
                        Upper menu id cannot be empty.
                      </FormFeedback>   */}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={2}>
                      <Label for="linkURL">Link URL</Label>
                    </Col>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="link_url"
                        id="linkURL"
                        value={props.fields['link_url']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['link_url'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Link URL cannot be empty.
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={2}>
                      <Label for="iconURL">Icon URL</Label>
                    </Col>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="icon_url"
                        id="iconURL"
                        value={props.fields['icon_url']}
                        bsSize="sm"
                        onChange={props.handleUserInput}
                        invalid={props.isValid['icon_url'] === false}
                        onBlur={props.handleBlur}
                      />
                      <FormFeedback invalid>
                        Icon URL cannot be empty.
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col className="text-right" xs={{ offset: 5 }}>
                      <Button
                        type="submit"
                        color="primary"
                        className="fixedWidthButton"
                      >
                        Save
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="danger"
                        className="fixedWidthButton"
                        onClick={() => {
                          props.resetState()
                          props.changeTab('list')
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  )
}

export default Menu
