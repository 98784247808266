import React from 'react';
import { useState } from 'react';
import { Input } from 'reactstrap';
import 'assets2/button.scss';

const RAIDSection = React.memo(
  ({ formik, projectId, module, moduleName, moduleId, moduleLabel }) => {
    const [isExpanded, setExpanded] = useState(true);
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card card-lightblue">
                <div className="card-header row" onClick={() => setExpanded((prev) => !prev)}>
                  <h3 class="card-title">{`${moduleLabel}`}</h3>
                  {/* <div className="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    {isExpanded ? <i class="fas fa-minus"></i> : <i class="fas fa-plus"></i>}
                  </button>
                </div> */}
                </div>
                {isExpanded && (
                  <div className="card-body ipp-grid">
                    {formik.values[`${module}`]?.map((risk, index) => {
                      return (
                        <>
                          {index == 0 && (
                            <div className="form-row ipp-grid-title-bar">
                              <label className="form-group col mb-0" htmlFor="risks">
                                {moduleLabel}
                              </label>
                              <div className="form-block-right-tool col">
                                <i
                                  class="fas fa-plus"
                                  onClick={() => {
                                    // let newVal = formik.values.items.concat({
                                    //   tech_info_id: "",
                                    //   technologies: "",
                                    // });
                                    formik.setFieldValue(module, [
                                      ...formik.values[`${module}`],
                                      {
                                        project_id: projectId,
                                        [`${moduleId}`]: index,
                                        [`${moduleName}`]: '',
                                      },
                                    ]);
                                  }}
                                ></i>
                              </div>
                            </div>
                          )}
                          <div className="dynamic-form-block form-row">
                            <div className="form-group col" key={index}>
                              <div className="field-with-tool">
                                <Input
                                  type="text"
                                  name={`${module}[${index}][${moduleName}]`}
                                  value={formik.values[`${module}`][`${index}`][`${moduleName}`]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </div>
                            {formik.values[`${module}`].length > 1 && (
                              <div className="block-tool">
                                <i
                                  class="fas fa-minus"
                                  onClick={(e) => {
                                    let delVal = formik.values[`${module}`].splice(index, 1);
                                    formik.setFieldValue(module, formik.values[`${module}`]);
                                  }}
                                ></i>
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default RAIDSection;
