/*eslint-disable*/
import React from 'react'

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'

class Footer extends React.Component {
  render() {
    return (
      <footer
        className="py-2"
        style={{
          position: 'fixed',
          bottom: '0',
          maxWidth: '100%',
          height: '2.5rem',
          fontSize: '12px',
        }}
      >
        <Container fluid maxwidth="lg">
          <Row className="align-items-center justify-content-sm-between">
            <Col xl="12">
              <div className="copyright text-center text-xl-right text-muted">
                {/* © 2020{' '} */}© {new Date().getFullYear()}{' '}
                <a
                  className="font-weight-bold ml-1"
                  href="http://www.lisnepal.com.np"
                  target="_blank"
                >
                  LIS Nepal
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

export default Footer
