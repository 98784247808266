import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listProjectModel = () => {
  const token = getUserToken()
  return axios
    .get('/model/list', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server(List of project models)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while getting the list of project models', error)
    })
}

export const createProjectModel = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/model/create', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Creating project model)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while creating project model ', error)
    })
}

export const deleteProjectModel = (modelId) => {
  const token = getUserToken()
  return axios
    .delete(`/model/delete/${modelId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        `Response from server(Deleting project model with id: ${modelId})`,
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(`Error while deleting project model id ${modelId}`, error)
      throw error;
    })
}

export const updateProjectModel = (modelId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/model/update/${modelId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating project model): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while updating project model with id ${modelId}`, error)
    })
}

export const updateApproved = (modelId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['approved_by'] = id
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/model/update/${modelId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of project model): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating project model's approved status with id ${modelId}`,
        error,
      )
    })
}

export const updateStatus = (modelId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/model/updateStatus/${modelId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of project model): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating project model's status with id ${modelId}`,
        error,
      )
    })
}

export const viewModel = (modelId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/model/view/${modelId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting a view of project model): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while getting a project model with id ${modelId}`, error)
    })
}

export const getDetail = (modelId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/model/detail/${modelId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting detail of project model): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting project model's detail with id ${modelId}`,
        error,
      )
    })
}

export const getTaskTypeByProjectModel = (modelId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/model/getTaskTypeByModel/${modelId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting assigned task types for a project model): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting project model's task types with id ${modelId}`,
        error,
      )
    })
}

export const getUnassignedTaskTypeByProjectModel = (modelId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/model/unassignedTaskTypeByModel/${modelId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting unassigned task types of a project model): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting project model's unassigned task types with id ${modelId}`,
        error,
      )
    })
}

export const addTaskType = (projectModelTaskTypeMap) => {
  const id = getUserId()
  const token = getUserToken()
  const projectModelTaskTypeArr = projectModelTaskTypeMap.ProjectModelTaskType
  projectModelTaskTypeArr.forEach((projectModelTaskType) => (projectModelTaskType['created_by'] = id))
  return axios
    .post(`/model/addTaskType`, projectModelTaskTypeMap, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Adding task types to project model): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while adding task types to project model`, error)
    })
}