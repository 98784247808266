import ClientDetailList from 'components/ClientDetailList';
import Loading from 'components/Loading';
import { theme } from 'utils/Theme';
import 'assets2/button.scss';

import React, { useState } from 'react';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { Button } from 'reactstrap';


const ClientInfoTable = ({
    clientTableList,
    handleEdit,
    handleDelete,
    isLoading,
    isActionAllowed,
    changeTab,
    onStatusIconClick,
    onApprovedIconClick,
}) => {

    const cellStyle = {
        paddingTop: 0,
        paddingBottom: 0
    }
    const [filter, setFilter] = useState(false);

    let clientList = _.cloneDeep(clientTableList);
    clientList = _.orderBy(clientList, ['status', 'approved'], ['asc', 'desc']);

    clientList = clientList.map((row) => {
        const rowString = JSON.stringify(row);

        row['status_icon'] = (
            <IconButton
                onClick={(e) => onStatusIconClick(e)}
                data-client={rowString}
                disabled={!isActionAllowed('status')}
            >
                {row['status'] === 'I' ? (
                    <Tooltip title="Inactive">
                        <Cancel style={{ color: 'red' }} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Active">
                        <CheckCircle style={{ color: 'green' }} />
                    </Tooltip>
                )}
            </IconButton>
        );

        row['approved_icon'] = (
            <IconButton
                onClick={(e) => onApprovedIconClick(e)}
                data-client={rowString}
                disabled={!isActionAllowed('approve')}
            >
                {row['approved'] === 'N' ? (
                    <Tooltip title="Unapproved">
                        <Cancel style={{ color: 'red' }} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Approved">
                        <CheckCircle style={{ color: 'green' }} />
                    </Tooltip>
                )}
            </IconButton>
        );

        return row;
    });

    const Title = () => {
        return (
            <>
                <div style={{ width: '500px' }}>
                    <Button
                        type="button"
                        title="Add new client"
                        color="primary"
                        style={{
                            margin: '0px 10px 5px 0px',
                            borderRadius: '50%',
                            padding: '0px',
                            width: '30px',
                            height: '30px',
                            float: 'left',
                        }}
                        onClick={() => {
                            changeTab('add');
                        }}
                        hidden={!isActionAllowed('add')}
                    >
                        <i class="fas fa-plus fa-sm"></i>
                    </Button>

                    <h2 style={{ color: '#00948B' }}>Client Information</h2>
                </div>
            </>
        );
    };

    const columns = [
        {
            title: 'Code',
            field: 'client_cd',
            cellStyle: cellStyle,
        },
        {
            title: 'Name',
            field: 'client_name',
            cellStyle: cellStyle,
            headerStyle: {
                width: 200,
                minWidth: 200,
            },
        },
        {
            title: 'Type',
            field: 'client_type_abbr',
            cellStyle: cellStyle,
        },
        {
            title: 'Status',
            field: 'status_icon',
            cellStyle: cellStyle,
            filtering: false,
            customFilterAndSearch: (text, rowData) => {
                const searchText = text.toLowerCase().trim();
                const word = rowData.status === 'I' ? 'inactive' : 'active';
                if (word.startsWith(searchText)) {
                    return true;
                }
                return false;
            },
        },
        {
            title: 'Approved',
            field: 'approved_icon',
            cellStyle: cellStyle,
            filtering: false,
            customFilterAndSearch: (text, rowData) => {
                const searchText = text.toLowerCase().trim();
                const word = rowData.approved === 'Y' ? 'yes' : 'no';
                if (word.startsWith(searchText)) {
                    return true;
                }
                return false;
            },
        },
    ];


    return (<>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                localization={{
                    body: {
                        emptyDataSourceMessage: isLoading ? 'No records to display' : ''
                    }
                }}
                components={{
                    OverlayLoading: (props) => <Loading />
                }}
                isLoading={isLoading}
                title={<Title />}
                columns={columns}
                data={clientList}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
                        disabled: !isActionAllowed('edit'),
                        onClick: (event, rowData) => {
                            handleEdit(rowData)
                        },
                    },
                    {
                        icon: 'delete',
                        tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
                        disabled: !isActionAllowed('delete'),
                        onClick: (event, rowData) => {
                            handleDelete(rowData)
                        },
                    }
                ]}
                options={{
                    headerStyle: {
                        color: '#00948B',
                        fontWeight: 'bold',
                        paddingTop: '0px',
                        paddingBottom: '5px',
                    },

                    actionsColumnIndex: -1,
                    detailPanelColumnAlignment: 'right',
                    pageSize: 10,
                    filtering: filter,
                }}
                detailPanel={[
                    {
                        icon: 'toc',
                        tooltip: 'Details',
                        disabled: !isActionAllowed('detail'),
                        render: (rowData) => {
                            const clientId = rowData['client_id']
                            return <ClientDetailList clientId={clientId} />
                        },
                    },
                ]}
            />
        </MuiThemeProvider>
    </>);
}

export default ClientInfoTable;