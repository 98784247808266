import React from 'react'
import { Link } from 'react-router-dom'
import { browserHistory } from 'history'
import { Redirect } from 'react-router-dom'
import {logout} from 'actions/UserFunctions'
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap'

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navigate: false
    }
  }

  logOut = () => {
    //event.preventDefault()
    logout();
    this.setState({ navigate: true });
  };
  
  render() {
    const {  navigate } = this.state
    if (navigate){
      return <Redirect to="/admin/login" push={true}/>
    }
    return (
      <>
        <Navbar className="navbar-top" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0 mt--3" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="User profile image"
                        src={require('assets2/img/brand/user_image.png')}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.usr_name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={this.logOut}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    )
  }

}
export default AdminNavbar
