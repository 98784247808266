import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
// reactstrap components
import { Container, Row, Col } from 'reactstrap'

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js'
import AuthFooter from 'components/Footer/AuthFooter.js'

import routes from '../routes/AuthRoutes'

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default')
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default')
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  render() {
    return (
      <>
        <div className='main-content'>
          <AuthNavbar />
          <div className='header py-6 py-lg-7'>
            <div className='separator separator-bottom separator-skew zindex-100'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                preserveAspectRatio='none'
                version='1.1'
                viewBox='0 0 2560 100'
                x='0'
                y='0'
              >
                <polygon
                  className='fill-default'
                  points='2560 0 2560 100 0 100'
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className='mt--8 pb-5'>
            <Row className='justify-content-center'>
              <Switch>
                {this.getRoutes(routes)}
                {/* <Redirect from='*' to='/auth/login' /> */}
              </Switch>
            </Row>
          </Container>
        </div>
        {/* <AuthFooter /> */}
      </>
    )
  }
}

export default Auth
