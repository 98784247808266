import React, { useState, useEffect } from 'react'
import { ToggleTabButtons, StyledToggleButton } from './DashboardContainer'
import { ProjectInfoByClient } from './projectInfo/ProjectInfoByClient'
import { ProjectInfoComponent } from './projectInfo/ProjectInfoComponent'
import Loading from 'components/Loading'
import { getProjectInfoData } from 'actions/DashboardReportFunctions'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'
import _ from 'lodash'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00948B',
    },

    secondary: {
      main: '#E1EBF0',
    },
  },
})

const ProjectInfo = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const [projectData, setProjectData] = useState(null)
  const [projectBarData, setProjectBarData] = useState([])
  const [selection, setSelection] = useState('client_type_name')
  const [categorizeBy, setCategorizeBy] = useState('stream_cd')

  const prepareBarChartData = (rawData) => {
    let rows = []
    let keySet = new Set()
    let grouped = _.groupBy(rawData, 'name')
    Object.keys(grouped).map((key) => {
      let entry = grouped[key].reduce(
        (acc, row) => {
          keySet.add(row?.label)
          acc[row?.label] = row?.value
          return acc
        },
        { name: key }
      )
      rows.push(entry)
    })
    return { rows, keySet: [...keySet] }
  }

  useEffect(() => {
    getProjectInfoData(selection).then((data) => {
      let sortedBarAll = prepareBarChartData([...data?.data?.barAll])
      let sortedBarActive = prepareBarChartData([...data?.data?.barActive])
      let sortedBarInactive = prepareBarChartData([...data?.data?.barInactive])
      setProjectData({
        ...data?.data,
        sortedBarAll,
        sortedBarActive,
        sortedBarInactive,
      })
      setIsLoading(false)
      console.log(data?.data)
    })
  }, [selection])

  const handleSelection = (e, value) => {
    value &&
      ['client_type_name', 'project_type', 'stream_cd'].includes(value) &&
      setSelection(value)
  }

  if (isLoading)
    return (
      <div className='d-flex justify-content-center'>
        <Loading />
      </div>
    )

  return (
    <div>
      <ToggleTabButtons selection={selection} handleSelection={handleSelection}>
        <StyledToggleButton value='client_type_name'>{`Client Type`}</StyledToggleButton>
        <StyledToggleButton value='project_type'>{`Project Type`}</StyledToggleButton>
        <StyledToggleButton value='stream_cd'>{`Stream`}</StyledToggleButton>
      </ToggleTabButtons>
      <div className='d-flex justify-content-end align-items-center pr-10'>
        <MuiThemeProvider theme={theme}>
          <Switch
            color='primary'
            defaultChecked
            title='Active Projects'
            value={showActive}
            onChange={(_, value) => setShowActive(value)}
          >
            Active Projects
          </Switch>
        </MuiThemeProvider>
        Active Projects
      </div>
      <ProjectInfoComponent
        projectPiData={showActive ? projectData?.active : projectData?.all}
        projectBarData={
          showActive ? projectData?.sortedBarActive : projectData?.sortedBarAll
        }
      />
    </div>
  )
}

export default ProjectInfo
