import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  getDashboardProjectHealth,
  getDashboardProjectHealthDetail,
  getDashboardProjectEstimation,
  getProjectHealth,
  getProjectHealthDetail,
  getProjectTaskPlan,
} from "../../../actions/DashboardFunctions";
import { Table } from "reactstrap";
import axios from "axios";
import { EffortEstimationTable } from "components/WeeklyReport/ReportTables";

const headerStyles = {
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
};

// stream table is grouped by stream_cd
export const StreamTable = ({ columns, data, handleHealthClick, selectedProjectId }) => {
  return (
    <Table size="sm" bordered responsive>
      <thead className="lisColor1">
        <tr>
          {columns.map((col) => (
            <th style={{ ...headerStyles }}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {Object.keys(data).map((stream) =>
          data[stream].map((row, idx) => (
            <tr key={`${idx}`} className={row.project_id === selectedProjectId ? "bg-secondary": ""}>
              {idx === 0 && <td rowSpan={Object.keys(data[stream]).length}>{stream}</td>}
              <td>{row.project_name}</td>
              <td>{row.client_name}</td>
              <td>
                <div onClick={() => handleHealthClick(row.project_id)}>
                  <ColorStrip code={row.healthstatus} />
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

// client table is grouped by client_name, stream_cd
export const ClientTable = ({ columns, data, groupClient, handleHealthClick, selectedProjectId }) => {
  return (
    <Table size="sm" bordered responsive>
      <thead className="lisColor1">
        <tr>
          {columns.map((col) => (
            <th style={{ ...headerStyles }}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {Object.keys(data).map((client) =>
          data[client].map((row, idx) => (
            <tr key={`${client}-${idx}`} className={row.project_id === selectedProjectId ? "bg-secondary": ""}>
              {idx === 0 && <td rowSpan={Object.keys(data[client]).length}>{client}</td>}
              <td>{row.stream_cd}</td>
              <td>{row.project_name}</td>
              <td>
                <div onClick={() => handleHealthClick(row.project_id)}>
                  <ColorStrip code={row.healthstatus} />
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export const HealthTable = ({ projectId }) => {
  const [health, setHealth] = useState([]);
  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  useEffect(() => {
    getProjectHealth(projectId).then((health) => {
      setHealth(health);
    });
  }, [projectId]);

  let approved = true;

  for (let row of health) {
    if (row.approved === "N") {
      approved = false;
      break;
    }
  }

  let lastWeeksHealth = [];
  if (health && health.length != 0) {
    const grouped = groupBy(health, "week_dt");
    const lastWeekKey = Object.keys(grouped)
      .sort((a, b) => new Date(a) - new Date(b))
      .slice(-1)[0];
    lastWeeksHealth = grouped[lastWeekKey];
  }

  return (
    <div>
      <Table size="sm" bordered style={{ overflow: "hidden" }}>
        <thead className="lisColor1">
          <tr>
            <th colSpan={2} className="text-center" style={{ ...headerStyles }}>
              Health
            </th>
          </tr>
        </thead>
        {approved ? (
          <tbody className="bg-white">
            {lastWeeksHealth &&
              lastWeeksHealth.map((row, idx) => (
                <tr key={`health-${idx}`}>
                  <td>{row.health_calc_name}</td>
                  <td>
                    <ColorStrip code={row.healthstatus} />
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <div className="text-center">Not approved for last week</div>
        )}
      </Table>
    </div>
  );
};

const stripTimeStamp = (date) => date.split(" ")[0];

const format = (value, key) => {
  if (key === "sprint_st_dt" || key === "sprint_end_dt") {
    return stripTimeStamp(value);
  }
  return value;
};

export const HealthDetailTable = ({ projectId }) => {
  const [detail, setDetail] = useState({});

  const rowMap = {
    release_size: "Release Size",
    completed_size: "Completed",
    remaining_size: "Remaining",
    sprint_size_planned: "Week/Sprint Size",
    sprint_capacity_planned: "Velocity",
    sprint_st_dt: "Start Date",
    sprint_end_dt: "End Date",
  };

  useEffect(() => {
    getProjectHealthDetail(projectId).then((detail) => setDetail(detail[0]));
  }, [projectId]);

  return (
    <Table size="sm" bordered>
      <thead className="lisColor1">
        <tr>
          <th colSpan={2} className="text-center" style={{ ...headerStyles }}>
            Release Size
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {detail &&
          Object.keys(detail).length !== 0 &&
          Object.keys(rowMap).map((key, idx) => (
            <tr key={`health-detail-${key}-${idx}`}>
              <td>{rowMap[key]}</td>
              <td className="text-right">{format(detail[key], key)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export const ProjectNameBox = ({ name }) => {
  return (
    <div className="d-flex justify-content-center align-items-center lisColor1 h-100 mr-2">
      <div style={{ ...headerStyles }}>{name}</div>
    </div>
  );
};

export const ProjectTaskPlan = ({ projectId }) => {
  const [taskPlan, setTaskPlan] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const currentDate = moment().clone().format("YYYY-MM-DD");
  const cancelToken = axios.CancelToken.source();
  useEffect(() => {
    let isMounted = true;
    getProjectTaskPlan(projectId, currentDate, cancelToken.token).then((plan) => {
      if (isMounted) {
        setTaskPlan(plan);
      }
    });

    return () => {
      isMounted = false;
      cancelToken.cancel();
    };
  }, [projectId]);

  const columns = ["Task Plan"];
  const rows = [
    ["Velocity"],
    ["Planned"],
    ["Complete"],
    ["In Progress"],
    ["Yet to Start"],
    ["% Complete"],
  ];

  // TODO: refactor this
  if (taskPlan && taskPlan.length !== 0) {
    const plans = showAll ? taskPlan.slice(0) : taskPlan.slice(-10);
    plans.map((plan) => {
      columns.push(plan.sprint_cd);
      let in_progress_points =
        plan.model_track === "S"
          ? Number(plan.total_planned_points) - Number(plan.total_completed_points)
          : Number(plan.total_planned_hrs) - Number(plan.total_completed_hrs);
      if (in_progress_points < 0) {
        in_progress_points = 0;
      }
      rows[0].push(
        plan.model_track === "S" ? plan.total_capacity_points : plan.total_capacity_hrs
      );
      rows[1].push(
        plan.model_track === "S" ? plan.total_planned_points : plan.total_planned_hrs
      );
      rows[2].push(
        plan.model_track === "S" ? plan.total_completed_points : plan.total_completed_hrs
      );
      rows[3].push(in_progress_points);
      rows[4].push(0);
      rows[5].push(
        `${Math.floor(
          (Number(plan.total_completed_hrs) / Number(plan.total_planned_hrs)) * 100
        )}%`
      );
    });
  }

  const handleShowAll = (event) => {
    event.preventDefault();
    setShowAll(!showAll);
  };

  return (
    <div>
      <Table size="sm" bordered>
        <thead className="lisColor1">
          <tr>
            {columns.map((col) => (
              <th className="text-center" style={{ ...headerStyles }}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {rows.map((row, idr) => (
            <tr key={`task-plan-row-${idr}`}>
              {row.map((cell, idc) => (
                <td
                  key={`task-plan-cell-${idr}-${idc}`}
                  className={idc === 0 ? "" : "text-right"}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ fontSize: 12, textAlign: "right" }}>
        <a href="#" onClick={handleShowAll}>
          {showAll ? "Show last 10" : "Show all"}
        </a>
      </div>
    </div>
  );
};

export const ColorStrip = ({
  code,
  id,
  name,
  onClick,
}) => {
  const colorMap = {
    G: "#00FF00",
    A: "#FFBF00",
    R: "#FF0000",
    S: "#f6f3f2"
  };
  return (
    <div
      className="d-flex flex-grow-1 link-pointer"
      style={{ backgroundColor: colorMap[code] }}
      onClick={id && name ? onClick : null}
    >
      <span className="invisible">{colorMap[code]}</span>
    </div>
  );
};

// dashboard nested table added here
export const DashboardHealthTable = ({ projectId, calc_date }) => {
  const [approved, setApproved] = useState(true);
  const [lastWeekHealth, setLastWeekHealth] = useState([]);

  const cancelToken = axios.CancelToken.source();

  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  useEffect(() => {
    let isMounted = true;

    getDashboardProjectHealth(projectId, calc_date, cancelToken.token).then((health) => {
      if (isMounted) {
        getLastWeekHealth(health);
      }
    });

    return () => {
      isMounted = false;
      cancelToken.cancel("cancelling requests from the server as component is unmounted");
    };
  }, [projectId, calc_date]);

  const getLastWeekHealth = (health) => {
    setApproved(!health.some((eachHealth) => eachHealth.approved === "N"));

    const grouped = groupBy(health, "week_dt");
    const lastWeekKey = Object.keys(grouped)
      .sort((a, b) => new Date(a) - new Date(b))
      .slice(-1)[0];

    setLastWeekHealth(grouped[lastWeekKey]);
  };

  return (
    <div>
      <Table size="sm" bordered style={{ overflow: "hidden" }}>
        <thead className="lisColor1">
          <tr>
            <th colSpan={2} className="text-center" style={{ ...headerStyles }}>
              Health
            </th>
          </tr>
        </thead>
        {approved ? (
          <tbody className="bg-white">
            {lastWeekHealth &&
              lastWeekHealth.map((row, idx) => (
                <tr key={`health-${idx}`}>
                  <td>{row.health_calc_name}</td>
                  <td>
                    <ColorStrip code={row.healthstatus} />
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <div className="text-center">Not approved for last week</div>
        )}
      </Table>
    </div>
  );
};

export const DashboardHealthDetailTable = ({ projectId, calc_date }) => {
  const cancelToken = axios.CancelToken.source();
  const [healthDetail, setHealthDetail] = useState({});
  let isMounted = true;
  const rowMap = {
    release_size: "Release Size",
    completed_size: "Completed",
    remaining_size: "Remaining",
    sprint_size_planned: "Week/Sprint Size",
    sprint_capacity_planned: "Velocity",
    sprint_st_dt: "Start Date",
    sprint_end_dt: "End Date",
  };
  useEffect(() => {
    getDashboardProjectHealthDetail(projectId, calc_date, cancelToken.token).then(
      (res) => {
        if (isMounted) {
          setHealthDetail(res[0]);
        }
      }
    );

    return () => {
      isMounted = false;
      cancelToken.cancel("cancelling requests from the server as component is unmounted");
    };
  }, [projectId, calc_date]);
  return (
    <Table size="sm" bordered>
      <thead className="lisColor1">
        <tr>
          <th colSpan={2} className="text-center" style={{ ...headerStyles }}>
            Release Size
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {healthDetail &&
          Object.keys(healthDetail).length !== 0 &&
          Object.keys(rowMap).map((key, idx) => (
            <tr key={`health-detail-${key}-${idx}`}>
              <td>{rowMap[key]}</td>
              <td className="text-right">{format(healthDetail[key], key)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export const DashboardEstimationTable = ({ projectId, calc_date }) => {
  const [estimation, setEstimation] = useState({});
  const cancelToken = axios.CancelToken.source();
  useEffect(() => {
    let isMounted = true;

    getDashboardProjectEstimation(projectId, calc_date, cancelToken.token).then((res) => {
      if (isMounted) {
        setEstimation(res[0]);
      }
    });

    return () => {
      isMounted = false;
      cancelToken.cancel();
    };
  }, [projectId, calc_date]);

  return <>{<EffortEstimationTable estimation={estimation} />}</>;
};
