import React from "react"
import _ from "lodash"
import {IconButton, MuiThemeProvider, Tooltip} from "@material-ui/core"
import {theme} from "../../utils/Theme"
import MaterialTable from "material-table"
import Loading from "../../components/Loading"
import {Cancel, CheckCircle} from "@material-ui/icons"
import {Button} from "reactstrap"
import MenuDetailList from "../../components/MenuDetailList"

const MenuTable = ({
    menuListData,
    handleEdit,
    handleDelete,
    onStatusIconClick,
    onApprovedIconClick,
    isLoading,
    isActionAllowed,
    changeTab,
}) => {

    const cellStyle = {
        paddingTop: '0px',
        paddingBottom: '0px',
    }

    let menuList = _.cloneDeep(menuListData)
    menuList = menuList.map((row) => {
        const rowString = JSON.stringify(row)
        row['status_icon'] = (
            <IconButton
                onClick={(e) => onStatusIconClick(e)}
                data-menu={rowString}
                disabled={!isActionAllowed('status')}
            >
                {row['status'] === 'I' ?
                    <Tooltip title="Inactive">
                        <Cancel style={{color: 'red'}} />
                    </Tooltip> :
                    <Tooltip title="Active">
                        <CheckCircle style={{color: 'green'}} />
                    </Tooltip>
                }
            </IconButton>
        )
        row['approved_icon'] = (
            <IconButton
                onClick={(e) => onApprovedIconClick(e)}
                data-menu={rowString}
                disabled={!isActionAllowed('approve')}
            >
                {row['approved'] === 'N' ?
                    <Tooltip title="Unapproved">
                        <Cancel style={{color: 'red'}} />
                    </Tooltip> :
                    <Tooltip title="Approved">
                        <CheckCircle style={{color: 'green'}} />
                    </Tooltip>
                }
            </IconButton>
        )
        if (row['upper_menu_id'] === 0) {
            row['upper_menu_name'] = 'Root'
        }
        return row
    })

    const columns = [
        { title: 'Menu', field: 'menu_name', cellStyle: cellStyle},
        { title: 'Upper Menu', field: 'upper_menu_name', cellStyle: cellStyle},
        { title: 'Link URL', field: 'link_url', cellStyle: cellStyle},
        { title: 'Icon URL', field: 'icon_url', cellStyle: cellStyle},
        { title: 'Status', field: 'status_icon', cellStyle: cellStyle},
        { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle},
    ]

    return <MuiThemeProvider theme={theme}>
        <MaterialTable
            components={{
                OverlayLoading: props => <Loading />
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: !isLoading ? 'No records to display!' : ''
                }
            }}
            isLoading={isLoading}
            title={
                <div style={{width: '500px'}}>
                    <Button
                        type="button"
                        title="Add new Menu"
                        color="primary"
                        style={{
                            margin: '0px 10px 5px 0px',
                            borderRadius: '50%',
                            padding: '0px',
                            width: '30px',
                            height: '30px',
                            float: 'left',
                        }}
                        onClick={() => {
                            changeTab('add')
                        }}
                        hidden={!isActionAllowed('add')}
                    >
                        <i className="fas fa-plus fa-sm"/>
                    </Button>
                    <h2 style={{color: '#00948B'}}>Menu List</h2>
                </div>
            }
            columns={columns}
            data={menuList}
            options={{
                headerStyle: {
                    color: '#00948B',
                    fontWeight: 'bold',
                    paddingTop: '0px',
                    paddingBottom: '5px',
                },
                rowStyle: {
                    hover: true,
                },
                actionsColumnIndex: -1,
                detailPanelColumnAlignment: 'right'
            }}
            actions={[
                {
                    icon: 'edit',
                    tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
                    disabled: !isActionAllowed('edit'),
                    onClick: (event, rowData) => {handleEdit(rowData)}
                },
                {
                    icon: 'delete',
                    tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
                    disabled: !isActionAllowed('delete'),
                    onClick: (event, rowData) => {handleDelete(rowData)}
                },
            ]}
            detailPanel={[
                {
                    icon: 'toc',
                    disabled: !isActionAllowed('detail'),
                    render: (rowData) => {
                        const menuId = rowData['menu_id'];
                        return <MenuDetailList menuId={menuId} />
                    }
                }
            ]}
        />
    </MuiThemeProvider>
}

export default MenuTable