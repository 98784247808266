import React, { Fragment, useContext } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footer/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { Link, NavLink as RouterNavLink } from "react-router-dom";

import routes from "../routes/UserRoutes";
import { viewProfile, logout, getDetail } from "../actions/UserFunctions";
import decoder from "jwt-decode";
import { getPermissionByUser } from "../actions/RoleFunctions";
import MenuIcon from "@material-ui/icons/Menu";
import { GlobalDebug } from "utils/RemoveConsoleProd";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  NavbarToggler,
} from "reactstrap";
import Tree from "components/Tree";
import { UserContext } from "../context/UserContext";
import { getUserId } from "../actions/UtilFunctions";
import { fetchAllResources } from "../actions/ResourceFunctions";

const HighlightedLink = (props) => {
  let match = useRouteMatch({
    path: props.to,
  });
  const name = props.children["2"].props.children;
  return <RouterNavLink {...props} className={match ? "active " : ""} />;
};

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      usr_name: "",
      email: "",
      sidebarClosed: false,
      navigate: false,
      user: null,
      employeeMap: {},
      employees: [],
    };
  }

  componentDidMount() {
    //this code removes console logs from production
    (process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false,true)
    
    const id = decoder(localStorage.getItem("usertoken")).sub;
    viewProfile(id).then((response) => {
      console.log(response);
      this.setState({
        usr_name: response.data.original.usr_name,
        email: response.data.original.email,
      });
    });
    getPermissionByUser().then((response) => {
      localStorage.setItem("userPermissions", JSON.stringify(response));
    });
    getDetail(getUserId()).then((response) => {
      if (response) {
        this.setState({ user: response[0] });
      }
    });
    fetchAllResources().then((response) => {
      const employeeMap = {};
      response.map((employee) => {
        employeeMap[employee.employee_id] = employee;
      });
      this.setState({
        employees: response,
        employeeMap: employeeMap,
      });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      // if (
      //   prop.layout === '/user' ||
      //   prop.layout === '/project' ||
      //   prop.layout === '/stream' ||
      //   prop.layout === '/client' ||
      //   prop.layout === '/menu' ||
      //   prop.layout === '/efforttype' ||
      //   prop.layout === '/phases' ||
      //   prop.layout === '/permissions' ||
      //   prop.layout === '/roles' ||
      //   prop.layout === '/userGroups' ||
      //   prop.layout === '/taskstatus' ||
      //   prop.layout === '/tasktype' ||
      //   prop.layout === '/dayofftype' ||
      //   prop.layout === '/priority'
      // ) {
      return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      // } else {
      //   // return <Route path={prop.path} component={prop.component} key={key} />
      //   return null
      // }
    });
  };

  logOut = () => {
    logout();
    this.setState({ navigate: true });
  };

  handleMenuToggle = (event) => {
    event.preventDefault();
    this.setState({
      sidebarClosed: !this.state.sidebarClosed,
    });
  };

  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.invisible === false) {
        return (
          <NavItem
            key={key}
            className="list-group-item 
            list-group-item-action list-group-hover lisColor2 border-0 p-0"
            // list-group-item-action lisColor2 border-0 p-0"
          >
            <NavLink
              to={prop.layout + prop.path}
              tag={RouterNavLink}
              onClick={this.closeCollapse}
              activeClassName="nav-link-sidebar"
              className="shadow-none py-2"
            >
              <i className={`${prop.icon} text-white pr-1`} /> <span className="text-white">{prop.name}</span>
            </NavLink>
          </NavItem>
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { usr_name, navigate } = this.state;
    const logo = {
      innerLink: "/user/index",
      imgSrc: require("assets2/img/vesatransparent.png"),
      imgSrc2: require("assets2/img/LIS_Nepal_new_logo.png"),
      imgAlt: "Vesa image",
    };
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }

    return (
      <UserContext.Provider
        value={{
          currentUser: this.state.user,
          employees: this.state.employees,
          employeeMap: this.state.employeeMap,
        }}
      >
        <div
          className={"d-flex " + (this.state.sidebarClosed ? "toggled" : "")}
          id="wrapper"
        >
          <div className="border-right lisColor2" id="sidebar-wrapper">
            <div
              className="sidebar-heading text-center text-white d-flex 
            align-items-center border-bottom"
            >
              <span style={{ width: "64px" }}>
                <img
                  alt="User profile image"
                  src={require("assets2/img/brand/user_image.png")}
                  className="rounded-circle logo-image"
                />
              </span>
              <span className="ml-2">
                <div className="small text-left">Welcome</div>
                <div className="text-left">{usr_name}</div>
              </span>
            </div>
            <div className="list-group list-group-flush list-group-custom">
              {/* {this.createLinks(routes)} */}
              <Tree />
              {/* <STree /> */}
            </div>
          </div>
          <div id="page-content-wrapper" ref="mainContent">
            <nav className="navbar navbar-expand-lg border-bottom d-flex justify-content-between py-0">
              <div className="d-flex align-items-center">
                <MenuIcon onClick={this.handleMenuToggle}/>
                <div style={{ width: "160px", marginLeft: '10px' }}>
                  <img alt={logo.imgAlt} className="logo-image" src={logo.imgSrc2} />
                </div>
              </div>

              <div style={{ width: "120px" }}>
                <img alt={logo.imgAlt} className="logo-image" src={logo.imgSrc} />
              </div>

              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn btn-sm"
                  id="dropdownMenuButtonUserName"
                  size="sm"
                  data-toggle="dropdown"
                  // aria-haspopup="true"
                  aria-expanded="false"
                  caret
                >
                  <span>
                    <img
                      alt="User profile image"
                      src={require("assets2/img/brand/user_image.png")}
                      className="rounded-circle mr-2"
                      width={"32px"}
                    />
                  </span>
                  <span className="text-lisColor2">{usr_name}</span>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="dropdownMenuButtonUserName">
                  <DropdownItem className="dropdown-item" onClick={this.logOut}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </nav>

            <div className="container-fluid">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/user/profile" />
              </Switch>
            </div>
          </div>
          {/* <AdminFooter /> */}
        </div>
      </UserContext.Provider>
    );
  }
}

export default User;
