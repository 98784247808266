import React from "react";
import MaterialTable from "material-table";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getProjectsReport } from "actions/DashboardReportFunctions";
import { ColorStrip } from "components/ProjectHealth/Table";
import { useState } from "react";
import { Tooltip } from "reactstrap";

const theme = createMuiTheme({
  palette: {
    fontSize: "1rem",
    primary: {
      main: "#00948B",
      fontSize: "1rem",
    },

    secondary: {
      main: "#E1EBF0",
      fontSize: "1rem",
    },
  },
});
export default function PMOHealthReport({selectedDate, setSelectedDate, selectedRows, setSeletedRows}) {
  const {
    isLoading,
    data: projects,
    isError,
    error,
  } = useQuery(["projectDistribution", selectedDate], () =>
    getProjectsReport(selectedDate).then(({ data }) => data)
  );

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const handleHealthClick = (rowData) => {
    window.open(`/healthDetail/${rowData?.project_id}/${selectedDate}/${rowData.project_name}/${rowData.project_category}/${rowData.sprint_id}/${rowData.sprint_name}`).focus();
  }

  return (
    <div className='py-2'>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          isLoading={isLoading}
          title={<h3 style={{ color: "#00948B" }}>PMO Report</h3>}
          options={{
            selection: true,
            paging: false,
            style: { borderColor: "#D4AF37" },
            headerStyle: {
              color: "#00948B",
              fontWeight: "bold",
              paddingTop: "0px",
              paddingBottom: "5px",
              whiteSpace: "nowrap",
            },
          }}
          columns={[
            {
              title: "Health",
              field: "healthstatus",
              cellStyle: {
                maxWidth: 45,
              },
              render: (rowData) => <ColorStrip onClick={()=>handleHealthClick(rowData)} id={1} name={'name'} code={rowData?.healthstatus} />,
            },
            {
              title: "Project Name",
              field: "project_name",
              cellStyle: {
                // width: 250,
              },
            },
            {
              title: "Client",
              field: "client_name",
              cellStyle: {
                // width: 150,
              },
            },
            {
              title: "Health",
              field: "health",
              cellStyle: {
                width: 240,
              },
              render: (rowData) => <HealthStrip {...rowData} />,
            },
          ]}
          data={projects || []}
          onSelectionChange={(rows) => setSeletedRows(rows)}
        />
      </MuiThemeProvider>
    </div>
  );
}

export const HealthStrip = ({
  budget,
  infra,
  quality,
  resources,
  schedule,
  scope,
}) => {
  return (
    <div className='container row'>
      <div>
        <ToolTipitem tip='Budget' id='1'>
          <Strip code={budget} />
        </ToolTipitem>
      </div>
      <div>
        <ToolTipitem tip='Infra' id='2'>
          <Strip code={infra} />
        </ToolTipitem>
      </div>
      <div>
        <ToolTipitem tip='Quality' id='3'>
          <Strip code={quality} />
        </ToolTipitem>
      </div>
      <div>
        <ToolTipitem tip='Resource' id='4'>
          <Strip code={resources} />
        </ToolTipitem>
      </div>
      <div>
        <ToolTipitem tip='Schedule' id='5'>
          <Strip code={schedule} />
        </ToolTipitem>
      </div>
      <div>
        <ToolTipitem tip='Scope' id='6'>
          <Strip code={scope} />
        </ToolTipitem>
      </div>
    </div>
  );
};

export const Strip = ({ code, ticker }) => {
  const colorMap = {
    G: "#00FF00",
    A: "#FFBF00",
    R: "#FF0000",
  };
  return (
    <div
      style={{
        backgroundColor: colorMap[code] || "#F1F1F1",
        width: 25,
        height: 20,
        margin: 2,
      }}
    >
      <span>{ticker}</span>
    </div>
  );
};

export function ToolTipitem({
  tip,
  id,
  placement = "top",
  color = "secondary",
  children,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div style={{ display: "inline", float: "left" }}>
      <div color={color} id={"Tooltip-" + id}>
        {children}
      </div>
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
      >
        {tip}
      </Tooltip>
    </div>
  );
}
