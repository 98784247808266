const ValidateField = (fields) => {
  const errors = {}
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  if (Object.keys(fields)[0] === 'email') {
    if (fields['email'] === '') {
      errors['isValidEmail'] = false
      errors['email'] = 'Email is Empty'
      errors['isEmailEmpty'] = true
    } else if (!re.test(fields['email'])) {
      errors['isValidEmail'] = false
      errors['email'] = 'Invalid Email Address'
    } else {
      errors['email'] = ''
      errors['isValidEmail'] = true
    }
  }
  if (Object.keys(fields)[1] === 'password') {
    if (fields['password'] === '') {
      errors['isValidPassword'] = false
      errors['password'] = 'Password is Empty'
      errors['isPasswordEmtpy'] = true
    } else {
      errors['isValidPassword'] = true
    }
  }
  if (errors['isValidEmail'] && errors['isValidPassword']) {
    errors['isValidForm'] = true
  }
  if (errors['isEmailEmpty'] && errors['isPasswordEmtpy']) {
    errors['form'] = 'Email and Password is Empty'
    errors['isValidForm'] = false
  } else if (errors['isEmailEmpty'] && errors['isPasswordEmtpy']) {
    errors['form'] = 'Email and Password is Empty'
    errors['isValidForm'] = false
  } else if (errors['isEmailEmpty'] && !errors['isPasswordEmtpy']) {
    errors['form'] = 'Email is Empty'
    errors['isValidForm'] = false
  } else if (!errors['isEmailEmpty'] && errors['isPasswordEmtpy']) {
    errors['form'] = 'Password is Empty'
    errors['isValidForm'] = false
  } else if (!errors['isValidEmail']) {
    errors['form'] = 'Invalid Email'
    errors['isValidForm'] = false
  } else if (errors['isValidEmail'] && !errors['isValidPassword']) {
    errors['form'] = 'Password is Incorrect'
    errors['isValidForm'] = false
  }

  return errors
}

export default ValidateField
