import {
  IconButton,
  MuiThemeProvider,
  Select,
  Tooltip,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import {
  getTimesheetDayoff,
  saveTimesheetDayoff,
} from "actions/TimeSheetFunctions";
import Loading from "components/Loading";
import _ from "lodash";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Input } from "reactstrap";
import { theme } from "utils/Theme";

const DayoffTimesheet = ({
  defaultProjects,
  isFetchComplete,
  getWeekDates = () => {},
  selectedUser,
  showActionFeedback = () => {},
}) => {
  const cellStyle = {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: "nowrap",
  };

  const dayoffLookup = _.chain(defaultProjects)
    .keyBy("project_id")
    .mapValues("def_project_name")
    .value();

  const Hours = (props) => (
    <Input
      type="number"
      id={`${props.taskId}-${props.day}`}
      // data-day={props.day}
      // data-taskId={props.taskId}
      data-projectId={props.projectId}
      placeholder="0"
      min={0}
      max={24}
      bsSize="sm"
      size="sm"
      style={{ width: "54px" }}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  );

  const [data, setData] = useState([
    {
      project_id: 0,
      Sun_hrs: 0,
      Mon_hrs: 0,
      Tue_hrs: 0,
      Wed_hrs: 0,
      Thu_hrs: 0,
      Fri_hrs: 0,
      Sat_hrs: 0,
      tableData: {
        id: 0,
      },
    },
  ]);

  const [currentWeek, setCurrentWeek] = useState({
    start: moment().startOf("week").format("YYYY-MM-DD"),
    end: moment().endOf("week").format("YYYY-MM-DD"),
  });

  const columns = [
    {
      title: "Type",
      field: "project_id",
      cellStyle: cellStyle,
      lookup: dayoffLookup,
      render: (rowData) => (
        <Select
          style={{ fontSize: "13px" }}
          value={rowData.project_id}
          onChange={(e) => {
            const dataToUpdate = [...data];
            const index = rowData.tableData.id;
            dataToUpdate[index]["project_id"] = e.target.value;
            setData([...dataToUpdate]);
          }}
          disabled={isDisabled(rowData)}
        >
          {defaultProjects.map((project, key) => (
            <option value={project.project_id} key={key}>
              {project.def_project_name}
            </option>
          ))}
        </Select>
      ),
    },

    {
      title: "Sun",
      field: "Sun_hrs",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Hours
          day="sun"
          onChange={(e) => handleHourChange(e, rowData, "Sun_hrs")}
          //taskId={task.task_id}
          projectId={rowData.project_id}
          value={data.length > 0 ? data[rowData?.tableData?.id]["Sun_hrs"] : 0}
          disabled={isDisabled(rowData)}
        />
      ),
    },
    {
      title: "Mon",
      field: "Mon_hrs",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Hours
          day="mon"
          onChange={(e) => handleHourChange(e, rowData, "Mon_hrs")}
          //taskId={task.task_id}
          projectId={rowData.project_id}
          value={data.length > 0 ? data[rowData?.tableData?.id]["Mon_hrs"] : 0}
          disabled={isDisabled(rowData)}
        />
      ),
    },
    {
      title: "Tue",
      field: "Tue_hrs",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Hours
          day="tue"
          onChange={(e) => handleHourChange(e, rowData, "Tue_hrs")}
          //taskId={task.task_id}
          projectId={rowData.project_id}
          value={data.length > 0 ? data[rowData?.tableData?.id]["Tue_hrs"] : 0}
          disabled={isDisabled(rowData)}
        />
      ),
    },
    {
      title: "Wed",
      field: "Wed_hrs",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Hours
          day="wed"
          onChange={(e) => handleHourChange(e, rowData, "Wed_hrs")}
          //taskId={task.task_id}
          projectId={rowData.project_id}
          value={data.length > 0 ? data[rowData?.tableData?.id]["Wed_hrs"] : 0}
          disabled={isDisabled(rowData)}
        />
      ),
    },
    {
      title: "Thu",
      field: "Thu_hrs",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Hours
          day="thu"
          onChange={(e) => handleHourChange(e, rowData, "Thu_hrs")}
          //taskId={task.task_id}
          projectId={rowData.project_id}
          value={data.length > 0 ? data[rowData?.tableData?.id]["Thu_hrs"] : 0}
          disabled={isDisabled(rowData)}
        />
      ),
    },
    {
      title: "Fri",
      field: "Fri_hrs",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Hours
          day="fri"
          onChange={(e) => handleHourChange(e, rowData, "Fri_hrs")}
          //taskId={task.task_id}
          projectId={rowData.project_id}
          value={data.length > 0 ? data[rowData?.tableData?.id]["Fri_hrs"] : 0}
          disabled={isDisabled(rowData)}
        />
      ),
    },
    {
      title: "Sat",
      field: "Sat_hrs",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Hours
          day="sat"
          onChange={(e) => handleHourChange(e, rowData, "Sat_hrs")}
          //taskId={task.task_id}
          projectId={rowData.project_id}
          value={data.length > 0 ? data[rowData?.tableData?.id]["Sat_hrs"] : 0}
          disabled={isDisabled(rowData)}
        />
      ),
    },
  ];

  const isDisabled = (rowData) => {
    if (rowData?.approved && rowData?.approved === "Y") {
      return true;
    }
    if (rowData?.updated_dt && rowData?.updated_dt?.length > 0) {
      return true;
    }
    if (rowData?.approved && rowData?.approved === "N") {
      return false;
    }
    return false;
  };

  useEffect(() => {
    let request = {
      start_of_week: currentWeek.start,
      end_of_week: currentWeek.end,
      resource_id: selectedUser.employee_id,
    };
    getTimesheetDayoff(request)
      .then((response) => {
        if (response.length == 0) {
          setData((prev) => [
            {
              project_id: 0,
              Sun_hrs: 0,
              Mon_hrs: 0,
              Tue_hrs: 0,
              Wed_hrs: 0,
              Thu_hrs: 0,
              Fri_hrs: 0,
              Sat_hrs: 0,
              // tableData: {
              //   id: 0,
              // },
            },
          ]);
        } else {
          setData((prev) => response);
        }
      })
      .catch((err) => console.error(err));
  }, [currentWeek, selectedUser]);

  const gotoNextWeek = () => {
    let newStartOfWeek = moment(currentWeek.start)
      .clone()
      .add(7, "days")
      .format("YYYY-MM-DD");
    let newEndOfWeek = moment(currentWeek.end)
      .clone()
      .add(7, "days")
      .format("YYYY-MM-DD");
    setCurrentWeek((prev) => ({
      start: newStartOfWeek,
      end: newEndOfWeek,
    }));
  };
  const gotoPreviousWeek = () => {
    let newStartOfWeek = moment(currentWeek.start)
      .clone()
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    let newEndOfWeek = moment(currentWeek.end)
      .clone()
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    setCurrentWeek((prev) => ({
      start: newStartOfWeek,
      end: newEndOfWeek,
    }));
  };

  const handleRowAdd = async (newData) => {
    setData((prev) => {
      let dayoffObj = {};
      Object.keys(prev[0]).map((day) => {
        dayoffObj = { ...dayoffObj, [day]: newData[day] || 0 };
      });
      return [...prev, dayoffObj];
    });
  };

  const handleHourChange = (e, rowData, day) => {
    const dataToUpdate = [...data];
    const index = rowData.tableData.id;
    if(e.target.value === ''){
    dataToUpdate[index][day] = 0;
    }
    else{
    dataToUpdate[index][day] = e.target.value;
    }
    setData([...dataToUpdate]);
  };

  const saveDayoffHrs = async () => {
    //const dayoffs = data;
    const dayoffs = _.chain(data)
      .keyBy("project_id")
      .mapValues((item) => {
        let { project_id, tableData, ...rest } = item;
        return rest;
      })
      .value();
    let week = currentWeek;
    const dateMap = getWeekDates(currentWeek.start, currentWeek.end);
    let request = { dayoffs, week, selectedUser, dateMap };
    console.log(request);

    saveTimesheetDayoff(request)
      .then((response) => {
        if (response) {
          showActionFeedback(response.message);
          let request = {
            start_of_week: currentWeek.start,
            end_of_week: currentWeek.end,
            resource_id: selectedUser.employee_id,
          };
          getTimesheetDayoff(request)
            .then((response) => {
              setData((prev) => response);
            })
            .catch((err) => console.error(err));
        }
      })
      .catch((err) => showActionFeedback("Could not save timesheet"));

    // alert(JSON.stringify(request, null, 4));
  };

  return (
    <>
      <Card>
        <div className="mt-1 mx-1 d-flex flex-row justify-content-between align-items-center">
          <IconButton onClick={gotoPreviousWeek}>
            <Tooltip title="Previous week">
              <div className="normal-font-size">
                <ArrowBack />
                Previous Week
              </div>
            </Tooltip>
          </IconButton>
          <div>
            Current Week: From {currentWeek.start} to {currentWeek.end}
          </div>
          <IconButton onClick={gotoNextWeek}>
            <Tooltip title="Next week">
              <div className="normal-font-size">
                Next Week
                <ArrowForward />
              </div>
            </Tooltip>
          </IconButton>
        </div>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            components={{
              OverlayLoading: (props) => <Loading />,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: isFetchComplete
                  ? "No records to display"
                  : "",
              },
            }}
            isLoading={!isFetchComplete}
            title={<h2 style={{ color: "#00948B" }}>Dayoff and Holidays</h2>}
            columns={columns}
            data={data}
            editable={{
              onRowAdd: (newData) => handleRowAdd(newData),
              // onRowUpdate: (newData, oldData) =>
              //   handleRowUpdate(newData, oldData),
            }}
            options={{
              // doubleHorizontalScroll: true,
              headerStyle: {
                color: "#00948B",
                fontWeight: "bold",
                paddingTop: "0px",
                paddingBottom: "5px",
              },
              actionsColumnIndex: -1,
              detailPanelColumnAlignment: "right",
              paging: isFetchComplete ? false : true,
            }}
          />
        </MuiThemeProvider>
        {isFetchComplete ? (
          <div>
            <Button
              className="float-right m-2"
              color="primary"
              // disabled={props.disableButtons}
              // onClick={(e) => props.saveTimeSheet(e, props.projectId)}
              onClick={(e) => saveDayoffHrs()}
            >
              Save
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default DayoffTimesheet;
