import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {Link, NavLink as RouterNavLink} from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import ProjectInfo from 'container/ProjectInfoContainer';
import Menu from '../../container/MenuContainer2';
import ClientInformationContainer from 'container/ClientInformationContainer';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, NavItem, NavLink} from 'reactstrap';
import { Typography } from '@material-ui/core';
import { activeMenu, getMenuByUser } from '../../actions/MenuFunctions';

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color)`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
  },
  content: {
    flexDirection: "row-reverse",
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    // fontWeight: theme.typography.fontWeightMedium,
    // '$expanded > &': {
    //   fontWeight: theme.typography.fontWeightRegular,
    // },
  },
  selected: {
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color)`,
      color: 'var(--tree-view-color)',
    },
  },
  expanded: {
    '&:hover > $content': {
      backgroundColor: 'none',
    },
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelText: {
    flexGrow: 1,
  },
  label: {
    // fontWeight: 'inherit',
    color: 'white',
    // fontSize: '16px',
    padding: '0.35rem 0 0.35rem 0.75rem',
    // paddingTop: '5px',
    // paddingBottom: '5px',
    // paddingLeft: '5px',
  }
}))

const ReversedTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const {labelText, ...other} = props;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': 'white',
        '--tree-view-bg-color': '#00948B',
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        label: classes.label,
        selected: classes.selected,
        expanded: classes.expanded,
      }}
      {...other}
    />
  )
}

class Tree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMenus: this.props.allMenus || [],
      subMenuMap: this.props.subMenuMap || {},
      menuMap: this.props.menuMap || {},
      modal: false,
      menuId: '',
      expanded: [],
      activeMenu: [],
    };
  }

  getMenuTree = () => {
    let menuMap = {};
    let subMenuMap = {};
    if(this.state.allMenus.length == 0){
      getMenuByUser().then((response) => {
        response.forEach((menu) => {
          menuMap[menu['menu_id']] = menu;
          if (!subMenuMap[menu['upper_menu_id']]) {
            subMenuMap[menu['upper_menu_id']] = [];
          }
          subMenuMap[menu['upper_menu_id']].push(menu);
        });
        this.setState({
          allMenus: response,
          subMenuMap: subMenuMap,
          menuMap: menuMap,
        });

       
      });
    }
  }

  // takes path, layout, icon and name of the component
  constructPath = (path, layout, icon, name, activeStatus) => {
    return (
      // <TreeItem nodeId={counter++} label={'Home'}>
        // <NavItem className="list-group-item lisColor2 border-0 p-0"
        <NavItem className="lisColor2 border-0 p-0"
          //list-group-item-action list-group-hover lisColor2 border-0 p-1"
          >
          <NavLink
            to={layout + path}
            tag={RouterNavLink}
            onClick={this.closeCollapse}
            activeClassName="nav-link-sidebar"
            className="shadow-none py-2"
          >
            {/* <i className={`text-white pr-1`}  />{' '} */}
            <span className="text-white">{name}</span>
          </NavLink>
        </NavItem>
      // </TreeItem>
    )
  }

  getLabel = (name, icon) => {
    return (
      <div className="d-flex align-items-center">
        <i className={`${icon}`} />
        <span className="ml-2">{name}</span>
      </div>
    )
  }

  constructPathAlt = (path, layout, icon, name, hasSubMenu) => {
    if (hasSubMenu) return this.getLabel(name, icon);
    return (
      <RouterNavLink to={layout+path} className="text-white d-flex">
        {this.getLabel(name, icon)}
      </RouterNavLink>);
  }

  handleClick = (e) => {
    const label = e.currentTarget;
    const menuStr = label.getAttribute('data-menu');
    const menuObj = JSON.parse(menuStr);
    switch (menuObj['menu_id']) {
      case 5:
      case 7:
      case 14:
        this.setState({modal: true, menuId: menuObj['menu_id']})
        break;
    }
  }

  componentDidMount() {
    this.getMenuTree();
  }

  renderLabel = (menuName, menu) => {
    const menuStr = JSON.stringify(menu);
    return(
      <span onClick={this.handleClick} data-menu={menuStr}>{menuName}</span>
    );
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  handleChange = (event, nodes) => {
    this.setState({
      expanded: nodes,
    })
  }

  formatIcon = (icon) => {
    return `ni ni-${icon} text-white`;
  }

  render() {
    const styles = makeStyles({
      root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 300,
      },
    });
    let counter = 1;
    const {subMenuMap, menuMap} = this.state;
    const keys = Object.keys(subMenuMap);
    keys.sort(function(a, b) {return a-b});
    const isVisited = {};
    let startNode = '0';
    let hasSubMenu = false;
    let firstLevelNodes = subMenuMap[startNode];
    let icon = 'ni-circle-08';
    return (
      window.location.pathname == '/user/index' &&
      this.state.allMenus.length > 0 &&
      !this.state.allMenus.find((menu) => menu.link_url == 'user/index')
       ? <Redirect to ='/project' />      
      :<Fragment>
        <TreeView
          className={styles.root}
          defaultCollapseIcon={<ExpandMoreIcon className="text-white" />}
          defaultExpandIcon={<ChevronRightIcon className="text-white" />}
          // expanded={this.state.expanded}
          onNodeSelect={this.handleChange}
        >
          {firstLevelNodes && firstLevelNodes.map((firstLevelNode, idx) => {
            startNode = firstLevelNode['menu_id']
            hasSubMenu = !!subMenuMap[startNode]
            icon = firstLevelNode['icon_url']
            return (
              <ReversedTreeItem nodeId={String(counter++)} key={idx}
                label={this.constructPathAlt('', `/${firstLevelNode['link_url']}`, this.formatIcon(icon), firstLevelNode['menu_name'], hasSubMenu)}>
                {!isVisited[startNode] && subMenuMap[startNode] && subMenuMap[startNode].map((secondLevelNode, indx) => {
                  isVisited[startNode] = true
                  startNode = secondLevelNode['menu_id']
                  hasSubMenu = !!subMenuMap[startNode]
                  icon = secondLevelNode['icon_url']
                  return (
                    <ReversedTreeItem nodeId={String(counter++)} key={indx}
                      label={this.constructPathAlt('', `/${secondLevelNode['link_url']}`, this.formatIcon(icon), secondLevelNode['menu_name'], hasSubMenu)}>
                      {!isVisited[startNode] && subMenuMap[startNode] && subMenuMap[startNode].map((thirdLevelNode, index) => {
                        isVisited[startNode] = true
                        startNode = thirdLevelNode['menu_id']
                        hasSubMenu = !!subMenuMap[startNode]
                        icon = thirdLevelNode['icon_url']
                        return (
                          <ReversedTreeItem nodeId={String(counter++)} key={index}
                            label={this.constructPathAlt('', `/${thirdLevelNode['link_url']}`, this.formatIcon(icon), thirdLevelNode['menu_name'], hasSubMenu)} />
                        )
                      })}
                    </ReversedTreeItem>
                  )
                })}
              </ReversedTreeItem>
            )
          })}
        {/* <TreeItem nodeId={counter++} label={this.constructPath('/index', '/user', 'ni ni-tv-2 text-primary', 'Dashboard', true)} >
          {!isVisited[startNode] && subMenuMap[startNode] && subMenuMap[startNode].map((subMenu) => {
            isVisited[startNode] = true;
            startNode = subMenu['menu_id']
            return (
              <TreeItem nodeId={counter++} 
                label={this.constructPath('', `/${subMenu['link_url']}`, 'ni ni-circle-08 text-pink', 
                this.renderLabel(subMenu['menu_name'], subMenu))}>
                {!isVisited[startNode] && subMenuMap[startNode] && subMenuMap[startNode].map((subMenu) => {
                  isVisited[startNode] = true;
                  startNode = subMenu['menu_id']
                  return (
                    <TreeItem nodeId={counter++} 
                      label={this.constructPath('', `/${subMenu['link_url']}`, 'ni ni-circle-08 text-pink', this.renderLabel(subMenu['menu_name'], subMenu))} />
                  )
                })}
              </TreeItem>
            )
          })}
        </TreeItem> */}
        </TreeView>
      </Fragment>
    );
  }
}

export default Tree;

{/* <TreeView
  // className={styles.root}
  defaultCollapseIcon={<ExpandMoreIcon />}
  defaultExpandIcon={<ChevronRightIcon />}
  // onNodeSelect={this.handleNodeSelect}
>
  <TreeItem nodeId={counter++} label={'Home'}>
    {!isVisited[startNode] && subMenuMap[startNode] && subMenuMap[startNode].map((subMenu) => {
      isVisited[startNode] = true;
      startNode = subMenu['menu_id']
      return (
        <TreeItem nodeId={counter++} label={this.renderLabel(subMenu['menu_name'], subMenu)}>
          {!isVisited[startNode] && subMenuMap[startNode] && subMenuMap[startNode].map((subMenu) => {
            isVisited[startNode] = true;
            startNode = subMenu['menu_id']
            return (
              <TreeItem nodeId={counter++} label={this.renderLabel(subMenu['menu_name'], subMenu)} />
            )
          })}
        </TreeItem>
      )
    })}
  </TreeItem>
</TreeView> */}