import React from "react"
import { Spinner } from "react-bootstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import ProjectSummary from "container/ProjectHealth/ProjectHealthNestedTabs/ProjectSummary"
import ProjectStatus from "container/ProjectHealth/ProjectHealthNestedTabs/ProjectStatus"
import ResourceSummary from "container/ProjectHealth/ProjectHealthNestedTabs/ResourceSummary"
import RisksAndIssues from "container/ProjectHealth/ProjectHealthNestedTabs/RisksAndIssues"
import { useParams } from "react-router-dom"
import { useState } from "react"
import { ExportProjectReport } from "utils/CreatePPt"

const HealthDetail = () => {
  let project = useParams()
  const resourceSlide = React.createRef()
  const sprintSlide = React.createRef()
  const statusSlide = React.createRef()
  const raidSlide = React.createRef()
  const [isExporting, setIsExporting] = useState(false)

  const handleExportReport = () => {
    ExportProjectReport({
      sprintSlide: sprintSlide,
      resourceSlide: resourceSlide,
      statusSlide: statusSlide,
      raidSlide: raidSlide,
      projectName: project?.projectName,
      projectCategory: project?.category,
    })
  }
  return (
    <>
      <div className="row mt-1">
        <div className="col">
          <h2>{project?.projectName}</h2>
        </div>
        <div className="col-2 d-flex justify-content-end">
            <button
              className="btn btn-md btn-primary"
              onClick={()=> handleExportReport()}
              // disabled={}
            >
              <span>Export</span>
            </button>
        </div>
      </div>
      <Tabs
        title="Hello"
        defaultActiveKey="projectSummary"
        id="justify-tab-example"
        className="mb-3 justify-content-right"
        variant="pills"
      >
        <Tab
          eventKey="projectSummary"
          title="Project Summary"
          tabClassName="btn btn-secondary btn-sm text-black mb-1"
        >
          <ProjectSummary
            name={project?.projectName}
            projectId={project?.id}
            calcDate={project?.date}
            sprintName={project?.sprintName}
          />
        </Tab>
        <Tab
          eventKey="resourceSummary"
          title="Resource Summary"
          tabClassName="btn btn-secondary text-black mb-1"
        >
          <ResourceSummary
            projectId={project?.id}
            calcDate={project?.date}
            name={project?.projectName}
            sprintId={project?.sprintId}
          />
        </Tab>
        <Tab
          eventKey="projectStatus"
          title="Project Status"
          tabClassName="btn btn-secondary text-black mb-1"
        >
          <ProjectStatus
            projectId={project?.id}
            calcDate={project?.date}
            name={project?.projectName}
          />
        </Tab>
        <Tab
          eventKey="riskAndIssues"
          title="Risk And Issues"
          tabClassName="btn btn-secondary text-black mb-1"
        >
          <RisksAndIssues
            projectId={project?.id}
            sprintId={project?.sprintId}
            name={project?.projectName}
          />
        </Tab>
      </Tabs>
      <div
        className="container-fluid"
        style={{
          maxHeight: 0,
          minWidth: 1100,
          maxWidth: 1101,
          overflow: "scroll",
        }}
      >
        <div id="projectSummary" ref={sprintSlide}>
          <ProjectSummary
            name={project?.projectName}
            projectId={project?.id}
            calcDate={project?.date}
            sprintName={project?.sprintName}
          />
        </div>
        <div id="resourceSummary" ref={resourceSlide}>
          <ResourceSummary
            projectId={project?.id}
            calcDate={project?.date}
            name={project?.projectName}
            sprintId={project?.sprintId}
          />
        </div>
        <div id="projectStatus" ref={statusSlide}>
          <ProjectStatus
            projectId={project?.id}
            calcDate={project?.date}
            name={project?.projectName}
          />
        </div>
        <div id="riskAndIssues" ref={raidSlide}>
          <RisksAndIssues
            projectId={project?.id}
            sprintId={project?.sprintId}
            name={project?.projectName}
          />
        </div>
      </div>
    </>
  )
}

export default HealthDetail
