import { getRecentSprints } from "actions/ProjectSprintFunctions";
import axios from "axios";
import { getDetail } from "actions/UserFunctions";
import { formatDate } from "components/WeeklyReport";
import { EffortEstimationTable } from "components/WeeklyReport/ReportTables";
import React, { useEffect, useState } from "react";
import NestedToggleButton from "../../../components/ProjectHealth/NestedToggleButton";
import {
  HealthTable,
  HealthDetailTable,
  ProjectNameBox as SprintNameBox,
  ProjectTaskPlan,
  DashboardHealthTable,
  DashboardHealthDetailTable,
  DashboardEstimationTable,
} from "../../../components/ProjectHealth/Table";

import { getDashboardProjectHealth } from "actions/DashboardFunctions";

function ProjectSummary(props) {
  return (
    <div>
      <div className="row">
        <div className="col">
          <SprintNameBox name={props.sprintName} />
        </div>
        <div className="col">
          <DashboardHealthTable projectId={props.projectId} calc_date={props.calcDate} />
        </div>
        <div className="col">
          {/* <HealthDetailTable projectId={props.projectId} /> */}
          <DashboardHealthDetailTable
            projectId={props.projectId}
            calc_date={props.calcDate}
          />
        </div>
        <div className="col">
          <DashboardEstimationTable
            projectId={props.projectId}
            calc_date={props.calcDate}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2">
          <ProjectTaskPlan projectId={props.projectId} />
        </div>
      </div>
    </div>
  );
}

export default ProjectSummary;
