import React from 'react'
import GenericPieChart from '../../../components/Charts/GenericPieChart'
import MaterialTable from 'material-table'
import { truncate } from 'lodash'

const cellStyle = {
  paddingTop: '6px',
  paddingBottom: '6px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderRight: '1px solid #e5e5e5',
}

const cellStyleData = {
  paddingTop: '6px',
  paddingBottom: '6px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderRight: '1px solid #e5e5e5',
  textAlign: 'right',
}

const totalRowStyle = {
  paddingTop: '6px',
  paddingBottom: '6px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
}

class TableContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      matColumn: [],
      Total: {},
      data1: [],
      id: '',
    }
  }

  componentDidMount = () => {
    this.resetState()
    this.setState({ data1: this.props.data, id: this.props.id })
    this.setColumns()
    this.getTotal(this.props.data)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data !== this.props.data) {
      this.resetState()
      this.setState({ data1: this.props.data, id: this.props.id })
      this.setColumns()
      this.getTotal(this.props.data)
    }
  }

  resetState() {
    // console.log('INSIDEEE REseeTT')
    this.setState({ matColumn: [], Total: {}, data1: [], id: '' })
  }

  // Capitalize the first letter of a string
  capitalFirst(string) {
    // console.log('STRINGSSSSSSS', string)
    if (string == 'OFF') {
      string = 'Offshore'
    }

    if (string == 'ON') {
      string = 'Onshore'
    }
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  //Get the total
  getTotal = (data) => {
    let Total = 0
    let TotalMap = {}

    //initialize the object

    for (let i = 0; i < data.length; i++) {
      let keyArray = Object.keys(data[i])
      for (let j = 1; j < keyArray.length; j++) {
        if (keyArray[j] != 'tableData') {
          // Total += data[i][keyArray[j]]
          TotalMap[keyArray[j]] = 0
        }
      }
    }

    //Calculate Total and map to the object
    for (let i = 0; i < data.length; i++) {
      let keyArray = Object.keys(data[i])
      for (let j = 1; j < keyArray.length; j++) {
        if (keyArray[j] != 'tableData') {
          // Total += data[i][keyArray[j]]
          // let tot = Number(TotalMap[keyArray[j]]) + data[i][keyArray[j]]
          TotalMap[keyArray[j]] =
            Number(TotalMap[keyArray[j]]) + data[i][keyArray[j]]

          // TotalMap[keyArray[j]] = <div className = "font-weight-bold" >{Number(TotalMap[keyArray[j]]) + Number(data[i][keyArray[j]])}</div>
          // TotalMap[keyArray[j]] = <div className = "font-weight-bold" >{tot}</div>
          // console.log('VALUESSSSSSS', data[i][keyArray[j]])
          // console.log('TotalMapINSIDEEEE', TotalMap)
        }
      }
    }

    console.log('TotalMap', TotalMap)

    console.log('THE DATA WE HAVE', this.state.data1)

    //Add row and make bold
    TotalMap['name'] = <div className='font-weight-bold'>Grand Total</div>
    TotalMap['serial'] = ''
    for (let i = 0; i < data.length; i++) {
      let keyArray = Object.keys(data[i])
      for (let j = 1; j < keyArray.length; j++) {
        TotalMap[keyArray[j]] = (
          <div className='font-weight-bold'>{TotalMap[keyArray[j]]}</div>
        )
      }
    }
    //END add row and make bold

    // this is for if it is bar and extra total in columns is required
    let TotalColumnMap = {}
    let FinalTotal = 0
    if (this.props.id == 'bar' || this.props.id == 'first') {
      //initialize the object

      for (let i = 0; i < data.length; i++) {
        TotalColumnMap[data[i]['name']] = 0
        data[i]['Total'] = 0

        // TotalColumnMap["Total"] = 0
      }

      //calculate grand total for column

      for (let i = 0; i < data.length; i++) {
        let keyArray = Object.keys(data[i])
        for (let j = 1; j < keyArray.length; j++) {
          if (keyArray[j] != 'tableData') {
            TotalColumnMap[data[i]['name']] =
              Number(TotalColumnMap[data[i]['name']]) + data[i][keyArray[j]]
          }
        }
        data[i]['Total'] = TotalColumnMap[data[i]['name']]

        FinalTotal += data[i]['Total']
      }

      //Assign and make bold
      TotalMap['Total'] = <div className='font-weight-bold'>{FinalTotal}</div>
      TotalMap['serial'] = ''
      for (let i = 0; i < data.length; i++) {
        //----
        data[i]['Total'] = (
          <div className='font-weight-bold'>{data[i]['Total']}</div>
        )
      }
      // console.log("COLUMNNN GRANDEEE TOTAL:", TotalColumnMap)
    }

    // END this is for if it is bar and extra total in columns is required

    // for bar graph with three dimensions
    let uniqueNameGroupMap = []
    let uniqueParentIDMap = {}
    if (this.props.id == 'second') {
      // loop to check unique nameGroups
      let uniqueNameGroup = []
      for (let i = 0; i < data.length; i++) {
        if (uniqueNameGroup.includes(data[i]['nameGroup']) == false) {
          uniqueNameGroup.push(data[i]['nameGroup'])
        }
      }

      //set up extra rows according to unique nameGroups
      for (let i = 0; i < uniqueNameGroup.length; i++) {
        let summaryTotal = { Billable: 0, 'Non-Billable': 0 }
        for (let j = 0; j < data.length; j++) {
          if (uniqueNameGroup[i] == data[j]['nameGroup']) {
            if (data[j]['Billable']) {
              summaryTotal['Billable'] += data[j]['Billable']
            }
            if (data[j]['Non-Billable']) {
              summaryTotal['Non-Billable'] += data[j]['Non-Billable']
            }
          }
        }

        console.log('SUMMAARRRY TOTAL:', summaryTotal)

        uniqueNameGroupMap.push({
          nameGroup: uniqueNameGroup[i],
          id: i + 1,
          name: 'Total',
          Billable: summaryTotal['Billable'],
          'Non-Billable': summaryTotal['Non-Billable'],
        })
        uniqueParentIDMap[uniqueNameGroup[i]] = i + 1
      }

      // set id and parentID for each row of data
      for (
        let i = uniqueNameGroup.length;
        i < data.length + uniqueNameGroup.length;
        i++
      ) {
        let j = i - uniqueNameGroup.length
        // ---- data[j]["id"] = j + 1
        //-----  data[j]["parentId"] = uniqueParentIDMap[data[j]["nameGroup"]]
        // ---- data[j]["nameGroup"]= ''
        data[j]['id'] = j + 1
        data[j]['parentId'] = uniqueParentIDMap[data[j]['nameGroup']]
        data[j]['nameGroup'] = ''
      }
    }

    //End threee dimensional bar graph

    // setup a serial number
    for (let i = 0; i < data.length; i++) {
      let keyArray = Object.keys(this.props.data[i]) // name, value

      // console.log ("VAAAAALLLLLLLLLLLLL", data.length)
      // console.log ("LOOOPPPPPP", keyArray)
      if (keyArray.includes('serial') == false) {
        data[i]['serial'] = i + 1
      }
    }
    // end serial number

    this.setState({
      data1: [...uniqueNameGroupMap, ...data, TotalMap],
    })

    //console.log("THE DATA PUSSSHHHHHHHHH", this.state.data1)
  }

  setColumns = () => {
    // [{title, field},{title, field}]
    let keyDict = []
    const matColumns = []

    //add a serial number column
    matColumns.push({
      width: 5,
      title: 'S.N.',
      field: 'serial',
      cellStyle: { ...cellStyle, width: '0px' },
    })

    for (let i = 0; i < this.props.data.length; i++) {
      let keyArray = Object.keys(this.props.data[i]) // name, value

      for (let j = 0; j < keyArray.length; j++) {
        if (
          keyDict.includes(keyArray[j]) == false &&
          keyArray[j] != 'tableData' &&
          keyArray[j] != 'serial' &&
          keyArray[j] != 'Total' &&
          keyArray[j] == 'name'
        ) {
          matColumns.push({
            title: this.capitalFirst(keyArray[j]),
            field: keyArray[j],
            cellStyle: cellStyle,
          })
          keyDict.push(keyArray[j])
        }

        if (
          keyDict.includes(keyArray[j]) == false &&
          keyArray[j] != 'tableData' &&
          keyArray[j] != 'serial' &&
          keyArray[j] != 'Total' &&
          keyArray[j] == 'nameGroup'
        ) {
          matColumns.push({
            title: this.capitalFirst(keyArray[j]),
            field: keyArray[j],
            cellStyle: cellStyle,
          })
          keyDict.push(keyArray[j])
        }

        if (
          keyDict.includes(keyArray[j]) == false &&
          keyArray[j] != 'tableData' &&
          keyArray[j] != 'serial' &&
          keyArray[j] != 'Total' &&
          keyArray[j] != 'name'
        ) {
          matColumns.push({
            title: this.capitalFirst(keyArray[j]),
            field: keyArray[j],
            cellStyle: { ...cellStyleData },
          })
          keyDict.push(keyArray[j])
        }
      }
    }

    if (this.props.id == 'bar' || this.props.id == 'first') {
      matColumns.push({
        title: 'Grand Total',
        field: 'Total',
        cellStyle: { ...cellStyleData, width: '0px' },
      })
    }

    this.setState({ matColumn: matColumns })
    // console.log("VALUUEEE OF COLSS SETTTTTTTTTTTTTTT", matColumns)
    // console.log("Value of id", this.state.id)
  }

  render() {
    // console.log("VALUUEEE OF COLSS SETTTTTTTTTTTTTTT", this.state.matColumns)
    // console.log("THE DATA AFTEr PUSHHH", this.state.data1)
    // console.log("THE DATA AFTEr PUSHHH dataaaaa", this.props.data1)

    return (
      <MaterialTable
        // columns={[
        //   { title: "Name", field: "name", cellStyle },
        //   { title: "Value", field: "value", cellStyle },

        // ]}

        columns={this.state.matColumn}
        // data={[{ name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 }]}
        data={this.state.data1}
        parentChildData={(row, rows) =>
          rows.find((a) => Number(a.id) === Number(row.parentId))
        }
        options={{
          toolbar: false,
          paging: false,
          // minBodyHeight: '250px',
          // style : { width: "60%", borderColor:"#D4AF37", },
          // rowStyle: rowData => {

          //     console.log("VALUE OF ROWWW DATTTAAA", rowData)
          //     if(rowData.name == "Grand Total") {
          //       return {backgroundColor: '#8cc3fa', fontWeight: 'bold'};

          //       console.log("VALUE OF ROWWW DATTTAAA", rowData)

          //     }

          //     return {};

          // },

          rowStyle: (rowData) => {
            // console.log("VALUE OF ROWWW DATTTAAA", rowData)
            if (rowData.tableData.id == this.state.data1.length - 1) {
              return { backgroundColor: '#80ADD7', fontWeight: 'bold' }
            }
            return {}
          },
          headerStyle: {
            color: '#080707',
            backgroundColor: '#80ADD7',
            fontWeight: 'bold',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '8px',
            paddingRight: '8px',
            filtering: true,
            whiteSpace: 'nowrap',
          },
        }}
      />
    )
  }
}

export default TableContainer
