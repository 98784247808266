import React from 'react';
import moment from 'moment';
import {Card} from 'reactstrap';
import {MuiThemeProvider} from '@material-ui/core/styles'
import MaterialTable, { MTableBodyRow, MTableCell, MTableAction, MTableActions } from 'material-table'
import Loading from '../Loading';
import {theme} from '../../utils/Theme';
import {Input} from 'reactstrap';
import {ArrowBack, ArrowForward} from '@material-ui/icons';
import {IconButton, Select, Tooltip} from '@material-ui/core';
import {Button} from 'reactstrap';

const Hours = (props) => (
  <Input
    type="number"
    name="number"
    id={`${props.taskId}-${props.day}`}
    data-day={props.day}
    data-taskId={props.taskId}
    data-projectId={props.projectId}
    placeholder="0"
    min={0}
    max={24}
    bsSize="sm"
    size="sm"
    style={{width: "54px"}}
    value={props.value}
    onChange={props.onChange}
    disabled={props.isDisabled}
  />
)

// get the value of a key if object is defined or
// return 0 if not
// also check if the key is present in the object
// else return 0
const getValueIfDefined = (obj, key) => {
  return obj ? (obj[key] ? obj[key] : 0) : 0;
} 

const TimeSheet = (props) => {
  const startDate = new Date(props.currentWeek.startOfWeek);
  const endDate = new Date(props.currentWeek.endOfWeek);
  let data = [];
  data = props.projectTasks && props.projectTasks.filter((task) => {
    const taskStartDate = new Date(task.task_st_dt?.substring(0,10));
    const taskEndDate = new Date(task.task_end_dt?.substring(0,10));

    if (taskStartDate >= startDate && taskStartDate <= endDate) {
      return true;
    }
    if (taskEndDate >= startDate && taskEndDate <= endDate) {
      return true;
    }
    if ((startDate >= taskStartDate && startDate <= taskEndDate) && 
          (endDate >= taskStartDate && endDate <= taskEndDate)) {
      return true;
    }
    return false;
  });

  // TODO: start using Map() instead of the plain object for key-value pairs
  const total = {
    'sun': 0,
    'mon': 0,
    'tue': 0,
    'wed': 0,
    'thu': 0,
    'fri': 0,
    'sat': 0,
  };

  const isSubmitted = () => {
    const projectMeta = props.projectBillableMeta[props.projectId] ?? {}
    const projectDayMeta = Object.values(projectMeta)[0] ?? {}

    if (!projectDayMeta) { return 'Timesheet not submitted' }
    if (projectDayMeta['approved'] === 'Y') { return 'Timesheet approved' }
    if (projectDayMeta['approved'] === 'N' && projectDayMeta['approved_dt'] && projectDayMeta['approved_by']) {
      return 'Timesheet rejected'
    }
    if (projectDayMeta['updated_by'] && projectDayMeta['updated_dt']) { return 'Timesheet submitted' }
    return 'Timesheet not submitted'
  }

  const isDisabled = (offset, projectId, taskId) => {
    const currentWeek = props.currentWeek;
    const projectTaskMeta = props.projectTaskMeta;
    const date = moment(currentWeek.startOfWeek).clone().add(offset, 'days').format('YYYY-MM-DD');
    const meta = 
      projectTaskMeta && projectTaskMeta[projectId] && 
        projectTaskMeta[projectId][taskId] && projectTaskMeta[projectId][taskId][date];
    if (meta) {
      if (meta['approved'] === 'Y') {
        // this means the time sheet was approved
        return true;
      }
      // when user submits the time sheet the updated_dt and updated_by are set
      if (meta['updated_by'] && meta['updated_dt']) {
        return true;
      }
      if (meta['approved'] === 'N' && meta['approved_dt'] && meta['approved_by']) {
        // this means the time sheet was rejected
        return false;
      }
      return false;
    } else {
      return false;
    }
  }

  const isBillableDisabled = (offset) => {
    if (props.disableBillable) return  true;
    const currentWeek = props.currentWeek;
    const date = moment(currentWeek.startOfWeek).clone().add(offset, 'days').format('YYYY-MM-DD');
    const meta = 
      props.projectBillableMeta && props.projectBillableMeta[props.projectId] &&
      props.projectBillableMeta[props.projectId][date];
    if (meta) {
      if (meta['approved'] === 'Y') {
        // this means the time sheet was approved
        return true;
      }
      // when user submits the time sheet the updated_dt and updated_by are set
      if (meta['updated_by'] && meta['updated_dt']) {
        return true;
      }
      if (meta['approved'] === 'N' && meta['approved_dt'] && meta['approved_by']) {
        // this means the time sheet was rejected
        return false;
      }
      return false;
    } else {
      return false;
    }
  }

  data && data.map((task) => {
    // if there is not task hours defined for given project and task combination
    // set the task hour for entire week to zero
    const taskHour = props.projectTaskHours && props.projectTaskHours[task.task_id];
    // if taskHour is undefined then set day-hrs to 0
    task['sun-hrs'] = getValueIfDefined(taskHour, 'sun-hrs');
    task['mon-hrs'] = getValueIfDefined(taskHour, 'mon-hrs');
    task['tue-hrs'] = getValueIfDefined(taskHour, 'tue-hrs');
    task['wed-hrs'] = getValueIfDefined(taskHour, 'wed-hrs');
    task['thu-hrs'] = getValueIfDefined(taskHour, 'thu-hrs');
    task['fri-hrs'] = getValueIfDefined(taskHour, 'fri-hrs');
    task['sat-hrs'] = getValueIfDefined(taskHour, 'sat-hrs');
    total['sun'] += Number(getValueIfDefined(taskHour, 'sun-hrs'));
    total['mon'] += Number(getValueIfDefined(taskHour, 'mon-hrs'));
    total['tue'] += Number(getValueIfDefined(taskHour, 'tue-hrs'));
    total['wed'] += Number(getValueIfDefined(taskHour, 'wed-hrs'));
    total['thu'] += Number(getValueIfDefined(taskHour, 'thu-hrs'));
    total['fri'] += Number(getValueIfDefined(taskHour, 'fri-hrs'));
    total['sat'] += Number(getValueIfDefined(taskHour, 'sat-hrs'));

    task['sun'] = 
      <Hours 
        day="sun" 
        onChange={props.onChange} 
        taskId={task.task_id} 
        projectId={props.projectId} 
        value={task['sun-hrs']}
        isDisabled={isDisabled(0, task['project_id'], task['task_id'])}
      />;
    task['mon'] = 
      <Hours 
        day="mon"
        onChange={props.onChange} 
        taskId={task.task_id} 
        projectId={props.projectId} 
        value={task['mon-hrs']} 
        isDisabled={isDisabled(1, task['project_id'], task['task_id'])}
      />;
    task['tue'] = 
      <Hours 
        day="tue" 
        onChange={props.onChange} 
        taskId={task.task_id} 
        projectId={props.projectId} 
        value={task['tue-hrs']} 
        isDisabled={isDisabled(2, task['project_id'], task['task_id'])}
      />;
    task['wed'] = 
      <Hours 
        day="wed" 
        onChange={props.onChange} 
        taskId={task.task_id} 
        projectId={props.projectId} 
        value={task['wed-hrs']} 
        isDisabled={isDisabled(3, task['project_id'], task['task_id'])}
      />;
    task['thu'] = 
      <Hours 
        day="thu" 
        onChange={props.onChange} 
        taskId={task.task_id} 
        projectId={props.projectId} 
        value={task['thu-hrs']} 
        isDisabled={isDisabled(4, task['project_id'], task['task_id'])}
      />;
    task['fri'] = 
      <Hours 
        day="fri" 
        onChange={props.onChange} 
        taskId={task.task_id} 
        projectId={props.projectId} 
        value={task['fri-hrs']} 
        isDisabled={isDisabled(5, task['project_id'], task['task_id'])}
      />;
    task['sat'] = 
      <Hours 
        day="sat" 
        onChange={props.onChange} 
        taskId={task.task_id} 
        projectId={props.projectId} 
        value={task['sat-hrs']} 
        isDisabled={isDisabled(6, task['project_id'], task['task_id'])}
      />;
  });

  const totalObjects = {};
  total && Object.keys(total).map((day) => {
    totalObjects[`${day}`] = 
      <Hours 
        day={`${day}`}
        isDisabled={true}
        value={total[`${day}`]}
      />
  })

  data && data.length !== 0 && data.push({
    'planned_hrs': 'Total', // TODO: may need to change the column where 'Total' is placed
    ...totalObjects,
  });

  const billableTotals = {};
  if (data && data.length !== 0 && props.billableTotals && 
      Object.keys(props.billableTotals).length !== 0) {
    Object.keys(props.billableTotals).map((dayKey, idx) => {
      billableTotals[dayKey] = 
        <Hours 
          day={dayKey} 
          onChange={props.onBillableChange} 
          projectId={props.projectId} 
          value={props.billableTotals[dayKey]}
          isDisabled={isBillableDisabled(idx)}
        />;
    })
    data.push({
      'planned_hrs': 'Billable Total', // TODO: may need to change the column where 'Billable Total' is placed
      ...billableTotals,
    });
  }

  //editable dropdown project status column
  const columns = [];
  
     props.columns.map((taskColumn) => {
      
      if (taskColumn.field === 'task_status_id') {
        columns.push({
          ...taskColumn,
          lookup: props.taskStatusMap[props.projectId],
          render: rowData => (
            rowData.task_status_id && <Select
            style={{fontSize: '13px'}} 
            value={rowData.task_status_id}
            onChange={(e)=>props.handleStatusChange(rowData, e)}
            disabled={isDisabled(null, rowData.project_id, rowData.task_id)} >
                {props.getTaskStatusOptionsMui(props.projectId)}
            </Select>
          )
        });
        
      } else {
        columns.push({ ...taskColumn });
      }
      
    })

  return (
    <Card className="mt-1 shadow-none">
      <div className="mt-1 mx-1 d-flex flex-row justify-content-between align-items-center">
        <IconButton onClick={props.gotoPreviousWeek} data-projectId={props.projectId}>
          <Tooltip title="Previous week">
            <div className="normal-font-size">
              <ArrowBack />
              Previous Week
            </div>
          </Tooltip>
        </IconButton>
        <div className="text-center">
          Current Week: 
          From {props.currentWeek.startOfWeek}{' '}
          to {props.currentWeek.endOfWeek}
          <br/>
          {isSubmitted()}
        </div>
        <IconButton onClick={props.gotoNextWeek} data-projectId={props.projectId}>
          <Tooltip title="Next week">
            <div className="normal-font-size">
              Next Week
              <ArrowForward />
            </div>
          </Tooltip>
        </IconButton>
      </div>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          components={{
            OverlayLoading: props => (<Loading />)
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 
                props.isFetchComplete ? 'No records to display' : '',
            }
          }}
          isLoading={!props.isFetchComplete}
          title={
            <h2 style={{ color: '#00948B' }}>{props.projectName}</h2>
          }
          columns={columns}
          data={data}
          options={{
            // doubleHorizontalScroll: true,
            headerStyle: {
              color: '#00948B',
              fontWeight: 'bold',
              paddingTop: '0px',
              paddingBottom: '5px',
            },
            actionsColumnIndex: -1,
            detailPanelColumnAlignment: 'right',
            paging: props.isFetchComplete ? false : true,
          }}
        />
      </MuiThemeProvider>
      
      {props.isFetchComplete ? 
       
        <div>
          <Button
            className="float-right m-2"
            color="primary"
            disabled={props.disableButtons}
            onClick={(e)=> props.saveTimeSheet(e, props.projectId)}
          >
                  Save
            </Button>
        </div>
      : <div />}
     
    </Card>
  )
}

export default TimeSheet;