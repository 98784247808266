const ValidateEmail = (email, emailList) => {
  // alert(email)
  const isIncluded = emailList.includes(email)
  const errors = {}
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

  if (email === '') {
    errors['isValidEmail'] = false
    errors['email'] = 'Email is Empty'
    console.log(errors['email'])
    errors['isEmailEmpty'] = true
  } else if (!re.test(email)) {
    errors['isValidEmail'] = false
    errors['email'] = 'Invalid Email Address'
  } else if (!isIncluded) {
    errors['isValidEmail'] = false
    errors['email'] = 'User with this email does not exist!'
    console.log(errors['email'])
  } else {
    errors['email'] = ''
    errors['isValidEmail'] = true
    console.log(errors['isValidEmail'])
  }

  // console.log('log', errors)
  // alert(errors['isValidEmail'])

  return errors
}

export default ValidateEmail
