import React from "react"
import { useState } from "react"
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap"
import "./PMOTable.css"
import _ from 'lodash';

const headerStyles = {
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
}

const PMOResourceTable = ({ data, grand_total }) => {

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState()
  const [modalTitle, setModalTitle] = useState("")
  const handleClick = (rowData) => {
    setModalTitle(rowData)
    let groupData = _.groupBy(rowData.resources, "project_name")
    setModalData(groupData)
    setModal(true)
  }
  const toggle = () => {
    setModal(!modal)
  }
  return (
    <>
      <Table size="sm" bordered responsive>
        <thead className="lisColor1">
          <tr>
            <th style={headerStyles}>S.N</th>
            <th style={headerStyles}>Name</th>
            <th style={headerStyles}>Count</th>
          </tr>
        </thead>
        <tbody className="pointer">
          {data.map((row, idx) => (
            <tr key={`${idx}`} onClick={() => handleClick(row)}>
              <td>{idx + 1}</td>
              <td>{row.name}</td>
              <td>{row.total_count}</td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>Grand Total</td>
            <td>{grand_total?.grand_total}</td>
          </tr>
        </tbody>
      </Table>
      <Modal
        size="lg"
        style={{ width: "100%" }}
        isOpen={modal}
        centered
      >
        <ModalHeader toggle={toggle}>{`${modalTitle?.name}`}</ModalHeader>
        <ModalBody>
          <Table size="sm" bordered responsive>
            <thead>
              <tr>
                <th className="w-30">Project Name</th>
                <th className="w-25">PMO Manager</th>
                <th className="w-25">Resources</th>
                <th className="w-20">Role</th>
              </tr>
            </thead>
            <tbody>
              {modalData &&
                Object.values(modalData)?.map((projects) => {
                  return projects?.map((row, idx) => (
                    <tr key={idx}>
                      {idx === 0 && (
                        <>
                          <td rowSpan={projects.length}>{row?.project_name}</td>
                          <td rowSpan={projects.length}>{row?.lead_name}</td>
                        </>
                      )}
                      <td>{row?.resource_name}</td>
                      <td>{row?.resource_role_name}</td>
                    </tr>
                  ))
                })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PMOResourceTable
