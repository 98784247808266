import Axios from "axios";
import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const getProjectDistributionDetail = () => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/dashboard/projectDistribution/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting Project Detail): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while Getting Project Detail`, error);
    });
};

export const getProjectDistributionDetailByWeek = (calcDate) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/dashboard/projectDistributionbyweek/${calcDate}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting Project Detail): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while Getting Project Detail`, error);
    });
};

export const getResourceDist = () => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/resource/dist`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Get resource distribution: ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get resource distribution`, error);
    });
};

export const getProjectCountData = (selection) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/projectCountGrouping/${selection}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting Project Group Count)", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while Getting Project Group Count`, error);
    });
};

export const getProjectStatusCount = (selection) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/ProjectStatusCount/${selection}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting Project Group Count)", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while Getting Project Group Count`, error);
    });
};

export const getProjectHealth = (projectId) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/health/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Get project health", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get project health`, error);
    });
};

export const getProjectHealthDetail = (projectId) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/health/detail/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Get project health detail", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get project health detail`, error);
    });
};

export const getProjectTaskPlan = (projectId, currentDate, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/task/plan/${projectId}/${currentDate}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((response) => {
      console.log("Get project task plan", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get project task plan`, error);
    });
};

// added for dashboard nested tabs in health tab
export const getDashboardProjectHealth = (projectId, calc_date, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/health/new/${projectId}/${calc_date}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get project health`, error);
    });
};

// first nested tab first row third table
export const getDashboardProjectHealthDetail = (projectId, calc_date, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/health/detail/new/${projectId}/${calc_date}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((response) => response.data)
    .catch((err) => console.log(`Error get project health detail`, err));
};

// first nested tab first row last table

export const getDashboardProjectEstimation = (projectId, calcDate, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/estimation/new/${projectId}/${calcDate}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => res.data)
    .catch((err) => console.log("Error get project health detail", err));
};

// second nested tab resource table
export const getDashboardProjectResource = (projectId, sprintId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/resource/new/${projectId}/${sprintId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (Axios.isCancel(err)){
        console.log("Cancelling requests from DashboardProjectResource");
      } else {
        console.log(err);
      }
    });
};

// third nested tab project status
export const getDashboardProjectNewsAccomplishmentsPlans = (
  projectId,
  calc_date,
  cancelToken
) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/newsAccomplishmentsPlans/new/${projectId}/${calc_date}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => res)
    .catch((err) => console.log(err));
};

// fourth nested tab risks and issues goes here
export const getDashboardProjectRisksIssues = (projectId, sprintId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/dashboard/project/risksIssues/new/${projectId}/${sprintId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken,
    })
    .then((res) => res)
    .catch((err) => console.log(err));
};
