import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Container,
} from 'reactstrap';
import 'assets2/button.scss';
import { useFormik } from 'formik';
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';

const ProjectStateForm = ({
  modal,
  setModal,
  resetForm,
  initValues,
  stateList,
  loading,
  handleSubmit,
}) => {
  const toggle = () => {
    setModal((prev) => !prev);
    resetForm();
  };
  const style = { fontSize: '12px', padding: 10, lineHeight: '20px' };

  const formikInitialValues = {
    state_project: '',
    state_date: '',
    state_remarks: '',
  };

  const initialValues = _.isEmpty(initValues)
    ? formikInitialValues
    : {
        ...initValues,
        Projectinfo: {
          ...initValues.Projectinfo,
          state_project: initValues.Projectinfo.state_project,
          state_date: initValues.Projectinfo.state_date,
          state_remarks: initValues.Projectinfo.state_remarks,
        },
      };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      //console.log(values);
      setModal(false);
      await handleSubmit(values);
    },
  });

  const renderStates = () => {
    return stateList.map((radio, key) => (
      <FormGroup check inline>
        <Label check>
          <Input
            type="radio"
            key={key}
            name="Projectinfo.state_project"
            value={radio.id}
            onChange={formik.handleChange}
            checked={formik.values.Projectinfo.state_project == radio.id}
          />
          {radio.name}
        </Label>
      </FormGroup>
    ));
  };
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader
        style={{
          backgroundColor: '#fff',
          color: 'white',

          padding: 10,
        }}
      >
        Project: {initValues.Projectinfo.project_name}
      </ModalHeader>
      {loading && <Spinner animation="border" />}
      <ModalBody style={style}>
        <Container>
          <Form onSubmit={formik.handleSubmit}>
            {renderStates()}
            <FormGroup row>
              <Label for="state_date" sm={2}>
                Date
              </Label>
              <Col sm={10}>
                <Input
                  type="date"
                  name="Projectinfo.state_date"
                  value={formik.values.Projectinfo.state_date}
                  onChange={formik.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="remarks" sm={2}>
                Remarks
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="Projectinfo.state_remarks"
                  value={formik.values.Projectinfo.state_remarks}
                  onChange={formik.handleChange}
                />
              </Col>
            </FormGroup>
            <Row style={{ float: 'right' }}>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="danger" onClick={() => toggle()}>
                Cancel
              </Button>
            </Row>
          </Form>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ProjectStateForm;
