import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const getAllFieldsOptions = () => {
  const token = getUserToken();
  return axios
    .get("/resourceHistory/labels", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((err) => console.log(err));
};

export const fetchResourceHistory = (request) => {
  const token = getUserToken();
  request.project_id = parseInt(request.project_id);
  request.resource_id = parseInt(request.resource_id);
  request.stream_id = request.stream_id === "" ? null : request.stream_id;
  request.status =  request.status === "" ? null : request.status;
  request.resource_st_dt = request.resource_st_dt === "" ? null : request.resource_st_dt;
  request.resource_end_dt = request.resource_end_dt === "" ? null : request.resource_end_dt;
  request.min_tenure = request.min_tenure === "" ? null : request.min_tenure;
  return axios
    .post("/resourceHistory", request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((err) => console.log(err));
};
