import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listClientTypes = () => {
  const token = getUserToken();
  return axios
    .get('/clientType/list', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('List client type', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error list client type', error);
    })
}

export const createClientType = (fields) => {
  const token = getUserToken();
  fields['created_by'] = getUserId();
  fields['created_dt'] = new Date().toISOString();
  return axios
    .post('/clientType/create', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Create client type', response);
      return response.data
    })
    .catch((error) => {
      console.log('Error create client type', error);
    })
}

export const deleteClientType = (id) => {
  const token = getUserToken();
  return axios
    .delete(`/clientType/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Delete client type', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error delete client type`, error);
      throw error;
    })
}

export const updateClientType = (id, rowData) => {
  const token = getUserToken();
  rowData['updated_by'] = getUserId();
  rowData['updated_dt'] = new Date().toISOString();
  return axios
    .put(`/clientType/update/${id}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Update client type', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error update client type', error);
    })
}

export const updateApproved = (id, rowData) => {
  const token = getUserToken();
  rowData['approved_by'] = getUserId();
  rowData['approved_dt'] = new Date().toISOString();
  return axios
    .put(`/clientType/approve/${id}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Approve client type', response);
      return response.data
    })
    .catch((error) => {
      console.log('Error update client type', error);
    })
}

export const updateStatus = (id, rowData) => {
  const token = getUserToken();
  rowData['updated_by'] = getUserId();
  rowData['updated_dt'] = new Date().toISOString();
  return axios
    .put(`/clientType/status/${id}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Status client type', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error status client type', error);
    })
}

export const getDetail = (id) => {
  const token = getUserToken();
  return axios
    .get(`/clientType/detail/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Detail client type', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error detail client type', error);
    })
}