import React, { useState, useEffect } from "react";
import { ToggleTabButtons, StyledToggleButton } from "./DashboardContainer";
import Loading from "components/Loading";
import { getResourceInfoData } from "actions/DashboardReportFunctions";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
import { ResourceInfoComponent } from "./resourceInfo/ResourceInfoComponent";
import _ from "lodash";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00948B",
    },

    secondary: {
      main: "#E1EBF0",
    },
  },
});

export const ResourceInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [showActive, setShowActive] = useState(true)

  const [resourceData, setResourceData] = useState(null);
  const [selection, setSelection] = useState("client_type_name");

  const prepareBarChartData = (rawData) => {
    let rows = [];
    let keySet = new Set();
    let grouped = _.groupBy(rawData, "name");
    Object.keys(grouped).map((key) => {
      let entry = grouped[key].reduce(
        (acc, row) => {
          keySet.add(row?.label);
          acc[row?.label] = row?.value;
          return acc;
        },
        { name: key }
      );
      rows.push(entry);
    });
    return { rows, keySet: [...keySet] };
  };

  useEffect(() => {
    getResourceInfoData(selection).then((data) => {
      let sortedBarActive = prepareBarChartData([...data?.data?.barActive]);
      let sortedProjectNameBarActive = prepareBarChartData([
        ...data?.data?.projectNameBarActive,
      ]);
      setResourceData({
        ...data?.data,
        sortedBarActive,
        sortedProjectNameBarActive,
      });
      setIsLoading(false);
    });
  }, [selection]);
  const handleSelection = (e, value) => {
    value &&
      ["client_type_name", "project_type", "stream_cd"].includes(value) &&
      setSelection(value);
  };

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  return (
    <div>
      <ToggleTabButtons selection={selection} handleSelection={handleSelection}>
        <StyledToggleButton value="client_type_name">{`Client Type`}</StyledToggleButton>
        <StyledToggleButton value="project_type">{`Project Type`}</StyledToggleButton>
        <StyledToggleButton value="stream_cd">{`Stream`}</StyledToggleButton>
      </ToggleTabButtons>
      <ResourceInfoComponent
        resourcePiData={resourceData?.active}
        resourceBarData={resourceData?.sortedBarActive}
        resourceProjectNameBarData={resourceData?.sortedProjectNameBarActive}
      />
    </div>
  );
};
