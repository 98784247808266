import React from "react";

const AuthManager = {
  isAuthenticated() {
    const token = localStorage.getItem("usertoken");
    const expireDate = localStorage.getItem("expireDate");
    const lastDate = parseInt(expireDate);
    if (!lastDate || !token || Date.now() > lastDate) {
      return false;
    }
    return true;
  },
};

export default AuthManager;
