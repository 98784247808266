import React from "react";
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import {
    Card,
    Button,
    FormFeedback,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    CardHeader,
    CardBody,
    Row,
    CardFooter,
} from 'reactstrap';


const ProjectModelForm = ({
    initValues,
    changeTab,
    loading,
    resetForm,
    handleSubmit
}) => {
    const formFields = {
        model_name: '',
        model_cd: '',
        model_abbr: ''
    };
    const initialValues = _.isEmpty(initValues) ? formFields : initValues;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            model_cd: Yup.string().required('Code is required'),
            model_name: Yup.string().required('Name is required'),
            model_abbr: Yup.string().required('Abbreviation is required'),
        }),
        onSubmit: async (values) => {
            await handleSubmit(values);
        }
    });


    return (
        <>
            <Card className="border-top-0 shadow-none">
                <CardHeader
                    style={{
                        fontSize: 20,
                        color: '#fff',
                        textAlign: 'center',
                        padding: 10,
                    }}
                >
                    Project Model
                </CardHeader>

                <CardBody style={{ maxHeight: 'none' }}>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <Form onSubmit={formik.handleSubmit}>
                            <FormGroup row>
                                <Col sm={2}>
                                    <Label for="projectModelName">Project Model</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="model_name"
                                        id="projectModelName"
                                        value={formik.values?.model_name}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.model_name &&
                                            formik.errors?.model_name
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.model_name}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={2}>
                                    <Label for="projectModelCode">Model Code</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="model_cd"
                                        id="projectModelCode"
                                        value={formik.values?.model_cd}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.model_cd &&
                                            formik.errors?.model_cd
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.model_cd}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={2}>
                                    <Label for="projectModelAbbr">Model Abbr</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="model_abbr"
                                        id="projectModelAbbr"
                                        value={formik.values?.model_abbr}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.model_abbr &&
                                            formik.errors?.model_abbr
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.model_abbr}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </CardBody>
                <CardFooter>
                    <Row style={{ float: 'right' }}>
                        <Button
                            type="submit"
                            color="primary"
                            className="fixedWidthButton"
                            onClick={formik.handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            color="danger"
                            className="fixedWidthButton"
                            onClick={() => {
                                resetForm();
                                changeTab('list');
                            }}
                        >
                            Cancel
                        </Button>
                    </Row>
                </CardFooter>
            </Card>
        </>
    );
}

export default ProjectModelForm;
