import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const createProject = (request) => {
  const token = getUserToken();
  const id = getUserId();
  request["Projectinfo"]["created_by"] = id; //TODO
  request["Projectinfo"]["created_dt"] = new Date().toISOString();
  const req = JSON.stringify(request);
  console.log("Request to be sent", req);
  return axios
    .post("/project/createProject", req, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchAllProject = () => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get("/project/listProject", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchAllProjectRelatedData = (cancelToken) => {
  const token = getUserToken();
  const id = getUserId();
  console.log(id);
  return axios
    .get(`/project/listProjectRelatedData/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchSelectedProject = (projectId) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/project/viewProject/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchProjectrisks = (projectId) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/project/getProjectrisks/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchProjectassumption = (projectId) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/project/getProjectassumption/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchProjectissues = (projectId) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/project/getProjectissues/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchProjectdependency = (projectId) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/project/getProjectdependency/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchAllStream = () => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get("/stream/listStream", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchAllEmployee = () => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get("/employee/listEmployee", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteProject = (projectId) => {
  const token = getUserToken();
  const id = getUserId();

  return axios
    .delete(`project/deleteProject/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
      return { message: error.response.statusText };
    });
};

export const updateProject = (projectId, rowData) => {
  const token = getUserToken();
  const id = getUserId();
  rowData["updated_by"] = id; // TODO: big todo: find some users or create new ones
  rowData["updated_dt"] = new Date().toISOString();
  return axios
    .put(`/project/updateProject/${projectId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Updating Project): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while updating project with projectId ${projectId}`, error);
    });
};

export const updateApproved = (projectId, rowData) => {
  const id = getUserId();
  const token = getUserToken();
  // TODO: do we need to changed the updated_by field as well here ?
  rowData["approved_by"] = id; // TODO: big todo: find some users or create new ones
  rowData["approved_dt"] = new Date().toISOString();

  return axios
    .put(`/project/updateApproved/${projectId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        "Response from server(Updating approved status of project): ",
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating project's approved status with projectId ${projectId}`,
        error
      );
    });
};

export const updateStatus = (projectId, rowData) => {
  const id = getUserId();
  const token = getUserToken();
  rowData["updated_by"] = id; // TODO: big todo: find some users or create new ones
  rowData["updated_dt"] = new Date().toISOString();

  return axios
    .put(`/project/updateStatus/${projectId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Updating status field of project): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating project's status with projectId ${projectId}`,
        error
      );
    });
};

export const getDetail = (projectId) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/project/getDetail/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateEffort = (projectId, request) => {
  const id = getUserId();
  const token = getUserToken();
  request["Effortdetail"].map((effort) => {
    effort["updated_by"] = id;
    effort["updated_dt"] = new Date().toISOString();
  });
  request["Phasedetail"].map((phase) => {
    phase["updated_by"] = id;
    phase["updated_dt"] = new Date().toISOString();
  });

  const req = JSON.stringify(request);
  return axios
    .put(`/projecteffort/updateEffort/${projectId}`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        "Response from server(Updating Effort estimation of project): ",
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating project's effort estimation with projectId ${projectId}`,
        error
      );
    });
};

export const getTaskProgress = (projectId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/project/getTaskprogress/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Response from server(Getting task progress of project): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while getting project's task progress with projectId ${projectId}`,
        error
      );
    });
};

export const getVelocityChart = (projectId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/project/getVelocitychart/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Response from server(Getting velocity chart of project): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while getting project's velocity chart with projectId ${projectId}`,
        error
      );
    });
};

export const getTasksAndSprints = (request) => {
  const token = getUserToken();
  return axios
    .post(`/project/getTasksAndSprints`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        "Response from server - fetching tasks and sprints for projects",
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error fetching project's tasks and sprints for projects 
        ${JSON.stringify(request)}`,
        error
      );
    });
};
