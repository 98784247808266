import React from "react"
import {Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap"
import {useFormik} from "formik"
import * as Yup from 'yup'
import _ from "lodash"
import {Spinner} from "react-bootstrap"

const MenuForm = ({
    initValues,
    menuListData,
    handleSubmit,
    isLoading,
    changeTab,
    resetForm
}) => {

    const menuInfo = {
        'menu_name': '',
        'upper_menu_id': '',
        'link_url': '',
        'icon_url': ''
    }
    const initialValues = _.isEmpty(initValues) ? menuInfo: initValues

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            menu_name: Yup.string().required('Menu Name is required.'),
            link_url: Yup.string().required('Link URL is required.'),
            icon_url: Yup.string().required('Icon URL is required.'),
        }),
        onSubmit: async (value) => {
            await handleSubmit(value)
        }
    })

    const getIndentation = (num) =>
        [...Array(num)].map((e, i) => String.fromCharCode(127))

    const getDFSOrder = (subMenuMap) => {
        const stack = []
        const result = []
        stack.push('0')
        while (stack.length !== 0) {
            const elem = stack.pop()
            result.push(elem)
            if (subMenuMap[elem]) {
                subMenuMap[elem].forEach((menu) => {
                    stack.push(menu['menu_id'])
                })
            }
        }
        return result
    }

    const generateTabbedMenu = () => {
        let menuMap = {}
        let subMenuMap = {}
        menuListData.forEach((menu) => {
            menuMap[menu['menu_id']] = menu
            if (!subMenuMap[menu['upper_menu_id']]) {
                subMenuMap[menu['upper_menu_id']] = []
            }
            subMenuMap[menu['upper_menu_id']].push(menu)
        })

        let stack = []
        let result = []
        let tab = 0
        stack.push({ menu_id: '0', tab: tab})
        while (stack.length !== 0) {
            let elem = stack.pop()
            if (subMenuMap[elem.menu_id]) {
                tab = elem.tab + 1
                subMenuMap[elem.menu_id].forEach((subMenu) => {
                    result.push({
                       menu_name: `${subMenu['menu_name']}`,
                       menu_id: subMenu['menu_id'],
                       tab: tab
                    })
                    stack.push({menu_id: `${subMenu['menu_id']}`, tab: tab});
                })
            }
        }
        const dfsOrder = getDFSOrder(subMenuMap)
        return { result, dfsOrder }
    }

    const getAllMenu = () => {
        const { result, dfsOrder } = generateTabbedMenu()
        return dfsOrder.map((order) => {
            const menu = result.find((menu) => menu['menu_id'] === order)
            if (menu) {
                return (
                    <option value={order} key={order}>
                        {getIndentation(menu.tab)}
                        {`${menu.menu_name}`}
                    </option>
                )
            }
        })
    }

    return <Card className="border-top-0 shadow-none">
        <CardHeader style={{
            fontSize: 20,
            color: '#fff',
            textAlign: 'center',
            padding: 10,
        }}>
            Menu Information
        </CardHeader>

        <CardBody>
            {isLoading ?
                <Spinner animation="border" /> :
                <Form onSubmit={formik.handleSubmit}>
                <FormGroup row>
                    <Col sm={2}>
                        <Label for="menuName">Menu</Label>
                    </Col>
                    <Col sm={5}>
                        <Input
                            type="text"
                            name="menu_name"
                            id="menuName"
                            value={formik.values?.menu_name}
                            bsSize="sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched?.menu_name &&
                                formik.errors?.menu_name
                            }
                        />
                        <FormFeedback invalid>
                            {formik.errors?.menu_name}
                        </FormFeedback>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={2}>
                        <Label for="upperMenuId">Upper Menu Id</Label>
                    </Col>
                    <Col sm={5}>
                        <Input
                            type="select"
                            name="upper_menu_id"
                            id="upperMenuId"
                            value={formik.values?.upper_menu_id}
                            bsSize="sm"
                            onChange={formik.handleChange}
                        >
                            <option value={0} selected>
                                {'Root'}
                            </option>
                            {getAllMenu()}
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={2}>
                        <Label for="linkUrl">Link Url</Label>
                    </Col>
                    <Col sm={5}>
                        <Input
                            type="text"
                            name="link_url"
                            id="linkUrl"
                            value={formik.values?.link_url}
                            bsSize="sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched?.link_url &&
                                formik.errors?.link_url
                            }
                        />
                        <FormFeedback invalid>
                            {formik.errors?.link_url}
                        </FormFeedback>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={2}>
                        <Label for="iconUrl">Icon URL</Label>
                    </Col>
                    <Col sm={5}>
                        <Input
                            type="text"
                            name="icon_url"
                            id="iconUrl"
                            value={formik.values?.icon_url}
                            bsSize="sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched?.icon_url &&
                                formik.errors?.icon_url
                            }
                        />
                        <FormFeedback invalid>
                            {formik.errors?.icon_url}
                        </FormFeedback>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col className="text-right" xs={{offset: 5}}>
                        <Button
                            type="submit"
                            color="primary"
                            className="fixedWidthButton"
                        >
                            Save
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            color="danger"
                            className="fixedWidthButton"
                            onClick={() => {
                                resetForm()
                                changeTab('list')
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
            }
        </CardBody>
    </Card>
}

export default MenuForm