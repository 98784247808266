import React from "react";
import { UncontrolledAlert } from "reactstrap";

const ActionFeedback = (props) => {
  return (
    <UncontrolledAlert
      className="fixed-top w-50 mx-auto text-center mt-2 py-2"
      style={{ zIndex: "9999999999999" }}
    >
      {props.message}
    </UncontrolledAlert>
  );
};

export default ActionFeedback;
