import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const getProjectInfoData = (groupByColumn) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .post(
      `/dashboardReport/projectInfo`,
      {
        groupByColumn,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(`Error while Getting Project Info Data`, error)
    })
}

export const getResourceInfoData = (groupByColumn) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .post(
      `/dashboardReport/resourceInfo`,
      {
        groupByColumn,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(`Error while Getting Project Info Data`, error)
    })
}

export const getProjectDistributionDetailByWeek = (calcDate) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/dashboard/projectDistributionbyweek/${calcDate}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting Project Detail): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while Getting Project Detail`, error)
    })
}

export const getProjectsReport = (calcDate) => {
  return axios
    .post(`/dashboardReport/projectsReport`, {
      calc_date: calcDate
    })
}

export const getDashboardProjectsByClientType = (groupByColumn, filterDate) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .post(`/dashboardReport/projectByClientType`,{
      groupByColumn,
      filterDate
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting Projects By client type): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Getting Projects By client type`, error)
    })
}

export const getResourceInfoDataByDate = (groupByColumn, selectedDate) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .post(`/dashboardReport/resourceInfoByDate`,{
      groupByColumn,
      selectedDate
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting Resources By client type): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Getting Resources By client type`, error)
    })
}
export const getProjectWorkHoursReport = (calcDate) => {
  return axios
    .post(`/dashboardReport/projectWorkHoursReport`, {
      calc_date: calcDate
    })
}

