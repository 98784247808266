import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const registerCall = (newUser) => {
  return axios
    .post("/user/register", newUser, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const emailCall = () => {
  const token = getUserToken()
  // const id = getUserId()
  return axios
    .get(`/employee/listEmployee`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log('Response from server - fetching employees', response.data)
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const loginCall = async (user) => {
  return axios
    .post(
      "/user/login",
      {
        email: user.email,
        password: user.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data?.token) {
        localStorage.setItem("usertoken", response.data.token);
        localStorage.setItem(
          "expireDate",
          (Date.now() + 3598 * 1000).toString()
          ); // set expireDate to 1 hours from current
          return response.data.success;
        }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const viewProfile = (id) => {
  const token = getUserToken();
  return axios
    .post(`/user/profile/${id}`, {
      headers: {
        'Authorization':`Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const logout = () => {
  localStorage.removeItem("usertoken");
  localStorage.removeItem("userPermissions");
  // return axios
  //   .get(`/user/logout`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   .then((response) => {
  //     console.log(response)
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   })
};

export const createUser = (user) => {
  const token = getUserToken();
  const id = getUserId();
  user["created_by"] = id;
  user["created_dt"] = new Date().toISOString();
  return axios
    .post(`/user/createUser`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(`Response from server(Creating a user): `, response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while creating user`, user);
    });
};

export const listUser = () => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/user/listUser`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(`Response from server(Getting a list of users): `, response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting the list of users `, error);
    });
};

export const updateUser = (userId, user) => {
  const token = getUserToken();
  const id = getUserId();
  user["updated_by"] = id;
  user["updated_dt"] = new Date().toISOString();
  return axios
    .put(`/user/updateUser/${userId}`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(`Response from server(Updating a user): `, response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while updating user`, user);
    });
};

export const updateStatus = (userId, rowData) => {
  const token = getUserToken();
  const id = getUserId();
  rowData["updated_by"] = id;
  rowData["updated_dt"] = new Date().toISOString();
  return axios
    .put(`/user/updateStatus/${userId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        "Response from server(Updating status field of user): ",
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating status field of user with userId ${userId}`,
        error
      );
    });
};

export const updateApproved = (userId, rowData) => {
  const id = getUserId();
  const token = getUserToken();
  rowData["approved_by"] = id;
  rowData["approved_dt"] = new Date().toISOString();
  return axios
    .put(`/user/updateApproved/${userId}`, rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        "Response from server(Updating approved status of user): ",
        response
      );
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while updating user's approved status with userId ${userId}`,
        error
      );
    });
};

export const getDetail = (userId) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/user/getDetail/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting detail of user): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while getting user's detail with userId ${userId}`,
        error
      );
    });
};
