import React from 'react';
import GenericPieChart from '../../../components/Charts/GenericPieChart';

class PiChartContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <GenericPieChart
        data={this.props.data}
        colors={this.props.colors} 
      />  
    )
  }
}

export default PiChartContainer;
