import React from "react";
import { emailCall, loginCall } from "../actions/UserFunctions";
import decoder from "jwt-decode";

import ValidateEmail from "../utils/ValidateEmail.js";
import { sendResetLink } from "../utils/ResetPassword.js";

import "@fortawesome/fontawesome-free";
import { Redirect, Link } from "react-router-dom";
import Error from "../components/Toaster";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import { theme } from "../utils/Theme";
import { MuiThemeProvider } from "@material-ui/core/styles";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardText,
} from "reactstrap";
import { getMenuByUser } from "actions/MenuFunctions";

class Forgot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errors: { isValidEmail: false },

      loading: false,
      redirect: false,
      redirectUrl: "",
      emails: [""],
      errorMessage: "",
      successMessage: "",
      success: false,
    };
  }

  handleUserInput = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    this.setState({ email: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // const getEmails = emailCall()

    console.log("getemails", this.state.allEmails);
    const error = ValidateEmail(this.state.email, this.state.emails);
    console.log(error);
    this.setState({ errors: error, successMessage: "" });

    if (error.isValidEmail) {
      this.setState({ loading: true });
      sendResetLink(this.state.email).then((response) => {
        this.setState({ loading: false });
        if (response.status === 200) {
          this.setState({ success: true });
          this.setState({
            successMessage: response.data.data,
            errorMessage: "",
          });
        } else {
          this.setState({ errorMessage: "Email Invalid", successMessage: "" });
        }
      });
    }

    if (error["isValidEmail"]) {
      const user = {
        email: this.state.email,
      };
    }

    this.setState({ errorMessage: error["email"] });
  };

  getEmails = async () => {
    var response = await emailCall();
    const allEmails = response.data.map((employee) => employee.email);
    this.setState({ emails: allEmails });
    console.log("response", this.state.emails);
    return response;
  };

  componentDidMount() {
    this.getEmails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getdata !== this.props.getdata) {
      if (this.props.getdata.res.success === false) {
        console.log("errorinupdate", this.props.getdata.res);
        let errors = {};
        errors["email"] = `${this.props.getdata.res.error}`;
        errors["isValidEmail"] = false;
        this.setState({ errors, redirect: false });
      }
    }
    // console.log('aray', this.state.emails)
  }

  render() {
    const { fields, errors, isPasswordVisible, loading, redirect, allMenus } =
      this.state;

    if (redirect === true) {
      return (
        <Redirect
          to={{
            pathname: this.redirectUrl,
          }}
        />
      );
    }
    return (
      <>
        <Col lg="5" md="5">
          <Card className="lisColor1 shadow border-0">
            <CardTitle>
              <img
                class="w-50 mx-auto d-block mt-5"
                alt="LIS Nepal Logo"
                src={require("assets2/img/LIS_LOGO.png")}
              />
            </CardTitle>
            <CardBody className="px-lg-5 py-lg-4">
              <div className="text-center text-muted mb-4">
                <big>Forgot Password</big>
              </div>
              {!this.state.success && (
                <Form role="form" noValidate onSubmit={this.handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="shadow" size="md">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="ni ni-email-83" /> */}
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        label={"Email"}
                        name={"email"}
                        type={"text"}
                        value={this.state.email}
                        onChange={this.handleUserInput}
                        // className={'normal'}
                        className="pl-2"
                        placeholder={"Enter your email address"}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      disabled={this.state.loading}
                      type="submit"
                    >
                      Send Email
                    </Button>
                  </div>
                  <div className="error text-center text-danger">
                    {this.state.errorMessage && (
                      <Error
                        errorMessage={`${this.state.errorMessage}`}
                        className={"Warning"}
                      />
                    )}
                  </div>
                </Form>
              )}
              {this.state.success && (
                <>
                  <div className="text-center">
                    <Link className="my-4 btn btn-primary" to="/auth/login">
                      Go to Login
                    </Link>
                  </div>
                  <div className="success text-center text-success">
                    {this.state.successMessage && (
                      <div>
                        <p>{this.state.successMessage}</p>
                      </div>
                    )}
                  </div>
                </>
              )}
              {loading && (
                <div className="d-flex justify-content-center ">
                  <MuiThemeProvider theme={theme}>
                    <CircularProgress color="secondary" />
                  </MuiThemeProvider>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Forgot;
