import React from "react";
import { useFormik } from 'formik';
import _, { parseInt } from 'lodash';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';

import {
    Card,
    Button,
    FormFeedback,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    CardHeader,
    CardBody,
    Row,
    CardFooter,
} from 'reactstrap';


const ClientInfoForm = ({
    initValues,
    changeTab,
    loading,
    clientTypeList,
    resetForm,
    handleSubmit,
}) => {

    const clientInfo = {
        client_cd: '',
        client_name: '',
        client_abbr: '',
        address: '',
        telephone_no: '',
        fax_no: '',
        po_box: '',
        email: '',
        remarks: '',
        client_type_id: ''
    };
    const initialValues = _.isEmpty(initValues) ? clientInfo : initValues;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            client_cd: Yup.string().required('Client code is required'),
            client_name: Yup.string().required('Client name is required'),
            client_abbr: Yup.string().required('Client abbr is required'),
            address: Yup.string().required('Address is required'),
            client_type_id: Yup.string().required('Client type is required'),
            telephone_no: Yup.string()
                .required('Telephone number is required')
                .matches(/^\d{7,15}$/, 'Should be between 7 and 15 digits.'),
            email: Yup.string()
                .required('Email is required')
                .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    'Invalid email address'),
        }),
        onSubmit: async (value) => {
            await handleSubmit(value);
        }
    });

    return (
        <>
            <Card className="border-top-0 shadow-none">
                <CardHeader
                    style={{
                        fontSize: 20,
                        color: '#fff',
                        textAlign: 'center',
                        padding: 10,
                    }}
                >
                    Client Information
                </CardHeader>

                <CardBody style={{ maxHeight: 'none' }}>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <Form onSubmit={formik.handleSubmit}>
                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="clientCode">Code</Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        name="client_cd"
                                        id="clientCode"
                                        value={formik.values?.client_cd}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.client_cd &&
                                            formik.errors?.client_cd
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.client_cd}
                                    </FormFeedback>
                                </Col>

                                <Col sm={2}>
                                    <Label for="clientType">Client Type</Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="select"
                                        name="client_type_id"
                                        id="clientType"
                                        value={formik.values?.client_type_id}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.client_type_id &&
                                            formik.errors?.client_type_id
                                        }
                                    >
                                        <option value=""></option>
                                        {clientTypeList.map((type, key) => (
                                            <option value={type.client_type_id} key={key}>
                                                {type.client_type_abbr}
                                            </option>
                                        ))}
                                    </Input>

                                    <FormFeedback invalid>
                                        {formik.errors?.client_type_id}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="clientName">Name</Label>
                                </Col>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="client_name"
                                        id="clientName"
                                        value={formik.values?.client_name}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.client_name &&
                                            formik.errors?.client_name
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.client_name}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="clientAbbr">Abbreviation</Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        name="client_abbr"
                                        id="clientAbbr"
                                        value={formik.values?.client_abbr}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.client_abbr &&
                                            formik.errors?.client_abbr
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.client_abbr}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="clientAddress">Address</Label>
                                </Col>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="address"
                                        id="clientAddress"
                                        value={formik.values?.address}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.address &&
                                            formik.errors?.address
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.address}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="telephoneNumber">Telephone</Label>
                                </Col>
                                <Col sm={3}>
                                    <Input
                                        type="text"
                                        name="telephone_no"
                                        id="telephoneNumber"
                                        value={formik.values?.telephone_no}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.telephone_no &&
                                            formik.errors?.telephone_no
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.telephone_no}
                                    </FormFeedback>
                                </Col>

                                <Label for="faxNumber">Fax</Label>
                                <Col sm={3}>
                                    <Input
                                        type="text"
                                        name="fax_no"
                                        id="faxNumber"
                                        value={formik.values?.fax_no}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                    />
                                </Col>

                                <Label for="poBox">PO Box</Label>
                                <Col sm={3}>
                                    <Input
                                        type="text"
                                        name="po_box"
                                        id="poBox"
                                        value={formik.values?.po_box}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="clientEmail">Email</Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="clientEmail"
                                        value={formik.values?.email}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.email &&
                                            formik.errors?.email
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.email}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="remarks">Remarks</Label>
                                </Col>
                                <Col sm={6}>
                                    <Input
                                        type="textarea"
                                        rows="5"
                                        name="remarks"
                                        id="remarks"
                                        value={formik.values?.remarks}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </CardBody>
                <CardFooter>
                    <Row style={{ float: 'right' }}>
                        <Button
                            type="submit"
                            color="primary"
                            className="fixedWidthButton"
                            onClick={formik.handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            color="danger"
                            className="fixedWidthButton"
                            onClick={() => {
                                resetForm();
                                changeTab('list');
                            }}
                        >
                            Cancel
                        </Button>
                    </Row>
                </CardFooter>
            </Card>
        </>
    );
}

export default ClientInfoForm;