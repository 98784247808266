import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const listProjectSprint = (projectId, cancelToken) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/projectsprint/listProjectsprint/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const listProjectSprintCapacity = (
  projectId,
  sprintId,
  isFirstSprint,
  cancelToken
) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(
      `/projectsprintcapacity/listProjectsprintcapacity/${projectId}/${sprintId}/${isFirstSprint}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken,
      }
    )
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const activeProjectSprint = (projectId) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/projectsprint/activeProjectsprint/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createProjectSprint = (request) => {
  const token = getUserToken();
  const id = getUserId();
  request["created_by"] = id;
  request["created_dt"] = new Date().toISOString();
  console.log(request);
  return axios
    .post(`/projectsprint/createProjectsprint`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createProjectSprintCapacity = (request) => {
  const token = getUserToken();
  const id = getUserId();
  request.map((sprintCap) => {
    if (!sprintCap["created_by"] && !sprintCap["created_dt"]) {
      sprintCap["created_by"] = id;
      sprintCap["created_dt"] = new Date().toISOString();
    } else {
      sprintCap["updated_by"] = id;
      sprintCap["updated_dt"] = new Date().toISOString();
    }
  });
  console.log(request);
  return axios
    .post(`/projectsprintcapacity/createProjectsprintcapacity`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const generateSprint = (projectId, request) => {
  const token = getUserToken();
  const id = getUserId();

  return axios
    .put(`/projectsprint/generateSprint/${projectId}`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateProjectSprint = (sprintId, request) => {
  const token = getUserToken();
  const id = getUserId();
  request["updated_by"] = id; // TODO: big todo: find some users or create new ones
  request["updated_dt"] = new Date().toISOString();
  return axios
    .put(`/projectsprint/updateProjectsprint/${sprintId}`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const updateStatus = (sprintId, request) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .put(`/projectsprint/updateStatus/${sprintId}`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateApproved = (sprintId, request) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .put(`/projectsprint/updateApproved/${sprintId}`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getNewsprintdates = (projectId, cancelToken) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/projectsprint/getNewsprintdates/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getDetail = (sprintId) => {
  const id = getUserId();
  const token = getUserToken();
  return axios
    .get(`/projectsprint/getDetail/${sprintId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server(Getting detail of sprint): ", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error while getting sprint's detail with sprintId ${sprintId}`, error);
    });
};

export const listProjectSprintDayOff = (sprintId, resourceId, cancelToken) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(`/projectsprintdayoff/listProjectsprintdayoff/${sprintId}/${resourceId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createProjectSprintDayOff = (request) => {
  const token = getUserToken();
  const id = getUserId();
  request.map((dayOff) => {
    if (!dayOff["created_by"] && !dayOff["created_dt"]) {
      dayOff["created_by"] = id;
      dayOff["created_dt"] = new Date().toISOString();
    } else {
      dayOff["updated_by"] = id;
      dayOff["updated_dt"] = new Date().toISOString();
    }
  });
  console.log(request);
  return axios
    .post(`/projectsprintdayoff/createProjectsprintdayoff`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createProjectCompanyHoliday = async (request) => {
  const token = getUserToken();
  const id = getUserId();
  request.map((dayOff) => {
    if (!dayOff["created_by"] && !dayOff["created_dt"]) {
      dayOff["created_by"] = id;
      dayOff["created_dt"] = new Date().toISOString();
    } else {
      dayOff["updated_by"] = id;
      dayOff["updated_dt"] = new Date().toISOString();
    }
  });
  // if (!request["created_by"] && !request["created_dt"]) {
  //   request["created_by"] = id;
  //   request["created_dt"] = new Date
  // }
  console.log(request);
  return axios
    .post(`/projectsprintdayoff/companyHoliday`, request, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteDayoff = async (req) => {};

export const getResourceDayOffCount = (projectId, sprintId, resourceId, cancelToken) => {
  const token = getUserToken();
  const id = getUserId();
  return axios
    .get(
      `/projectsprintdayoff/getResourcedayoffcnt/${projectId}/${sprintId}/${resourceId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken,
      }
    )
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSprintCapacity = (projectId, sprintId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/projectsprintcapacity/sprintCapacity/${projectId}/${sprintId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get project sprint capacity", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error get project sprint capacity", error);
    });
};

export const getSprintProgress = (projectId, sprintId, sprintStDt, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/projectsprint/progress/${projectId}/${sprintId}/${sprintStDt}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get sprint progress", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error get sprint progress", error);
    });
};

export const getCompanyHoliday = (projectId, sprintId, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/projectsprintdayoff/companyHoliday/${projectId}/${sprintId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get company holidays in sprint", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error get company holidays in sprint", error);
    });
};

export const getRecentSprints = (projectId, currentDate, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/projectsprint/recent/${projectId}/${currentDate}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get recent sprints", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error get recent sprints", error);
    });
};

export const removeSprintCapacity = async (id, cancelToken) => {
  const token = getUserToken();
  return axios
    .delete(`/projectsprintcapacity/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const removeSprintDayoff = async (id, cancelToken) => {
  const token = getUserToken();
  return axios
    .delete(`/projectsprintdayoff/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const removeCompanyHoliday = async (projectId, sprintId, cancelToken) => {
  const token = getUserToken();
  return axios
    .delete(`/projectsprintdayoff/companyHoliday/${projectId}/${sprintId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
