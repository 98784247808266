import React, { Component } from "react"
import Gantt from "components/Gantt"
import { useState } from "react"
import Axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { listProjectTaskRoadmap } from "actions/ProjectTaskFunctions"
import Loading from "components/Loading"

const RoadmapContainer = ({ projectId }) => {
  const [currentZoom, setCurrentZoom] = useState("Days")
  const {
    data: taskList,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ["listProjectTask", projectId],
    queryFn: ({ signal }) => {
      const CancelToken = Axios.CancelToken
      const source = CancelToken.source()
      let data = []
      const promise = listProjectTaskRoadmap(projectId, source.token).then(
        (result) => {
          result.map((row) => {
            let color
            if (row.type === 1) {
              color = "#006400"
            }
            if (row.type === 2) {
              color = "#008080"
            }
            if (row.type === 3) {
              color = "#00cdcd"
            }

            let rowData = {
              id: row.id,
              text: row.text,
              start_date: row.start_date,
              duration: row.duration,
              color: color,
              parent: row.parent,
            }
            data.push(rowData)
          })
          return { data: data }
        }
      )
      signal.addEventListener("abort", () => {
        source.cancel("Query was cancelled by React Query")
      })
      return promise
    },
  })
  if (isLoading)
    return (
      <div style={{ height: "30px" }}>
         <Loading/>
      </div>
    )

  if (isError) return <p>THere is Error</p>

  return (
    <div className="gantt-container" style={{height: "70vh"}}>
      <Gantt tasks={taskList} zoom={currentZoom} />
    </div>
  )
}

export default RoadmapContainer
