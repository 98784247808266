import React, { Fragment } from 'react';
import {getDetail as getDetailPermission} from '../../actions/PermissionFunctions';
import {getDetail as getDetailRole} from '../../actions/RoleFunctions';
import {getDetail as getDetailUserGroup} from '../../actions/UserGroupFunctions';
import { Card, CardBody, CardTitle, CardSubtitle, CardColumns } from 'reactstrap';

class ConfigDetailList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      detail: {}
    }
  }

  notEmpty = (value) => {
    return value ? value : '';
  }

  componentDidMount() {
    if (this.props.type === 'permission') {
      getDetailPermission(this.props.id).then((detail) => {
        this.setState({
          detail: detail[0],
        })
      });
    }
    if (this.props.type === 'role') {
      getDetailRole(this.props.id).then((detail) => {
        this.setState({
          detail: detail[0],
        })
      });
    }
    if (this.props.type === 'userGroup') {
      getDetailUserGroup(this.props.id).then((detail) => {
        this.setState({
          detail: detail[0],
        })
      });
    }
  }

  render() {
    const {detail} = this.state;
    const statsMap = {
      'status': 'Status',
      'approved': 'Approved',
      'approved_by': 'Approved By',
      'approved_dt': 'Approved Date',
      'updated_by': 'Updated By',
      'updated_dt': 'Updated Date',
      'created_by': 'Created By',
      'created_dt': 'Created Date',
    };

    let map = {};
    if (this.props.type === 'role') {
      map['role_name'] = 'Role Name';
    }
    if (this.props.type === 'permission') {
      map['perm_name'] = 'Permission Name';
    }
    if (this.props.type === 'userGroup') {
      map['grp_name'] = 'User Group Name'
    }
    Object.assign(map, statsMap);
    
    return (
      <CardColumns className="lisColor1 pt-2">
        {Object.keys(map).map((key) => <Card className="border-0 lisColor1">
          <CardBody className="p-0 pl-3">
            <CardTitle>{`${map[key]}`}</CardTitle>
            <CardSubtitle className="font-weight-bold">{`${this.notEmpty(detail[key])}`}</CardSubtitle>
          </CardBody>
        </Card>)}
      </CardColumns>
    );
  }
}

export default ConfigDetailList;