import React from "react";
import moment from "moment";
import TimeSheet from "../../components/TimeSheet";
import { getUserId } from "../../actions/UtilFunctions";
import { getDetail, listUser } from "../../actions/UserFunctions";
import {
  fetchAllResources,
  getProjectResourceIds,
} from "../../actions/ResourceFunctions";
import { fetchProjects } from "../../actions/ResourceFunctions";
import { getTasksAndSprints } from "../../actions/ProjectFunctions";
import {
  listMultipleProjectTaskstatus,
  listProjectTaskstatus,
  listTaskstatus,
} from "../../actions/TaskstatusFunctions";
import {
  updateTimeSheet,
  getTimeSheet,
  getBillableTotals,
  updateBillableTotals,
  submitTimeSheet,
  getDefaultProjects,
} from "../../actions/TimeSheetFunctions";
import { Button } from "reactstrap";
import ActionFeedback from "../../components/ActionFeedbackAlert";
import { Input } from "reactstrap";
import _, { map } from "lodash";
import { FaThemeisle } from "react-icons/fa";
import DayoffTimesheet from "components/TimeSheet/DayoffTimesheet";

const cellStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  whiteSpace: "nowrap",
};

export default class TimeSheetContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      originalUser: {},
      employeeMap: {},
      employees: [],
      projects: [],
      projectIds: [],
      data: [],
      isFetchComplete: false,
      disableButtons: true,
      actionFeedbackMessage: "",
      columns: [
        { title: "Tasks", field: "task_name", cellStyle: cellStyle },
        { title: "Status", field: "task_status_id", cellStyle: cellStyle },
        // { title: 'Point', field: 'planned_points', cellStyle: cellStyle },
        { title: "Planned", field: "planned_hrs", cellStyle: cellStyle },
        // { title: 'Actual', field: 'actual_hrs', cellStyle: cellStyle },
        // { title: 'Assigned To', field: 'resource_id', cellStyle: cellStyle },
        { title: "Sun", field: "sun", cellStyle: cellStyle },
        { title: "Mon", field: "mon", cellStyle: cellStyle },
        { title: "Tue", field: "tue", cellStyle: cellStyle },
        { title: "Wed", field: "wed", cellStyle: cellStyle },
        { title: "Thu", field: "thu", cellStyle: cellStyle },
        { title: "Fri", field: "fri", cellStyle: cellStyle },
        { title: "Sat", field: "sat", cellStyle: cellStyle },
      ],
      projectNameMap: {},
      // {
      //   projectId: projectName
      // }
      projectResourceMap: {},
      // {
      //   projectId: projectResourceId
      // }
      billableTotalMap: {},
      // {
      //   projectId: {
      //     sun: 1,
      //     mon: 2,
      //     ...
      //     sat: 0,
      //   }
      // }
      currentWeek: {},
      // current week per project
      // {
      //   projectId: {
      //     startDate: 'YYYY-MM-DD',
      //     endDate: 'YYYY-MM-DD'
      //   }
      // }
      dateMap: {},
      // map from day of week to the date
      // {
      //   projectId: {
      //     sun: 'YYYY-MM-DD',
      //     mon: 'YYYY-MM-DD',
      //     ...
      //     sat: 'YYYY-MM-DD',
      //   }
      // }
      projectTaskHours: {},
      // map from project id to task id to hours
      // {
      //   projectId: {
      //     taskId: {
      //       sun-hrs: 3,
      //       mon-hrs: 4,
      //       ...
      //       sat-hrs: 0,
      //     }
      //   }
      // }
      projectTaskMap: {},
      // map from project id to a list of tasks
      // {
      //   projectId: [{task_id: 1, ...}, {task_id: 2, ...}]
      // }
      projectTaskMeta: {},
      // map from project id to task id to date
      // {
      //   projectId: {
      //     taskId: {
      //       '2020-02-02': { status: 'A', ...}
      //     }
      //   }
      // }
      projectBillableMeta: {},
      // map from project id to date
      // {
      //   projectId: {
      //     '2020-02-02': { status: 'A', ...}
      //   }
      // }
      taskStatusMap: {}, // map from task status id to task status name
      resourceMap: [],
      defaultProjects: [],
      billableMap: [],
    };
  }

  componentDidMount() {
    getDetail(getUserId())
      .then((response) => {
        if (response) {
          this.setState({
            user: response[0],
            originalUser: response[0],
          });
        }
      })
      .then(() => {
        console.log("Original User", this.state.originalUser);
        if (this.state.originalUser) {
          getProjectResourceIds(this.state.originalUser.employee_id).then(
            (response) => {
              var resourceMap = [];
              console.log("Resource IDs", response);
              if (Array.isArray(response)) {
                response.map((employee) => {
                  resourceMap.push(employee["resource_id"]);
                });
                this.setState({ resourceMap: resourceMap });
              }
            }
          );
        }
      });

    // returns the employee details of only active resources
    fetchAllResources().then((response) => {
      const employeeMap = {};
      if (Array.isArray(response)) {
        response.map((employee) => {
          employeeMap[employee.employee_id] = employee;
        });
        this.setState({
          employees: response,
          employeeMap: employeeMap,
        });
      }

      console.log("active", employeeMap);
    });

    // listMultipleProjectTaskstatus(this.state.projectIds)
    // .then((response) => {
    //   const taskStatusMap = {};
    //   response.map((taskStatus) => {
    //     taskStatusMap[taskStatus.task_status_id] = taskStatus.task_status_abbr;
    //   })
    //   this.setState({
    //     taskStatusMap: taskStatusMap
    //   })
    // })
  }

  showActionFeedback = (message) => {
    this.setState({
      actionFeedbackMessage: message,
    });
    setTimeout(() => this.setState({ actionFeedbackMessage: "" }), 3000);
  };

  getWeekDates = (startDate, endDate) => {
    return {
      sun: moment(startDate).clone().add(0, "days").format("YYYY-MM-DD"),
      mon: moment(startDate).clone().add(1, "days").format("YYYY-MM-DD"),
      tue: moment(startDate).clone().add(2, "days").format("YYYY-MM-DD"),
      wed: moment(startDate).clone().add(3, "days").format("YYYY-MM-DD"),
      thu: moment(startDate).clone().add(4, "days").format("YYYY-MM-DD"),
      fri: moment(startDate).clone().add(5, "days").format("YYYY-MM-DD"),
      sat: moment(startDate).clone().add(6, "days").format("YYYY-MM-DD"),
    };
  };

  // get the value of a key if object is defined or
  // return 0 if not
  // also check if the key is present in the object
  // else return 0
  getValueIfDefined = (obj, key) => {
    return obj ? (obj[key] ? obj[key] : 0) : 0;
  };

  getTasksInCurrentWeek = (projectId) => {
    const currentWeek = this.state.currentWeek[projectId];
    const startDate = new Date(currentWeek.startOfWeek);
    const endDate = new Date(currentWeek.endOfWeek);
    const currentTasks = this.state.projectTaskMap[projectId].filter((task) => {
      const taskStartDate = new Date(task.task_st_dt);
      const taskEndDate = new Date(task.task_end_dt);
      if (taskStartDate >= startDate && taskStartDate <= endDate) {
        return true;
      }
      if (taskEndDate >= startDate && taskEndDate <= endDate) {
        return true;
      }
      if (
        startDate >= taskStartDate &&
        startDate <= taskEndDate &&
        endDate >= taskStartDate &&
        endDate <= taskEndDate
      ) {
        return true;
      }
      return false;
    });
    return currentTasks.map((task) => task.task_id);
  };

  // the function copies the total hours worked in a week for a given project
  // as the billable hours
  mirrorTotalHrsToBillable = (day, currTaskId, projectId, hours) => {
    const taskIds = this.getTasksInCurrentWeek(projectId);
    let totalHrs = Number(hours);
    taskIds.map((taskId) => {
      if (`${taskId}` !== `${currTaskId}`) {
        const taskHrs = this.state.projectTaskHours[projectId][taskId];
        totalHrs += Number(this.getValueIfDefined(taskHrs, `${day}-hrs`));
      }
    });
    // TODO: this is an exact copy from the below function saveBillableHours
    const billableHours = { ...this.state.billableTotalMap };
    if (projectId in billableHours) {
      billableHours[projectId][day] = totalHrs;
    } else {
      billableHours[projectId] = { ...this.state.billableTotalMap[projectId] };
      billableHours[projectId][day] = totalHrs;
    }
    this.setState({
      billableTotalMap: billableHours,
    });
  };

  saveHours = (event) => {
    event.preventDefault();
    const input = event.currentTarget;
    const day = input.getAttribute("data-day");
    const taskId = input.getAttribute("data-taskId");
    const projectId = input.getAttribute("data-projectId");
    const hours = input.value;
    const taskHour = { ...this.state.projectTaskHours[projectId][taskId] };
    taskHour[`${day}-hrs`] = hours;
    const projectTaskHours = { ...this.state.projectTaskHours };
    projectTaskHours[projectId][taskId] = taskHour;
    this.setState({
      projectTaskHours: projectTaskHours,
    });
    if (this.state.billableMap[projectId] === 'Y')
      this.mirrorTotalHrsToBillable(day, taskId, projectId, hours);
  };

  saveBillableHours = (event) => {
    event.preventDefault();
    const input = event.currentTarget;
    const day = input.getAttribute("data-day");
    const projectId = input.getAttribute("data-projectId");
    const hours = input.value;
    const billableHours = { ...this.state.billableTotalMap };
    if (projectId in billableHours) {
      billableHours[projectId][day] = hours;
    } else {
      billableHours[projectId] = { ...this.state.billableTotalMap[projectId] };
      billableHours[projectId][day] = hours;
    }
    this.setState({
      billableTotalMap: billableHours,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.user.employee_id &&
      this.state.user.employee_id !== prevState.user.employee_id
    ) {
      fetchProjects(this.state.user.employee_id).then((response) => {
        const projectIds = [];
        const projectNameMap = {};
        const projectResourceMap = {};
        const billableMap = {}
        var resourceMap = [];
        response &&
          response.map((project) => {
            projectIds.push(project.project_id);
            projectNameMap[project.project_id] = project.project_name;
            projectResourceMap[project.project_id] =
              project.project_resource_id;
            billableMap[project.project_id] = project.billable
          });
        const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
        const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
        const currentWeek = {};
        const dateMap = {};
        projectIds.map((projectId) => {
          currentWeek[`${projectId}`] = {
            startOfWeek: startOfWeek,
            endOfWeek: endOfWeek,
          };
          dateMap[`${projectId}`] = this.getWeekDates(startOfWeek, endOfWeek);
        });
        this.setState({
          projects: response,
          projectIds: projectIds,
          currentWeek: currentWeek,
          dateMap: dateMap,
          projectNameMap: projectNameMap,
          projectResourceMap: projectResourceMap,
          billableMap: billableMap
        });

        listMultipleProjectTaskstatus(projectIds).then((response) => {
          let taskStatusMap = {};
          let tmp = _.groupBy(response, "project_id");

          Object.keys(tmp).forEach((pr) => {
            if (!(pr in taskStatusMap)) {
              taskStatusMap[pr] = {};
            }

            tmp[pr].forEach((taskStatus) => {
              taskStatusMap[pr][taskStatus.task_status_id] =
                taskStatus.task_status_abbr;
            });
          });

          this.setState({
            taskStatusMap: taskStatusMap,
          });
        });
      });
      getDefaultProjects()
        .then((response) => {
          this.setState({ defaultProjects: response });
        })
        .catch((err) => {
          this.showActionFeedback(err.message);
        });
    }

    // NOTE: whenever a new user is selected newer set of projectIds are fetched
    // and thus this condition no longer holds true and we fetch newer set of
    // project task and sprints for the projectIds
    if (this.state.projectIds !== prevState.projectIds) {
      getTasksAndSprints({
        projectIds: this.state.projectIds,
        resourceId: this.state.user.employee_id,
      }).then((response) => {
        const projectTaskMap = {};
        const projectTaskHours = {};

        response &&
          response.map((projectData) => {
            projectTaskMap[`${projectData.projectId}`] = [...projectData.tasks];

            const taskHours = {};
            projectData.tasks.map((task) => {
              taskHours[`${task.task_id}`] = {
                "sun-hrs": 0,
                "mon-hrs": 0,
                "tue-hrs": 0,
                "wed-hrs": 0,
                "thu-hrs": 0,
                "fri-hrs": 0,
                "sat-hrs": 0,
              };
            });

            projectTaskHours[`${projectData.projectId}`] = taskHours;
          });

        this.setState({
          data: response,
          projectTaskMap: projectTaskMap,
          projectTaskHours: projectTaskHours,
        });
        this.refreshTimeSheet();
      });
    }
  }

  refreshTimeSheet = () => {
    getTimeSheet({
      projectIds: this.state.projectIds,
      currentWeek: this.state.currentWeek,
      resourceId: this.state.user.employee_id,
    }).then((response) => {
      // format the time sheet data here and save it in appropriate format
      const projectTaskHours = this.initializeTaskHours();
      const projectTaskMeta = {};

      if (response && Array.isArray(response) && response.length !== 0) {
        response.map((timeSheetEntry) => {
          const projectId = timeSheetEntry.project_id;
          const taskId = timeSheetEntry.task_id;
          const day = new Date(timeSheetEntry.worked_dt)
            .toLocaleString("default", { weekday: "short" })
            .toLocaleLowerCase();
          const dayKey = `${day}-hrs`;

          if (!(projectId in projectTaskMeta)) {
            projectTaskMeta[projectId] = {};
          }
          if (!(taskId in projectTaskMeta[projectId])) {
            projectTaskMeta[projectId][taskId] = {};
          }
          const workedDate = timeSheetEntry.worked_dt.split(" ")[0];
          projectTaskMeta[projectId][taskId][workedDate] = {
            status: timeSheetEntry.status,
            approved: timeSheetEntry.approved,
            updated_by: timeSheetEntry.updated_by,
            updated_dt: timeSheetEntry.updated_dt,
            approved_by: timeSheetEntry.approved_by,
            approved_dt: timeSheetEntry.approved_dt,
          };

          if (timeSheetEntry.project_id in projectTaskHours) {
            // the projectId exists
            if (timeSheetEntry.task_id in projectTaskHours[projectId]) {
              // task id exists
              projectTaskHours[projectId][taskId] = {
                ...projectTaskHours[projectId][taskId],
                [dayKey]: timeSheetEntry.worked_hrs,
              };
            } else {
              // if task id doesn't exist
              projectTaskHours[projectId][taskId] = {
                [dayKey]: timeSheetEntry.worked_hrs,
              };
            }
          } else {
            // key doesn't exist
            projectTaskHours[projectId] = {};
            projectTaskHours[projectId][taskId] = {
              [dayKey]: timeSheetEntry.worked_hrs,
            };
          }
        });
      }

      this.setState({ projectTaskMeta: projectTaskMeta });

      if (Object.keys(projectTaskHours).length !== 0) {
        // initially projectTaskHours can be empty as no timeSheet data is stored
        this.setState({
          projectTaskHours: projectTaskHours,
        });
      } else {
        this.setState({
          projectTaskHours: this.initializeTaskHours(),
        });
      }
    });

    getBillableTotals({
      projectIds: this.state.projectIds,
      currentWeek: this.state.currentWeek,
      resourceId: this.state.user.employee_id,
    }).then((response) => {
      let billableTotalMap = {};
      let projectBillableMeta = {};

      if (response && Array.isArray(response) && response.length !== 0) {
        response.map((billableEntry) => {
          const projectId = billableEntry.project_id;

          if (!(projectId in projectBillableMeta)) {
            projectBillableMeta[projectId] = {};
          }

          const workedDate = billableEntry.worked_dt.split(" ")[0];
          projectBillableMeta[projectId][workedDate] = {
            status: billableEntry.status,
            approved: billableEntry.approved,
            updated_by: billableEntry.updated_by,
            updated_dt: billableEntry.updated_dt,
            approved_by: billableEntry.approved_by,
            approved_dt: billableEntry.approved_dt,
          };

          const day = new Date(billableEntry.worked_dt)
            .toLocaleString("default", { weekday: "short" })
            .toLocaleLowerCase();
          billableTotalMap[projectId] = {
            ...billableTotalMap[projectId],
            [day]: billableEntry.worked_hrs,
          };
        });

        // billable totals is associated project wise
        this.state.projectIds.map((projectId) => {
          if (
            Object.keys(billableTotalMap).includes(projectId.toString()) ===
            false
          ) {
            billableTotalMap[projectId] = {
              sun: 0,
              mon: 0,
              tue: 0,
              wed: 0,
              thu: 0,
              fri: 0,
              sat: 0,
            };
          }
        });
      } else {
        billableTotalMap = this.initializeBillableHours();
      }

      this.setState({
        billableTotalMap: billableTotalMap,
        isFetchComplete: true,
        disableButtons: false,
        projectBillableMeta: projectBillableMeta,
      });
    });
  };

  initializeTaskHours = () => {
    const projectTaskHours = {};
    this.state.data.map((projectData) => {
      const taskHours = {};
      projectData.tasks.map((task) => {
        taskHours[`${task.task_id}`] = {
          "sun-hrs": 0,
          "mon-hrs": 0,
          "tue-hrs": 0,
          "wed-hrs": 0,
          "thu-hrs": 0,
          "fri-hrs": 0,
          "sat-hrs": 0,
        };
      });

      projectTaskHours[`${projectData.projectId}`] = taskHours;
    });
    return projectTaskHours;
  };

  initializeBillableHours = () => {
    const billableTotalMap = {};
    this.state.projectIds.map((projectId) => {
      billableTotalMap[projectId] = {
        sun: 0,
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
      };
    });
    return billableTotalMap;
  };

  saveTimeSheet = (event, projectId) => {
    // TODO: maybe submit time sheet for a single project only
    // instead of submitting time sheet for all the projects
    event.preventDefault();
    this.setState({ disableButtons: true });

    // create a clone of projectTaskMap
    const projectTaskMap = {};
    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    //Object.keys(this.state.projectTaskMap).map((projectId) => {
    projectTaskMap[projectId] = [];
    const tasks = this.state.projectTaskMap[projectId];
    tasks.map((task) => {
      daysOfWeek.map((day) => delete task[day]);
      projectTaskMap[projectId].push({ ...task });
    });
    //})

    let request = {
      projectId: projectId,
      currentWeek: this.state.currentWeek,
      projectTaskMap: projectTaskMap,
      dateMap: this.state.dateMap,
      projectTaskHours: this.state.projectTaskHours,
      selectedUser: this.state.user,
      projectResourceMap: this.state.projectResourceMap,
    };
    console.log("Timesheet update request:", request);
    updateTimeSheet(request)
      .then((response) => {
        console.log("Timesheet update response:", response);
        if (response && response.success) {
          this.showActionFeedback(response.message);
        }
      })
      .then((response) => {
        const billableEntries = [];
        //this.state.projectIds.map((projectId) => {
        const dateMap = this.state.dateMap[projectId];
        const billableTotal = this.state.billableTotalMap[projectId];
        Object.keys(dateMap).map((day) => {
          billableEntries.push({
            project_id: projectId,
            resource_id: this.state.user.employee_id,
            project_resource_id: this.state.projectResourceMap[projectId],
            created_by: this.state.user.usr_id,
            worked_dt: dateMap[day],
            worked_hrs: billableTotal[day],
          });
        });
        console.log("Billable entries request:", billableEntries);
        updateBillableTotals(billableEntries)
          .then((response) => {
            console.log(response);
            this.refreshTimeSheet();
          })
          .catch((error) => {
            console.log(error);
            this.showActionFeedback(error);
          });
      })
      //})
      .catch((error) => {
        console.log(error);
        this.showActionFeedback(error);
      });
  };

  gotoNextWeek = (event) => {
    const button = event.currentTarget;
    const projectId = button.getAttribute("data-projectId");
    const currentWeek = { ...this.state.currentWeek };

    let newStartOfWeek = moment(currentWeek[projectId].startOfWeek)
      .clone()
      .add(7, "days")
      .format("YYYY-MM-DD");
    let newEndOfWeek = moment(currentWeek[projectId].endOfWeek)
      .clone()
      .add(7, "days")
      .format("YYYY-MM-DD");

    const dateMap = { ...this.state.dateMap };
    dateMap[projectId] = this.getWeekDates(newStartOfWeek, newEndOfWeek);

    currentWeek[projectId] = {
      startOfWeek: newStartOfWeek,
      endOfWeek: newEndOfWeek,
    };
    this.setState(
      {
        currentWeek: currentWeek,
        dateMap: dateMap,
      },
      () => this.refreshTimeSheet()
    );
  };

  gotoPreviousWeek = (event) => {
    const button = event.currentTarget;
    const projectId = button.getAttribute("data-projectId");
    const currentWeek = { ...this.state.currentWeek };

    let newStartOfWeek = moment(currentWeek[projectId].startOfWeek)
      .clone()
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    let newEndOfWeek = moment(currentWeek[projectId].endOfWeek)
      .clone()
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    const dateMap = { ...this.state.dateMap };
    dateMap[projectId] = this.getWeekDates(newStartOfWeek, newEndOfWeek);

    currentWeek[projectId] = {
      startOfWeek: newStartOfWeek,
      endOfWeek: newEndOfWeek,
    };
    // Hmm, call refreshTimeSheet only after setState finishes updating the state
    this.setState(
      {
        currentWeek: currentWeek,
        dateMap: dateMap,
      },
      () => this.refreshTimeSheet()
    );
  };

  getEmployees = () => {
    // sort employees name in ascending order
    let employees = this.state.employees.sort((empA, empB) =>
      empA.employee_name > empB.employee_name ? 1 : -1
    );
    let projectEmployees = this.state.resourceMap;
    //console.log("Project team members", projectEmployees);
    if (this.isProjectLead()) {
      return employees
        .filter((employee) => projectEmployees.includes(employee.employee_id))
        .map((employee) => (
          <option
            key={`selectedEmployee-${employee.employee_id}`}
            value={employee.employee_id}
            selected={
              this.state.user
                ? employee.employee_id === this.state.user.employee_id
                : false
            }
          >
            {employee.employee_name}
          </option>
        ));
    }
    return this.state.employees.map((employee) => (
      <option
        key={`selectedEmployee-${employee.employee_id}`}
        value={employee.employee_id}
        selected={
          this.state.user
            ? employee.employee_id === this.state.user.employee_id
            : false
        }
      >
        {employee.employee_name}
      </option>
    ));
  };

  changeSelectedEmployee = (event) => {
    const value = event.target.value;
    this.setState({
      user: this.state.employeeMap[value],
    });
  };

  employeeSelection = () => {
    return (
      <Input
        type="select"
        name="selectedEmployee"
        id="selectedEmployee"
        bsSize="sm"
        onChange={this.changeSelectedEmployee}
      >
        <option></option>
        {this.state.employees && this.getEmployees()}
      </Input>
    );
  };

  isAdmin = () => {
    if (Object.keys(this.state.originalUser).length === 0) return false;
    return ["superadmin", "admin", "timesheet admin"].includes(
      this.state.originalUser.role_name.toLowerCase()
    );
  };

  isProjectLead = () => {
    if (Object.keys(this.state.originalUser).length === 0) return false;
    return ["project lead"].includes(
      this.state.originalUser.role_name.toLowerCase()
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ disableButtons: true });
    submitTimeSheet({
      currentWeek: this.state.currentWeek,
      resourceId: this.state.user.employee_id,
      projectIds: this.state.projectIds,
    }).then(({ data, success }) => {
      this.refreshTimeSheet();
      if (success === true) {
        this.showActionFeedback("Timesheet submitted successfully");
      } else {
        this.showActionFeedback("Timesheet submission failed");
      }
    });
  };

  /**START: editable status for timesheet**/
  getTaskStatusOptionsMui = (projectId) => {
    const taskStatusList =
      projectId in this.state.taskStatusMap
        ? this.state.taskStatusMap[projectId]
        : {};
    return Object.keys(taskStatusList).map((taskStatus) => (
      <option
        key={`task-status-${taskStatus}`}
        value={taskStatus}
        style={{ fontSize: "13px" }}
      >
        {taskStatusList[taskStatus]}
      </option>
    ));
  };

  handleStatusChange = (row, e) => {
    const rowId = row.task_id;
    const value = e.target.value;
    let projectTaskMap = this.state.projectTaskMap;
    projectTaskMap[row.project_id].find(
      (taskid) => taskid.task_id === rowId
    ).task_status_id = value;
    this.setState({ projectTaskMap: projectTaskMap });
  };

  render() {
    const columns = [];
    this.state.columns.map((taskColumn) => {
      if (taskColumn.field === "task_status_id") {
        columns.push({
          ...taskColumn,
          lookup: this.state.taskStatusMap,
        });
      } else {
        columns.push({ ...taskColumn });
      }
    });
    return (
      <>
        {this.state.actionFeedbackMessage && (
          <ActionFeedback message={this.state.actionFeedbackMessage} />
        )}
        {(this.isAdmin() || this.isProjectLead()) && (
          // Method#1 of this answer: https://stackoverflow.com/a/38948646/7358595
          <div className="d-flex flex-row mt-2 align-items-center justify-content-center position-relative">
            <div className="h2 d-flex text-lisColor1">Timesheet</div>
            <div className="d-flex flex-row align-items-center position-absolute top-right">
              <div className="mr-2">Filter by:</div>
              <div>{this.employeeSelection()}</div>
            </div>
          </div>
        )}
        <div className="d-flex flex-column">
          {this.state.projectIds.map((projectId) => {
            return (
              <div>
                <TimeSheet
                  key={`timeSheet-for-project-${projectId}`}
                  user={this.state.user}
                  projectId={projectId}
                  projectName={this.state.projectNameMap[projectId]}
                  disableBillable={this.state.billableMap[projectId] !== 'Y'}
                  onChange={this.saveHours}
                  onBillableChange={this.saveBillableHours}
                  columns={columns}
                  dateMap={this.state.dateMap}
                  gotoNextWeek={this.gotoNextWeek}
                  gotoPreviousWeek={this.gotoPreviousWeek}
                  isFetchComplete={this.state.isFetchComplete}
                  currentWeek={this.state.currentWeek[projectId]}
                  billableTotals={
                    this.state.billableTotalMap &&
                    this.state.billableTotalMap[projectId]
                  }
                  projectTasks={
                    this.state.projectTaskMap &&
                    this.state.projectTaskMap[projectId]
                  }
                  projectTaskHours={
                    this.state.projectTaskHours &&
                    this.state.projectTaskHours[projectId]
                  }
                  projectTaskMeta={this.state.projectTaskMeta}
                  projectBillableMeta={this.state.projectBillableMeta}
                  disableButtons={this.state.disableButtons}
                  taskStatusMap={this.state.taskStatusMap}
                  getTaskStatusOptionsMui={this.getTaskStatusOptionsMui}
                  handleStatusChange={this.handleStatusChange}
                  saveTimeSheet={this.saveTimeSheet}
                />
              </div>
            );
          })}
          <DayoffTimesheet
            defaultProjects={this.state.defaultProjects}
            isFetchComplete={this.state.isFetchComplete}
            getWeekDates={this.getWeekDates}
            selectedUser={this.state.user}
            showActionFeedback={this.showActionFeedback}
          />
        </div>
        {this.state.isFetchComplete ? (
          <div className="float-right m-2">
            <Button
              color="primary"
              disabled={this.state.disableButtons}
              onClick={this.handleSubmit}
            >
              Weekly Submit
            </Button>
            {/* <Button
              color="primary"
              disabled={this.state.disableButtons}
              onClick={this.saveTimeSheet}
            >
              Save
            </Button> */}
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }
}
