import React from 'react';
import Menu from '../../components/Menu';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';
import {
  listMenu,
  createMenu,
  updateMenu,
  deleteMenu,
  updateApproved,
  updateStatus,
} from '../../actions/MenuFunctions';
import { getPermissionsFor } from '../../actions/UtilFunctions';

const cellStyle = {
  paddingTop: '0px', paddingBottom: '0px',
}

export default class MenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // state for the form fields
      fields: {
        menu_name: '',
        upper_menu_id: '',
        link_url: '',
        icon_url: '',
      },
      upperMenuId: 0,
      // state for the list of menus
      menuListColumns: [
        { title: 'Menu', field: 'menu_name', cellStyle: cellStyle},
        { title: 'Upper Menu', field: 'upper_menu_name', cellStyle: cellStyle},
        { title: 'Link URL', field: 'link_url', cellStyle: cellStyle},
        { title: 'Icon URL', field: 'icon_url', cellStyle: cellStyle},
        { title: 'Status', field: 'status_icon', cellStyle: cellStyle},
        { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle},
      ],
      menuListData: [],
      // state for edit mode
      editMode: false,
      currentTab: 'list',
      currentId: '',
      actionFeedbackMessage: '',
      isValid: {},

      allowedPermissions: getPermissionsFor('menu'),
      isFetchComplete: false,
    }
  }

  componentDidMount() {
    this.refreshMenuList();
  }

  refreshMenuList = () => {
    listMenu().then(response => {
      this.setState({
        menuListData: response,
        isFetchComplete: true,
      });
    })
  }

  changeTab = (tabName) => {
    this.setState({
      currentTab: tabName,
    });
  }

  handleEdit = (rowData) => {
    const menuId = rowData.menu_id;
    const currentState = {...this.state.fields};
    for (const key of Object.keys(currentState)) {
      currentState[key] =  rowData[key]; 
    }
    this.setState({
      fields: currentState,
      currentId: menuId,
      editMode: true,
      currentTab: 'add',
    });
  }

  handleApproved =  (rowData) => {
    const menuId = rowData.menu_id;
    const isApproved = rowData['approved'];
    rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y';
    updateApproved(menuId, rowData).then((response) => {
      console.log(`Menu successfully approved with menu id: ${menuId} `, response);
      if (response) {
        this.refreshMenuList();
        this.showActionFeedback(response.message);
      }
    });
  }

  handleDelete = (rowData) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const menuId = rowData.menu_id;
    const menuListData = this.state.menuListData.filter((menu) => menu['menu_id'] !== menuId);
    this.setState({
      menuListData: menuListData,
    });
    deleteMenu(menuId).then((response) => {
      console.log(`Menu successfully deleted with menu id: ${menuId} `, response);
      if (response) {
        this.showActionFeedback(response.message);
      }
    });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  resetState = () => {
    this.setState({
      fields: {
        menu_name: '',
        upper_menu_id: '',
        link_url: '',
        icon_url: '',
      },
      editMode: false,
      isValid: {},
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            //e.preventDefault();
            this.validateFields();

            if (!this.isFormValid()) {
              this.showActionFeedback('Invalid or missing form data!');
              return;
            }

            // the select element doesn't return the default
            // option for some reason
            const fields = this.state.fields;
            if (!fields['upper_menu_id']) {
              fields['upper_menu_id'] = '0';
              this.setState({
                fields: fields
              });
            }

            if (this.state.editMode) {
              updateMenu(this.state.currentId, fields).then((response) => {
                this.refreshMenuList();
                console.log(`Success updating the menu with id: ${this.state.currentId} `, response);
                if (response) {
                  this.showActionFeedback(response.message);
                }
              });
            
              this.setState({
                currentId: '',
              });
            } else {
              createMenu(fields).then((response) => {
                this.refreshMenuList();
                console.log('Success: ', response);
                if (response) {
                  this.showActionFeedback(response.message);
                  // TODO: if no response is received show the failed status here
                }
              });
            }
            this.resetState();
            this.changeTab('list');
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  // check if all the form value are valid
  isFormValid = () => {
    const notRequiredFields = ['upper_menu_id'];
    const isValid = this.state.isValid;
    const fields = {...this.state.fields};
    notRequiredFields.forEach((field) => delete fields[field]);
    for (const key in fields) {
      if (isValid[key] === false || isValid[key] === undefined) 
        return false; 
    }
    return true;
  }

  handleUserInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    let fields = this.state.fields
    fields[name] = value
    this.setState({ fields })
    this.setState({ [name]: value })
    this.validateFields();
  }

  // TODO: Replace this validation logic for all other non empty validations
  validateNonEmpty = (key, value) => {
    const {isValid} = this.state;
    isValid[key] = false;
    const nonEmptyRegex = /^(?!\s*$).+/;
    if (value && `${value}`.match(nonEmptyRegex)) {
      isValid[key] = true;
    }
    this.setState({isValid});
  }

  validateFields = () => {
    for (const key of Object.keys(this.state.fields)) {
      if (key === 'upper_menu_id') continue;
      const value = this.state.fields[key];
      this.validateNonEmpty(key, value);
    }
  } 
  
  handleBlur = (e) => {
    this.validateFields();
  }

  showActionFeedback = (message) => {
    this.setState({
      actionFeedbackMessage: message,
    });
    setTimeout(() => this.setState({actionFeedbackMessage: '',}), 5000);
  }

  isActionAllowed = (action) => {
    const { allowedPermissions } = this.state;
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase()))
        return true;
    }
    return false;
  }

  render() {
    return(
      <Menu 
        fields={this.state.fields} 
        handleUserInput={this.handleUserInput}
        handleSubmit={this.handleSubmit}
        menuListColumns={this.state.menuListColumns}
        menuListData={this.state.menuListData}
        handleDelete={this.handleDelete}
        handleEdit={this.handleEdit}
        editMode={this.state.editMode}
        currentTab={this.state.currentTab}
        currentId={this.state.currentId}
        changeTab={this.changeTab}
        resetState={this.resetState}
        message={this.state.actionFeedbackMessage}
        isValid={this.state.isValid}
        handleBlur={this.handleBlur}
        onApprovedIconClick={this.onApprovedIconClick}
        onStatusIconClick={this.onStatusIconClick}
        isActionAllowed={this.isActionAllowed}
        isFetchComplete={this.state.isFetchComplete}
      />
    );
  }

  onStatusIconClick = (e) => {
    const button = e.currentTarget;
    const menu = button.getAttribute('data-menu');
    const menuObj = JSON.parse(menu);
    this.handleStatus(menuObj);
  }

  onApprovedIconClick = (e) => {
    const button = e.currentTarget;
    const menu = button.getAttribute('data-menu');
    const menuObj = JSON.parse(menu);
    this.handleApproved(menuObj);
  }

  handleStatus = (rowData) => {
    const menuId = rowData.menu_id;
    const status = rowData['status'];
    rowData['status'] = status === 'A' ? 'I' : 'A';
    updateStatus(menuId, rowData).then((response) => {
      console.log(`Status successfully updated for menu with menu id: ${menuId} `, response);
      if (response) {
        this.refreshMenuList();
        this.showActionFeedback(response.message);
      }
    });
  }
}