import { useFormik } from 'formik';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import CardFooter from 'reactstrap/lib/CardFooter';
import RAIDSection from './RAIDSection';
import * as Yup from 'yup';
const ProjectInfoForm = ({
  initValues,
  changeTab,
  loading,
  clientList,
  streamList,
  employeeList,
  clientTypeList,
  projectModelList,
  resetForm,
  handleSubmit,
}) => {
  const formikInitialValues = {
    Projectinfo: {
      project_cd: '',
      project_name: '',
      project_abbr: '',
      project_type: '',
      stream_id: '',
      lead_id: '',
      client_id: '',
      project_st_dt: '',
      project_end_dt: '',
      model_id: '',
      capacity_hrs: '',
      capacity_points: '',
      work_plan: '',
      work_days: '',
      project_category: '',
      remarks: '',
      status: 'I',
      approved: 'N',
      model_track: '',
      client_type_id: '',
    },
    Risk: [{ risk_id: 0, risk_name: '' }],
    Assumption: [{ assumption_id: 0, assumption_name: '' }],
    Issue: [{ issue_id: 0, issue_name: '' }],
    Dependency: [{ dependency_id: 0, dependency_name: '' }],
  };
  const initialValues = _.isEmpty(initValues) ? formikInitialValues : initValues;

  const projectTypeList = [
    { id: 'I', name: 'Internal' },
    { id: 'E', name: 'External' },
  ];

  const categoryList = [
    { id: 'D', name: 'Development' },
    { id: 'S', name: 'Support' },
  ];

  const projectStateList = [
    { id: 'H', name: 'Halted' },
    { id: 'I', name: 'In Progress' },
    { id: 'C', name: 'Closed' },
  ];

  const taskPlanList = [
    { id: 'W', name: 'Weekly' },
    { id: 'F', name: 'Fortnightly' },
    { id: 'M', name: 'Monthly' },
  ];

  //Formik hook
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      Projectinfo: Yup.object({
        project_cd: Yup.string().required('Project Code is required'),
        project_name: Yup.string().required('Project Name is requried'),
        project_abbr: Yup.string().required('Project Abbr is requried'),
        project_abbr: Yup.string().required('Project Abbr is requried'),
        project_type: Yup.string().required('Project type is required'),
        project_category: Yup.string().required('Project Category is required'),
        stream_id: Yup.string().required('Stream is required'),
        lead_id: Yup.string().required('Project lead is required'),
        client_id: Yup.string().required('Client is required'),
        project_st_dt: Yup.string()
          .required('Start Date is required')
          .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'Invalid date'),
        project_end_dt: Yup.string()
          .required('End date is required')
          .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'Invalid date'),
        model_id: Yup.string().required('Project Model is required'),
        model_track: Yup.string().required('Project Model track is required'),
        capacity_hrs: Yup.number()
          .required('Velocity is required')
          .min(Number.EPSILON, 'Velocity should be a positive number'),
        capacity_points: Yup.number()
          .required('Points is required')
          .min(Number.EPSILON, 'Points must be positive number'),
        work_plan: Yup.string().required('Task Plan is required'),
        work_days: Yup.number().required('Work Days is required').min(Number.EPSILON),
      }),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values);
      formik.resetForm(formikInitialValues);
    },
  });
  return (
    <>
      <Fragment>
        {/* action feedback message here */}
        <Card className="border-top-0 shadow-none" style={{ minHeight: '100vh' }}>
          <CardHeader
            style={{
              fontSize: 20,
              color: '#fff',
              // fontWeight: 'bold',
              textAlign: 'center',
              padding: 10,
            }}
          >
            Project Information
          </CardHeader>

          <CardBody style={{ minHeight: '100vh' }}>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <Form onSubmit={formik.handleSubmit}>
                <fieldset>
                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="projectCode">Code</Label>
                        <Input
                          type="text"
                          name="Projectinfo.project_cd"
                          value={formik.values?.Projectinfo?.project_cd}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched?.Projectinfo?.project_cd &&
                            formik.errors?.Projectinfo?.project_cd
                          }
                        />
                        {formik.errors?.Projectinfo?.project_cd && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.project_cd}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={8}>
                      <FormGroup>
                        <Label for="projectName">Name</Label>
                        <Input
                          type="text"
                          name="Projectinfo.project_name"
                          value={formik.values?.Projectinfo?.project_name}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched?.Projectinfo?.project_name &&
                            formik.errors?.Projectinfo?.project_name
                          }
                        />
                        {formik.errors?.Projectinfo?.project_name && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.project_name}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="projectCode">Abbr.</Label>
                        <Input
                          type="text"
                          name="Projectinfo.project_abbr"
                          value={formik.values?.Projectinfo?.project_abbr}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched?.Projectinfo?.project_abbr &&
                            formik.errors?.Projectinfo?.project_abbr
                          }
                        />
                        {formik.errors?.Projectinfo?.project_abbr && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.project_abbr}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label>Type</Label>
                        <Input
                          type="select"
                          name="Projectinfo.project_type"
                          value={formik.values?.Projectinfo?.project_type}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched?.Projectinfo?.project_type &&
                            formik.errors?.Projectinfo?.project_type
                          }
                        >
                          <option value=""></option>
                          {projectTypeList.map((type, key) => (
                            <option value={type.id} key={key}>
                              {type.name}
                            </option>
                          ))}
                        </Input>
                        {formik.errors?.Projectinfo?.project_type && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.project_type}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label>Category</Label>
                        <Input
                          type="select"
                          name="Projectinfo.project_category"
                          value={formik.values?.Projectinfo?.project_category}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched?.Projectinfo?.project_category &&
                            formik.errors?.Projectinfo?.project_category
                          }
                        >
                          <option value=""></option>
                          {categoryList.map((type, key) => (
                            <option value={type.id} key={key}>
                              {type.name}
                            </option>
                          ))}
                        </Input>
                        {formik.errors?.Projectinfo?.project_category && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.project_category}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label>Stream</Label>
                        <Input
                          type="select"
                          name="Projectinfo.stream_id"
                          value={formik.values?.Projectinfo?.stream_id}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched?.Projectinfo?.stream_id &&
                            formik.errors?.Projectinfo?.stream_id
                          }
                        >
                          <option value=""></option>
                          {streamList.map((type, key) => (
                            <option value={type.stream_id} key={key}>
                              {type.stream_name}
                            </option>
                          ))}
                        </Input>
                        {formik.errors?.Projectinfo?.stream_id && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.stream_id}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label>Client name</Label>
                        <Input
                          type="select"
                          name="Projectinfo.client_id"
                          value={formik.values?.Projectinfo?.client_id}
                          bsSize="sm"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue(
                              'Projectinfo.client_type_id',
                              clientList.find((client) => client.client_id == e.target.value)
                                ?.client_type_id,
                            );
                          }}
                          onBlur={formik.handleBlur}
                        >
                          <option value=""></option>
                          {clientList.map((type, key) => (
                            <option value={type.client_id} key={key}>
                              {type.client_name}
                            </option>
                          ))}
                        </Input>
                        {formik.errors?.Projectinfo?.client_id && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.cleint_id}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label>Client Type</Label>
                        <Input
                          type="select"
                          name="Projectinfo.client_type_id"
                          value={formik.values?.Projectinfo?.client_type_id}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                        >
                          <option value=""></option>
                          {clientTypeList.map((type, key) => (
                            <option value={type.client_type_id} key={key}>
                              {type.client_type_name}
                            </option>
                          ))}
                        </Input>
                        {formik.errors?.Projectinfo?.client_type_id && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.client_type_id}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>PMO Manager/Lead</Label>
                        <Input
                          type="select"
                          name="Projectinfo.lead_id"
                          value={formik.values?.Projectinfo?.lead_id}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched?.Projectinfo?.lead_id &&
                            formik.errors?.Projectinfo?.lead_id
                          }
                        >
                          <option value=""></option>
                          {employeeList.map((type, key) => (
                            <option
                              value={type.employee_id}
                              key={key}
                            >{`${type.first_name} ${type.last_name}`}</option>
                          ))}
                        </Input>
                        {formik.errors?.Projectinfo?.lead_id && (
                          <FormFeedback invalid>{formik.errors?.Projectinfo?.lead_id}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="st_dt">Start Date</Label>
                        <Input
                          type="date"
                          name="Projectinfo.project_st_dt"
                          value={formik.values?.Projectinfo?.project_st_dt}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors?.Projectinfo?.project_st_dt && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.project_st_dt}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="st_dt">End Date</Label>
                        <Input
                          type="date"
                          name="Projectinfo.project_end_dt"
                          value={formik.values?.Projectinfo?.project_end_dt}
                          bsSize="sm"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors?.Projectinfo?.project_end_dt && (
                          <FormFeedback invalid>
                            {formik.errors?.Projectinfo?.project_end_dt}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup row>
                    <Col md={2}>
                      <Label for="model_id">Project Model</Label>
                      <Input
                        type="select"
                        name="Projectinfo.model_id"
                        value={formik.values?.Projectinfo?.model_id}
                        bsSize="sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value=""></option>
                        {projectModelList.map((type, key) => (
                          <option value={type.model_id} key={key}>
                            {type.model_name}
                          </option>
                        ))}
                      </Input>

                      {formik.errors?.Projectinfo?.model_id && (
                        <FormFeedback invalid>{formik.errors?.Projectinfo?.model_id}</FormFeedback>
                      )}
                    </Col>
                    <Col md={2}>
                      <Label for="model_track">Model Track</Label>
                      <Input
                        type="select"
                        name="Projectinfo.model_track"
                        value={formik.values?.Projectinfo?.model_track}
                        bsSize="sm"
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched?.Projectinfo?.model_track &&
                          formik.errors?.Projectinfo?.model_track
                        }
                        onBlur={formik.handleBlur}
                      >
                        <option value=""></option>
                        <option value="S" key={`story-point-s`}>
                          Story Point
                        </option>
                        <option value="H" key={`hours-h`}>
                          Hours
                        </option>
                      </Input>

                      {formik.errors?.Projectinfo?.model_track && (
                        <FormFeedback invalid>{formik.errors?.Projectinfo?.model_id}</FormFeedback>
                      )}
                    </Col>
                    <Col md={2}>
                      <Label for="velocity">Velocity per resource</Label>
                      <Input
                        type="number"
                        name="Projectinfo.capacity_hrs"
                        value={formik.values?.Projectinfo?.capacity_hrs}
                        bsSize="sm"
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched?.Projectinfo?.capacity_hrs &&
                          formik.errors?.Projectinfo?.capacity_hrs
                        }
                        onBlur={formik.handleBlur}
                      />
                      Hrs
                      {formik.errors?.Projectinfo?.capacity_hrs && (
                        <FormFeedback invalid>
                          {formik.errors?.Projectinfo?.capacity_hrs}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col md={2}>
                      {/* very very bad aproach but works */}
                      <Label for="points" style={{ color: 'white' }}>
                        Points
                      </Label>
                      <Input
                        type="number"
                        name="Projectinfo.capacity_points"
                        value={formik.values.Projectinfo.capacity_points}
                        bsSize="sm"
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched?.Projectinfo?.capacity_points &&
                          formik.errors?.Projectinfo?.capacity_points
                        }
                        onBlur={formik.handleBlur}
                      />
                      Points
                      {formik.errors?.Projectinfo?.capacity_points && (
                        <FormFeedback invalid>
                          {formik.errors?.Projectinfo?.capacity_points}
                        </FormFeedback>
                      )}
                    </Col>

                    <Col md={2}>
                      <Label for="taskPlan">Task plan</Label>

                      <Input
                        type="select"
                        name="Projectinfo.work_plan"
                        value={formik.values?.Projectinfo?.work_plan}
                        bsSize="sm"
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched?.Projectinfo?.work_plan &&
                          formik.errors?.Projectinfo?.work_plan
                        }
                        onBlur={formik.handleBlur}
                      >
                        <option value=""></option>

                        {taskPlanList.map((type, key) => (
                          <option value={type.id} key={key}>
                            {type.name}
                          </option>
                        ))}
                      </Input>
                      {formik.errors?.Projectinfo?.work_plan && (
                        <FormFeedback invalid>{formik.errors?.Projectinfo?.work_plan}</FormFeedback>
                      )}
                    </Col>
                    <Col md={2}>
                      <Label for="days"> Work days</Label>
                      <Input
                        type="number"
                        name="Projectinfo.work_days"
                        value={formik.values?.Projectinfo?.work_days}
                        bsSize="sm"
                        onChange={formik.handleChange}
                        invalid={
                          formik.touched?.Projectinfo?.work_days &&
                          formik.errors?.Projectinfo?.work_days
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors?.Projectinfo?.work_days && (
                        <FormFeedback invalid>{formik.errors?.Projectinfo?.work_plan}</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label for="remarks">Remarks</Label>
                    <Input
                      type="textarea"
                      rows="5"
                      name="Projectinfo.remarks"
                      value={formik.values.Projectinfo.remarks}
                      bsSize="sm"
                      onChange={formik.handleChange}
                      invalid={
                        formik.touched?.Projectinfo?.remarks && formik.errors?.Projectinfo?.remarks
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors?.Projectinfo?.remarks && (
                      <FormFeedback invalid>{formik.errors?.Projectinfo?.remarks}</FormFeedback>
                    )}
                  </FormGroup>

                  <RAIDSection
                    formik={formik}
                    projectId={formik.values?.Projectinfo?.project_id || 0}
                    module="Risk"
                    moduleName="risk_name"
                    moduleId="risk_id"
                    moduleLabel="Risks and Challenges"
                  />
                  <RAIDSection
                    formik={formik}
                    projectId={formik.values?.Projectinfo?.project_id || 0}
                    module="Assumption"
                    moduleName="assumption_name"
                    moduleId="assumption_id"
                    moduleLabel="Assumption"
                  />
                  <RAIDSection
                    formik={formik}
                    projectId={formik.values?.Projectinfo?.project_id || 0}
                    module="Issue"
                    moduleName="issue_name"
                    moduleId="issue_id"
                    moduleLabel="Issues"
                  />
                  <RAIDSection
                    formik={formik}
                    projectId={formik.values?.Projectinfo?.project_id || 0}
                    module="Dependency"
                    moduleName="dependency_name"
                    moduleId="dependency_id"
                    moduleLabel="Dependencies"
                  />
                </fieldset>
              </Form>
            )}
          </CardBody>
          <CardFooter>
            <Row style={{ float: 'right' }}>
              <Button
                type="submit"
                color="primary"
                className="fixedWidthButton"
                onClick={formik.handleSubmit}
                //hidden={props.fields['approved'] === 'Y' ? true : false}
              >
                Save
              </Button>
              {/* <Link to={`/project`}> */}
              <Button
                color="danger"
                type="button"
                className="fixedWidthButton"
                onClick={() => {
                  resetForm(); //not working so used formik instead
                  formik.resetForm(formikInitialValues);
                  changeTab('list');
                }}
              >
                Cancel
              </Button>
              {/* </Link> */}
            </Row>
          </CardFooter>
        </Card>
      </Fragment>
    </>
  );
};

export default ProjectInfoForm;
