import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listTasktype = (modelId, cancelToken) => {
  const token = getUserToken()
  let url = '/tasktype/listTasktype';
  if (modelId) {
    url += `?model_id=${modelId}`;
  }
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}
export const activeTasktype = () => {
  const token = getUserToken()
  return axios
    .get('/tasktype/activeTasktype', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createTasktype = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/tasktype/createTasktype', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteTasktype = (tasktypeId) => {
  const token = getUserToken()
  return axios
    .delete(`/tasktype/deleteTasktype/${tasktypeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting effort type with id ${tasktypeId}`,
        error,
      )
    })
}

export const updateTasktype = (tasktypeId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/tasktype/updateTasktype/${tasktypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating Effort type): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Effort type with id ${tasktypeId}`,
        error,
      )
    })
}

export const updateApproved = (tasktypeId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['approved_by'] = id
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/tasktype/updateApproved/${tasktypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of Effort type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Effort type's approved status with id ${tasktypeId}`,
        error,
      )
    })
}

export const updateStatus = (tasktypeId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/tasktype/updateStatus/${tasktypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of Effort type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Effort type's status with Id ${tasktypeId}`,
        error,
      )
    })
}

export const getDetail = (tasktypeId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/tasktype/getDetail/${tasktypeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of Effort type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting Effort type's detail with Id ${tasktypeId}`,
        error,
      )
    })
}