import React from "react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {
  getProjectDistributionDetail,
  getProjectDistributionDetailByWeek,
} from "../../actions/DashboardFunctions";
import {
  StreamTable,
  ClientTable,
  HealthTable,
  HealthDetailTable,
  ProjectNameBox,
  ProjectTaskPlan,
} from "../../components/ProjectHealth/Table";
import ToggleButton from "../../components/ProjectHealth/ToggleButton";
import { Input } from "reactstrap";
import ProjectSummary from "./ProjectHealthNestedTabs/ProjectSummary";
import ProjectStatus from "./ProjectHealthNestedTabs/ProjectStatus";
import ResourceSummary from "./ProjectHealthNestedTabs/ResourceSummary";
import RisksAndIssues from "./ProjectHealthNestedTabs/RisksAndIssues";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ExportProjectReport } from "utils/CreatePPt";
import { Spinner } from "react-bootstrap";
import { groupBy, countBy } from "../../../src/utils/Utilities"

class ProjectHealth extends React.Component {
  constructor(props) {
    super(props);
    this.axiosCancelSource = axios.CancelToken.source();
    this.sprintSlide = React.createRef();
    this.resourceSlide = React.createRef();
    this.statusSlide = React.createRef();
    this.raidSlide = React.createRef();
    this.dashRef = React.createRef();
    this.state = {
      data: [],
      columnsStream: ["Stream", "Project Name", "Client", "Health"],
      colKeysStream: ["stream_cd", "project_name", "client_name", "healthstatus"],
      columnsClient: ["Client", "Stream", "Project Name", "Health"],
      colKeysClient: ["client_name", "stream_name", "project_name", "healthstatus"],
      selectedProject: {
        project_name: '',
        project_id: null,
        sprint_name: '',
        sprint_id: null
      },
      calcDate: new Date().toISOString().split("T")[0],
      isExporting: false,
    };
  }

  componentDidMount() {
    getProjectDistributionDetailByWeek(this.state.calcDate).then((response) => {
      const projectInProgress = response.filter(
        (project) => project["state_project"] === "In Progress"
      );
      projectInProgress?.length && this.setState({
        data: projectInProgress,
        selectedProject: projectInProgress[0]
      });

    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.calcDate && this.state.calcDate !== prevState.calcDate) {
      getProjectDistributionDetailByWeek(this.state.calcDate).then((response) => {
        const projectInProgress = response?.filter(
          (project) => project["state_project"] === "In Progress"
        );
        if (projectInProgress?.length) {
          this.setState({
            data: projectInProgress,
            selectedProject: projectInProgress[0]
          });
        }
      });
    }
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Cancel requests from Dashboard Report");
  }

  // groups data first by key1 and then by key2
  groupByTwoKeys = (data, key1, key2) => {
    let key1Group = groupBy(data, key1);
    Object.keys(key1Group).map((key1) => {
      let key2Group = groupBy(key1Group[key1], key2);
      key1Group[key1] = key2Group;
    });
    return key1Group;
  };

  handleHealthClick = (projectId) => {
    this.setState({
      selectedProject: this.state.data.find(({ project_id }) => project_id === projectId),
    });
  };

  handleExportReport = async () => {
    this.setState({
      isExporting: true
    });
    await ExportProjectReport({
      sprintSlide: this.sprintSlide,
      resourceSlide: this.resourceSlide,
      statusSlide: this.statusSlide,
      raidSlide: this.raidSlide,
      projectCategory: this.state.selectedProject?.project_category,
      projectName: this.state.selectedProject?.project_name,
    });
    this.setState({
      isExporting: false
    });
  }

  render() {
    const { columnsStream, data, colKeysStream, colKeysClient, columnsClient } =
      this.state;
    const groupStream = groupBy(data, "stream_cd");
    // const groupClient = this.groupByTwoKeys(data, "client_name", "stream_cd");
    const groupClient = groupBy(data, "client_name");

    const { selection, setSelection } = this.props;

    console.log("group stream is ",groupStream);

    return (
      <div className="mt-2">
        <div className="d-flex flex-row justify-content-between">
          <h2>Project Health</h2>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h4 className="mb-0">Filter</h4>
            <Input
              className="m-1"
              type="date"
              name="calcDate"
              value={this.state.calcDate}
              bsSize="sm"
              onChange={(e) => {
                this.setState({
                  calcDate: e.target.value,
                });
              }}
            />
          </div>
          <ToggleButton selection={selection} setSelection={setSelection} />
        </div>
        <div className="row">
          <div className="col-lg-6" ref={this.dashRef}>
            <StreamTable
              columns={columnsStream}
              data={groupStream}
              colKeys={colKeysStream}
              selectedProjectId={this.state.selectedProject.project_id}
              handleHealthClick={this.handleHealthClick}
            />
          </div>
          <div className="col-lg-6">
            <ClientTable
              columns={columnsClient}
              data={groupClient}
              colKeys={colKeysClient}
              selectedProjectId={this.state.selectedProject.project_id}
              handleHealthClick={this.handleHealthClick}
            />
          </div>
        </div>

        {this.state?.selectedProject?.project_id && (
          <>
            <div className="row">
              <div className="col">
                <h2>{this.state.selectedProject?.project_name}</h2>
              </div>
              <div className="col-2 d-flex justify-content-end">
                <button
                  className="btn btn-md btn-primary"
                  onClick={this.handleExportReport}
                  disabled={this.state.isExporting}
                >
                  {this.state.isExporting ? (
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden"></span>
                    </Spinner>
                  ) : (
                    <span>Export</span>
                  )}
                </button>
              </div>
            </div>
            <Tabs
              title="Hello"
              defaultActiveKey="projectSummary"
              id="justify-tab-example"
              className="mb-3 justify-content-right"
              variant="pills"
            >
              <Tab
                eventKey="projectSummary"
                title="Project Summary"
                tabClassName="btn btn-secondary btn-sm text-black mb-1"
              >
                <ProjectSummary
                  name={this.state.selectedProject?.project_name}
                  projectId={this.state.selectedProject.project_id}
                  calcDate={this.state.calcDate}
                  sprintName={this.state.selectedProject.sprint_name}
                />
              </Tab>
              <Tab
                eventKey="resourceSummary"
                title="Resource Summary"
                tabClassName="btn btn-secondary text-black mb-1"
              >
                <ResourceSummary
                  projectId={this.state.selectedProject.project_id}
                  calcDate={this.state.calcDate}
                  sprintId={this.state.selectedProject.sprint_id}
                  name={this.state.selectedProject.project_name}
                />
              </Tab>
              <Tab
                eventKey="projectStatus"
                title="Project Status"
                tabClassName="btn btn-secondary text-black mb-1"
              >
                <ProjectStatus
                  projectId={this.state.selectedProject.project_id}
                  calcDate={this.state.calcDate}
                  name={this.state.selectedProject.project_name}
                />
              </Tab>
              <Tab
                eventKey="riskAndIssues"
                title="Risk And Issues"
                tabClassName="btn btn-secondary text-black mb-1"
              >
                <RisksAndIssues
                  projectId={this.state.selectedProject.project_id}
                  sprintId={this.state.selectedProject.sprint_id}
                  name={this.state.selectedProject.project_name}
                />
              </Tab>
            </Tabs>
            <div
              className="container-fluid"
              style={{ maxHeight: 0, minWidth: 1100, maxWidth: 1101, overflow: "scroll" }}
            >
              <div id="projectSummary" ref={this.sprintSlide}>
                <ProjectSummary
                  name={this.state.selectedProject.project_name}
                  projectId={this.state.selectedProject.project_id}
                  calcDate={this.state.calcDate}
                  sprintName={this.state.selectedProject.sprint_name}
                />
              </div>
              <div id="resourceSummary" ref={this.resourceSlide}>
                <ResourceSummary
                  projectId={this.state.selectedProject.project_id}
                  calcDate={this.state.calcDate}
                  sprintId={this.state.selectedProject.sprint_id}
                  name={this.state.selectedProject.project_name}
                />
              </div>
              <div id="projectStatus" ref={this.statusSlide}>
                <ProjectStatus
                  projectId={this.state.selectedProject.project_id}
                  calcDate={this.state.calcDate}
                  name={this.state.selectedProject.project_name}
                />
              </div>
              <div id="riskAndIssues" ref={this.raidSlide}>
                <RisksAndIssues
                  projectId={this.state.selectedProject.project_id}
                  sprintId={this.state.selectedProject.sprint_id}
                  name={this.state.selectedProject.project_name}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProjectHealth;
