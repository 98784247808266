import React from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import { getResourceDist } from "../../../actions/DashboardFunctions";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import BarChart from "../BarChartContainer";
import _ from "lodash";
import BarChartMultipleXAxis from "../../../components/Charts/BarChartMultipleXAxis";
import ToggleButton from "components/ProjectHealth/ToggleButton";
import TableContainer from "container/ProjectHealth/TableContainer";

const GraphOptions = ({
  selected,
  otherSelected,
  options,
  id,
  handleSelection,
}) => (
  <UncontrolledDropdown size="sm">
    <DropdownToggle caret>{getOptionName(selected)}</DropdownToggle>
    <DropdownMenu>
      {options.map(
        (option, idx) =>
          option !== selected &&
          option !== otherSelected && (
            <DropdownItem
              key={`res-bar-${id}-${idx}`}
              data-id={id}
              data-key={option}
              onClick={handleSelection}
            >
              {getOptionName(option)}
            </DropdownItem>
          )
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
);

const GraphCard = ({
  id,
  selected,
  otherSelected,
  options,
  handleSelection,
  data,
  keySet,
  colors,
  barChartComponent: BarChartComponent,
}) => {
  return (
    <Card>
      <CardBody>
        {BarChartComponent ? (
          <BarChartComponent data={data} colors={colors} keyset={keySet} />
        ) : (
          <BarChart data={data} keySet={keySet} colors={colors} />
        )}
      </CardBody>
      <CardFooter>
        <GraphOptions
          id={`${id}-left`}
          selected={selected}
          otherSelected={otherSelected}
          options={options}
          handleSelection={handleSelection}
        />
        <GraphOptions
          id={`${id}-right`}
          selected={otherSelected}
          otherSelected={selected}
          options={options}
          handleSelection={handleSelection}
        />
      </CardFooter>
    </Card>
  );
};

const TableCard = ({
  id,
  selected,
  otherSelected,
  options,
  handleSelection,
  data,
  keySet,
  colors,
  barChartComponent: BarChartComponent,
}) => {
  return (
    <Card>
      <CardBody>
        {/* {BarChartComponent ? (
          <BarChartComponent data={data} colors={colors} keyset={keySet} />
        ) : (
          <BarChart data={data} keySet={keySet} colors={colors} />
        )} */}

        <TableContainer
            data={data}
            keySet={keySet}
            id={id}
          />

      </CardBody>
      <CardFooter>
        <GraphOptions
          id={`${id}-left`}
          selected={selected}
          otherSelected={otherSelected}
          options={options}
          handleSelection={handleSelection}
        />
        <GraphOptions
          id={`${id}-right`}
          selected={otherSelected}
          otherSelected={selected}
          options={options}
          handleSelection={handleSelection}
        />
      </CardFooter>
    </Card>
  );
};

class ResourceDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dist: [],
      colors: colors,
      options: [...options],
      firstBarLeft: "project_name",
      firstBarRight: "billable_type",
      firstBarData: [],
      firstBarKeySet: [],

      secondBarLeft: "stream_cd",
      secondBarRight: "project_type",
      secondBarCountBy: "billable",
      secondBarData: [],
      secondBarKeySet1: [],
      secondBarKeySet2: [],
      secondBarKeySet: [],
    };
  }

  handleSelection = (e) => {
    const item = e.currentTarget;
    const id = item.getAttribute("data-id");
    const value = item.getAttribute("data-key");
    if (id === "first-left") {
      this.setState(
        {
          firstBarLeft: value,
        },
        () => this.updateResourceGraphs()
      );
    } else if (id === "first-right") {
      this.setState(
        {
          firstBarRight: value,
        },
        () => this.updateResourceGraphs()
      );
    } else if (id === "second-left") {
      this.setState(
        {
          secondBarLeft: value,
        },
        () => this.updateResourceGraphs()
      );
    } else {
      this.setState(
        {
          secondBarRight: value,
        },
        () => this.updateResourceGraphs()
      );
    }
  };

  // groups the objectArray by property
  groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  // converts an object into array
  // {key: count} is converted into
  // [{name: 'key', value: count}]
  convertObjectToArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      name: key,
      value: obj[key],
    }));
  };

  // counts the objectArray based on property
  countBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (key in acc) {
        acc[key]++;
      } else {
        acc[key] = 1;
      }
      return acc;
    }, {});
  };

  updateResourceGraphs = () => {
    const { dist, firstBarLeft, firstBarRight } = this.state;
    const grouped = this.groupBy(dist, firstBarLeft);
    const result = [];
    const keySet = new Set();
    Object.keys(grouped).map((key) => {
      const groupedCount = this.countBy(grouped[key], firstBarRight);
      Object.keys(groupedCount).map((key) => keySet.add(key));
      result.push({
        name: key,
        ...groupedCount,
      });
    });
    this.setState({
      firstBarData: result,
      firstBarKeySet: Array.from(keySet),
    });

    const { secondBarLeft, secondBarRight, secondBarCountBy } = this.state;
    const groupedSecondLeft = this.groupBy(dist, secondBarLeft);
    const result2 = [];
    const keySet1 = new Set();
    const keySet2 = new Set();
    const keySetSecond = new Set();
    Object.keys(groupedSecondLeft).map((key) => {
      keySet1.add(key);
      let groupedSecondRight = this.groupBy(
        groupedSecondLeft[key],
        secondBarRight
      );
      Object.keys(groupedSecondRight).map((key2) => {
        keySet2.add(key2);
        let groupedCount = this.countBy(
          groupedSecondRight[key2],
          secondBarCountBy
        );
        Object.keys(groupedCount).map((key) => keySetSecond.add(key));
        result2.push({
          nameGroup: key,
          name: key2,
          ...groupedCount,
        });
      });
    });
    this.setState({
      secondBarData: result2,
      secondBarKeySet1: Array.from(keySet1),
      secondBarKeySet2: Array.from(keySet2),
      secondBarKeySet: Array.from(keySetSecond),
    });
  };

  componentDidMount() {
    getResourceDist().then((res) => {
      res.map((resource) => {
        if (resource['billable'] === 'Y') {
          resource['billable'] = 'Billable';
        } else {
          resource['billable'] = 'Non-Billable';
        }
      })
      this.setState({ dist: res }, () => this.updateResourceGraphs())
    });
  }



  render() {
    const {
      options,
      firstBarLeft,
      firstBarRight,
      firstBarData,
      firstBarKeySet,
      colors,
      secondBarLeft,
      secondBarRight,
      secondBarData,
      secondBarKeySet,
    } = this.state;

    const { selection, setSelection } = this.props;

    return (
      <div className="d-flex flex-column">
        {/* <h2>Resource Distribution</h2> */}
        <div className="d-flex flex-row justify-content-between">
                <h2>Resource Distribution</h2>
                {/* <ToggleButton
                  selection={this.props.toggleSelection}
                  setSelection={this.props.setSelection}
                /> */}
                <ToggleButton selection={selection} setSelection={setSelection} />
        </div>
        <div>
          {/* <div className="row">
            <div className={firstBarData.length > 5 ? "col-md-12 mb-2" : "col-md-6"}>
              <GraphCard
                id="first"
                selected={firstBarLeft}
                otherSelected={firstBarRight}
                data={firstBarData}
                keySet={firstBarKeySet}
                colors={colors}
                options={options}
                handleSelection={this.handleSelection}
              />
            </div>
            <div className={secondBarData.length > 5 ? "col-md-12 mt-2" : "col-md-6"}>
              <GraphCard
                id="second"
                selected={secondBarLeft}
                otherSelected={secondBarRight}
                data={secondBarData}
                keySet={secondBarKeySet}
                colors={colors}
                options={options}
                handleSelection={this.handleSelection}
                barChartComponent={BarChartMultipleXAxis}
              />
            </div>
          </div> */}

            <div className="row">
            <div className={firstBarData.length > 5 ? "col-md-12 mb-2" : "col-md-6"}>
              <GraphCard
                id="first"
                selected={firstBarLeft}
                otherSelected={firstBarRight}
                data={firstBarData}
                keySet={firstBarKeySet}
                colors={colors}
                options={options}
                handleSelection={this.handleSelection}
              />
            </div>
            <div className={firstBarData.length > 5 ? "col-md-12 mt-2" : "col-md-6"}>
            <TableCard
                id="first"
                selected={firstBarLeft}
                otherSelected={firstBarRight}
                data={firstBarData}
                keySet={firstBarKeySet}
                colors={colors}
                options={options}
                handleSelection={this.handleSelection}
              />
            </div>
          </div>

          {/* second row  */}
          <br/>
          <div className="row">
           
            <div className={secondBarData.length > 5 ? "col-md-12 mt-2" : "col-md-6"}>
              <GraphCard
                id="second"
                selected={secondBarLeft}
                otherSelected={secondBarRight}
                data={secondBarData}
                keySet={secondBarKeySet}
                colors={colors}
                options={options}
                handleSelection={this.handleSelection}
                barChartComponent={BarChartMultipleXAxis}
              />
            </div>

            <div className={secondBarData.length > 5 ? "col-md-12 mb-2" : "col-md-6"}>
            <TableCard
                id="second"
                selected={secondBarLeft}
                otherSelected={secondBarRight}
                data={secondBarData}
                keySet={secondBarKeySet}
                colors={colors}
                options={options}
                handleSelection={this.handleSelection}
                barChartComponent={BarChartMultipleXAxis}
              />
            </div>

          </div>


        </div>
      </div>
    );
  }
}

export default ResourceDistribution;

// TODO: refactor this function
const getOptionName = (key) => {
  switch (key) {
    case "project_name":
      return "Project Name";
    case "resource_name":
      return "Resource Name";
    case "stream_cd":
      return "Stream";
    case "client_name":
      return "Client";
    case "client_type_name":
      return "Client Type";
    case "state_project":
      return "State";
    case "project_type":
      return "Project Type";
    case "project_category":
      return "Project Category";
    case "billable_type":
      return "Billable Type";
    case "billable":
      return "Billable";
    case "billability":
      return "Billability";
    default:
      return null;
  }
};

const options = [
  "project_name",
  // exclude resource_name for now
  // "resource_name",
  "project_type",
  "project_category",
  "state_project",
  "stream_cd",
  "client_name",
  "client_type_name",
  "billable_type",
  "billable",
  "billability",
];

const colors = [
  "#80add7",
  "#c0334d",
  "#00743f",
  "#93a806",
  "#d8d583",
  "#0294a5",
  "#665191",
  "#6a8a82",
  "#a37c27",
  "#563838",
  "#0444bf",
  "#a79674",
  "#f05837",
  "#aba6bf",
  "#bf988f",
  "#192e5b",
  "#f2a104",
  "#888c46",
  "#0abda0",
];