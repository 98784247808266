import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';


export const listPhases = (cancelToken) => {
  const token = getUserToken()
  return axios
    .get('/phases/listPhases', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const activePhases = (cancelToken) => {
  const token = getUserToken()
  return axios
    .get('/phases/activePhases', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createPhases = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id // TODO: big todo: find some users or create new ones
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/phases/createPhases', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deletePhases = (phasesId) => {
  // TODO: manage this token at a central store
  const token = getUserToken()
  return axios
    .delete(`/phases/deletePhases/${phasesId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while deleting phase with id ${phasesId}`, error)
    })
}

export const updatePhases = (phasesId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/phases/updatePhases/${phasesId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating phase): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while updating phase with id ${phasesId}`, error)
    })
}

export const updateApproved = (phasesId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  // TODO: do we need to changed the updated_by field as well here ?
  rowData['approved_by'] = id // TODO: big todo: find some users or create new ones
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/phases/updateApproved/${phasesId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of phase): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating phase's approved status with id ${phasesId}`,
        error,
      )
    })
}

export const updateStatus = (phasesId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/phases/updateStatus/${phasesId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of phase): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating phase's status with Id ${phasesId}`,
        error,
      )
    })
}

export const getDetail = (phasesId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/phases/getDetail/${phasesId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting detail of phase): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting phase's detail with Id ${phasesId}`,
        error,
      )
    })
}

export const listProjectPhase = (projectId, cancelToken) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/projectphase/listPhase/${projectId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of project phase): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting phase's detail with Id ${projectId}`,
        error,
      )
    })
}

export const getProjectPhaseDetail = (projectId, cancelToken) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/projectphase/getDetail/${projectId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of project phase): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting phase's detail with Id ${projectId}`,
        error,
      )
    })
}
