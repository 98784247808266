import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const getAllStatusRaid = (project_id, status_id, cancelToken) => {
  const token = getUserToken();
  return axios
    .get(`/sprint/status/raid/${project_id}/${status_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log("Get sprint status raid ", response);
      return response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)){
        console.log("Cancelling request from sprint status raid");
      } else {
        console.log("Error get sprint status raid", error);
      }
    });
};

export const createStatus = (fields) => {
  const token = getUserToken();
  const id = getUserId();
  fields["created_by"] = id;
  fields["created_dt"] = new Date().toISOString();
  return axios
    .post("/sprint/status/create", fields, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Create sprint status", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error create sprint status", error);
    });
};

export const createRaid = (fields) => {
  const token = getUserToken();
  const id = getUserId();
  fields["created_by"] = id;
  fields["created_dt"] = new Date().toISOString();
  return axios
    .post("/sprint/raid/create", fields, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Create sprint raid", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error create sprint raid", error);
    });
};

export const updateRaid = (raid_id, fields, sprintId) => {
  const token = getUserToken();
  const id = getUserId();
  fields["updated_by"] = id;
  fields["updated_dt"] = new Date().toISOString();
  if (fields["status"] === "I") fields["closed_sprint_id"] = sprintId;
  return axios
    .put(`/sprint/raid/update/${raid_id}`, fields, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Update sprint raid", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error update sprint raid", error);
    });
};

export const updateStatus = (status_id, fields) => {
  const token = getUserToken();
  const id = getUserId();
  fields["updated_by"] = id;
  fields["updated_dt"] = new Date().toISOString();
  return axios
    .put(`/sprint/status/update/${status_id}`, fields, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Update sprint status", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error update sprint status", error);
    });
};

export const deleteRaid = (raid_id) => {
  const token = getUserToken();
  return axios
    .delete(`/sprint/raid/delete/${raid_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Delete sprint raid", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error delete sprint raid`, error);
    });
};

export const deleteStatus = (status_id) => {
  const token = getUserToken();
  return axios
    .delete(`/sprint/status/delete/${status_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Delete sprint status", response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error delete sprint status`, error);
    });
};

export const getAllSprintRaids = () => {
  const token = getUserToken();
  return axios
    .get("/sprint/raid/list", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Get all sprint raids", response);
      return response.data;
    })
    .catch((error) => {
      console.log("Error getting all sprint raids", error);
    });
};
