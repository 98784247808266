import React from 'react';
import GenericBarChart from '../../../components/Charts/GenericBarChart';

class BarChartContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <GenericBarChart
        data={this.props.data}
        dataKey="name"
        keyset={this.props.keySet}
        colors={this.props.colors}
      />
    )
  }
}

export default BarChartContainer;
