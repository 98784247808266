import React, {useEffect, useState} from "react"
import {Container, TabContent, TabPane} from "reactstrap";
import RoleTable from "./RoleTable";
import RoleForm from "./RoleForm";
import {getPermissionsFor} from "../../actions/UtilFunctions";
import {
  addMenu,
  addPermission,
  createRole,
  deleteRole,
  getDetail, getMenuByRole, getPermissionByRole, getUnassignedMenuByRole, getUnassignedPermissionByRole,
  listRole,
  updateApproved,
  updateRole,
  updateStatus
} from "../../actions/RoleFunctions";
import ActionFeedback from "../../components/ActionFeedbackAlert";
import {confirmAlert} from "react-confirm-alert";
import RolePermission from "./RolePermission";
import RoleMenu from "./RoleMenu";

const RoleContainer = () => {

  const fields = {
    role_name: ''
  }

  const [initialValues, setInitialValues] = useState({})
  const [roleList, setRoleList] = useState([])

  const [selectedRole, setSelectedRole] = useState({})

  const [assignedPermissions, setAssignedPermissions] = useState([])
  const [unassignedPermissions, setUnassignedPermissions] = useState([])

  const [assignedMenus, setAssignedMenus] = useState([])
  const [unassignedMenus, setUnassignedMenus] = useState([])

  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [activeTab, setActiveTab] = useState('list')
  const [actionFeedbackMsg, setActionFeedbackMsg] = useState('')

  const [allowedPermissions] = useState(getPermissionsFor('role'))

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        await refreshProject()
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }) ()
  }, [])

  const refreshProject = async () => {
    const roles = await listRole()
    if (roles) {
      setRoleList(roles)
    }
    setSelectedRole({})
  }

  const changeTab = tabName => setActiveTab(tabName)

  const populateFormData = async rowData => {
    const roleId = rowData.role_id
    try {
      if (roleId > 0) {
        setLoading(true)
        const detail = (await getDetail(roleId))[0]
        setInitialValues(detail)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = async rowData => {
    setEditMode(true)
    setActiveTab('add')
    await populateFormData(rowData)
  }

  const handleDelete = rowData => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const roleId = rowData.role_id
            const roleListData = roleList.filter(role => role['role_id'] !== roleId)
            setRoleList(roleListData)
            deleteRole(roleId).then(response => {
              console.log(
                `Role successfully deleted with role id: ${roleId} `,
                response,
              )
              if (response) {
                showActionFeedback(response.message)
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const handleStatus = rowData => {
    const roleId = rowData.role_id
    const status = rowData['status']
    rowData['status'] = status === 'A' ? 'I' : 'A'
    updateStatus(roleId, rowData).then(response => {
      console.log(
        `Status successfully updated for permission with perm id: ${roleId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })
  }

  const handleApproved = rowData => {
    const roleId = rowData.role_id
    const isApproved = rowData['approved']
    rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
    updateApproved(roleId, rowData).then(response => {
      console.log(
        `Permission successfully approved with permId: ${roleId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })
  }

  const handlePermissionsUpdate = selectedPermissions => {
    const roleId = selectedRole.roleId
    const rolePermMap = []
    selectedPermissions.map(perm => {
      rolePermMap.push({
        'role_id': roleId,
        'perm_id': perm.perm_id,
        'status': 'A',
        'approved': 'Y'
      })
    })
    addPermission({
      'role_id': roleId,
      'RolePermission': rolePermMap
    }).then(response => {
      if (response) {
        showActionFeedback(response.message)
      }
    })
  }

  const handleMenusUpdate = selectedMenus => {
    const roleId = selectedRole.roleId
    const roleMenuMap = []
    selectedMenus.map(menu => {
      roleMenuMap.push({
        'role_id': roleId,
        'menu_id': menu.menu_id,
        'status': 'A',
        'approved': 'Y',
      })
    })
    addMenu({
      'role_id': roleId,
      'RoleMenu': roleMenuMap,
    }).then(response => {
      if (response) {
        showActionFeedback(response.message)
      }
    })
  }

  const handlePermissions = async rowData => {
    const roleId = rowData.role_id
    changeTab('permission')
    setSelectedRole({
      roleId,
      roleName: rowData.role_name
    })
    getPermissionByRole(roleId).then(response => setAssignedPermissions(response))
    getUnassignedPermissionByRole(roleId).then(response => setUnassignedPermissions(response))
  }

  const handleMenus = rowData => {
    const roleId = rowData.role_id
    changeTab('menu')
    setSelectedRole({
      roleId,
      roleName: rowData.role_name
    })
    getMenuByRole(roleId).then(response => setAssignedMenus(response))
    getUnassignedMenuByRole(roleId).then(response => setUnassignedMenus(response))
  }

  const onStatusIconClick = e => {
    e.preventDefault()
    const roleData = e.currentTarget.getAttribute('data-config')
    handleStatus(JSON.parse(roleData))
  }

  const onApprovedIconClick = e => {
    e.preventDefault()
    const roleData = e.currentTarget.getAttribute('data-config')
    handleApproved(JSON.parse(roleData))
  }

  const onPermissionIconClick = e => {
    e.preventDefault()
    const roleData = e.currentTarget.getAttribute('data-config')
    handlePermissions(JSON.parse(roleData))
  }

  const onMenuIconClick = e => {
    e.preventDefault()
    const roleData = e.currentTarget.getAttribute('data-config')
    handleMenus(JSON.parse(roleData))
  }

  const handleSubmit = values => {
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let response

            if (editMode) {
              if (values?.role_id && values?.role_id > 0) {
                for (const key of Object.keys(fields)) {
                  fields[key] = values[key]
                }
                response = await updateRole(values.role_id, fields)
              }
            } else {
              response = await createRole(values)
            }

            if (response) {
              showActionFeedback(response.message)
              resetForm()
              changeTab('list')
              refreshProject()
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const isActionAllowed = action => {
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true
    }
    return false
  }

  const showActionFeedback = message => {
    setActionFeedbackMsg(message)
    setTimeout(() => setActionFeedbackMsg(''), 5000)
  }

  const resetForm = () => {
    setInitialValues({})
    setEditMode(false)
  }

  return <div className="ipp-page">
    {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}
    <Container className="mt-3" fluid style={{maxWidth: '100%', margin: 'auto'}}>
      <TabContent activeTab={activeTab}>

        <TabPane tabId="list">
          <RoleTable
            roleListData={roleList}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onStatusIconClick={onStatusIconClick}
            onApprovedIconClick={onApprovedIconClick}
            onPermissionIconClick={onPermissionIconClick}
            onMenuIconClick={onMenuIconClick}
            isLoading={loading}
            isActionAllowed={isActionAllowed}
            changeTab={changeTab}
          />
        </TabPane>

        <TabPane tabId="add">
          <RoleForm
            initValues={initialValues}
            handleSubmit={handleSubmit}
            isLoading={loading}
            changeTab={changeTab}
            resetForm={resetForm}
          />
        </TabPane>

        <TabPane tabId="permission">
          <RolePermission
            role={selectedRole}
            assignedPermissions={assignedPermissions}
            unassignedPermissions={unassignedPermissions}
            handlePermissionsUpdate={handlePermissionsUpdate}
            changeTab={changeTab}
          />
        </TabPane>

        <TabPane tabId="menu">
          <RoleMenu
            role={selectedRole}
            assignedMenus={assignedMenus}
            unassignedMenus={unassignedMenus}
            handleMenusUpdate={handleMenusUpdate}
            changeTab={changeTab}
          />
        </TabPane>

      </TabContent>
    </Container>
  </div>
}

export default RoleContainer