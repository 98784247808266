import pptxgen from "pptxgenjs";
import { LIS_LOGO, RETAIL } from "components/WeeklyReport/images";
import moment from "moment";
import domtoimage from "dom-to-image";

export function getImageDimensions(file) {
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved({ w: i.width, h: i.height });
    };
    i.src = file;
  });
}

export function getImageHeight(height) {
  const imgHeight = Math.floor(height/750 * 92);
  if (imgHeight >= 90){
    return "85%";
  } else {
    return `${imgHeight}%`;
  }
}

export async function ExportProjectReport({ sprintSlide, resourceSlide, statusSlide, raidSlide, projectCategory, projectName }) {
  console.log(sprintSlide, resourceSlide, statusSlide, raidSlide, projectCategory, projectName);
  console.log(await domtoimage.toPng(resourceSlide.current));
  Promise.allSettled([
    await domtoimage.toPng(sprintSlide.current),
    await domtoimage.toPng(resourceSlide.current),
    await domtoimage.toPng(statusSlide.current),
    await domtoimage.toPng(raidSlide.current),
    // await domtoimage.toPng(issueSlide.current),
  ]).then(
    ([
      sprintImgPro,
      resourceImgPro,
      statusImgPro,
      raidImgPro,
      // issueImgPro,
    ]) => {
      console.log("Next", sprintImgPro,
        resourceImgPro,
        statusImgPro,
        raidImgPro,)
      const sprintSvgDataUrl = sprintImgPro.value;
      const resourceSvgDataUrl = resourceImgPro.value;
      const statusSvgDataUrl = statusImgPro.value;
      const raidSvgDataUrl = raidImgPro.value;
      // const issueSvgDataUrl = issueImgPro.value;

      Promise.allSettled([
        getImageDimensions(sprintSvgDataUrl),
        getImageDimensions(resourceSvgDataUrl),
        getImageDimensions(statusSvgDataUrl),
        getImageDimensions(raidSvgDataUrl),
        // getImageDimensions(issueSvgDataUrl),
      ]).then(
        ([
          sprintImgDims,
          resourceImgDims,
          statusImgDims,
          raidImgDims,
          // issueImgDims,
        ]) => {
          const currentDate = moment().clone().format("DD/MMM/YYYY");

          const pres = new pptxgen();
          const slideNumberProps = {
            x: "94%",
            y: "92%",
            color: "#818181",
            fontFace: "Calibri",
            fontSize: 12,
          };
          const projectMeta = `${projectName} | ${projectCategory}`;
          const projectMetaProps = {
            color: "#009999",
            fontFace: "Verdana",
            align: "right",
            x: 2.8,
            y: "10%",
            h: "10%",
            fontSize: 28,
          };
          pres.layout = "LAYOUT_WIDE";

          pres.defineSlideMaster({
            title: "LIS_TEMPLATE",
            background: { color: "#FFFFFF" },
            margin: [0.5, 0.25, 1.0, 1.0],
            objects: [
              { image: { data: LIS_LOGO, x: 0.5, y: 0.1, w: "20%", h: "10%" } },
            ],
          });

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({ data: RETAIL, x: 0.5, y: 1.8, w: "92%", h: "45%" })
            .addText(`Weekly Project Status\n${projectName}\n${currentDate}`, {
              color: "#009999",
              fontSize: 28,
              align: "right",
              y: "80%",
              x: 2.8,
              h: "15%",
              fontFace: "Verdana",
            });

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addText(projectMeta, { ...projectMetaProps })
            .addImage({
              data: sprintSvgDataUrl,
              x: 0.5,
              y: "22%",
              w: "92%",
              h: getImageHeight(sprintImgDims.value.h),
            }).slideNumber = { ...slideNumberProps };

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addText(projectMeta, { ...projectMetaProps })
            .addImage({
              data: resourceSvgDataUrl,
              x: 0.5,
              y: "22%",
              w: "92%",
              h: getImageHeight(resourceImgDims.value.h),
            }).slideNumber = { ...slideNumberProps };

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addText(projectMeta, { ...projectMetaProps })
            .addImage({
              data: statusSvgDataUrl,
              x: 0.5,
              y: "22%",
              w: "92%",
              h: getImageHeight(statusImgDims.value.h),
            }).slideNumber = { ...slideNumberProps };

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addText(projectMeta, { ...projectMetaProps })
            .addImage({
              data: raidSvgDataUrl,
              x: 0.5,
              y: "22%",
              w: "92%",
              h: getImageHeight(raidImgDims.value.h),
            }).slideNumber = { ...slideNumberProps };

          pres.writeFile(`${projectName} - Weekly Report - ${currentDate}.pptx`);
        }
      );
      // TODO: currently there are only two project categories
    }
  );
};

export async function exportPMOReport(projectsByClientTypeTableSlide, resourcesByClientTypeTableSlide, projectsHealthTableSlide, projectWorkHoursTableSlide1, projectWorkHoursTableSlide2, riskSlide, issueSlide) {
  Promise.allSettled([
    await domtoimage.toPng(projectsByClientTypeTableSlide.current),
    await domtoimage.toPng(resourcesByClientTypeTableSlide.current),
    await domtoimage.toPng(projectsHealthTableSlide.current),
    await domtoimage.toPng(projectWorkHoursTableSlide1.current),
    await domtoimage.toPng(projectWorkHoursTableSlide2.current),
    await domtoimage.toPng(riskSlide.current),
    await domtoimage.toPng(issueSlide.current)
  ]).then(
    ([
      projectSlidePro,
      resourceSlidePro,
      healthSlidePro,
      projectWorkPro1,
      projectWorkPro2,
      riskSlidePro,
      issueSlidePro
    ]) => {
      const projectSvgDataUrl = projectSlidePro.value;
      const resourceSvgDataUrl = resourceSlidePro.value;
      const healthSvgDataUrl = healthSlidePro.value;
      const projectWorkSvgDataUrl1 = projectWorkPro1.value;
      const projectWorkSvgDataUrl2 = projectWorkPro2.value;
      const riskSvgDataUrl = riskSlidePro.value;
      const issueSvgDataUrl = issueSlidePro.value;

      Promise.allSettled([
        getImageDimensions(projectSvgDataUrl),
        getImageDimensions(resourceSvgDataUrl),
        getImageDimensions(healthSvgDataUrl),
        getImageDimensions(projectWorkSvgDataUrl1),
        getImageDimensions(projectWorkSvgDataUrl2),
        getImageDimensions(riskSvgDataUrl),
        getImageDimensions(issueSvgDataUrl)
      ]).then(
        ([
          projectImgDims,
          resourceImgDims,
          healthImgDims,
          projectWorkImg1Dims ,
          projectWorkImg2Dims ,
          riskImgDims,
          issueImgDims
        ]) => {
          const currentDate = moment().clone().format("DD/MMM/YYYY");

          const pres = new pptxgen();
          const slideNumberProps = {
            x: "94%",
            y: "92%",
            color: "#818181",
            fontFace: "Calibri",
            fontSize: 12,
          };
          // const projectMeta = `${projectName} | ${projectCategory}`;
          // const projectMetaProps = {
          //   color: "#009999",
          //   fontFace: "Verdana",
          //   align: "right",
          //   x: 2.8,
          //   y: "10%",
          //   h: "10%",
          //   fontSize: 28,
          // };
          pres.layout = "LAYOUT_WIDE";

          pres.defineSlideMaster({
            title: "LIS_TEMPLATE",
            background: { color: "#FFFFFF" },
            margin: [0.5, 0.25, 1.0, 1.0],
            objects: [
              { image: { data: LIS_LOGO, x: 0.5, y: 0.1, w: "20%", h: "10%" } },
            ],
          });

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({
              data: projectSvgDataUrl,
              x: 0.5,
              y: "12%",
              w: "96%",
              h: getImageHeight(projectImgDims.value.h),
            }).slideNumber = { ...slideNumberProps };

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({
              data: resourceSvgDataUrl,
              x: 0.5,
              y: "12%",
              w: "96%",
              h: getImageHeight(resourceImgDims.value.h),
            }).slideNumber = { ...slideNumberProps };

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({
              data: healthSvgDataUrl,
              x: 0.3,
              y: "12%",
              w: "96%",
              h: getImageHeight(healthImgDims.value.h),
            }).slideNumber = { ...slideNumberProps };

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({
              data: projectWorkSvgDataUrl1,
              x: 0.5,
              y: "12%",
              w: "96%",
              h: getImageHeight(projectWorkImg1Dims.value.h),
          }).slideNumber = { ...slideNumberProps };
          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({
              data: projectWorkSvgDataUrl2,
              x: 0.5,
              y: "12%",
              w: "96%",
              h: getImageHeight(projectWorkImg2Dims.value.h),
          }).slideNumber = { ...slideNumberProps };
          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({
            data: riskSvgDataUrl,
            x: 0.5,
            y: "12%",
            w: "96%",
            h: getImageHeight(riskImgDims.value.h),
          }).slideNumber = { ...slideNumberProps };

          pres
            .addSlide({ masterName: "LIS_TEMPLATE" })
            .addImage({
            data: issueSvgDataUrl,
            x: 0.5,
            y: "12%",
            w: "96%",
            h: getImageHeight(issueImgDims.value.h),
          }).slideNumber = { ...slideNumberProps };

          pres.writeFile(`PMO Executive Report - ${currentDate}.pptx`);
        }
      );
      // TODO: currently there are only two project categories
    }
  );
};