import React from "react"
import {IconButton, MuiThemeProvider, Tooltip} from "@material-ui/core"
import {theme} from "../../utils/Theme"
import MaterialTable from "material-table"
import _ from "lodash"
import {Cancel, CheckCircle} from "@material-ui/icons"
import Loading from "../../components/Loading"
import {Button} from "reactstrap"
import StreamDetailList from "../../components/StreamDetailList"

const StreamInfoTable = ({
  streamListData,
  handleEdit,
  handleDelete,
  onStatusIconClick,
  onApprovedIconClick,
  isLoading,
  isActionAllowed,
  changeTab
}) => {

  let streamList = _.cloneDeep(streamListData)
  streamList = streamList.map(row => {
    const rowString = JSON.stringify(row)

    row['status_icon'] = <IconButton onClick={onStatusIconClick} data-stream={rowString} disableFocusRipple={!isActionAllowed('status')}>
      {row['status'] === 'I' ? (
        <Tooltip title="Inactive">
          <Cancel style={{color: 'red'}} />
        </Tooltip>
      ) : (
        <Tooltip title="Active">
          <CheckCircle style={{color: 'green'}} />
        </Tooltip>
      )}
    </IconButton>

    row['approved_icon'] = <IconButton onClick={onApprovedIconClick} data-stream={rowString} disableFocusRipple={!isActionAllowed('status')}>
      {row['approved'] === 'N' ? (
        <Tooltip title="Unapproved">
          <Cancel style={{color: 'red'}} />
        </Tooltip>
      ) : (
        <Tooltip title="Approved">
          <CheckCircle style={{color: 'green'}} />
        </Tooltip>
      )}
    </IconButton>

    return row
  })

  const cellStyle = {
    paddingTop: '0px',
    paddingBottom: '0px',
  }

  const columns = [
    { title: 'Stream code', field: 'stream_cd', cellStyle: cellStyle },
    { title: 'Stream Name', field: 'stream_name', cellStyle: cellStyle },
    { title: 'Status', field: 'status_icon', cellStyle: cellStyle },
    { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle },
  ]

  return <MuiThemeProvider theme={theme}>
    <MaterialTable
      components={{
        OverlayLoading: props => <Loading />
      }}
      localization={{
        body: {
          emptyDataSourceMessage: !isLoading ? 'No records to display!' : ''
        }
      }}
      isLoading={isLoading}
      title={
        <div style={{width: '500px'}}>
          <Button
            type="button"
            title="Add new Menu"
            color="primary"
            style={{
              margin: '0px 10px 5px 0px',
              borderRadius: '50%',
              padding: '0px',
              width: '30px',
              height: '30px',
              float: 'left',
            }}
            onClick={() => {
              changeTab('add')
            }}
            hidden={!isActionAllowed('add')}
          >
            <i className="fas fa-plus fa-sm"/>
          </Button>
          <h2 style={{color: '#00948B'}}>Stream List</h2>
        </div>
      }
      columns={columns}
      data={streamList}
      options={{
        headerStyle: {
          color: '#00948B',
          fontWeight: 'bold',
          paddingTop: '0px',
          paddingBottom: '5px',
        },
        rowStyle: {
          hover: true,
        },
        actionsColumnIndex: -1,
        detailPanelColumnAlignment: 'right'
      }}
      actions={[
        {
          icon: 'edit',
          tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
          disabled: !isActionAllowed('edit'),
          onClick: (event, rowData) => {handleEdit(rowData)}
        },
        {
          icon: 'delete',
          tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
          disabled: !isActionAllowed('delete'),
          onClick: (event, rowData) => {handleDelete(rowData)}
        }
      ]}
      detailPanel={[
        {
          icon: 'toc',
          disabled: !isActionAllowed('detail'),
          render: rowData => {
            const streamId = rowData['stream_id']
            return <StreamDetailList streamId={streamId} />
          }
        }
      ]}
    />
  </MuiThemeProvider>
}

export default StreamInfoTable