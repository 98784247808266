import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'

const Toaster = (props) => {
  return (
    <div className={props.className}>
      <FaExclamationTriangle />
      <span>{props.className}</span>:&nbsp;{props.errorMessage}
    </div>
  )
}

export default Toaster
