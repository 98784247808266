import React, { Component } from "react";
import axios from "axios";
import {
  createProject,
  fetchAllStream,
  fetchAllEmployee,
  fetchAllProject,
  fetchAllProjectRelatedData,
  updateProject,
  fetchSelectedProject,
  fetchProjectrisks,
  fetchProjectassumption,
  fetchProjectissues,
  fetchProjectdependency,
  updateApproved,
  updateStatus,
  deleteProject,
  getProjectDetail,
} from "../../actions/ProjectFunctions";
import { fetchAllClient } from "../../actions/ClientFunctions";
import { listProjectModel } from "../../actions/ProjectModelFunctions";
import { listClientTypes } from "../../actions/ClientTypeFunctions";
import ProjectInformation from "../../components/ProjectInformation";
import _ from "lodash";
import ProjectInfoCard from "../../components/InfoCard";
import { Link } from "react-router-dom";
import { getPermissionsFor } from "../../actions/UtilFunctions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

//style
const cellStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  // paddingRight: 0,
  // paddingLeft: 0,
  // fontSize: 'inherit',
};

export default class ProjectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_list: [
        { id: "I", name: "Internal" },
        { id: "E", name: "External" },
      ],

      category_list: [
        { id: "D", name: "Development" },
        { id: "S", name: "Support" },
      ],

      state_list: [
        { id: "H", name: "Halted" },
        { id: "I", name: "In Progress" },
        { id: "C", name: "Closed" },
      ],
      task_plan: [
        { id: "W", name: "Weekly" },
        { id: "F", name: "Fortnightly" },
        { id: "M", name: "Monthly" },
      ],
      project_model_list: [],
      client_list: [],
      employee_list: [],
      stream_list: [],

      clientTypeMap: {},
      clientMap: {},

      //state for projectinfo form
      fields: {
        project_cd: "",
        project_name: "",
        project_abbr: "",
        project_type: "",
        stream_id: "",
        lead_id: "",
        client_id: "",
        project_st_dt: "",
        project_end_dt: "",
        model_id: "",
        capacity_hrs: "",
        capacity_points: "",
        work_plan: "",
        work_days: "",
        project_category: "",
        remarks: "",
        status: "I",
        approved: "N",
        state_project: "",
        state_date: "",
        state_remarks: "",
        model_track: "",
        client_type_id: "",
      },

      request: {
        Projectinfo: "",
        Risk: [],
        Assumption: [],
        Issue: [],
        Dependency: [],
      },

      //states for project list column

      project_list_columns: [
        {
          title: "Code",
          field: "project_cd",
          cellStyle: cellStyle,
        },
        {
          title: "Name",
          field: "project_name",
          cellStyle: cellStyle,
          headerStyle: {
            width: 200,
            minWidth: 200,
          },
          render: (rowData) => (
            <Link to={"/project/" + rowData.project_id}>
              {rowData.project_name}{" "}
            </Link>
          ),
        },
        {
          title: "Type",
          field: "project_type",
          cellStyle: cellStyle,
        },
        {
          title: "Stream",
          field: "stream_id",
          cellStyle: cellStyle,
        },
        {
          title: "Category",
          field: "project_category",
          cellStyle: cellStyle,
        },
        //{
        //  title: 'Start-Date',
        //  field: 'project_st_dt',
        //  type: 'date',
        //},
        //{
        //  title: 'End-Date',
        //  field: 'project_end_dt',
        //  type: 'date',
        //},
        {
          title: "Status",
          field: "status_icon",
          cellStyle: cellStyle,
          filtering: false,
          customFilterAndSearch: (text, rowData) => {
            const searchText = text.toLowerCase().trim();
            const word = rowData.status === "I" ? "inactive" : "active";
            if (word.startsWith(searchText)) {
              return true;
            }
            return false;
          },
        },
        {
          title: "Approved",
          field: "approved_icon",
          cellStyle: cellStyle,
          filtering: false,
          customFilterAndSearch: (text, rowData) => {
            const searchText = text.toLowerCase().trim();
            const word = rowData.approved === "Y" ? "yes" : "no";
            if (word.startsWith(searchText)) {
              return true;
            }
            return false;
          },
        },
      ],
      project_list: [],
      selectedRow: null,
      selectedRowData: "",

      //state for edit mode
      editMode: false,
      currentTab: "list",
      currentId: "",
      actionFeedbackMessage: "",
      isValid: {},

      //state for risk and challenges fields
      risks: [{ id: 0, risk_name: "" }],

      //state for assumption fields
      assumption: [{ id: 0, assumption_name: "" }],

      //state for issues fields
      issues: [{ id: 0, issue_name: "" }],

      //state for dependency fields
      dependency: [{ id: 0, dependency_name: "" }],

      editRow: true,

      //Project modal state
      isModal: false,

      allowedPermissions: getPermissionsFor("project"),
      projectModelNameMap: {},
      isFetchComplete: false,
    };
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Axios request canceled.");
  }

  componentDidMount() {
    // const projectId = this.props.match.params.id
    // const currentState = { ...this.state.fields }
    // for (const key of Object.keys(currentState)) {
    //   currentState[key] = rowData[key]
    // }

    // if (projectId) {
    //   this.populateForm(projectId)

    //   this.setState({
    //     fields: currentState,
    //     currentId: projectId,
    //     editMode: true,
    //     currentTab: 'add',
    //   })
    // }

    /*fetchAllClient()
      .then((response) => {
        let newArray = []
        response.map((item) => {
          newArray = [
            ...newArray,
            { id: item.client_id, name: item.client_name },
          ]
        })
        console.log(newArray)
        this.setState({ client_list: newArray })
      })
      .catch((error) => console.log(error))

    fetchAllStream()
      .then((response) => {
        let newArray = []
        response.map((item) => {
          newArray = [...newArray, { id: item.stream_id, name: item.stream_cd }]
        })
        console.log(newArray)
        this.setState({ stream_list: newArray })
      })
      .catch((error) => console.log(error))

    fetchAllEmployee()
      .then((response) => {
        let newArray = []
        response.map((item) => {
          newArray = [
            ...newArray,
            {
              id: item.employee_id,
              name:
                item.first_name + ' ' + item.middle_name + ' ' + item.last_name,
            },
          ]
        })
        console.log(newArray)
        this.setState({ employee_list: newArray })
      })
      .catch((error) => console.log(error))*/

    this.axiosCancelSource = axios.CancelToken.source();
    listProjectModel().then((response) => {
      const projectModelNameMap = {};
      response.map(
        (model) => (projectModelNameMap[model.model_id] = model.model_name)
      );
      this.setState({
        project_model_list: response,
        projectModelNameMap: projectModelNameMap,
      });
    });
    listClientTypes().then((response) => {
      const clientTypeMap = {};
      response.map((clientType) => {
        clientTypeMap[clientType.client_type_id] = clientType;
      });
      this.setState({ clientTypeMap: clientTypeMap });
    });
    this.refreshProject();
  }

  fetchedClient = (response) => {
    const clientMap = {};
    let newArray = [];
    response.map((item) => {
      newArray = [...newArray, { id: item.client_id, name: item.client_name }];
      clientMap[item.client_id] = item;
    });

    this.setState({
      client_list: newArray,
      clientMap: clientMap,
    });
  };

  fetchedStream = (response) => {
    let newArray = [];
    response.map((item) => {
      newArray = [...newArray, { id: item.stream_id, name: item.stream_cd }];
    });
    console.log(newArray);
    this.setState({ stream_list: newArray });
  };

  fetchedEmployee = (response) => {
    let newArray = [];
    response.map((item) => {
      newArray = [
        ...newArray,
        {
          id: item.employee_id,
          name: item.first_name + " " + item.middle_name + " " + item.last_name,
        },
      ];
    });
    newArray.sort((empA, empB) =>
      empA.name.toLowerCase().localeCompare(empB.name.toLowerCase())
    );
    console.log(newArray);
    this.setState({ employee_list: newArray });
  };

  refreshProject = () => {
    //fetchAllProject()
    fetchAllProjectRelatedData(this.axiosCancelSource.token)
      .then((response) => {
        if (response != undefined) {
          this.fetchedClient(response[0]);
          this.fetchedStream(response[1]);
          this.fetchedEmployee(response[2]);
          const listOfProjects = response[3];
          const sortedListOfProjects = _.sortBy(
            listOfProjects,
            (obj) => obj.order_no
          );

          let newArray = [];
          sortedListOfProjects.map((item) => {
            const type1 = this.state.type_list.find(
              (type2) => type2.id === item.project_type
            ).name;
            const category1 = this.state.category_list.find(
              (category2) => category2.id === item.project_category
            ).name;
            const stream1 = this.state.stream_list.find(
              (stream2) => stream2.id === item.stream_id
            ).name;
            const plan1 = this.state.task_plan.find(
              (plan2) => plan2.id === item.work_plan
            ).name;
            newArray = [
              ...newArray,
              {
                project_id: item.project_id,
                project_cd: item.project_cd,
                project_name: item.project_name,
                project_type: type1,
                stream_id: stream1,
                work_plan: plan1,
                project_category: category1,
                project_st_dt: item.project_st_dt,
                project_end_dt: item.project_end_dt,
                status: item.status,
                approved: item.approved,
                state_project: item.state_project,
              },
            ];
          });
          console.log("project ", newArray);
          this.setState({ project_list: newArray, isFetchComplete: true });
        }
      })
      .catch((error) => console.log(error));
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let fields = this.state.fields;
    fields[name] = value;
    this.setState({ fields });
    this.setState({ [name]: value });
  };

  handleChangeRiskRow = (e) => {
    var idVal = parseInt(e.target.id);
    let risks = this.state.risks;
    let currentRisk = risks[idVal];
    currentRisk.risk_name = e.target.value;
    this.setState({ currentRisk });
    //console.log(this.state.risks)
  };

  handleChangeAssumptionRow = (e) => {
    var idVal = parseInt(e.target.id);
    let assumption = this.state.assumption;
    let currentAssumption = assumption[idVal];
    currentAssumption.assumption_name = e.target.value;
    this.setState({ currentAssumption });
    //console.log(this.state.assumption)
  };

  handleChangeIssueRow = (e) => {
    var idVal = parseInt(e.target.id);
    let issues = this.state.issues;
    let currentIssues = issues[idVal];
    currentIssues.issue_name = e.target.value;
    this.setState({ currentIssues });
    //console.log(this.state.issues)
  };

  handleChangeDependencyRow = (e) => {
    var idVal = parseInt(e.target.id);
    let dependency = this.state.dependency;
    let currentDependency = dependency[idVal];
    currentDependency.dependency_name = e.target.value;
    this.setState({ currentDependency });
    //console.log(this.state.dependency)
  };

  //handleSubmitState of project state submit button

  handleSubmitState = (e) => {
    e.preventDefault();
    console.log("ZZZZZZZZZZZZZZZZZ");
    let request = this.state.request;
    request["Projectinfo"] = this.state.fields;
    this.setState({ request });
    updateProject(this.state.currentId, this.state.request).then((response) => {
      console.log(`Success updating ${this.state.currentId}`, response);
      if (response) {
        this.refreshProject();
        this.showActionFeedback(response.message);
      }
    });
    this.resetState();
    this.changeTab("list");
  };

  handleSubmit = (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Confirm Submission",
      message: "Are you sure you want to submit?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.validateFields();
            if (!this.isFormValid()) {
              this.showActionFeedback("Invalid or missing form data");
              return;
            }
            let request = this.state.request;
            request["Projectinfo"] = this.state.fields;
            request["Risk"] = this.state.risks;
            request["Assumption"] = this.state.assumption;
            request["Issue"] = this.state.issues;
            request["Dependency"] = this.state.dependency;
            this.setState({ request });
            //console.log(this.state.request)
            if (this.state.editMode) {
              updateProject(this.state.currentId, this.state.request).then(
                (response) => {
                  console.log(
                    `Success updating ${this.state.currentId}`,
                    response
                  );
                  if (response) {
                    this.refreshProject();
                    this.showActionFeedback(response.message);
                  }
                }
              );

              this.setState({
                currentId: "",
              });
            } else {
              createProject(this.state.request).then((response) => {
                console.log("Success: ", response);
                if (response) {
                  this.refreshProject();
                  this.showActionFeedback(response.message);
                }
              });
            }

            this.resetState();
            this.changeTab("list");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  showActionFeedback = (message) => {
    this.setState({
      actionFeedbackMessage: message,
    });
    setTimeout(() => this.setState({ actionFeedbackMessage: "" }), 5000);
  };

  handleEdit = (rowData) => {
    const projectId = rowData.project_id;
    // const currentState = { ...this.state.fields }
    // for (const key of Object.keys(currentState)) {
    //   currentState[key] = rowData[key]
    // }
    this.populateForm(projectId);

    this.setState({
      //fields: currentState,
      currentId: projectId,
      editMode: true,
      currentTab: "add",
    });
  };

  handleBlur = (e) => {
    this.validateFields();
  };

  validateFields = () => {
    for (const key of Object.keys(this.state.fields)) {
      const value = this.state.fields[key];
      switch (key) {
        case "project_st_dt":
        case "project_end_dt":
          this.validateDate(key, value);
          break;

        case "work_days":
        case "capacity_hrs":
        case "capacity_points":
          this.validateNonNegative(key, value);
          break;

        default:
          this.validateNonEmpty(key, value);
          break;
      }
    }
  };

  validateDate = (key, value) => {
    const { isValid } = this.state;
    const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    isValid[key] = false;
    if (value && value.match(dateRegex)) {
      isValid[key] = true;
    }
    this.setState({ isValid });
  };

  validateNonNegative = (key, value) => {
    const { isValid } = this.state;
    isValid[key] = false;
    if (Math.sign(parseInt(value)) > 0) {
      isValid[key] = true;
    }
    this.setState({ isValid });
  };

  validateNonEmpty = (key, value) => {
    const { isValid } = this.state;
    if (value === "") {
      isValid[key] = false;
    } else {
      isValid[key] = true;
    }
    this.setState({ isValid });
  };

  isFormValid = () => {
    // TODO: ignore these fields when database table changes
    const notRequiredFields = ["state_date", "state_project", "state_remarks"];
    const { isValid, fields } = this.state;
    notRequiredFields.forEach((field) => delete fields[field]);
    for (const key in fields) {
      if (isValid[key] === false || isValid[key] === undefined) return false;
    }
    return true;
  };

  // isModalFormValid = () => {
  //   // TODO: ignore these fields when database table changes
  //   const {
  //     state_date,
  //     state_project,
  //     state_remarks,
  //     ...rest
  //   } = this.state.fields
  //   const notRequiredFields = Object.keys(rest)
  //   const { isValid, fields } = this.state
  //   notRequiredFields.forEach((field) => delete fields[field])

  //   for (const key in fields) {
  //     if (isValid[key] === false || isValid[key] === undefined) return false
  //   }
  //   return true
  // }

  populateForm = (id) => {
    fetchSelectedProject(id)
      .then((response) => {
        console.log(response);
        const resp = this.stripTimeStamp(response);

        this.setState({ fields: resp });
      })
      .catch((error) => console.log(error));

    fetchProjectrisks(id)
      .then((response) => {
        console.log(response);
        let risklist = [];
        response.map((res) => {
          risklist = [
            ...risklist,
            { id: response.indexOf(res), risk_name: res.risk_name },
          ];
        });
        if (risklist.length == 0) {
          this.setState({ risks: [{ id: 0, risk_name: "" }] });
        } else {
          this.setState({ risks: risklist });
        }
      })
      .catch((error) => console.log(error));

    fetchProjectassumption(id)
      .then((response) => {
        console.log(response);
        let assumptionlist = [];
        response.map((assum) => {
          assumptionlist = [
            ...assumptionlist,
            {
              id: response.indexOf(assum),
              assumption_name: assum.assumption_name,
            },
          ];
        });
        if (assumptionlist.length == 0) {
          this.setState({ assumption: [{ id: 0, assumption_name: "" }] });
        } else {
          this.setState({ assumption: assumptionlist });
        }
      })
      .catch((error) => console.log(error));

    fetchProjectissues(id)
      .then((response) => {
        console.log(response);
        let issueslist = [];
        response.map((iss) => {
          issueslist = [
            ...issueslist,
            { id: response.indexOf(iss), issue_name: iss.issue_name },
          ];
        });
        if (issueslist.length == 0) {
          this.setState({ issues: [{ id: 0, issue_name: "" }] });
        } else {
          this.setState({ issues: issueslist });
        }
      })
      .catch((error) => console.log(error));

    fetchProjectdependency(id)
      .then((response) => {
        console.log(response);
        let dependencylist = [];
        response.map((dep) => {
          dependencylist = [
            ...dependencylist,
            {
              id: response.indexOf(dep),
              dependency_name: dep.dependency_name,
            },
          ];
        });
        if (dependencylist.length == 0) {
          this.setState({ dependency: [{ id: 0, dependency_name: "" }] });
        } else {
          this.setState({ dependency: dependencylist });
        }
      })
      .catch((error) => console.log(error));
  };

  stripTimeStamp = (response) => {
    var startDate = response.project_st_dt
      ? response.project_st_dt.split(" ")[0]
      : null;
    var endDate = response.project_end_dt
      ? response.project_end_dt.split(" ")[0]
      : null;
    var stateDate = response.state_date
      ? response.state_date.split(" ")[0]
      : null;

    response.project_st_dt = startDate;
    response.project_end_dt = endDate;
    response.state_date = stateDate;
    return response;
  };

  handleDelete = (rowData) => {
    //create copy of existing project list
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const projectId = rowData.project_id;
            const projectName = rowData.project_name;
            const project_list = this.state.project_list.filter(
              (project) => project["projectId"] !== projectId
            );
            this.setState({
              project_list: project_list,
            });
            // eslint-disable-next-line no-restricted-globals
            //confirm(`Are you sure you want to delete ${projectName}? `)
            deleteProject(projectId)
              .then((response) => {
                console.log(
                  `Project successfully deleted with project id: ${projectId} `,
                  response
                );
                if (response) {
                  this.showActionFeedback(response.message);
                }
              })
              .catch((error) => console.log(error));

            this.refreshProject();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  resetState = () => {
    this.setState({
      fields: {
        project_cd: "",
        project_name: "",
        project_abbr: "",
        project_type: "",
        stream_id: "",
        lead_id: "",
        client_id: "",
        project_st_dt: "",
        project_end_dt: "",
        capacity_hrs: "",
        capacity_points: "",
        work_plan: "",
        work_days: "",
        project_category: "",
        remarks: "",
        status: "I",
        approved: "N",
        model_id: "",
        model_track: "",
        client_type_id: "",
      },
      request: {
        Projectinfo: "",
        Risk: [],
        Assumption: [],
        Issue: [],
        Dependency: [],
      },
      editMode: false,
      risks: [{ id: 0, risk_name: "" }],
      assumption: [{ id: 0, assumption_name: "" }],
      issues: [{ id: 0, issue_name: "" }],
      dependency: [{ id: 0, dependency_name: "" }],
      editRow: true,
      isModal: false,
      isValid: {},
    });
  };

  changeTab = (tabName) => {
    this.setState({
      currentTab: tabName,
    });
  };

  createProjectType = () => {
    return this.state.type_list.map((data) => (
      <option value={data.id} key={data.id}>
        {data.name}
      </option>
    ));
  };

  createProjectCategory = () => {
    return this.state.category_list.map((data) => (
      <option value={data.id} key={data.id}>
        {data.name}
      </option>
    ));
  };

  createClientList = () => {
    return this.state.client_list.map((data) => (
      <option value={data.id} key={data.id}>
        {data.name}
      </option>
    ));
  };

  createProjectStream = () => {
    return this.state.stream_list.map((data) => (
      <option value={data.id} key={data.id}>
        {data.name}
      </option>
    ));
  };

  createEmployeeList = () => {
    return this.state.employee_list.map((data) => (
      <option value={data.id} key={data.id}>
        {data.name}
      </option>
    ));
  };

  createTaskPlan = () => {
    return this.state.task_plan.map((data) => (
      <option value={data.id} key={data.id}>
        {data.name}
      </option>
    ));
  };

  createProjectModel = () => {
    return this.state.project_model_list.map((data) => (
      <option value={data.model_id} key={data.model_id}>
        {data.model_name}
      </option>
    ));
  };

  addRiskRow = () => {
    var rows = this.state.risks;
    var count = parseInt(rows[rows.length - 1].id) + 1;
    rows.push({ id: count, risk_name: "" });
    this.setState({ risks: rows, editRow: true });
  };

  deleteRiskRow = (row, e) => {
    var array = [...this.state.risks];
    const rowId = row.id;
    console.log(rowId, "is the row to be deleted");
    array.splice(rowId, 1);
    if (array.length == 0) {
      array = [{ id: 0, risk_name: "" }];
    }
    // var count = parseInt(array[array.length - 1].id)
    // if (count > 1) {
    //   rows.pop({ id: count })
    // }
    this.setState({ risks: array, editRow: true });
  };

  addAssumptionRow = () => {
    var rows = this.state.assumption;
    var count = parseInt(rows[rows.length - 1].id) + 1;
    rows.push({ id: count, assumption_name: "" });
    this.setState({ assumption: rows, editRow: true });
  };

  deleteAssumptionRow = (row, e) => {
    var array = [...this.state.assumption];
    const rowId = row.id;
    console.log(rowId, "is the row to be deleted");
    array.splice(rowId, 1);
    if (array.length == 0) {
      array = [{ id: 0, assumption_name: "" }];
    }
    // var count = parseInt(array[array.length - 1].id)
    // if (count > 1) {
    //   rows.pop({ id: count })
    // }
    this.setState({ assumption: array, editRow: true });
  };

  addIssueRow = () => {
    var rows = this.state.issues;
    var count = parseInt(rows[rows.length - 1].id) + 1;
    rows.push({ id: count, issue_name: "" });
    this.setState({ issues: rows, editRow: true });
  };

  deleteIssueRow = (row, e) => {
    var array = [...this.state.issues];
    const rowId = row.id;
    console.log(rowId, "is the row to be deleted");
    array.splice(rowId, 1);
    if (array.length == 0) {
      array = [{ id: 0, issue_name: "" }];
    }
    // var count = parseInt(array[array.length - 1].id)
    // if (count > 1) {
    //   rows.pop({ id: count })
    // }
    this.setState({ issues: array, editRow: true });
  };

  addDependencyRow = () => {
    var rows = this.state.dependency;
    var count = parseInt(rows[rows.length - 1].id) + 1;
    rows.push({ id: count, dependency_name: "" });
    this.setState({ dependency: rows, editRow: true });
  };

  deleteDependencyRow = (row, e) => {
    var array = [...this.state.dependency];
    const rowId = row.id;
    console.log(rowId, "is the row to be deleted");
    array.splice(rowId, 1);
    if (array.length == 0) {
      array = [{ id: 0, dependency_name: "" }];
    }
    // var count = parseInt(array[array.length - 1].id)
    // if (count > 1) {
    //   rows.pop({ id: count })
    // }
    this.setState({ dependency: array, editRow: true });
  };

  onStatusIconClick = (e) => {
    const button = e.currentTarget;
    const project = button.getAttribute("data-project");
    const projectObj = JSON.parse(project);
    const type1 = this.state.type_list.find(
      (type2) => type2.name === projectObj.project_type
    ).id;
    const category1 = this.state.category_list.find(
      (category2) => category2.name === projectObj.project_category
    ).id;
    const stream1 = this.state.stream_list.find(
      (stream2) => stream2.name === projectObj.stream_id
    ).id;
    const plan1 = this.state.task_plan.find(
      (plan2) => plan2.name === projectObj.work_plan
    ).id;

    projectObj.project_type = type1;
    projectObj.project_category = category1;
    projectObj.stream_id = stream1;
    projectObj.work_plan = plan1;

    this.handleStatus(projectObj);
  };

  handleStatus = (rowData) => {
    const projectId = rowData.project_id;
    const status = rowData["status"];
    rowData["status"] = status === "A" ? "I" : "A";
    updateStatus(projectId, rowData).then((response) => {
      console.log(
        `Status successfully updated for project with project id: ${projectId} `,
        response
      );
      if (response) {
        this.refreshProject();
        this.showActionFeedback(response.message);
      }
    });
  };

  onApprovedIconClick = (e) => {
    const button = e.currentTarget;
    const project = button.getAttribute("data-project");
    const projectObj = JSON.parse(project);
    const type1 = this.state.type_list.find(
      (type2) => type2.name === projectObj.project_type
    ).id;
    const category1 = this.state.category_list.find(
      (category2) => category2.name === projectObj.project_category
    ).id;
    const stream1 = this.state.stream_list.find(
      (stream2) => stream2.name === projectObj.stream_id
    ).id;
    const plan1 = this.state.task_plan.find(
      (plan2) => plan2.name === projectObj.work_plan
    ).id;

    projectObj.project_type = type1;
    projectObj.project_category = category1;
    projectObj.stream_id = stream1;
    projectObj.work_plan = plan1;
    this.handleApproved(projectObj);
  };

  handleApproved = (rowData) => {
    const projectId = rowData.project_id;
    const isApproved = rowData["approved"];
    rowData["approved"] = isApproved === "Y" ? "N" : "Y";
    updateApproved(projectId, rowData).then((response) => {
      console.log(
        `Project successfully approved with project id: ${projectId} `,
        response
      );
      if (response) {
        this.refreshProject();
        this.showActionFeedback(response.message);
      }
    });
  };

  handleRowClick = () => {
    this.setState({ selectedRow: true });
  };

  handleProjectStateModal = (rowData) => {
    const selectedRowData = rowData;
    this.populateForm(rowData.project_id);
    this.setState({
      isModal: !this.state.isModal,
      editMode: true,
      currentId: rowData.project_id,
      selectedRowData: selectedRowData,
    });
    console.log(this.state.selectedRowData);
  };

  handleStateChange = (e) => {
    const value = e.target.value;
    const fields = this.state.fields;
    const selectedRowData = this.state.selectedRowData;
    fields["state_project"] = value;
    selectedRowData["state_project"] = value;
    switch (value) {
      case "H" && "I":
        fields["status"] = "A";
        break;
      case "C":
        fields["status"] = "I";
    }
    this.setState({ selectedRowData });
    this.setState({ fields });
    console.log(this.state.selectedRowData);
  };

  isActionAllowed = (action) => {
    const { allowedPermissions } = this.state;
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase()))
        return true;
    }
    return false;
  };

  createClientTypeList = () => {
    const clientTypeMap = this.state.clientTypeMap;
    const client_id = this.state.fields.client_id;
    // if a client is selected assign the client's client type id here
    let selectedClientTypeId = "";
    if (client_id) {
      const client = this.state.clientMap[client_id];
      selectedClientTypeId = client.client_type_id;
      if (selectedClientTypeId !== this.state.fields.client_type_id) {
        // check if previous client type id is same to avoid max depth exceeded error
        const fields = {
          ...this.state.fields,
          client_type_id: selectedClientTypeId,
        };
        this.setState({ fields: fields });
      }
    }
    return Object.keys(clientTypeMap).map((clientTypeId) => (
      <option
        key={`clientType-${clientTypeId}`}
        value={clientTypeId}
        selected={
          selectedClientTypeId ? selectedClientTypeId === clientTypeId : false
        }
      >
        {clientTypeMap[clientTypeId].client_type_abbr}
      </option>
    ));
  };

  render() {
    return (
      <ProjectInformation
        editMode={this.state.editMode}
        type_list={this.state.type_list}
        category_list={this.state.category_list}
        stream_list={this.state.stream_list}
        employee_list={this.state.employee_list}
        fields={this.state.fields}
        client_list={this.state.client_list}
        project_list={this.state.project_list}
        project_list_columns={this.state.project_list_columns}
        risks={this.state.risks}
        assumption={this.state.assumption}
        issues={this.state.issues}
        dependency={this.state.dependency}
        createProjectType={this.createProjectType}
        createProjectCategory={this.createProjectCategory}
        createClientList={this.createClientList}
        createProjectStream={this.createProjectStream}
        createEmployeeList={this.createEmployeeList}
        createTaskPlan={this.createTaskPlan}
        createProjectModel={this.createProjectModel}
        handleSubmit={this.handleSubmit}
        handleSubmitState={this.handleSubmitState}
        handleUserInput={this.handleUserInput}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        changeTab={this.changeTab}
        resetState={this.resetState}
        refreshProject={this.refreshProject}
        populateForm={this.populateForm}
        currentTab={this.state.currentTab}
        rows={this.state.rows}
        addRiskRow={this.addRiskRow}
        addAssumptionRow={this.addAssumptionRow}
        addIssueRow={this.addIssueRow}
        addDependencyRow={this.addDependencyRow}
        deleteRiskRow={this.deleteRiskRow}
        deleteAssumptionRow={this.deleteAssumptionRow}
        deleteIssueRow={this.deleteIssueRow}
        deleteDependencyRow={this.deleteDependencyRow}
        editRow={this.state.editRow}
        //handleSave={this.handleSave}
        handleChangeRiskRow={this.handleChangeRiskRow}
        handleChangeAssumptionRow={this.handleChangeAssumptionRow}
        handleChangeIssueRow={this.handleChangeIssueRow}
        handleChangeDependencyRow={this.handleChangeDependencyRow}
        onStatusIconClick={this.onStatusIconClick}
        onApprovedIconClick={this.onApprovedIconClick}
        message={this.state.actionFeedbackMessage}
        isModal={this.state.isModal}
        handleProjectStateModal={this.handleProjectStateModal}
        handleRowClick={this.handleRowClick}
        selectedRow={this.state.selectedRow}
        state_list={this.state.state_list}
        selectedRowData={this.state.selectedRowData}
        handleStateChange={this.handleStateChange}
        isValid={this.state.isValid}
        handleBlur={this.handleBlur}
        isActionAllowed={this.isActionAllowed}
        isFetchComplete={this.state.isFetchComplete}
        createClientTypeList={this.createClientTypeList}
      />
    );
  }
}
