import React from 'react';
import { ColorStrip } from 'components/ProjectHealth/Table';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Table } from 'reactstrap';
import { Button } from "react-bootstrap";

export const ProjectHealthTable = ({ projectsHealthData }) => {
  return (
    <Table size="sm" bordered responsive style={{ overflow: "hidden" }}>
      <thead>
        <tr>
          <th className="text-left">Project Name</th>
          <th className="text-center">Budget</th>
          <th className="text-center">Infra</th>
          <th className="text-center">Resources</th>
          <th className="text-center">Scope</th>
          <th className="text-center">Schedule</th>
          <th className="text-center">Quality</th>
        </tr>
      </thead>
      <tbody>
        {projectsHealthData.map((projectHealthData) => (
          <tr key={projectHealthData.project_id}>
            <>
              <td className="text-left">{projectHealthData.project_name}</td>
              <td>
                <ColorStrip code={projectHealthData.budget? projectHealthData.budget: "S"} />
              </td>
              <td>
                <ColorStrip code={projectHealthData.infra? projectHealthData.infra: "S"} />
              </td>
              <td>
                <ColorStrip code={projectHealthData.resources? projectHealthData.resources: "S"} />
              </td>
              <td>
                <ColorStrip code={projectHealthData.schedule? projectHealthData.schedule: "S"} />
              </td>
              <td>
                <ColorStrip code={projectHealthData.quality? projectHealthData.quality: "S"} />
              </td>
              <td>
                <ColorStrip code={projectHealthData.scope? projectHealthData.scope: "S"} />
              </td>
            </>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};


export const ReportModal = ({isOpen, toggle, projectsHealthData, generatePMOReport}) => {
  console.log("heere is",projectsHealthData);
  return (
    <div>
      <Modal size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ProjectHealthTable projectsHealthData={projectsHealthData}/>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
          <Button
               variant="primary"
               onClick={generatePMOReport}
             >
               Yes
             </Button>
             <Button
               variant="primary"
               onClick={toggle}
             >
               No
             </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
