import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listRole = () => {
  const token = getUserToken()
  return axios
    .get('/role/listRole', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server(List of roles)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while getting the list of roles', error)
    })
}

export const createRole = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/role/createRole', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Creating role)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while creating role ', error)
    })
}

export const deleteRole = (roleId) => {
  const token = getUserToken()
  return axios
    .delete(`/role/deleteRole/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        `Response from server(Deleting role with id: ${roleId})`,
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(`Error while deleting role with roleId ${roleId}`, error)
    })
}

export const updateRole = (roleId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/role/updateRole/${roleId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating role): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while updating role with roleId ${roleId}`, error)
    })
}

export const updateApproved = (roleId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['approved_by'] = id
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/role/updateApproved/${roleId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of role): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating role's approved status with roleId ${roleId}`,
        error,
      )
    })
}

export const updateStatus = (roleId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/role/updateStatus/${roleId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of role): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating role's status with roleId ${roleId}`,
        error,
      )
    })
}

export const viewRole = (roleId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/role/viewRole/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting a view of role): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while getting a role with roleId ${roleId}`, error)
    })
}

export const getDetail = (roleId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/role/getDetail/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting detail of role): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting role's detail with roleId ${roleId}`,
        error,
      )
    })
}

export const getPermissionByRole = (roleId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/role/getPermissionByRole/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting assigned permissions of role): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting role's permissions with roleId ${roleId}`,
        error,
      )
    })
}

export const getUnassignedPermissionByRole = (roleId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/role/unassignedPermissionByRole/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting unassigned permissions of role): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting role's unassigned permissions with roleId ${roleId}`,
        error,
      )
    })
}

export const addPermission = (rolePermMap) => {
  const id = getUserId()
  const token = getUserToken()
  const rolePermArr = rolePermMap.RolePermission
  rolePermArr.forEach((rolePerm) => (rolePerm['created_by'] = id))
  return axios
    .post(`/role/addPermission`, rolePermMap, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Adding permission to role): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while adding permissions`, error)
    })
}

export const getMenuByRole = (roleId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/role/getMenuByRole/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting assigned menus of role): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting role's menus with roleId ${roleId}`,
        error,
      )
    })
}

export const getUnassignedMenuByRole = (roleId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/role/unassignedMenuByRole/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting unassigned menus of role): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting role's unassigned menus with roleId ${roleId}`,
        error,
      )
    })
}

export const addMenu = (roleMenuMap) => {
  const id = getUserId()
  const token = getUserToken()
  const roleMenuArr = roleMenuMap.RoleMenu
  roleMenuArr.forEach((roleMenu) => (roleMenu['created_by'] = id))
  return axios
    .post(`/role/addMenu`, roleMenuMap, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Adding menus to role): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while adding menus`, error)
    })
}

export const getPermissionByUser = () => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/role/getPermissionByUser/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Getting permissions for user): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(`Error while getting permissions for user`, error)
    })
}