import { listClientTypes } from '../../actions/ClientTypeFunctions';
import { fetchProjectassumption } from 'actions/ProjectFunctions';
import { fetchProjectissues } from 'actions/ProjectFunctions';
import { fetchAllProjectRelatedData } from 'actions/ProjectFunctions';
import { fetchProjectrisks } from 'actions/ProjectFunctions';
import { fetchProjectdependency } from 'actions/ProjectFunctions';
import { fetchSelectedProject } from 'actions/ProjectFunctions';
import { listProjectModel } from 'actions/ProjectModelFunctions';
import Axios from 'axios';

import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import * as Yup from 'yup';
import Header from '../../components/Headers/Header';
import ProjectInfoForm from './ProjectInfoForm';
import ProjectInfoTable from './ProjectInfoTable';
import { confirmAlert } from 'react-confirm-alert';
import ProjectStateModal from 'components/ProjectStateModal';
import { deleteProject } from '../../actions/ProjectFunctions';
import { getPermissionsFor } from 'actions/UtilFunctions';
import _ from 'lodash';
import '../IPPContainer/IPPContainer.scss';
import ActionFeedback from 'components/ActionFeedbackAlert';
import {
  updateProject,
  createProject,
  updateStatus,
  updateApproved,
} from '../../actions/ProjectFunctions';
import ProjectStateForm from './ProjectStateForm';

const ProjectInfoContainer = () => {
  //intial states
  const [initValues, setInitValues] = useState({});

  const [projectModelList, setProjectModelList] = useState([]);
  const [clientTypeList, setClientTypeList] = useState([]);
  const [currentProjectId, setCurrentProjectId] = useState(0);
  const [activeTab, setActiveTab] = useState('list');
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionFeedbackMessage, setActionFeedbackMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [allowedPermissions, setAllowedPermissions] = useState(getPermissionsFor('project'));
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [streamList, setStreamList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState({
    success: false,
    loading: false,
    message: '',
  });
  const [stateModalOpen, setStateModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const state_list = [
    { id: 'H', name: 'Halted' },
    { id: 'I', name: 'In Progress' },
    { id: 'C', name: 'Closed' },
  ];

  const projectTypeList = [
    { id: 'I', name: 'Internal' },
    { id: 'E', name: 'External' },
  ];

  const categoryList = [
    { id: 'D', name: 'Development' },
    { id: 'S', name: 'Support' },
  ];

  const projectStateList = [
    { id: 'H', name: 'Halted' },
    { id: 'I', name: 'In Progress' },
    { id: 'C', name: 'Closed' },
  ];

  const taskPlanList = [
    { id: 'W', name: 'Weekly' },
    { id: 'F', name: 'Fortnightly' },
    { id: 'M', name: 'Monthly' },
  ];

  //useEffects hooks
  const axiosCancelSource = Axios.CancelToken.source();
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const projectModels = await listProjectModel();
        const clientTypes = await listClientTypes();
        await refreshProject();
        //set project modle options
        setProjectModelList(projectModels);
        //set Client type options
        setClientTypeList(clientTypes);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (submitSuccess.success) {
      refreshProject();
      showActionFeedback(submitSuccess.message || 'Success');
      // resetForm();
    }
  }, [submitSuccess]);

  const isActionAllowed = (action) => {
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true;
    }
    return false;
  };

  const refreshProject = async () => {
    setAllowedPermissions(getPermissionsFor('project'))
    try {
      setLoading(true);
      const allProjectData = await fetchAllProjectRelatedData(axiosCancelSource.token);
      if (allProjectData != undefined) {
        const clients = allProjectData[0];
        const streams = allProjectData[1];
        const employees = allProjectData[2];
        const projects = allProjectData[3];

        setClientList(clients);
        setStreamList(streams);
        setEmployeeList(employees);

        const sortedProjects = projects.map((item) => {
          return {
            ...item,
            stream_id: streams.find((stream) => stream.stream_id == item.stream_id).stream_name,
            work_plan: taskPlanList.find((plan) => plan.id == item.work_plan).name,
            project_category: categoryList.find((cat) => cat.id == item.project_category).name,
            project_type: projectTypeList.find((type) => type.id == item.project_type).name,
          };
        });
        setProjectList((prev) => sortedProjects);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const pouplateForm = async (projectId) => {
    try {
      if (projectId > 0) {
        setLoading(true);
        const selecteProjectResponse = await fetchSelectedProject(projectId);
        const risks = await fetchProjectrisks(projectId);
        const assumptions = await fetchProjectassumption(projectId);
        const issues = await fetchProjectissues(projectId);
        const dependency = await fetchProjectdependency(projectId);

        //strip timestamp
        const selectedProject = await stripTimeStamp(selecteProjectResponse);

        setInitValues((prev) => ({
          ...prev,

          Projectinfo: selectedProject,
          Risk: !_.isEmpty(risks) ? risks : [{ risk_id: 0, risk_name: '' }],
          Assumption: !_.isEmpty(assumptions)
            ? assumptions
            : [{ assumption_id: 0, assumption_name: '' }],
          Issue: !_.isEmpty(issues) ? issues : [{ issue_id: 0, issue_name: '' }],
          Dependency: !_.isEmpty(dependency)
            ? dependency
            : [{ dependency_id: 0, dependency_name: '' }],
        }));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const stripTimeStamp = (response) => {
    var startDate = response.project_st_dt ? response.project_st_dt.split(' ')[0] : null;
    var endDate = response.project_end_dt ? response.project_end_dt.split(' ')[0] : null;
    var stateDate = response.state_date ? response.state_date.split(' ')[0] : null;

    response.project_st_dt = startDate;
    response.project_end_dt = endDate;
    response.state_date = stateDate;
    return response;
  };

  //table event handlers
  const handleEdit = async (rowData) => {
    const projectId = rowData.project_id;
    setEditMode(true);
    setActiveTab('add');
    //pupulate form here
    await pouplateForm(projectId);
  };

  const handleDelete = (rowData) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const projectId = rowData.project_id;
            const projectName = rowData.project_name;
            const project_list = projectList.filter(
              (project) => project['projectId'] !== projectId,
            );
            setProjectList((prev) => project_list);
            // eslint-disable-next-line no-restricted-globals
            //confirm(`Are you sure you want to delete ${projectName}? `)
            deleteProject(projectId)
              .then((response) => {
                if (response) {
                  console.log(`Project successfully deleted with project id: ${projectId} `);
                  showActionFeedback(response.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });

            refreshProject();
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const showActionFeedback = (message) => {
    setActionFeedbackMessage(message);
    setTimeout(() => setActionFeedbackMessage(''), 5000);
  };

  const handleProjectStateModal = async (rowData) => {
    const projectId = rowData.project_id;
    await pouplateForm(projectId);
    setEditMode(true);
    setStateModalOpen(true);
  };

  const changeTab = (tabname) => {
    setActiveTab(tabname);
  };

  const resetForm = () => {
    setInitValues({});
    setEditMode(false);
    setSubmitSuccess({ success: false, laoding: false, message: '' });
  };

  const handleSubmit = async (values) => {
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              let response;
              if (editMode) {
                if (values.Projectinfo?.project_id && values.Projectinfo?.project_id > 0) {
                  response = await updateProject(values.Projectinfo?.project_id, values);
                }
              } else {
                response = await createProject(values);
              }

              if (response) {
                setSubmitSuccess((prev) => ({
                  ...prev,
                  success: true,
                  message: response.message,
                  loading: false,
                }));
                // resetForm();
                changeTab('list');
              }
            } catch (error) {
              console.error(error);
              setSubmitSuccess((prev) => ({
                ...prev,
                success: false,
                message: error.message,
                loading: false,
              }));
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });

    // alert(JSON.stringify(values));
  };

  const onStatusIconClick = (e) => {
    e.preventDefault();
    const button = e.currentTarget;
    const project = button.getAttribute('data-project');
    const projectObj = JSON.parse(project);
    const type1 = projectTypeList.find((type2) => type2.name === projectObj.project_type).id;
    const category1 = categoryList.find(
      (category2) => category2.name === projectObj.project_category,
    ).id;
    const stream1 = streamList.find(
      (stream2) => stream2.stream_name === projectObj.stream_id,
    ).stream_id;
    const plan1 = taskPlanList.find((plan2) => plan2.name === projectObj.work_plan).id;

    projectObj.project_type = type1;
    projectObj.project_category = category1;
    projectObj.stream_id = stream1;
    projectObj.work_plan = plan1;

    handleStatus(projectObj);
  };

  const handleStatus = (rowData) => {
    const projectId = rowData.project_id;
    const status = rowData['status'];
    rowData['status'] = status === 'A' ? 'I' : 'A';

    updateStatus(projectId, rowData).then((response) => {
      console.log(
        `Status successfully updated for project with project id: ${projectId} `,
        response,
      );
      if (response) {
        refreshProject();
        showActionFeedback(response.message);
      }
    });
  };

  const onApprovedIconClick = (e) => {
    e.preventDefault();
    const button = e.currentTarget;
    const project = button.getAttribute('data-project');
    const projectObj = JSON.parse(project);
    const type1 = projectTypeList.find((type2) => type2.name === projectObj.project_type).id;
    const category1 = categoryList.find(
      (category2) => category2.name === projectObj.project_category,
    ).id;
    const stream1 = streamList.find(
      (stream2) => stream2.stream_name === projectObj.stream_id,
    ).stream_id;
    const plan1 = taskPlanList.find((plan2) => plan2.name === projectObj.work_plan).id;

    projectObj.project_type = type1;
    projectObj.project_category = category1;
    projectObj.stream_id = stream1;
    projectObj.work_plan = plan1;
    handleApproved(projectObj);
  };

  const handleApproved = (rowData) => {
    const projectId = rowData.project_id;
    const isApproved = rowData['approved'];
    rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y';
    updateApproved(projectId, rowData).then((response) => {
      console.log(`Project successfully approved with project id: ${projectId} `, response);
      if (response) {
        refreshProject();
        showActionFeedback(response.message);
      }
    });
  };

  return (
    <div className="ipp-page">
      <Fragment>
        <Header />
        {/* Feedback message section Here */}
        {actionFeedbackMessage && <ActionFeedback message={actionFeedbackMessage} />}
        {/* State modal section Here */}
        {stateModalOpen && (
          <ProjectStateForm
            modal={stateModalOpen}
            setModal={setStateModalOpen}
            resetForm={resetForm}
            initValues={initValues}
            stateList={state_list}
            laoding={loading}
            handleSubmit={handleSubmit}
          />
        )}
        <Container className="mt-3 ml-0" fluid style={{ maxWidth: '100%', margin: 'auto auto' }}>
          <Nav tabs className="border-bottom-0"></Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="list">
              <ProjectInfoTable
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleProjectStateModal={handleProjectStateModal}
                stateModalOpen={stateModalOpen}
                isActionAllowed={isActionAllowed}
                project_list={projectList}
                isLoading={loading}
                changeTab={changeTab}
                onStatusIconClick={onStatusIconClick}
                onApprovedIconClick={onApprovedIconClick}
              />
            </TabPane>
            <TabPane tabId="add">
              <ProjectInfoForm
                initValues={initValues}
                changeTab={changeTab}
                loading={loading}
                clientList={clientList}
                streamList={streamList}
                employeeList={employeeList}
                clientTypeList={clientTypeList}
                projectModelList={projectModelList}
                resetForm={resetForm}
                handleSubmit={handleSubmit}
              />
            </TabPane>
          </TabContent>
        </Container>
      </Fragment>
    </div>
  );
};

export default ProjectInfoContainer;
