import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
} from "recharts";

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

const CustomizedAxisTickGrouped = ({ x, y, payload, data }) => {
  // nameGroup can also be passed as a prop
  const ticks = data.filter((tick) => tick.nameGroup === payload.value);
  const firstIdx = data.findIndex((tick) => tick.nameGroup === payload.value);
  if (firstIdx === payload.index) {
    const xOffset = payload.offset * Math.floor(ticks.length / 2);
    return (
      <Text x={x + xOffset} y={y} width={75} textAnchor="middle" verticalAnchor="start">
        {payload.value}
      </Text>
    );  
  }
  return null;
};

const GenericBarChart = (props) => {
  const { data, keyset, colors, height } = props;
  return (
    <ResponsiveContainer width={"100%"} height={height ? height : 250}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        className=""
      >
        <XAxis dataKey="name" tick={CustomizedAxisTick} interval={0} />
        <XAxis
          dataKey="nameGroup"
          xAxisId="nameGroup"
          tickLine={false}
          axisLine={false}
          interval={0}
          tick={<CustomizedAxisTickGrouped data={data} />}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        {keyset &&
          keyset.map((entry, index) => (
            <Bar
              key={`${index}-${entry}`}
              dataKey={`${keyset[index]}`}
              fill={colors[index]}
              label={{ position: "top" }}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GenericBarChart;
