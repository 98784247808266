import React, {useState} from "react"
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap"
import TransferList from "../../components/TransferList";

const RoleMenu = ({
  role,
  assignedMenus,
  unassignedMenus,
  handleMenusUpdate,
  changeTab
}) => {

  const [selectedMenus, setSelectedMenus] = useState([])

  return <Card className="border-top-0 shadow-none">
    <CardHeader style={{
      fontSize: 20,
      color: '#fff',
      textAlign: 'center',
      padding: 10,
    }}>
      {`Select menus for role: ${role.roleName}`}
    </CardHeader>

    <CardBody className="overflow-hidden">
      <TransferList
        assigned={assignedMenus}
        unassigned={unassignedMenus}
        setSelected={setSelectedMenus}
        typeKey={'menu_name'}
        type={'Menus'}
        titleLeft={'Unassigned Menus'}
        titleRight={'Assigned Menus'}
      />
    </CardBody>

    <CardFooter>
      <Button
        color="primary"
        className="fixedWidthButton"
        onClick={() => {
          handleMenusUpdate(selectedMenus)
          changeTab('list')
        }}
      >
        Submit
      </Button>{' '}
      <Button
        color="danger"
        className="fixedWidthButton"
        onClick={() => {
          changeTab('list')
        }}
      >
        Cancel
      </Button>
    </CardFooter>
  </Card>
}

export default RoleMenu