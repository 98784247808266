import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listUserGroup = () => {
  const token = getUserToken()
  return axios
    .get('/usergroup/listUsergroup', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server(List of user groups)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while getting the list of user groups', error)
    })
}

export const createUserGroup = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/usergroup/createUsergroup', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Creating user group)', response)
      return response.data
    })
    .catch((error) => {
      console.log('Error while creating user group ', error)
    })
}

export const deleteUserGroup = (userGroupId) => {
  const token = getUserToken()
  return axios
    .delete(`/usergroup/deleteUsergroup/${userGroupId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        `Response from server(Deleting user group with id: ${userGroupId})`,
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting user group with user group id ${userGroupId}`,
        error,
      )
    })
}

export const updateUserGroup = (userGroupId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/usergroup/updateUsergroup/${userGroupId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating user group): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating user group with userGroupId ${userGroupId}`,
        error,
      )
    })
}

export const updateApproved = (userGroupId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['approved_by'] = id
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/usergroup/updateApproved/${userGroupId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of user group): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating user group's approved status with userGroupId ${userGroupId}`,
        error,
      )
    })
}

export const updateStatus = (userGroupId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/usergroup/updateStatus/${userGroupId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of user group): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating user group's status with userGroupId ${userGroupId}`,
        error,
      )
    })
}

export const viewUserGroup = (userGroupId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/usergroup/viewUsergroup/${userGroupId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting a view of user group): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting a user group with userGroupId ${userGroupId}`,
        error,
      )
    })
}

export const getDetail = (userGroupId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/usergroup/getDetail/${userGroupId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of user group): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting user group's detail with userGroupId ${userGroupId}`,
        error,
      )
    })
}
