import TransferList from 'components/TransferList';

import React, { useState } from "react";
import { Spinner } from 'react-bootstrap';
import {
    Card,
    Button,
    CardHeader,
    CardBody,
    CardFooter,
    Row,

} from 'reactstrap';

const ProjectModelTaskType = ({
    projectModelName,
    assignedTaskTypes,
    unassignedTaskTypes,
    handleTaskTypeUpdate,
    changeTab,
    resetState,
    loading
}) => {

    const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);

    return (
        <>
            <Card className="border-top-0 shadow-none">
                <CardHeader style={{
                    fontSize: 18,
                    color: '#fff',
                    textAlign: 'center',
                    padding: 10,
                }}>
                    {`Select task types for project model: ${projectModelName}`}
                </CardHeader>

                <CardBody style={{ maxHeight: 'none', overflow: 'unset' }}>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <TransferList
                            assigned={assignedTaskTypes}
                            unassigned={unassignedTaskTypes}
                            setSelected={setSelectedTaskTypes}
                            typeKey={'task_type_name'}
                            type={'Task Types'}
                            titleLeft={'Unassigned Task Types'}
                            titleRight={'Assigned Task Types'}
                        />
                    )}
                </CardBody>

                <CardFooter>
                    <Row style={{ float: 'right' }}>
                        <Button
                            color="primary"
                            className="fixedWidthButton text-center"
                            onClick={() => {
                                handleTaskTypeUpdate(selectedTaskTypes);
                                changeTab('list');
                            }}
                        >
                            Submit
                        </Button>
                        <Button
                            color="danger"
                            className="fixedWidthButton text-center"
                            onClick={() => {
                                resetState();
                                changeTab('list');
                            }}
                        >
                            Cancel
                        </Button>
                    </Row>
                </CardFooter>
            </Card>
        </>
    );

}

export default ProjectModelTaskType;