import React, {useEffect, useState} from "react"
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap"
import TransferList from "../../components/TransferList";
import {getPermissionByRole, getUnassignedPermissionByRole} from "../../actions/RoleFunctions";

const RolePermission = ({
  role,
  assignedPermissions,
  unassignedPermissions,
  handlePermissionsUpdate,
  changeTab
}) => {

  const [selectedPermissions, setSelectedPermissions] = useState([])

  return <Card className="border-top-0 shadow-none">
    <CardHeader style={{
      fontSize: 20,
      color: '#fff',
      textAlign: 'center',
      padding: 10,
    }}>
      {`Select permissions for role: ${role.roleName}`}
    </CardHeader>

    <CardBody className="overflow-hidden">
      <TransferList
        assigned={assignedPermissions}
        unassigned={unassignedPermissions}
        setSelected={setSelectedPermissions}
        typeKey={'perm_name'}
        type={'Permissions'}
        titleLeft={'Unassigned Permissions'}
        titleRight={'Assigned Permissions'}
      />
    </CardBody>

    <CardFooter className="pt-0 border-top-0">
      <Button
        color="primary"
        className="fixedWidthButton"
        onClick={() => {
          handlePermissionsUpdate(selectedPermissions)
          changeTab('list')
        }}
        >
        Submit
      </Button>{' '}
      <Button
        color="danger"
        className="fixedWidthButton"
        onClick={() => {
          changeTab('list')
        }}
      >
        Cancel
      </Button>
    </CardFooter>
  </Card>

}

export default RolePermission