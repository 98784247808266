import Axios from 'axios';
import decoder from 'jwt-decode';

export const getUserId = () => {
  const userId = decoder(getUserToken()).sub;
  return userId;
};

export const clearUserToken = () => {
  localStorage.removeItem("usertoken");
}

export const getUserToken = () => {
  return localStorage.getItem('usertoken');
};

export const getPermissionsFor = (componentName) => {
  try {
    const allPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    let allowedPermissions = [];
    if (allPermissions && allPermissions.length > 0) {
      allowedPermissions = allPermissions.filter((permission) =>
        permission.perm_name.toLowerCase().includes(componentName.toLowerCase()),
      );
    }
    return allowedPermissions;
  } catch(error) {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userPermissions");
  }
};

export const download = (path, cancelToken) => {
  const token = getUserToken();
  return Axios.post(`/file/downloadFile`, path, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    responseType: 'blob', //very very important
    cancelToken: cancelToken,
  })
    .then((response) => {
      console.log(`Response from server(downlioading all the project tasks)`, response);
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
