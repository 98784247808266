import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listDayofftype = (cancelToken) => {
  const token = getUserToken()
  return axios
    .get('/dayofftype/listDayofftype', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}
export const activeDayofftype = () => {
  const token = getUserToken()
  return axios
    .get('/dayofftype/activeDayofftype', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createDayofftype = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id // TODO: big todo: find some users or create new ones
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/dayofftype/createDayofftype', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteDayofftype = (dayofftypeId) => {
  // TODO: manage this token at a central store
  const token = getUserToken()
  return axios
    .delete(`/dayofftype/deleteDayofftype/${dayofftypeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting Dayoff type with id ${dayofftypeId}`,
        error,
      )
    })
}

export const updateDayofftype = (dayofftypeId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/dayofftype/updateDayofftype/${dayofftypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating Dayoff type): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Dayoff type with id ${dayofftypeId}`,
        error,
      )
    })
}

export const updateApproved = (dayofftypeId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  // TODO: do we need to changed the updated_by field as well here ?
  rowData['approved_by'] = id // TODO: big todo: find some users or create new ones
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/dayofftype/updateApproved/${dayofftypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of Dayoff type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Dayoff type's approved status with id ${dayofftypeId}`,
        error,
      )
    })
}

export const updateStatus = (dayofftypeId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/dayofftype/updateStatus/${dayofftypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of Dayoff type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Dayoff type's status with Id ${dayofftypeId}`,
        error,
      )
    })
}

export const getDetail = (dayofftypeId) => {
  const token = getUserToken()
  return axios
    .get(`/dayofftype/getDetail/${dayofftypeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of Dayoff type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting Dayoff type's detail with Id ${dayofftypeId}`,
        error,
      )
    })
}
