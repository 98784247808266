import React, {useState} from 'react';
import ToggleButton from '../../../components/ProjectHealth/ToggleButton';
import { Input } from "reactstrap";
import { useEffect } from 'react';
import { getResourceInfoData, getDashboardProjectsByClientType, getResourceInfoDataByDate } from 'actions/DashboardReportFunctions';
import _ from 'lodash';
import TableContainer  from '../TableContainer'
import {Spinner} from 'reactstrap';
import GenericBarChart from 'components/Charts/GenericBarChart';
import { colors } from '../../DashboardContainer/projectInfo/ProjectInfoComponent'
import RisksAndIssues from './RisksAndIssues';
import PMOHealthReport from 'container/DashboardContainer/PMOReport/PMOHealthReport';
import PMOTable from "container/DashboardContainer/PMOReport/PMOTable"
import {ReportModal, ProjectHealthTable} from './ReportModal';
import { Button } from "react-bootstrap";
import { exportPMOReport } from 'utils/CreatePPt';
import ProjectWorkHrsTable, { ProjectWorkHrsTable2 } from "../../DashboardContainer/PMOReport/ProjectWorkHrsTable";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styles from "../ExecutiveDashboardContainer/executive.module.css"
import PMOResourceTable from 'container/DashboardContainer/PMOReport/PMOResourceTable';

const ExecutiveDashboardContainer = (props) => {

    const prepareBarChartData = (rawData) => {
        let rows = []
        let keySet = new Set()
        let grouped = _.groupBy(rawData, 'name');
        Object.keys(grouped).map((key) => {
          let entry = grouped[key].reduce(
            (acc, row) => {
              keySet.add(row?.label)
              acc[row?.label] = row?.total_count
              return acc
            },
            { name: key }
          )
          rows.push(entry)
        })
        return { rows, keySet: [...keySet] }
      }


    const [filterDate, setFilterDate] = useState(new Date().toISOString().split("T")[0]);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [projectByClientTypeData, setprojectByClientTypeData] = useState(null);
    const [resourcesByClientTypeData, setResourcesByClientTypeData] = useState(null);
    const [selectedRows, setSeletedRows] = useState([]);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const projectsByClientTypeTableSlide = React.createRef();
    const resourcesByClientTypeTableSlide = React.createRef();
    const projectsHealthTableSlide = React.createRef();
    const projectWorkHoursTableSlide1 = React.createRef();
    const projectWorkHoursTableSlide2 = React.createRef();
    const riskSlide = React.createRef();
    const issueSlide = React.createRef();
    const [tabKey, setTabKey] = useState("projectSelection");


    useEffect(() => {
      getDashboardProjectsByClientType("client_type_name", filterDate).then((data) => {
          let sortedBarActive = prepareBarChartData([
            ...data?.data?.barActive,
          ])
          let grand_total = 0
          let active = data.data.active.map(({total_count})=>grand_total+= total_count)

          setprojectByClientTypeData({
            ...data?.data,
            sortedBarActive,
            "total": {"grand_total": grand_total},
          });
          setIsLoading1(false);
        });

        getResourceInfoDataByDate("client_type_name", filterDate).then((data) => {
          let sortedBarActive = prepareBarChartData([...data?.data?.barActive]);
          let resource_total = 0
          let resource_active = data.data.active.map(({total_count})=>resource_total+= total_count)
          setResourcesByClientTypeData({
            ...data?.data,
            sortedBarActive,
            "total":{"grand_total": resource_total}
          });
          setIsLoading2(false);
        });


    },[filterDate]);
    const previewReport = async() => {
      setTabKey("report");
    }

    const generatePMOReport = async() => {
      await exportPMOReport(
        projectsByClientTypeTableSlide,
        resourcesByClientTypeTableSlide,
        projectsHealthTableSlide,
        projectWorkHoursTableSlide1,
        projectWorkHoursTableSlide2,
        riskSlide,
        issueSlide
      );
    }

     return (
       <div className="my-4">
         <div className="d-flex flex-row justify-content-between">
           <h2>Executive Dashboard</h2>
           <div className="d-flex flex-row justify-content-between align-items-center">
             <h4 className="mb-0">Filter</h4>
             <Input
               className="m-1"
               type="date"
               name="calcDate"
               value={filterDate}
               bsSize="sm"
               onChange={(e) => {
                 setFilterDate(e.target.value)
               }}
             />
           </div>
           <ToggleButton
             selection={props.selection}
             setSelection={props.setSelection}
           />
         </div>
         {/* <div className="container bg-white p-4 mt-4" ref={projectsByClientTypeTableSlide}>
           <div className="row d-flex justify-content-center">
             <h2>Projects By Client Type</h2>
           </div>
           {isLoading1 ? (
             <div className="row d-flex justify-content-center">
               <Spinner
                 animation="border"
                 style={{ alignSelf: "center", margin: "1rem" }}
               />
             </div>
           ) : (
             <>
               <div className="row my-4">
                 <div className="col-lg-6 offset-lg-3">
                   <TableContainer data={projectByClientTypeData?.active} id="pi" />
                 </div>
               </div>
               <div className="row my-4">
                 <GenericBarChart
                   dataKey="name"
                   colors={colors}
                   data={projectByClientTypeData?.sortedBarActive?.rows}
                   keyset={projectByClientTypeData?.sortedBarActive?.keySet}
                   height={250}
                 />
               </div>
             </>
           )}
         </div>
         <div className="container py-4 bg-white" ref={resourcesByClientTypeTableSlide}>
           <div className="row d-flex justify-content-center">
             <h2>Resources By Client Type</h2>
           </div>
           {isLoading2 ? (
             <div className="row d-flex justify-content-center">
               <Spinner
                 animation="border"
                 style={{ alignSelf: "center", margin: "1rem" }}
               />
             </div>
           ) : (
             <>
               <div className="row my-4 py-4">
                 <div className="col-lg-6 offset-lg-3">
                   <TableContainer
                     data={[...resourcesByClientTypeData?.active]}
                     id="pi"
                   />
                 </div>
               </div>
               <div className="row my-4">
                 <GenericBarChart
                   dataKey="name"
                   colors={colors}
                   data={resourcesByClientTypeData?.sortedBarActive?.rows}
                   keyset={resourcesByClientTypeData?.sortedBarActive?.keySet}
                   height={250}
                 />
               </div>
             </>
           )}
         </div>
         <div className="container bg-white">
         <ProjectWorkHrsTable selectedDate={filterDate}/>
         </div>
         <div className="container bg-white p-4 mt-4">
           <PMOHealthReport
             selectedDate={filterDate}
             setSelectedDate={setFilterDate}
             selectedRows={selectedRows}
             setSeletedRows={setSeletedRows}
           />
           <div className="d-flex flex-row-reverse my-2">
             <Button variant="primary" onClick={toggle}>
               Report
             </Button>
             <ReportModal
               isOpen={isReportModalOpen}
               toggle={toggle}
               projectsHealthData={selectedRows}
               previewReport={previewReport}
             />
           </div>
         </div>
         <div className="container">
           <RisksAndIssues riskSlide={riskSlide} issueSlide={issueSlide}/>
         </div>
         <div
           className="container-fluid"
           style={{ maxHeight: 0, minWidth: 1100, maxWidth: 1101, overflow: "scroll" }}
         >
          <div id="project-health-id" ref={projectsHealthTableSlide}>
          <ProjectHealthTable projectsHealthData={selectedRows} />
          </div>
         </div> */}
         <Tabs
           id="controlled-tab-example"
           activeKey={tabKey}
           onSelect={(k) => setTabKey(k)}
           className="mb-3"
           variant="pills"
           title="Report"
         >
           <Tab
             eventKey="projectSelection"
             title="Project Selection"
             tabClassName="btn btn-secondary btn-sm text-black mb-1"
           >
             <div className="container">
               <div className="row">
                 <div className="col-12 p-4">
                   <PMOHealthReport
                     selectedDate={filterDate}
                     setSelectedDate={setFilterDate}
                     selectedRows={selectedRows}
                     setSeletedRows={setSeletedRows}
                   />
                 </div>
               </div>
               <div className="row d-flex justify-content-end px-4">
                 <Button variant="primary" onClick={previewReport}>
                   Preview
                 </Button>
               </div>
             </div>
           </Tab>
           <Tab
             eventKey="report"
             title="Report"
             tabClassName="btn btn-secondary btn-md text-black mb-1"
           >
             <div className={`container bg-white p-4 mt-4`}>
               <div className={`${styles.shadowCSS} p-4`}>
                 <div className={`row d-flex justify-content-center`}>
                   <h2>Projects By Client Type</h2>
                 </div>
                 {isLoading1 ? (
                   <div className="row d-flex justify-content-center">
                     <Spinner
                       animation="border"
                       style={{ alignSelf: "center", margin: "1rem" }}
                     />
                   </div>
                 ) : (
                   <>
                     <div className="row">
                       <div className="col-lg-6 offset-lg-3">
                         <PMOTable
                           grand_total={projectByClientTypeData?.total}
                           data={projectByClientTypeData?.active}
                           id="pi"
                         />
                       </div>
                     </div>
                     <div className="row my-4">
                       <GenericBarChart
                         dataKey="name"
                         colors={colors}
                         data={projectByClientTypeData?.sortedBarActive?.rows}
                         keyset={
                           projectByClientTypeData?.sortedBarActive?.keySet
                         }
                         height={250}
                       />
                     </div>
                   </>
                 )}
               </div>
             </div>
             <div className="container py-4 bg-white">
               <div className={`${styles.shadowCSS} p-4`}>
                 <div className="row d-flex justify-content-center">
                   <h2>Resources By Client Type</h2>
                 </div>
                 {isLoading2 ? (
                   <div className="row d-flex justify-content-center">
                     <Spinner
                       animation="border"
                       style={{ alignSelf: "center", margin: "1rem" }}
                     />
                   </div>
                 ) : (
                   <>
                     <div className="row">
                       <div className="col-lg-6 offset-lg-3">
                         <PMOResourceTable
                           grand_total={resourcesByClientTypeData?.total}
                           data={[...resourcesByClientTypeData?.active]}
                           id="pi"
                         />
                       </div>
                     </div>
                     <div className="row my-4">
                       <GenericBarChart
                         dataKey="name"
                         colors={colors}
                         data={resourcesByClientTypeData?.sortedBarActive?.rows}
                         keyset={
                           resourcesByClientTypeData?.sortedBarActive?.keySet
                         }
                         height={250}
                       />
                     </div>
                   </>
                 )}
               </div>
             </div>
             <div className="container bg-white py-4">
               <div className={`${styles.shadowCSS} p-4`}>
                 <div className="row d-flex justify-content-center">
                   <h2>Projects Health</h2>
                 </div>
                 <ProjectHealthTable projectsHealthData={selectedRows} />
               </div>
             </div>
             <div className="container bg-white py-4">
               <div className={`${styles.shadowCSS} p-4`}>
                 <div className="row d-flex justify-content-center">
                   <h2>Projects Work Hours Table</h2>
                 </div>
                 <ProjectWorkHrsTable selectedDate={filterDate} />
               </div>
             </div>
             <div className="container bg-white py-4">
               <RisksAndIssues />
             </div>
             <div className="container bg-white py-4">
               <div className="row d-flex justify-content-end pr-4">
                 <Button variant="primary" onClick={generatePMOReport}>
                   Report
                 </Button>
               </div>
             </div>

             {/* hidden container for generating ppt report */}
             <div
               style={{
                 maxHeight: 0,
                 minWidth: 1200,
                 maxWidth: 1201,
                 overflow: "scroll",
               }}
             >
               <div id="project-id" ref={projectsByClientTypeTableSlide}>
                 <div className="container py-4 bg-white">
                   <div className="row d-flex justify-content-center">
                     <h2>Projects By Client Type</h2>
                   </div>
                   {isLoading1 ? (
                     <div className="row d-flex justify-content-center">
                       <Spinner
                         animation="border"
                         style={{ alignSelf: "center", margin: "1rem" }}
                       />
                     </div>
                   ) : (
                     <>
                       <div className="row">
                         <div className="col-lg-6 offset-lg-3">
                           <PMOTable
                            grand_total={projectByClientTypeData?.total}
                             data={projectByClientTypeData?.active}
                             id="pi"
                           />
                         </div>
                       </div>
                       <div className="row my-4">
                         <GenericBarChart
                           dataKey="name"
                           colors={colors}
                           data={projectByClientTypeData?.sortedBarActive?.rows}
                           keyset={
                             projectByClientTypeData?.sortedBarActive?.keySet
                           }
                           height={250}
                         />
                       </div>
                     </>
                   )}
                 </div>
               </div>
               <div
                 className="container py-4 bg-white"
                 ref={resourcesByClientTypeTableSlide}
               >
                 <div className="row d-flex justify-content-center">
                   <h2>Resources By Client Type</h2>
                 </div>
                 {isLoading2 ? (
                   <div className="row d-flex justify-content-center">
                     <Spinner
                       animation="border"
                       style={{ alignSelf: "center", margin: "1rem" }}
                     />
                   </div>
                 ) : (
                   <>
                     <div className="row">
                       <div className="col-lg-6 offset-lg-3">
                         <PMOResourceTable
                          grand_total={resourcesByClientTypeData?.total}
                           data={[...resourcesByClientTypeData?.active]}
                           id="pi"
                         />
                       </div>
                     </div>
                     <div className="row my-4">
                       <GenericBarChart
                         dataKey="name"
                         colors={colors}
                         data={resourcesByClientTypeData?.sortedBarActive?.rows}
                         keyset={
                           resourcesByClientTypeData?.sortedBarActive?.keySet
                         }
                         height={250}
                       />
                     </div>
                   </>
                 )}
               </div>
               <div
                 className="container bg-white py-4"
                 ref={projectsHealthTableSlide}
               >
                 <div className="row d-flex justify-content-center">
                   <h2>Projects Health</h2>
                 </div>
                 <ProjectHealthTable projectsHealthData={selectedRows} />
               </div>
               <div className="container bg-white py-4">
                 <div className="row d-flex justify-content-center">
                   <h2>Projects Work Hours Table</h2>
                 </div>
                 <ProjectWorkHrsTable2
                   workHourRef1={projectWorkHoursTableSlide1}
                   workHourRef2={projectWorkHoursTableSlide2}
                   selectedDate={filterDate}
                 />
               </div>
               <div className="container bg-white py-4">
                 <RisksAndIssues
                   riskSlide={riskSlide}
                   issueSlide={issueSlide}
                 />
               </div>
             </div>
           </Tab>
         </Tabs>
       </div>
     )
}

export default ExecutiveDashboardContainer;