import React from "react"
import _ from "lodash"
import {IconButton, MuiThemeProvider} from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import {Cancel, CheckCircle} from "@material-ui/icons"
import {theme} from "../../utils/Theme"
import MaterialTable from "material-table"
import Loading from "../../components/Loading"
import {Button} from "reactstrap"
import PhasesDetailList from "../../components/PhasesDetailList"

const PhasesTable = ({
 phasesListData,
 handleEdit,
 handleDelete,
 onStatusIconClick,
 onApprovedIconClick,
 isLoading,
 isActionAllowed,
 changeTab
}) => {

  let phasesList = _.cloneDeep(phasesListData)
  phasesList = phasesList.map(row => {
    let rowString = JSON.stringify(row)

    row['status_icon'] = <IconButton
      onClick={onStatusIconClick}
      data-phases={rowString}
      disabled={!isActionAllowed('status')}
    >
      {row['status'] === 'I' ? (
        <Tooltip title="Inactive">
          <Cancel style={{ color: 'red' }} />
        </Tooltip>
      ) : (
        <Tooltip title="Active">
          <CheckCircle style={{ color: 'green' }} />
        </Tooltip>
      )}
    </IconButton>


    row['approved_icon'] = <IconButton
      onClick={onApprovedIconClick}
      data-phases={rowString}
      disabled={!isActionAllowed('approve')}
    >
      {row['approved'] === 'N' ? (
        <Tooltip title="Unapproved">
          <Cancel style={{ color: 'red' }} />
        </Tooltip>
      ) : (
        <Tooltip title="Approved">
          <CheckCircle style={{ color: 'green' }} />
        </Tooltip>
      )}
    </IconButton>

    return row
  })

  const cellStyle = {
    paddingTop: '0px',
    paddingBottom: '0px',
  }

  const columns = [
    { title: 'Code', field: 'phases_cd', cellStyle: cellStyle},
    { title: 'Name', field: 'phases_name', cellStyle: cellStyle},
    { title: 'Abbreviation', field: 'phases_abbr', cellStyle: cellStyle},
    { title: 'Status', field: 'status_icon', cellStyle: cellStyle},
    { title: 'Approved', field: 'approved_icon', cellStyle: cellStyle},
  ]

  return <MuiThemeProvider theme={theme}>
    <MaterialTable
      components={{
        OverlayLoading: props => <Loading />
      }}
      localization={{
        body: {
          emptyDataSourceMessage: !isLoading ? 'No records to display' : ''
        }
      }}
      isLoading={isLoading}
      title={
        <div style={{width: '500px'}}>
          <Button
            type="button"
            title="Add new phase"
            color="primary"
            style={{
              margin: '0px 10px 5px 0px',
              borderRadius: '50%',
              padding: '0px',
              width: '30px',
              height: '30px',
              float: 'left',
            }}
            onClick={() => {
              changeTab('add')
            }}
            hidden={!isActionAllowed('add')}
          >
            <i className="fas fa-plus fa-sm" />
          </Button>

          <h2 style={{color: '#00948B'}}>Phases List</h2>
        </div>
      }
      columns={columns}
      data={phasesList}
      options={{
        headerStyle: {
          color: '#00948B',
          fontWeight: 'bold',
          paddingTop: '0px',
          paddingBottom: '5px',
        },
        rowStyle: {
          hover: true,
        },
        actionsColumnIndex: -1,
        detailPanelColumnAlignment: 'right'
      }}
      actions={[
        {
          icon: 'edit',
          tooltip: isActionAllowed('edit') ? 'Edit' : 'You don\'t have permission to perform this action.',
          disabled: !isActionAllowed('edit'),
          onClick: (event, rowData) => handleEdit(rowData)
        },
        {
          icon: 'delete',
          tooltip: isActionAllowed('delete') ? 'Delete' : 'You don\'t have permission to perform this action.',
          disabled: !isActionAllowed('delete'),
          onClick: (event, rowData) => handleDelete(rowData)
        }
      ]}
      detailPanel={[
        {
          icon: 'toc',
          disabled: !isActionAllowed('detail'),
          render: rowData => {
            const phaseId = rowData['phases_id']
            return <PhasesDetailList phasesId={phaseId} />
          }
        }
      ]}
    />
  </MuiThemeProvider>
}

export default PhasesTable