import React, { useEffect, useState } from "react";
import { getAllSprintRaids } from "../../../actions/SprintStatusRaidFunctions";
import RAIDTable from "./RAIDTable";
import RAIDFilter from "./RAIDFilter";
import { Button, ButtonGroup, TabContent, TabPane } from "reactstrap";
import ToggleButton from "../../../components/ProjectHealth/ToggleButton";

// excel sheet export imports
import { saveAs } from "file-saver";
const ExcelJS = require("exceljs");

const RAIDContainer = ({ selection, setSelection }) => {
  const [raidsList, setRaidsList] = useState([]);
  const [risksList, setRisksList] = useState([]);
  const [issuesList, setIssuesList] = useState([]);

  const [activeTab, setActiveTab] = useState("Risks");

  // all columns list in excel sheet
  const cellStyle = {
    padding: "8px",
  };

  const columns = [
    { title: 'Stream', field: 'stream_name', cellStyle: cellStyle },
    { title: 'Project', field: 'project_name', cellStyle: cellStyle },
    { title: 'Sprint', field: 'sprint_st_dt', cellStyle: cellStyle },
    {
      title: 'Description',
      field: 'remarks',
      cellStyle: cellStyle,
    },
    { title: 'Severity', field: 'priority', cellStyle: cellStyle },
    { title: 'Status', field: 'status', cellStyle: cellStyle },
    { title: 'Owner', field: 'raid_owner', cellStyle: cellStyle },
    {
      title: 'Mitigation Plan',
      field: 'con_miti_plan',
      cellStyle: cellStyle,
    },
    // { title: "Date Difference", field: "diff", cellStyle: cellStyle },
    {
      title: 'Created Date',
      field: 'created_dt',
      cellStyle: { padding: '8px', textAlign: 'center' },
    },
    {
      title: 'Number of Days',
      field: 'number_of_days',
      cellStyle: { padding: '8px', textAlign: 'center' },
    },
    { title: 'Closed Date', field: 'closed_dt', cellStyle: cellStyle },
  ]

  useEffect(() => {
    getAllSprintRaids().then((response) => {
      if (response) {
        setRaidsList(response);
        let risks = response.filter((raid) => raid.raid_type === "R");
        let issues = response.filter((raid) => raid.raid_type === "I");
        console.log('risks', risks);
        setRisksList(risks);
        setIssuesList(issues);
      }
    });
  }, []);

  const filterRaids = (params) => {
    let allRaids = raidsList;

    if (params.stream)
      allRaids = allRaids.filter((raid) => raid.stream_name === params.stream);

    if (params.project)
      allRaids = allRaids.filter((raid) => raid.project_name === params.project);

    if (params.till_date)
      allRaids = allRaids.filter((raid) => raid.sprint_end_dt < params.till_date);

    if (params.status)
      allRaids = allRaids.filter((raid) => raid.status === params.status);

    if (params.severity)
      allRaids = allRaids.filter((raid) => raid.priority === params.severity);

    let risks = allRaids.filter((raid) => raid.raid_type === "R");
    let issues = allRaids.filter((raid) => raid.raid_type === "I");

    setRisksList(risks);
    setIssuesList(issues);
  };

  const onExportClick = async (tablesData, columns) => {
    console.log("the export button is clicked");

    const workbook = new ExcelJS.Workbook();
    const riskSheet = workbook.addWorksheet("Risk Sheet");

    // sheet 2 additions
    const issueSheet = workbook.addWorksheet("Issue Sheet");
    const excelColumns = []; // columns formatted for excel
    // const headerNames = []
    // console.log("columns mapping", columns[0].hidden);
    columns.map((column) => {
      column.title === "Description" || column.title === "Mitigation Plan"
        ? excelColumns.push({
            header: column.title,
            key: column.field,
            style: { alignment: { wrapText: true } },
            width: 40,
            // headerNames.push(column.field)
          })
        : excelColumns.push({
            header: column.title,
            key: column.field,
            style: { alignment: { wrapText: true } },
            width: 20,
            // headerNames.push(column.field)
          });
    });
    riskSheet.columns = [...excelColumns];
    // sheet2 columns
    issueSheet.columns = [...excelColumns];

    tablesData[0].map((row) => riskSheet.addRow(row));

    // sheet 2 data addition
    tablesData[1].map((row) => issueSheet.addRow(row));

    // console.log("TESSSTTTTT", worksheet.getRow(2));
    riskSheet.getRow(1).font = {
      color: { argb: "ff00948B" },
      bold: true,
    };

    issueSheet.getRow(1).font = {
      color: { argb: "ff00948B" },
      bold: true,
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExt = ".xlsx";
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, "Risk And Issue Sheets " + fileExt);
  };

  return (
    <div className="mt-2 ipp-page">
      <div className="d-flex flex-row justify-content-between">
        <h2>RAID Report</h2>
        <ToggleButton selection={selection} setSelection={setSelection} />
      </div>
      <RAIDFilter onFilter={filterRaids} />
      <div className=" mt-3">
        <div className="d-flex justify-content-between">
          <ButtonGroup>
            {["Risks", "Issues"].map((tab, index) => (
              <Button onClick={() => setActiveTab(tab)} active={activeTab === tab}>
                {tab}
              </Button>
            ))}
          </ButtonGroup>
          <div className="primary">
            <Button
              color="primary"
              type="button"
              onClick={() => onExportClick([risksList, issuesList], columns)}
            >
              Export
            </Button>
          </div>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Risks">
            <RAIDTable data={risksList} tabID={activeTab} />
          </TabPane>
          <TabPane tabId="Issues">
            <RAIDTable data={issuesList} tabID={activeTab} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default RAIDContainer;
