import React from "react"
import { useState } from "react"
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap"
import "./PMOTable.css"

const headerStyles = {
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
}

const PMOTable = ({ data , grand_total }) => {
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState()
  const [grandTotal, setgrandTotal] = useState(0)
  const handleClick = (rowData) => {
    setModalData(rowData)
    setModal(true)
  }
  const toggle = () => {
    setModal(!modal)
  }
  return (
    <>
      <Table size="sm" bordered responsive>
        <thead className="lisColor1">
          <tr>
            <th style={headerStyles}>S.N</th>
            <th style={headerStyles}>Name</th>
            <th style={headerStyles}>Count</th>
          </tr>
        </thead>
        <tbody className="pointer">
          {data.map((row, idx) => {
            return (
              <tr key={`${idx}`} onClick={() => handleClick(row)}>
                <td>{idx + 1}</td>
                <td>{row.name}</td>
                <td>{row.total_count}</td>
              </tr>
            )
          })}
          <tr>
            <td></td>
            <td>Grand Total</td>
            <td>{grand_total?.grand_total}</td>
          </tr>
        </tbody>
      </Table>
      <Modal isOpen={modal} centered>
        <ModalHeader toggle={toggle}>{`${modalData?.name}`}</ModalHeader>
        <ModalBody>
          <ul>
            {modalData?.projects.map((project) => (
              <li>{project.project_name}</li>
            ))}
          </ul>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PMOTable
