import React, {useEffect, useState} from "react"
import {getPermissionsFor} from "../../actions/UtilFunctions"
import {
  createStream,
  deleteStream,
  fetchAllStream,
  getDetail,
  updateApproved,
  updateStatus, updateStream
} from "../../actions/StreamFunctions"
import _ from "lodash"
import ActionFeedback from "../../components/ActionFeedbackAlert"
import {Container, TabContent, TabPane} from "reactstrap"
import StreamInfoTable from "./StreamInfoTable"
import StreamInfoForm from "./StreamInfoForm"
import {confirmAlert} from "react-confirm-alert"

const StreamInfoContainer = () => {

  const fields = {
    stream_cd: '',
    stream_name: '',
    stream_abbr: '',
  }
  const [initialValues, setInitialValues] = useState({})

  const [streamList, setStreamList] = useState([])

  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [activeTab, setActiveTab] = useState('list')
  const [actionFeedbackMsg, setActionFeedbackMsg] = useState('')

  const [allowedPermissions] = useState(getPermissionsFor('stream'))

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        await refreshProject()
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }) ()
  },[])

  const refreshProject = async () => {
    let streams = await fetchAllStream()
    let sortedStreams = _.sortBy(streams, obj => obj.order_no)
    setStreamList(sortedStreams)
  }

  const changeTab = tabName => setActiveTab(tabName)

  const populateFormData = async rowData => {
    const streamId = rowData.stream_id
    try {
      if (streamId > 0) {
        setLoading(true)
        const detail = (await getDetail(streamId))[0]
        setInitialValues(detail)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = async rowData => {
    setEditMode(true)
    setActiveTab('add')
    await populateFormData(rowData)
  }

  const handleDelete = rowData => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const streamId = rowData.stream_id
            const streamListData = streamList.filter(stream => stream['stream_id'] !== streamId)
            setStreamList(streamListData)
            deleteStream(streamId).then(response => {
              console.log(
                `Stream successfully deleted with client id: ${streamId} `,
                response,
              )
              if (response) {
                showActionFeedback(response.message)
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const handleStatus = rowData => {
    const streamId = rowData.stream_id
    const status = rowData['status']
    rowData['status'] = status === 'A' ? 'I' : 'A'
    updateStatus(streamId, rowData).then((response) => {
      console.log(
        `Status successfully updated for stream with stream id: ${streamId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })
  }

  const handleApproved = rowData => {
    const streamId = rowData.stream_id
    const isApproved = rowData['approved']
    rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
    updateApproved(streamId, rowData).then((response) => {
      console.log(
        `Stream successfully approved with stream id: ${streamId} `,
        response,
      )
      if (response) {
        refreshProject()
        showActionFeedback(response.message)
      }
    })
  }

  const handleSubmit = values => {
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let response

            if (editMode) {
              if (values?.stream_id && values?.stream_id > 0) {
                for (const key of Object.keys(fields)) {
                  fields[key] = values[key]
                }
                response = await updateStream(values.stream_id, fields)
              }
            } else {
              response = await createStream(values)
            }

            if (response) {
              showActionFeedback(response.message)
              changeTab('list')
              refreshProject()
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const onStatusIconClick = e => {
    e.preventDefault()
    const streamData = e.currentTarget.getAttribute('data-stream')
    handleStatus(JSON.parse(streamData))
  }

  const onApprovedIconClick = e => {
    e.preventDefault()
    const streamData = e.currentTarget.getAttribute('data-stream')
    handleApproved(JSON.parse(streamData))
  }

  const showActionFeedback = message => {
    setActionFeedbackMsg(message)
    setTimeout(() => setActionFeedbackMsg(''), 5000)
  }

  const isActionAllowed = action => {
    for (let permission of allowedPermissions) {
      if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true
    }
    return false
  }

  const resetForm = () => {
    setInitialValues({})
    setEditMode(false)
  }

  return <div className="ipp-page">
    {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}
    <Container className="mt-3" fluid style={{maxWidth: '100%', margin: 'auto'}}>
      <TabContent activeTab={activeTab}>

        <TabPane tabId="list">
          <StreamInfoTable
            streamListData={streamList}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onStatusIconClick={onStatusIconClick}
            onApprovedIconClick={onApprovedIconClick}
            isLoading={loading}
            isActionAllowed={isActionAllowed}
            changeTab={changeTab}
          />
        </TabPane>

        <TabPane tabId="add">
          <StreamInfoForm
            initValues={initialValues}
            handleSubmit={handleSubmit}
            isLoading={loading}
            changeTab={changeTab}
            resetForm={resetForm}
          />
        </TabPane>

      </TabContent>
    </Container>
  </div>
}

export default StreamInfoContainer