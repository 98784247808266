import { getDashboardProjectResource } from "actions/DashboardFunctions";
import axios from "axios";
import { ProjectTaskPlan } from "components/ProjectHealth/Table";
import { ResourceHoursTable } from "components/WeeklyReport/ReportTables";
import React, { useState } from "react";
import { useEffect } from "react";
import { useStore } from "react-redux";
import NestedToggleButton from "../../../components/ProjectHealth/NestedToggleButton";

function ResourceSummary(props) {
  const [billable, setBillable] = useState([]);
  const [actual, setActual] = useState([]);

  const cancelToken = axios.CancelToken.source();

  useEffect(() => {
    let isMounted = true;
    getDashboardProjectResource(props.projectId, props.sprintId, cancelToken.token).then(
      (res) => {
        if (isMounted) {
          setBillable(res["billable"]);
          setActual(res["actual"]);
        }
      }
    );

    return () => {
      isMounted = false;
      cancelToken.cancel();
    };
  }, [props.projectId, props.calcDate, props.sprintId]);
  return (

    <ResourceHoursTable billable={billable} actual={actual} />
  );
}

export default ResourceSummary;
