import { getPermissionsFor } from 'actions/UtilFunctions';
import ActionFeedback from 'components/ActionFeedbackAlert';
import {
    listProjectModel,
    createProjectModel,
    deleteProjectModel,
    updateProjectModel,
    updateApproved,
    updateStatus,
    getTaskTypeByProjectModel,
    getUnassignedTaskTypeByProjectModel,
    addTaskType,
} from '../../actions/ProjectModelFunctions';
import ProjectModelTable from './ProjectModelTable';
import ProjectModelForm from './ProjectModelForm';
import ProjectModelTaskType from './ProjectModelTaskType';

import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { Container, TabContent, TabPane } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import Axios from "axios";


const ProjectModelContainer = () => {
    const fields = {
        model_name: '',
        model_cd: '',
        model_abbr: ''
    };

    const [initValues, setInitValues] = useState({});
    const [projectModelList, setProjectModelList] = useState([]);
    const [assignedTaskTypes, setAssignedTaskTypes] = useState([]);
    const [unassignedTaskTypes, setUnassignedTaskTypes] = useState([]);
    const [selectedProjectModelId, setSelectedProjectModelId] = useState('');
    const [selectedProjectModelName, setSelectedProjectModelName] = useState('');

    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('list');
    const [actionFeedbackMsg, setActionFeedbackMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [allowedPermission, setAllowedPermission] = useState(getPermissionsFor('projectModel'));
    const [submitSuccess, setSubmitSuccess] = useState({
        success: false,
        loading: false,
        message: '',
    });

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await refresh();
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })()
    }, []);

    useEffect(() => {
        if (submitSuccess.success) {
            refresh();
            showActionFeedback(submitSuccess.message || 'Success');
        }
    }, [submitSuccess]);

    const refresh = async () => {
        await listProjectModel(Axios.CancelToken.source().token).then(res => {
            const sortedList = _.sortBy(res, obj => obj.order_no);
            setProjectModelList(sortedList);
        });
    }

    const showActionFeedback = (message) => {
        setActionFeedbackMsg(message);
        setTimeout(() => setActionFeedbackMsg(''), 5000);
    }

    const changeTab = (tabName) => {
        setActiveTab(tabName);
    }

    const isActionAllowed = (action) => {
        for (let permission of allowedPermission) {
            if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true;
        }
        return false;
    }

    const handleEdit = async (rowData) => {
        try {
            const modelId = rowData['model_id'];
            if (modelId > 0) {
                setEditMode(true);
                setActiveTab('add');
                setLoading(true);
                setInitValues(rowData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = (rowData) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const modelId = rowData.model_id;
                        deleteProjectModel(modelId)
                            .then((response) => {
                                if (response) {
                                    const model_list = projectModelList.filter(
                                        (model) => model.model_id !== modelId,
                                    );
                                    setProjectModelList(model_list);
                                    showActionFeedback(response.message);
                                }
                            })
                            .catch((error) => {
                                showActionFeedback('Error While Deleting!');
                                console.log(error);
                            });
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    }

    const handleStatus = (rowData) => {
        const modelId = rowData.model_id;
        const status = rowData['status'];
        rowData['status'] = status === 'A' ? 'I' : 'A';
        updateStatus(modelId, rowData).then((response) => {
            if (response) {
                const updatedList = projectModelList.map(model => {
                    if (model.model_id === modelId) {
                        return { ...model, status: (status === 'A' ? 'I' : 'A') };
                    }
                    return model;
                });

                setProjectModelList(updatedList);
                showActionFeedback(response.message);
            }
        });
    }

    const onStatusIconClick = (e) => {
        e.preventDefault();
        const modelData = e.currentTarget.getAttribute('data-project-model');
        handleStatus(JSON.parse(modelData));
    }

    const onApprovedIconClick = (e) => {
        e.preventDefault();
        const modelData = e.currentTarget.getAttribute('data-project-model');
        handleApprove(JSON.parse(modelData));
    }

    const handleApprove = (rowData) => {
        const modelId = rowData.model_id;
        const isApproved = rowData['approved']
        rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
        updateApproved(modelId, rowData).then((response) => {
            if (response) {
                const updatedList = projectModelList.map(model => {
                    if (model.model_id === modelId) {
                        return { ...model, approved: (isApproved === 'Y' ? 'N' : 'Y') }
                    }
                    return model;
                });

                setProjectModelList(updatedList);
                showActionFeedback(response.message);
            }
        });
    }

    const resetForm = () => {
        setInitValues({});
        setEditMode(false);
    }

    const onTaskTypeIconClick = (e) => {
        e.preventDefault();
        const modelData = e.currentTarget.getAttribute('data-project-model');
        handleTaskTypes(JSON.parse(modelData));
    }

    const handleTaskTypes = async (rowData) => {
        try {
            setLoading(true);
            const modelId = rowData.model_id;
            changeTab('taskType');

            setSelectedProjectModelId(modelId);
            setSelectedProjectModelName(rowData.model_name);

            getTaskTypeByProjectModel(modelId)
                .then((response) => {
                    setAssignedTaskTypes(response);
                });

            await getUnassignedTaskTypeByProjectModel(modelId)
                .then((response) => {
                    setUnassignedTaskTypes(response);
                });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleTaskTypeUpdate = async (selectedTaskTypes) => {
        const projectModelTaskTypeMap = [];
        selectedTaskTypes.map((taskType) => {
            projectModelTaskTypeMap.push({
                'model_id': selectedProjectModelId,
                'task_type_id': taskType.task_type_id,
                'status': 'A',
                'approved': 'Y',
            });
        });

        await addTaskType({
            'model_id': selectedProjectModelId,
            'ProjectModelTaskType': projectModelTaskTypeMap,
        }).then((response) => {
            if (response) {
                showActionFeedback(response.message)
            }
        });
    }

    const handleSubmit = async (values) => {
        confirmAlert({
            title: 'Confirm Submission',
            message: 'Are you sure you want to submit?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            let response;

                            if (editMode) {
                                if (values?.model_id && values?.model_id > 0) {
                                    for (const key of Object.keys(fields)) {
                                        fields[key] = values[key]
                                    }

                                    response = await updateProjectModel(values.model_id, fields);
                                }
                            } else {
                                response = await createProjectModel(values);
                            }

                            if (response) {
                                setSubmitSuccess({
                                    success: true,
                                    message: response.message,
                                    loading: false,
                                });
                                resetForm();
                                changeTab("list");
                            }
                        } catch (error) {
                            console.error(error);
                            setSubmitSuccess({
                                success: false,
                                message: error.message,
                                loading: false,
                            });
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    }


    return (
        <>
            <div className="ipp-page">
                {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}

                <Container className="mt-3 ml-0" fluid style={{ maxWidth: '100%', margin: 'auto auto' }}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="list">
                            <ProjectModelTable
                                dataList={projectModelList}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                isLoading={loading}
                                isActionAllowed={isActionAllowed}
                                changeTab={changeTab}
                                onStatusIconClick={onStatusIconClick}
                                onApprovedIconClick={onApprovedIconClick}
                                onTaskTypeIconClick={onTaskTypeIconClick}
                            />
                        </TabPane>
                        <TabPane tabId="add">
                            <ProjectModelForm
                                initValues={initValues}
                                changeTab={changeTab}
                                loading={loading}
                                resetForm={resetForm}
                                handleSubmit={handleSubmit}
                            />
                        </TabPane>
                        <TabPane tabId="taskType">
                            <ProjectModelTaskType
                                projectModelName={selectedProjectModelName}
                                assignedTaskTypes={assignedTaskTypes}
                                unassignedTaskTypes={unassignedTaskTypes}
                                handleTaskTypeUpdate={handleTaskTypeUpdate}
                                changeTab={changeTab}
                                resetState={resetForm}
                                loading={loading}
                            />
                        </TabPane>
                    </TabContent>
                </Container>
            </div>
        </>
    );

}

export default ProjectModelContainer;
