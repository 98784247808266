import React, { useEffect, useState } from "react"
import Container from "reactstrap/lib/Container"
import { TabContent, TabPane } from "reactstrap"
import UserForm from "./UserForm"
import UserTable from "./UserTable"
import {createUser, getDetail, listUser, updateApproved, updateStatus, updateUser} from "actions/UserFunctions"
import { getPermissionsFor } from 'actions/UtilFunctions'
import {listRole} from "../../actions/RoleFunctions"
import {getUnregisteredEmployees} from "../../actions/EmployeeFunctions"
import {confirmAlert} from "react-confirm-alert"
import ActionFeedback from "../../components/ActionFeedbackAlert"

const UserContainer = () => {

    const [initValues, setInitValues] = useState({})

    const [userList, setUserList] = useState([])
    const [roleList, setRoleList] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [employeeMap, setEmployeeMap] = useState({})

    const [editMode, setEditMode] = useState(false)
    const [activeTab, setActiveTab] = useState('list')
    const [loading, setLoading] = useState(false)
    const [actionFeedbackMsg, setActionFeedbackMsg] = useState('')

    const [allowedPermission, setAllowedPermission] = useState(getPermissionsFor('user'))

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                await refreshProject()
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const refreshProject = async () => {
        await listRole().then((roles) => {
            let roleMap = {}
            if (roles) {
                roles.forEach((role) => {
                    roleMap[role['role_id']] = role
                })
                setRoleList(roles)
            }
        })
        await listUser().then((users) => {
            if (users) {
                setUserList(users)
            }
        })
        await getUnregisteredEmployees().then((employees) => {
            let empMap = {}
            if (employees) {
                employees.forEach((employee) => {
                    empMap[employee['employee_id']] = employee
                })
                setEmployeeList(employees)
                setEmployeeMap(empMap)
            }
        });
    }

    const changeTab = (tabName) => {
        setActiveTab(tabName)
    }

    const populateForm = async (rowData) => {
        const userId = rowData.usr_id
        try {
            if (userId > 0) {
                setLoading(true)
                const detail = (await getDetail(userId))[0]
                detail['email'] = rowData['email']
                setInitValues(detail)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleEdit = async (rowData) => {
        const userId = rowData.usr_id
        setEditMode(true)
        setActiveTab('add')
        await populateForm(rowData)
    }

    const handleDelete = (rowData) => {}

    const handleStatus = (rowData) => {
        const userId = rowData.usr_id
        const status = rowData['status']
        rowData['status'] = (status === 'A' || status === 'Active') ? 'I' : 'A'
        updateStatus(userId, {'status': rowData.status}).then((response) => {
            if (response) {
                refreshProject()
                showActionFeedback(response.message)
            }
        })
    }

    const handleApproved = (rowData) => {
        const userId = rowData.usr_id
        const isApproved = rowData['approved']
        rowData['approved'] = (isApproved === 'Y' || isApproved === 'Yes' ) ? 'N' : 'Y'
        updateApproved(userId, {'approved': rowData.approved}).then((response) => {
            if (response) {
                refreshProject()
                showActionFeedback(response.message)
            }
        })
    }

    const onStatusIconClick = (e) => {
        e.preventDefault()
        const userData = e.currentTarget.getAttribute('data-user')
        handleStatus(JSON.parse(userData))
    }

    const onApprovedIconClick = (e) => {
        e.preventDefault()
        const userData = e.currentTarget.getAttribute('data-user')
        handleApproved(JSON.parse(userData))
    }

    const isActionAllowed = (action) => {
        for (let permission of allowedPermission) {
            if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true
        }
        return false;
    }

    const showActionFeedback = (message) => {
        setActionFeedbackMsg(message)
        setTimeout(() => setActionFeedbackMsg(''), 5000)
    }

    const resetForm = () => {
        setInitValues({})
        setEditMode(false)
    }

    const handleSubmit = async (values) => {
        confirmAlert({
            title: 'Confirm Submission',
            message: 'Are you sure you want to submit?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            let response

                            if (editMode) {
                                if (values.usr_id && values.usr_id > 0) {
                                    response = await updateUser(values.usr_id, {
                                        'password': values['password'],
                                        'role_id': values['role_id']
                                    })
                                }
                            } else {
                                response = await createUser({
                                    'employee_id': values['employee_id'],
                                    'password': values['password'],
                                    'role_id': values['role_id']
                                })
                            }

                            if (response) {
                                showActionFeedback(response.message)
                                changeTab('list')
                                refreshProject()
                            }
                        } catch (error) {
                            console.error(error)
                            showActionFeedback(error.message)
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        })
    }


    return <div className="ipp-page">
        {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}
        <Container className="mt-3" fluid style={{maxWidth: '100%', margin: 'auto'}}>
            <TabContent activeTab={activeTab}>

                <TabPane tabId="list">
                    <UserTable 
                        userTableList={userList}
                        employeeMap={employeeMap}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        isLoading={loading}
                        isActionAllowed={isActionAllowed}
                        changeTab={changeTab}
                        onStatusIconClick={onStatusIconClick}
                        onApprovedIconClick={onApprovedIconClick}
                    />
                </TabPane>

                <TabPane tabId="add">
                    <UserForm
                        initValues={initValues}
                        changeTab={changeTab}
                        loading={loading}
                        resetForm={resetForm}
                        handleSubmit={handleSubmit}
                        editMode={editMode}
                        userList={userList}
                        roleList={roleList}
                        employeeList={employeeList}
                        employeeMap={employeeMap}
                    />
                </TabPane>

            </TabContent>
        </Container>
    </div>
}

export default UserContainer