import { createEmployee } from 'actions/EmployeeFunctions';
import { fetchGenders } from 'actions/EmployeeFunctions';
import ActionFeedback from 'components/ActionFeedbackAlert';
import { useFormik } from 'formik';
import React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

const EmployeeForm = () => {
  const showActionFeedback = (message) => {
    setFeedback(message);
    setTimeout(() => setFeedback(null), 3000);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: '',
      last_name: '',
      middle_name: '',
      email: '',
      gender_id: 0,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      gender_id: Yup.number().min(1, 'Choose a valid gender').required('Gender is required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      createEmployee(values).then((response) => {
        if (response && response.success) {
          showActionFeedback(response.message);
          handleReset();
        }
      });
    },
  });

  const handleReset = () => {
    formik.handleReset();
  };

  const [genders, setGenders] = useState([]);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    fetchGenders()
      .then((response) => {
        setGenders(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formHeaderStyle = {
    fontSize: 20,
    color: '#00948B',
    textAlign: 'center',
    padding: 10,
  };

  return (
    <>
      {feedback && <ActionFeedback message={feedback} />}
      <Card className="border-top-0 shadow-none">
        <CardHeader style={formHeaderStyle}>Employee Information</CardHeader>
        <CardBody>
          <div className="form-container p-4" style={{ maxWidth: '40%' }}>
            <form action="#" onSubmit={formik.handleSubmit}>
              <div className="form-group form-row">
                <div className="col">
                  <label htmlFor="fname">First name</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="first_name"
                    className="form-control form-control-sm"
                    placeholder="First name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {!_.isEmpty(formik.errors) &&
                    !_.isEmpty(formik.touched) &&
                    formik.errors.first_name &&
                    formik.touched.first_name && (
                      <div className="text-danger ml-1">{formik.errors.first_name}</div>
                    )}
                </div>
              </div>
              <div className="form-group form-row">
                <div className="col">
                  <label htmlFor="mname">Middle name</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="middle_name"
                    className="form-control form-control-sm"
                    placeholder="Middle name"
                    value={formik.values.middle_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="form-group form-row">
                <div className="col">
                  <label htmlFor="lname">Last name</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="last_name"
                    className="form-control form-control-sm"
                    placeholder="Last name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {!_.isEmpty(formik.errors) &&
                    !_.isEmpty(formik.touched) &&
                    formik.errors.last_name &&
                    formik.touched.last_name && (
                      <div className="text-danger ml-1">{formik.errors.last_name}</div>
                    )}
                </div>
              </div>

              <div className="form-group form-row">
                <div className="col">
                  <label htmlFor="email">Email</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="email"
                    className="form-control form-control-sm"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {!_.isEmpty(formik.errors) &&
                    !_.isEmpty(formik.touched) &&
                    formik.errors.email &&
                    formik.touched.email && (
                      <div className="text-danger ml-1">{formik.errors.email}</div>
                    )}
                </div>
              </div>
              <div className="form-group form-row">
                <div className="col">
                  <label htmlFor="email">Gender</label>
                </div>
                <div className="col">
                  <select
                    name="gender_id"
                    id="gender"
                    className="form-control form-control-sm"
                    value={formik.values.gender_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={0}></option>
                    {genders.map((gender, key) => (
                      <option value={gender.gender_id} key={key}>
                        {gender.gender_name}
                      </option>
                    ))}
                  </select>
                  {!_.isEmpty(formik.errors) &&
                    !_.isEmpty(formik.touched) &&
                    formik.errors.gender_id &&
                    formik.touched.gender_id && (
                      <div className="text-danger ml-1">{formik.errors.gender_id}</div>
                    )}
                </div>
              </div>

              <Button
                type="submit"
                color="primary"
                className="fixedWidthButton"
                style={{ float: 'right' }}
              >
                Save
              </Button>
            </form>
          </div>
        </CardBody>
      </Card>
      <div className="container-fluid"></div>
    </>
  );
};

export default EmployeeForm;
