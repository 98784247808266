import { createClient, fetchAllClient, getClientTypes, updateApproved, updateClient, updateStatus, deleteClient, getDetail } from '../../actions/ClientFunctions'
import { getPermissionsFor } from 'actions/UtilFunctions';

import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash'
import ActionFeedback from 'components/ActionFeedbackAlert';
import { TabContent, TabPane } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import ClientInfoTable from './ClientInfoTable';
import ClientInfoForm from './ClientInfoForm';
import { confirmAlert } from 'react-confirm-alert';


const ClientInfoContainer = () => {
    const [initValues, setInitValues] = useState({});
    const fields = {
        address: '',
        client_abbr: '',
        client_cd: '',
        client_name: '',
        client_type_id: '',
        email: '',
        telephone_no: '',
        updated_by: '',
        updated_dt: ''
    }
    const [clientList, setClientList] = useState([]);

    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('list');
    const [actionFeedbackMsg, setActionFeedbackMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [allowedPermission, setAllowedPermission] = useState(getPermissionsFor('client'));
    const [clientTypes, setClientTypes] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState({
        success: false,
        loading: false,
        message: '',
    });

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                refreshProject();

                getClientTypes().then(res => {
                    setClientTypes(res)
                });

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })()
    }, []);

    useEffect(() => {
        if (submitSuccess.success) {
            refreshProject();
            showActionFeedback(submitSuccess.message || 'Success');
        }
    }, [submitSuccess]);

    const refreshProject = async () => {
        await fetchAllClient().then((res) => {
            const sortedClientList = _.sortBy(res, (obj) => obj.order_no);
            setClientList(sortedClientList);
        });
    };

    const changeTab = (tabName) => {
        setActiveTab(tabName);
    };

    const handleEdit = async (rowData) => {
        const clientId = rowData.client_id;
        setEditMode(true);
        setActiveTab('add');
        await pouplateForm(clientId);
    };

    const pouplateForm = async (clientId) => {
        try {
            if (clientId > 0) {
                setLoading(true);
                const detail = (await getDetail(clientId))[0];
                setInitValues(detail);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    };

    const handleDelete = (rowData) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const clientId = rowData.client_id;
                        deleteClient(clientId)
                            .then((response) => {
                                if (response) {
                                    const client_list = clientList.filter(
                                        (client) => client.client_id !== clientId,
                                    );
                                    setClientList(client_list);
                                    showActionFeedback(response.message);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    };

    const isActionAllowed = (action) => {
        for (let permission of allowedPermission) {
            if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true;
        }
        return false;
    };

    const onStatusIconClick = (e) => {
        e.preventDefault();
        const clientData = e.currentTarget.getAttribute('data-client');
        handleStatus(JSON.parse(clientData));
    };

    const handleStatus = (rowData) => {
        const clientId = rowData.client_id
        const status = rowData['status']
        rowData['status'] = status === 'A' ? 'I' : 'A'
        updateStatus(clientId, rowData).then((response) => {
            if (response) {
                const updatedList = clientList.map(client => {
                    if (client.client_id === clientId) {
                        return { ...client, status: (status === 'A' ? 'I' : 'A') };
                    }
                    return client;
                });

                setClientList(updatedList);
                showActionFeedback(response.message);
            }
        })
    }

    const onApprovedIconClick = (e) => {
        e.preventDefault();
        const clientData = e.currentTarget.getAttribute('data-client');
        handleApprove(JSON.parse(clientData))
    };

    const handleApprove = (rowData) => {
        const clientId = rowData.client_id
        const isApproved = rowData['approved']
        rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
        updateApproved(clientId, rowData).then((response) => {
            if (response) {
                const updatedList = clientList.map(client => {
                    if (client.client_id === clientId) {
                        return { ...client, approved: (client.approved === 'Y' ? 'N' : 'Y') }
                    }
                    return client;
                });

                setClientList(updatedList);
                showActionFeedback(response.message)
            }
        })
    };

    const showActionFeedback = (message) => {
        setActionFeedbackMsg(message);
        setTimeout(() => setActionFeedbackMsg(''), 5000);
    };

    const resetForm = () => {
        setInitValues({});
        setEditMode(false);
        setSubmitSuccess({ success: false, loading: false, message: '' });
    };

    const handleSubmit = async (values) => {
        confirmAlert({
            title: 'Confirm Submission',
            message: 'Are you sure you want to submit?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            let response;
                            if (editMode) {
                                if (values?.client_id && values?.client_id > 0) {
                                    for (const key of Object.keys(fields)) {
                                        fields[key] = values[key]
                                    }

                                    response = await updateClient(values.client_id, fields);
                                }
                            } else {
                                response = await createClient(values);
                            }

                            if (response) {
                                setSubmitSuccess((prev) => ({
                                    ...prev,
                                    success: true,
                                    message: response.message,
                                    loading: false,
                                }));
                                resetForm();
                                changeTab("list");
                            }
                        } catch (error) {
                            console.error(error);
                            setSubmitSuccess((prev) => ({
                                ...prev,
                                success: false,
                                message: error.message,
                                loading: false,
                            }));
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    };

    return (
        <div className="ipp-page">
            <Fragment>
                {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}

                <Container className="mt-3 ml-0" fluid style={{ maxWidth: '100%', margin: 'auto auto' }}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="list">
                            <ClientInfoTable
                                clientTableList={clientList}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                isLoading={loading}
                                isActionAllowed={isActionAllowed}
                                changeTab={changeTab}
                                onStatusIconClick={onStatusIconClick}
                                onApprovedIconClick={onApprovedIconClick}
                            />
                        </TabPane>
                        <TabPane tabId="add">
                            <ClientInfoForm
                                initValues={initValues}
                                changeTab={changeTab}
                                loading={loading}
                                clientTypeList={clientTypes}
                                resetForm={resetForm}
                                handleSubmit={handleSubmit}
                            />
                        </TabPane>
                    </TabContent>
                </Container>
            </Fragment>
        </div>
    );
}

export default ClientInfoContainer;