import GenericPieChart from "components/Charts/GenericPieChart";
import React, {  } from "react";
import TableContainer from "../../ProjectHealth/TableContainer";
import { ResponsiveContainer } from "recharts";
import _ from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { CircularProgress } from "@material-ui/core";
import GenericBarChart from "components/Charts/GenericBarChart";

export const ResourceInfoComponent = ({ resourcePiData, resourceBarData, resourceProjectNameBarData}) => {
  return (
    <div className="d-flex flex-column">
      <section>
        <div className="row pb-10">
          <div id="pichart" className="col-md-6">
            <Card className="h-100">
              <CardBody>
                {resourcePiData ? (
                  <GenericPieChart
                    data={resourcePiData}
                    colors={colors}
                    height={350}
                  />
                ) : (
                  <CircularProgress />
                )}
              </CardBody>
            </Card>
          </div>
          <div id="summary_table" className="col-md-6">
            <Card>
              <CardBody className="align-items-center">
                <ResponsiveContainer height={350} width="100%">
                  <TableContainer data={[...resourcePiData]} id="pi" />
                </ResponsiveContainer>
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
      <br/>
      <section>
        <div>
          <div id="bar_graph">
            <Card>
              <CardBody>
                <GenericBarChart
                  dataKey="name"
                  colors={colors}
                  data={resourceBarData?.rows}
                  keyset={resourceBarData?.keySet}
                  height={250}
                />
              </CardBody>
            </Card>
          </div>
          <div id="detail_table">
            <Card>
              <CardBody>
                <TableContainer
                  data={resourceBarData.rows}
                  keySet={resourceBarData.keySet}
                  id="bar"
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
      <br/>
      <section>
        <div>
          <div id="project_name_bar_graph">
            <Card>
              <CardBody>
                {/* <CardTitle>Project Resource Distribution</CardTitle> */}
                <GenericBarChart
                  dataKey="name"
                  stackId="a"
                  colors={colors}
                  data={resourceProjectNameBarData?.rows}
                  keyset={resourceProjectNameBarData?.keySet}
                  height={250}
                />
              </CardBody>
            </Card>
          </div>
          <div id="detail_table">
            <Card>
              <CardBody>
                <TableContainer
                  data={resourceProjectNameBarData?.rows}
                  keySet={resourceProjectNameBarData?.keySet}
                  id="bar"
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
      <br/>
    </div>
  );
};

const colors = [
  "#80add7",
  "#c0334d",
  "#00743f",
  "#93a806",
  "#d8d583",
  "#0294a5",
  "#665191",
  "#6a8a82",
  "#a37c27",
  "#563838",
  "#0444bf",
  "#a79674",
  "#f05837",
  "#aba6bf",
  "#bf988f",
  "#192e5b",
  "#f2a104",
  "#888c46",
  "#0abda0",
];
