import React, { Fragment, useState } from 'react'
import ProjectHealth from 'container/ProjectHealth'
import GenericPieChart from 'container/ProjectHealth/PiChartContainer'
import GenericBarChart from 'container/ProjectHealth/BarChartContainer'
import TableContainer from 'container/ProjectHealth/TableContainer'

import { getProjectDistributionDetail } from '../actions/DashboardFunctions'
import ResourceDistribution from '../container/ProjectHealth/ResourceDistribution'
import ToggleButton from '../components/ProjectHealth/ToggleButton'

import { Card, CardBody, CardFooter } from 'reactstrap'
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

import _ from 'lodash'
import RAIDContainer from '../container/ProjectHealth/RAIDContainer'
import DashboardContainer from 'container/DashboardContainer/DashboardContainer'
import ProjectInfo from 'container/DashboardContainer/ProjectInfo'
import { ResourceInfo } from 'container/DashboardContainer/ResourceInfo'
import ExecutiveDashboardContainer from 'container/ProjectHealth/ExecutiveDashboardContainer'

const options = [
  'stream_cd',
  'client_name',
  'client_type_name',
  'state_project',
  'project_type',
  'project_category',
]

const GraphOptions = ({
  selected,
  otherSelected,
  options,
  id,
  handleSelection,
}) => (
  <UncontrolledDropdown size='sm'>
    <DropdownToggle caret>{getOptionName(selected)}</DropdownToggle>
    <DropdownMenu>
      {options.map(
        (option, idx) =>
          option !== selected &&
          option !== otherSelected && (
            <DropdownItem
              key={`res-bar-${id}-${idx}`}
              data-id={id}
              data-key={option}
              onClick={handleSelection}
            >
              {getOptionName(option)}
            </DropdownItem>
          )
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
)

let Total = ''

const GraphCard = ({
  id,
  selected,
  otherSelected,
  options,
  handleSelection,
  data,
  keySet,
  colors,
}) => {
  return (
    <Card>
      <CardBody>
        {id === 'pi' ? (
          <GenericPieChart data={data} colors={colors} />
        ) : (
          <GenericBarChart data={data} keySet={keySet} colors={colors} />
        )}
      </CardBody>
      <CardFooter>
        <GraphOptions
          id={`${id}-left`}
          selected={selected}
          otherSelected={otherSelected}
          options={options}
          handleSelection={handleSelection}
        />
        {id === 'pi' ? null : (
          <GraphOptions
            id={`${id}-right`}
            selected={otherSelected}
            otherSelected={selected}
            options={options}
            handleSelection={handleSelection}
          />
        )}

        {/* Total Projects: 
        {getTotal(data)}
        
        {console.log("HHHHHHHHHHHHHHHHHHHHHHHHH",data)}
        {console.log("JUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU",keySet)} */}
      </CardFooter>
    </Card>
  )
}

const getTotal = (data) => {
  let Total = 0
  for (let i = 0; i < data.length; i++) {
    let keyArray = Object.keys(data[i])
    for (let j = 1; j < keyArray.length; j++) {
      Total += data[i][keyArray[j]]
    }
  }

  return Total
}

const TableCard = ({
  id,
  selected,
  otherSelected,
  options,
  handleSelection,
  data,
  keySet,
  colors,
  matColumns,
}) => {
  return (
    <Card>
      <CardBody>
        {id === 'pi' ? (
          // <GenericPieChart
          //   data={data}
          //   colors={colors}
          // />
          <TableContainer data={[...data]} id={id} />
        ) : (
          // <GenericBarChart this is for the bar graph table visualization
          <TableContainer data={data} keySet={keySet} id={id} />
        )}
      </CardBody>
      <CardFooter>
        <GraphOptions
          id={`${id}-left`}
          selected={selected}
          otherSelected={otherSelected}
          options={options}
          handleSelection={handleSelection}
        />
        {id === 'pi' ? null : (
          <GraphOptions
            id={`${id}-right`}
            selected={otherSelected}
            otherSelected={selected}
            options={options}
            handleSelection={handleSelection}
          />
        )}
      </CardFooter>
    </Card>
  )
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: colors,
      dist: [],
      projectPieOptions: [...options],
      projectBarOptions: [...options],
      projectPiSelected: 'state_project',
      projectBarSelected: 'stream_cd',
      projectBarData: [],
      projectPiData: [],
      projectBarKeySet: [],
      toggleSelection: 'health', //health selection
    }
  }

  setSelection = (event, option) => {
    if (option !== null) {
      this.setState({
        toggleSelection: option,
      })
    }
  }

  componentDidMount() {
    getProjectDistributionDetail().then((response) =>
      this.setState({ dist: response }, () => this.updateProjectGraphs())
    )
  }

  toggleProjectPiDrop = () => {
    this.setState({
      projectDropPiOpen: !this.state.projectDropPiOpen,
    })
  }

  selectProjectPi = (e) => {
    const value = e.currentTarget.getAttribute('data-key')
    this.setState(
      {
        projectPiSelected: value,
      },
      () => this.updateProjectGraphs()
    )
  }

  toggleProjectBarDrop = () => {
    this.setState({
      projectDropBarOpen: !this.state.projectDropBarOpen,
    })
  }

  selectProjectBar = (e) => {
    const id = e.currentTarget.getAttribute('data-id')
    const value = e.currentTarget.getAttribute('data-key')
    if (id === 'bar-right') {
      this.setState(
        {
          projectBarSelected: value,
        },
        () => this.updateProjectGraphs()
      )
    } else {
      this.setState(
        {
          projectPiSelected: value,
        },
        () => this.updateProjectGraphs()
      )
    }
  }

  // counts the objectArray based on property
  countBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property]
      if (key in acc) {
        acc[key]++
      } else {
        acc[key] = 1
      }
      return acc
    }, {})
  }

  // groups the objectArray by property
  groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  // converts an object into array
  // {key: count} is converted into
  // [{name: 'key', value: count}]
  convertObjectToArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      name: key,
      value: obj[key],
    }))
  }

  getProjectBarData = (dist, piSelected, barSelected) => {
    const grouped = this.groupBy(dist, piSelected)
    const result = []
    const keySet = new Set()
    Object.keys(grouped).map((key) => {
      const groupedCount = this.countBy(grouped[key], barSelected)
      Object.keys(groupedCount).map((key) => keySet.add(key))
      result.push({
        name: key,
        ...groupedCount,
      })
    })
    return { data: result, keySet: Array.from(keySet) }
  }

  updateProjectGraphs = () => {
    const { dist, projectPiSelected, projectBarSelected } = this.state
    const projectPiData = this.convertObjectToArray(
      this.countBy(dist, projectPiSelected)
    )
    const { data, keySet } = this.getProjectBarData(
      dist,
      projectPiSelected,
      projectBarSelected
    )
    this.setState({
      projectPiData: projectPiData,
      projectBarData: data,
      projectBarKeySet: keySet,
    })
  }

  render() {
    const { toggleSelection } = this.state
    const {
      projectPiData,
      projectPiSelected,
      projectBarSelected,
      projectBarKeySet,
      projectBarData,
    } = this.state
    return (
      <div>
        {toggleSelection === "dashboard" && (
          <div className="container-fluid">
            <div className="mt-2">
              <div className="d-flex flex-row justify-content-between">
                <h2>Project Distribution</h2>
                <ToggleButton
                  selection={toggleSelection}
                  setSelection={this.setSelection}
                />
              </div>
              <div className="row">
                <div className={projectPiData.length < 4 ? "col-md-6" : "col-12"}>
                  <GraphCard
                    id="pi"
                    selected={projectPiSelected}
                    otherSelected={projectBarSelected}
                    data={projectPiData}
                    colors={colors}
                    options={options}
                    handleSelection={this.selectProjectPi}
                  />
                </div>
                <div
                  className={projectPiData.length < 4 ? "col-md-6" : "col-12"}
                  style={{ height: "10px;" }}
                >
                  <TableCard
                    id="pi"
                    selected={projectPiSelected}
                    otherSelected={projectBarSelected}
                    data={projectPiData}
                    colors={colors}
                    options={options}
                    handleSelection={this.selectProjectPi}
                  />
                </div>
              </div>

              {/* For table display */}
              <br />
              <div className="row">
                <div className={projectBarData.length < 4 ? "col-md-6" : "col-12"}>
                  <GraphCard
                    id="bar"
                    selected={projectPiSelected}
                    otherSelected={projectBarSelected}
                    data={projectBarData}
                    colors={colors}
                    options={options}
                    keySet={projectBarKeySet}
                    handleSelection={this.selectProjectBar}
                  />
                </div>

                <div className={projectBarData.length < 4 ? "col-md-6" : "col-12"}>
                  <TableCard
                    id="bar"
                    selected={projectPiSelected}
                    otherSelected={projectBarSelected}
                    data={projectBarData}
                    colors={colors}
                    options={options}
                    keySet={projectBarKeySet}
                    handleSelection={this.selectProjectBar}
                  />
                </div>
              </div>

              {/* end table display */}
            </div>

            {/* <div>
              <ResourceDistribution className="mt-2" />
            </div> */}
          </div>
        )}
        {toggleSelection === "projectInfo" && (
          <div>
            <div className="container-fluid">
              <div className="mt-2">
                <div className="d-flex flex-row justify-content-between">
                  <h2>Project Distribution</h2>
                  <ToggleButton
                    selection={toggleSelection}
                    setSelection={this.setSelection}
                  />
                </div>

                <div>
                  <ProjectInfo />
                </div>

                {/* end table display */}
              </div>

              {/* <div>
              <ResourceDistribution className="mt-2" />
            </div> */}
            </div>
          </div>
        )}
        {toggleSelection === "resourceInfo" && (
          <div>
            <div className="container-fluid">
              <div className="mt-2">
                <div className="d-flex flex-row justify-content-between">
                  <h2>Resource Distribution</h2>
                  <ToggleButton
                    selection={toggleSelection}
                    setSelection={this.setSelection}
                  />
                </div>

                <div>
                  <ResourceInfo />
                </div>

                {/* end table display */}
              </div>

              {/* <div>
              <ResourceDistribution className="mt-2" />
            </div> */}
            </div>
          </div>
        )}

        {toggleSelection === "resource" && (
          <div className="container-fluid">
            <ResourceDistribution
              className="mt-2"
              selection={toggleSelection}
              setSelection={this.setSelection}
            />
          </div>
        )}

        {toggleSelection === "health" && (
          <div className="container-fluid">
            <ProjectHealth selection={toggleSelection} setSelection={this.setSelection} />
          </div>
        )}

        {toggleSelection === "raid" && (
          <div className="container-fluid">
            <RAIDContainer selection={toggleSelection} setSelection={this.setSelection} />
          </div>
        )}
        {toggleSelection === "pmo_report" && (
          <div className="container-fluid">
            <ExecutiveDashboardContainer selection={toggleSelection} setSelection={this.setSelection}/>
          </div>
        )}
      </div>
    );
  }
}

export default Index

const getOptionName = (key) => {
  switch (key) {
    case 'stream_cd':
      return 'Stream'
    case 'client_name':
      return 'Client'
    case 'client_type_name':
      return 'Client Type'
    case 'state_project':
      return 'State'
    case 'project_type':
      return 'Project Type'
    case 'project_category':
      return 'Project Category'
    default:
      return null
  }
}

const colors = [
  '#80add7',
  '#c0334d',
  '#00743f',
  '#93a806',
  '#d8d583',
  '#0294a5',
  '#665191',
  '#6a8a82',
  '#a37c27',
  '#563838',
  '#0444bf',
  '#a79674',
  '#f05837',
  '#aba6bf',
  '#bf988f',
  '#192e5b',
  '#f2a104',
  '#888c46',
  '#0abda0',
]
