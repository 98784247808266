import React, { useState, Fragment } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Container,
} from 'reactstrap'
import 'assets2/button.scss'

const style = { fontSize: '12px', padding: 10, lineHeight: '20px' }

const ProjectStateModal = (props) => {
  const [modal, setModal] = useState(props.isModal)

  const toggle = () => {
    setModal(!modal)
    props.resetState()
  }

  const createStateList = () => {
    return props.state_list.map((radio) => (
      <FormGroup check inline>
        <Label check>
          <Input
            type="radio"
            key={radio.id}
            value={radio.id}
            onChange={props.handleStateChange}
            checked={props.selectedRowData.state_project === radio.id}
          />
          {radio.name}
        </Label>
      </FormGroup>
    ))
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader
        style={{
          backgroundColor: '#fff',
          color: 'white',

          padding: 10,
        }}
      >
        Project: {props.selectedRowData.project_name}
      </ModalHeader>
      <ModalBody style={style}>
        <Container>
          <Form onSubmit={props.handleSubmitState}>
            <FormGroup row>
              <Col sm={2}>
                <Label>State</Label>
              </Col>
              <Col sm={10}>{createStateList()}</Col>
            </FormGroup>
            <FormGroup row>
              <Label for="state_date" sm={2}>
                Date
              </Label>
              <Col sm={10}>
                <Input
                  type="date"
                  name="state_date"
                  value={props.fields['state_date']}
                  onChange={props.handleUserInput}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="state_remarks" sm={2}>
                Remarks
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="state_remarks"
                  value={props.fields['state_remarks']}
                  onChange={props.handleUserInput}
                />
              </Col>
            </FormGroup>
            <Row style={{ float: 'right' }}>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="danger" onClick={toggle}>
                Cancel
              </Button>
            </Row>
          </Form>
        </Container>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}

export default ProjectStateModal
