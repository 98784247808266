import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { theme } from '../utils/Theme';
import { MuiThemeProvider } from '@material-ui/core/styles'

export default function Loading() {
  return (
    <MuiThemeProvider theme={theme}>
      <CircularProgress 
        color="secondary" 
        style={{position: 'absolute', left: '50%', top: '50%'}}
      />
    </MuiThemeProvider>
  );
}