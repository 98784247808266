import TimeSheetContainer from "container/TimeSheetContainer";
import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import { getUserId } from "../../actions/UtilFunctions";
import { getDetail, listUser } from "../../actions/UserFunctions";
import {
  fetchAllResources,
  getProjectResourceIds,
  getProjectResourceIdStreamLead,
} from "../../actions/ResourceFunctions";
import {
  getAllFieldsOptions,
  fetchResourceHistory,
} from "../../actions/ResourceHistoryFunctions";
import { auto } from "@popperjs/core";
import Loading from "../Loading";
import { duration } from "moment";
import MaterialTable from "material-table";
import Axios from "axios";
import { stream } from "exceljs";

import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useFormik } from "formik";

// excel sheet export imports
import { saveAs } from "file-saver";
const ExcelJS = require("exceljs");

const MembersView = () => {
  const [isDataFetched, setisDataFetched] = useState(true);
  const [projects, setProjects] = useState([]);
  const [resources, setResources] = useState([]);
  const [streams, setStreams] = useState([]);
  const [tableData, setTableData] = useState([]);
  // async function asyncSetUser(user) {
  //   setUser(user);
  // }

  const tableColumnStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#00948B",
    backgroundColor: "#E1EBF0",
    textTransform: "none",
    textAlign: "center",
    width: "5%",
  };

  const cellStyle = {
    padding: "8px",
    color: "#50575D",
  };

  useEffect(() => {
    // const response = await Axios.get("https://jsonplaceholder.typicode.com/posts");
    // setData(response.data);
    // console.log(response.data);
    // console.log("check this our");
    async function fetchData() {
      const allFieldsOptions = await getAllFieldsOptions();
      setProjects(allFieldsOptions.data["projects"]);
      setResources(allFieldsOptions.data["resources"]);
      setStreams(allFieldsOptions.data["streams"]);
    }
    fetchData();
  }, []);

  // formik initialization
  const formik = useFormik({
    initialValues: {
      resource_id: null,
      project_id: null,
      stream_id: "",
      status: "",
      resource_st_dt: "",
      resource_end_dt: "",
      min_tenure: "",
    },
    onSubmit: async (values) => {
      // values["min_tenure"] = tenureMap
      const res = await fetchResourceHistory({
        ...values,
        ...tenureMap[values.min_tenure],
      });
      console.log("From get Relevant projects", res);
      setTableData(res.data);
    },
  });

  const tenureMap = {
    "1 mon": {
      min_tenure: "0 days",
      max_tenure: "30 days",
    },
    "2 mons": {
      min_tenure: "31 days",
      max_tenure: "60 days",
    },
    "3 mons": {
      min_tenure: "61 days",
      max_tenure: "90 days",
    },
    "4 mons": {
      min_tenure: "91 days",
      max_tenure: "120 days",
    },
    "5 mons": {
      min_tenure: "121 days",
      max_tenure: "150 days",
    },
    "6 mons": {
      min_tenure: "5 mons 1 day",
      max_tenure: "6 mons",
    },
    "1 year": {
      min_tenure: "181 days",
      max_tenure: "365 days",
    },
    "2 years": {
      min_tenure: "1 year",
      max_tenure: "2 years",
    },
  };

  const columns = [
    { title: "Project", field: "project_name", cellStyle },
    { title: "Resource Name", field: "resource_name", cellStyle, width: "20%" },
    {
      title: "Status",
      field: "status",
      cellStyle,
      render: (rowData) => (rowData.status === "A" ? "Active" : "Inactive"),
    },
    { title: "Stream", field: "stream_name", cellStyle },
    { title: "Tenure", field: "tenure", cellStyle },
    {
      title: "Start Date",
      field: "resource_st_dt",
      cellStyle,
      render: (rowData) =>
        rowData.resource_st_dt ? rowData.resource_st_dt.slice(0, 10) : "",
    },
    {
      title: "End Date",
      field: "resource_end_dt",
      cellStyle,
      render: (rowData) =>
        rowData.resource_end_dt ? rowData.resource_end_dt.slice(0, 10) : "",
    },
  ];

  const onExportClick = async (resourceTableData) => {
    if (resourceTableData.length === 0) {
      return;
    }

    console.log("this button was clicked!1");
    const workbook = new ExcelJS.Workbook();
    const resourceSheet = workbook.addWorksheet("Resource History Sheet");
    const excelColumns = [];

    columns.map((column) => {
      column.title === "Project" || column.title === "Resource Name"
        ? excelColumns.push({
            header: column.title,
            key: column.field,
            style: { alignment: { wrapText: true } },
            width: 40,
          })
        : excelColumns.push({
            header: column.title,
            key: column.field,
            style: { alignment: { wrapText: true } },
            width: 20,
          });
    });

    resourceSheet.columns = [...excelColumns];
    resourceTableData.map((row) =>
      resourceSheet.addRow({
        ...row,
        status: row.status === "A" ? "Active" : "Inactive",
      })
    );

    resourceSheet.getRow(1).font = {
      color: { argb: "ff00948B" },
      bold: true,
    };

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExt = ".xlsx";
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, "Resource Sheet - " + new Date().toISOString().slice(0, 10) + fileExt);
  };

  console.log("total no of projects is ", projects.length);

  return (
    <div className="ipp-page">
      <h2 style={{ color: "#00948B" }}>Resource History</h2>
      <Card className="mt-3 mb-3">
        <CardBody>
          <Form className="row" onSubmit={formik.handleSubmit}>
            {/* 1st row start */}
            <div className="col-md-5">
              <FormGroup row>
                <Col sm={4}>
                  <Label for="resource_id">Resource Name</Label>
                </Col>
                <Col sm={8}>
                  <Input
                    type="select"
                    name="resource_id"
                    id="resource_id"
                    bsSize="sm"
                    placeholder="All"
                    value={formik.values.resource_id}
                    onChange={formik.handleChange}
                  >
                    <option value="">All</option>
                    {resources &&
                      resources.map((resource) => {
                        return (
                          <option value={resource.resource_id} key={resource.resource_id}>
                            {resource.resource_name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup row>
                <Col md={2} className="pl-0">
                  <Label for="project_id">Project</Label>
                </Col>
                <Col md={10} className="pl-0">
                  <Input
                    type="select"
                    name="project_id"
                    id="project_id"
                    bsSize="sm"
                    placeholder="All"
                    value={formik.values.project_id}
                    onChange={formik.handleChange}
                  >
                    <option value="">All</option>
                    {projects &&
                      projects.map((project) => (
                        <option key={project.project_id} value={project.project_id}>
                          {project.project_name}
                        </option>
                      ))}
                  </Input>
                </Col>
              </FormGroup>
            </div>
            <div className="col-md-3">
              <FormGroup row className="mb-0">
                <Col sm={{ size: "3" }} className="pl-0">
                  <Label for="stream_id">Stream</Label>
                </Col>
                <Col sm={{ size: "9" }} className="pl-0">
                  <Input
                    type="select"
                    name="stream_id"
                    id="stream_id"
                    bsSize="sm"
                    value={formik.values.stream_id}
                    onChange={formik.handleChange}
                  >
                    <option value="">All</option>
                    {streams &&
                      streams.map((stream) => (
                        <option value={stream.stream_id} key={stream.stream_id}>
                          {stream.stream_name}
                        </option>
                      ))}
                  </Input>
                </Col>
              </FormGroup>
            </div>
            {/* 1st row end */}

            {/* 2nd row start */}
            <div className="col-md-5">
              <FormGroup row>
                <Col md={4} className="pr-0">
                  <Label for="resource_st_dt">Start Date / End Date </Label>
                </Col>

                <Col md={4} className="d-flex">
                  <Input
                    type="date"
                    name="resource_st_dt"
                    id="resource_st_dt"
                    bsSize="sm"
                    value={formik.values.resource_st_dt}
                    onChange={formik.handleChange}
                  />
                  <span
                    style={{ fontSize: "18px", marginLeft: "10px" }}
                    className="d-flex justify-content-center"
                  >
                    ~
                  </span>
                </Col>

                <Col md={4}>
                  <Input
                    type="date"
                    name="resource_end_dt"
                    id="resource_end_dt"
                    bsSize="sm"
                    value={formik.values.resource_end_dt}
                    onChange={formik.handleChange}
                  />
                </Col>
                <FormGroup />
              </FormGroup>
            </div>

            <div className="col-md-4">
              <FormGroup row>
                <Col md={2} className="pl-0">
                  <Label for="min_tenure">Tenure</Label>
                </Col>
                <Col md={10} className="pl-0">
                  <Input
                    type="select"
                    name="min_tenure"
                    id="min_tenure"
                    bsSize="sm"
                    value={formik.values.tenure}
                    onChange={formik.handleChange}
                  >
                    <option value="">All</option>
                    <option value="1 mon">1 Month</option>
                    <option value="2 mons">2 months</option>
                    <option value="3 mons">3 months</option>
                    <option value="4 mons">4 months</option>
                    <option value="5 mons">5 months</option>
                    <option value="6 mons">6 months</option>
                    <option value="1 year">1 year</option>
                    <option value="2 years">2 years</option>
                    <option value="2 years 1 day">Above 2 years</option>
                  </Input>
                </Col>
              </FormGroup>
            </div>

            <div className="col-md-3">
              <FormGroup row>
                <Col md={{ size: "3" }} className="pl-0">
                  <Label for="status">Status</Label>
                </Col>
                <Col md={{ size: "9" }} className="pl-0">
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    bsSize="sm"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                  >
                    <option value="" key="null">
                      All
                    </option>
                    <option value="Active" key="Active">
                      Active
                    </option>
                    <option value="Inactive" key="Inactive">
                      Inactive
                    </option>
                  </Input>
                </Col>
              </FormGroup>
            </div>
            <div className="col-md-12">
              <Button
                type="submit"
                color="primary"
                className="fixedWidthButton float-right"
              >
                Filter
              </Button>
            </div>
          </Form>

          {/* <Row></Row> */}
        </CardBody>
      </Card>
      <div className="col d-flex justify-content-end">
        <Button
          color="primary"
          type="button"
          onClick={() => onExportClick(tableData)}
          disabled={!tableData.length}
        >
          Export
        </Button>
      </div>
      <MaterialTable
        columns={columns}
        data={tableData}
        options={{
          toolbar: false,
          headerStyle: tableColumnStyle,
        }}
      ></MaterialTable>
    </div>
  );
};

export default MembersView;
