import axios from 'axios'

export function sendResetLink(email) {
  return axios
    .post(
      '/password/sendPasswordResetLink',
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      console.log(response)
      return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
}

export function changePassword(
  email,
  resetToken,
  password,
  password_confirmation
) {
  return axios
    .post(
      '/password/resetPassword',
      {
        email,
        resetToken,
        password,
        password_confirmation,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      console.log(response)
      return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
}
