import React, { useState } from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../utils/Theme'
import ProjectInfo from './ProjectInfo'
import ResourceInfo from './ResourceInfo'

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export const StyledToggleButton = withStyles((theme) => ({
  label: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: '#282d31',
  },
  root: {
    height: '2rem',
  },
}))(ToggleButton)

export const ToggleTabButtons = ({ selection, handleSelection, children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <StyledToggleButtonGroup
        value={selection}
        exclusive
        size='small'
        onChange={handleSelection}
        aria-label='dashboard toggle'
      >
        {children}
      </StyledToggleButtonGroup>
    </MuiThemeProvider>
  )
}
