import axios from "axios";
import { getUserId, getUserToken } from "./UtilFunctions";

export const fetchResources = (projectId, cancelToken) => {
  return axios
    .get(`/projectresource/listResource/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateResource = (projectId, request) => {
  const token = getUserToken();
  const id = getUserId();
  request["Resourcedetail"].map((resources) => {
    resources["role_id"]= parseInt(resources?.role_id) || null;
    resources["updated_by"] = id;
    resources["updated_dt"] = new Date().toISOString();
  });
 console.log("Request",request);
  return axios
    .put(`/projectresource/updateResource/${projectId}`, request, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const fetchProjects = (resourceId) => {
  const token = getUserToken();
  return axios
    .get(`/projectresource/getProjects/${resourceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Response from server (Getting Projects for timehseet)", response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error while fetching projects for resource with id ${resourceId}`,
        error
      );
    });
};

export const fetchAllResources = async () => {
  const token = getUserToken();
  return axios
    .get(`/projectresource/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Get all active resources");
      return response.data;
    })
    .catch((error) => {
      console.log(`Error get all active resources `, error);
    });
};

export const fetchAllResourceRoles = async () => {
  const token = getUserToken();
  return axios
    .get(`/projectresource/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("Get all resource roles");
      return response.data;
    })
    .catch((error) => {
      console.log(`Error getting all resource roles `, error)
    });
};

/**
 * Function to get resource_id of projects with respect to the project lead
 * in order to allow project leads to view their team's timesheet
 */
export const getProjectResourceIds = async (leadId) => {
  const token = getUserToken();
  console.log("inside get resourceids", token);
  return axios
    .get(`/projectresource/getProjectResourceIds/${leadId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server - fetching project resource ids", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error fetching project resource ids
      ${leadId}}`,
        error
      );
    });
};

/* used to fetch the project info, its stream, lead employee and status
 */
export const getProjectResourceIdStreamLead = async (leadId) => {
  const token = getUserToken();
  console.log("inside get resourceids", token);
  return axios
    .get(`/projectresource/getProjectsStreamLead/${leadId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Response from server - fetching projects", response);
      return response.data;
    })
    .catch((error) => {
      console.log(
        `Error fetching project resource ids
     ${leadId}}`,
        error
      );
    });
};
