import React from "react";
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import {
    Card,
    Button,
    FormFeedback,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    CardHeader,
    CardBody,
    Row,
    CardFooter,
} from 'reactstrap';


const HealthCalculatorForm = ({
    initValues,
    changeTab,
    loading,
    resetForm,
    handleSubmit
}) => {
    const formFields = {
        health_calc_cd: '',
        health_calc_name: '',
        health_calc_abbr: '',
        lower_limit: '',
        upper_limit: ''
    };
    const initialValues = _.isEmpty(initValues) ? formFields : initValues;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            health_calc_cd: Yup.string().required('Code is required'),
            health_calc_name: Yup.string().required('Name is required'),
            health_calc_abbr: Yup.string().required('Abbreviation is required'),
            lower_limit: Yup.number().required('Lower Limit is required'),
            upper_limit: Yup.number().required('Upper Limit is required')
        }),
        onSubmit: async (values) => {
            await handleSubmit(values);
        }
    });


    return (
        <>
            <Card className="border-top-0 shadow-none">
                <CardHeader
                    style={{
                        fontSize: 20,
                        color: '#fff',
                        textAlign: 'center',
                        padding: 10,
                    }}
                >
                    Health Calculation
                </CardHeader>

                <CardBody style={{ maxHeight: 'none' }}>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <Form onSubmit={formik.handleSubmit}>
                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="healthCalculationCode">Code</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="health_calc_cd"
                                        id="healthCalculationCode"
                                        value={formik.values?.health_calc_cd}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.health_calc_cd &&
                                            formik.errors?.health_calc_cd
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.health_calc_cd}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="healthCalculationName">Name</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="health_calc_name"
                                        id="healthCalculationName"
                                        value={formik.values?.health_calc_name}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.health_calc_name &&
                                            formik.errors?.health_calc_name
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.health_calc_name}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="healthCalculationAbbr">Abbreviation</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        name="health_calc_abbr"
                                        id="healthCalculationAbbr"
                                        value={formik.values?.health_calc_abbr}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.health_calc_abbr &&
                                            formik.errors?.health_calc_abbr
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.health_calc_abbr}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="lowerLimit">Lower Limit</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="number"
                                        name="lower_limit"
                                        id="lowerLimit"
                                        value={formik.values?.lower_limit}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.lower_limit &&
                                            formik.errors?.lower_limit
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.lower_limit}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={1}>
                                    <Label for="upperLimit">Upper Limit</Label>
                                </Col>
                                <Col sm={5}>
                                    <Input
                                        type="number"
                                        name="upper_limit"
                                        id="upperLimit"
                                        value={formik.values?.upper_limit}
                                        bsSize="sm"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched?.upper_limit &&
                                            formik.errors?.upper_limit
                                        }
                                    />
                                    <FormFeedback invalid>
                                        {formik.errors?.upper_limit}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </CardBody>
                <CardFooter>
                    <Row style={{ float: 'right' }}>
                        <Button
                            type="submit"
                            color="primary"
                            className="fixedWidthButton"
                            onClick={formik.handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            color="danger"
                            className="fixedWidthButton"
                            onClick={() => {
                                resetForm();
                                changeTab('list');
                            }}
                        >
                            Cancel
                        </Button>
                    </Row>
                </CardFooter>
            </Card>
        </>
    );

}

export default HealthCalculatorForm;