import GenericPieChart from "components/Charts/GenericPieChart";
import GenericBarChart from "components/Charts/GenericBarChart";

import React, {  } from "react";
import TableContainer from "../../ProjectHealth/TableContainer";
// import { ResponsiveContainer } from "recharts";
import _ from "lodash";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { CircularProgress } from "@material-ui/core";
import { ResponsiveContainer } from "recharts";

export const ProjectInfoComponent = ({ projectPiData, projectBarData }) => {
  return (
    <div className="d-flex flex-column">
      <section className="pb-10">
        <Row>
          <Col md="6">
            <div id="pichart">
              <Card>
                <CardBody>
                  {projectPiData ? (
                    <GenericPieChart
                      data={projectPiData}
                      colors={colors}
                      height={350}
                    />
                  ) : (
                    <CircularProgress />
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col md="6">
            <div id="summary_table card">
              <Card >
                <CardBody className="align-items-center">
                  <ResponsiveContainer height={350} width="100%">
                    <TableContainer data={projectPiData} id="pi" />
                  </ResponsiveContainer>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </section>
      <br/>
      <section>
        <div id="bar_graph">
          <Card>
            <CardBody>
              <GenericBarChart
                dataKey="name"
                colors={colors}
                data={projectBarData?.rows}
                keyset={projectBarData?.keySet}
                height={250}
              />
            </CardBody>
          </Card>
        </div>
        <div id="detail_table">
          <Card>
            <CardBody>
              <TableContainer
                data={projectBarData.rows}
                keySet={projectBarData.keySet}
                id="bar"
              />
            </CardBody>
          </Card>
        </div>
      </section>
      <br/>
    </div>
  );
};

export const colors = [
  "#80add7",
  "#c0334d",
  "#00743f",
  "#93a806",
  "#d8d583",
  "#0294a5",
  "#665191",
  "#6a8a82",
  "#a37c27",
  "#563838",
  "#0444bf",
  "#a79674",
  "#f05837",
  "#aba6bf",
  "#bf988f",
  "#192e5b",
  "#f2a104",
  "#888c46",
  "#0abda0",
];
