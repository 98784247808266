import { getPermissionsFor } from 'actions/UtilFunctions';
import ActionFeedback from 'components/ActionFeedbackAlert';
import {
    listHealthCalculation,
    updateApproved,
    updateHealthCalculation,
    updateStatus,
    deleteHealthCalculation,
    createHealthCalculation,
} from '../../actions/HealthCalculationFunctions';
import HealthCalculatorTable from './HealthCalculatorTable';
import HealthCalculatorForm from './HealthCalculatorForm';

import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { Container, TabContent, TabPane } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';


const HealthCalculatorContainer = () => {
    const fields = {
        health_calc_cd: '',
        health_calc_name: '',
        health_calc_abbr: '',
        lower_limit: '',
        upper_limit: ''
    };

    const [initValues, setInitValues] = useState({});
    const [healthCalculatorList, setHealthCalculatorList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('list');
    const [actionFeedbackMsg, setActionFeedbackMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [allowedPermission, setAllowedPermission] = useState(getPermissionsFor('healthCalculation'));
    const [submitSuccess, setSubmitSuccess] = useState({
        success: false,
        loading: false,
        message: '',
    });

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await refresh();
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })()
    }, []);

    useEffect(() => {
        if (submitSuccess.success) {
            refresh();
            showActionFeedback(submitSuccess.message || 'Success');
        }
    }, [submitSuccess]);

    const refresh = async () => {
        await listHealthCalculation().then(res => {
            const sortedList = _.sortBy(res, obj => obj.order_no);
            setHealthCalculatorList(sortedList);
        });
    }

    const showActionFeedback = (message) => {
        setActionFeedbackMsg(message);
        setTimeout(() => setActionFeedbackMsg(''), 5000);
    }

    const changeTab = (tabName) => {
        setActiveTab(tabName);
    }

    const isActionAllowed = (action) => {
        for (let permission of allowedPermission) {
            if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true;
        }
        return false;
    }

    const handleEdit = async (rowData) => {
        try {
            const healthCalId = rowData['health_calc_id'];
            if (healthCalId > 0) {
                setEditMode(true);
                setActiveTab('add');
                setLoading(true);
                setInitValues(rowData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = (rowData) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const healthCalId = rowData.health_calc_id;
                        deleteHealthCalculation(healthCalId)
                            .then((response) => {
                                if (response) {
                                    const healthCal_list = healthCalculatorList.filter(
                                        (healthCal) => healthCal.health_calc_id !== healthCalId,
                                    );
                                    setHealthCalculatorList(healthCal_list);
                                    showActionFeedback(response.message);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                showActionFeedback('Error while deleting!');
                            });
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    }

    const handleStatus = (rowData) => {
        const healthCalId = rowData.health_calc_id;
        const status = rowData['status'];
        rowData['status'] = status === 'A' ? 'I' : 'A';
        updateStatus(healthCalId, rowData).then((response) => {
            if (response) {
                const updatedList = healthCalculatorList.map(healthCal => {
                    if (healthCal.health_calc_id === healthCalId) {
                        return { ...healthCal, status: (status === 'A' ? 'I' : 'A') };
                    }
                    return healthCal;
                });

                setHealthCalculatorList(updatedList);
                showActionFeedback(response.message);
            }
        });
    }

    const onStatusIconClick = (e) => {
        e.preventDefault();
        const healthCalData = e.currentTarget.getAttribute('data-health-cal');
        handleStatus(JSON.parse(healthCalData));
    }

    const onApprovedIconClick = (e) => {
        e.preventDefault();
        const healthCalData = e.currentTarget.getAttribute('data-health-cal');
        handleApprove(JSON.parse(healthCalData));
    }

    const handleApprove = (rowData) => {
        const healthCalId = rowData.health_calc_id;
        const isApproved = rowData['approved']
        rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
        updateApproved(healthCalId, rowData).then((response) => {
            if (response) {
                const updatedList = healthCalculatorList.map(healthCal => {
                    if (healthCal.health_calc_id === healthCalId) {
                        return { ...healthCal, approved: (isApproved === 'Y' ? 'N' : 'Y') }
                    }
                    return healthCal;
                });

                setHealthCalculatorList(updatedList);
                showActionFeedback(response.message);
            }
        });
    }

    const resetForm = () => {
        setInitValues({});
        setEditMode(false);
    }

    const handleSubmit = async (values) => {
        confirmAlert({
            title: 'Confirm Submission',
            message: 'Are you sure you want to submit?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            let response;

                            if (editMode) {
                                if (values?.health_calc_id && values?.health_calc_id > 0) {
                                    for (const key of Object.keys(fields)) {
                                        fields[key] = values[key]
                                    }

                                    response = await updateHealthCalculation(values.health_calc_id, fields);
                                }
                            } else {
                                response = await createHealthCalculation(values);
                            }

                            if (response) {
                                setSubmitSuccess({
                                    success: true,
                                    message: response.message,
                                    loading: false,
                                });
                                resetForm();
                                changeTab("list");
                            }
                        } catch (error) {
                            console.error(error);
                            setSubmitSuccess({
                                success: false,
                                message: error.message,
                                loading: false,
                            });
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    }


    return (
        <>
            <div className="ipp-page">
                {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}

                <Container className="mt-3 ml-0" fluid style={{ maxWidth: '100%', margin: 'auto auto' }}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="list">
                            <HealthCalculatorTable
                                dataList={healthCalculatorList}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                isLoading={loading}
                                isActionAllowed={isActionAllowed}
                                changeTab={changeTab}
                                onStatusIconClick={onStatusIconClick}
                                onApprovedIconClick={onApprovedIconClick}
                            />
                        </TabPane>
                        <TabPane tabId="add">
                            <HealthCalculatorForm
                                initValues={initValues}
                                changeTab={changeTab}
                                loading={loading}
                                resetForm={resetForm}
                                handleSubmit={handleSubmit}
                            />
                        </TabPane>
                    </TabContent>
                </Container>
            </div>
        </>
    );
}

export default HealthCalculatorContainer;