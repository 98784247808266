import React from "react";
import MaterialTable from "material-table";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getProjectWorkHoursReport } from "actions/DashboardReportFunctions";
import { useEffect } from "react";
import { groupBy } from "lodash";
import { Table } from "reactstrap"
const theme = createMuiTheme({
  palette: {
    fontSize: "1rem",
    primary: {
      main: "#00948B",
      fontSize: "1rem",
    },
    secondary: {
      main: "#E1EBF0",
      fontSize: "1rem",
    },
  },
});
const headerStyles = {
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
};

export default function ProjectWorkHrsTable({ selectedDate }) {
  const { isLoading, data, isError, error } = useQuery(
    ["projectWorkHoursReport", selectedDate],
    () =>
      getProjectWorkHoursReport(selectedDate).then(({ data }) => {
        let total_actual = 0;
        let total_billed = 0;
        let result = data?.map(({ actual_hrs, billed_hrs, ...rest }) => {
          let actual = parseFloat(actual_hrs) || 0;
          let billed = parseFloat(billed_hrs) || 0;
          total_actual += actual;
          total_billed += billed;
          let non_billed_hrs = actual - billed;
          return {
            actual_hrs: actual,
            billed_hrs: billed,
            non_billed_hrs,
            ...rest
          };
        });
        result.push({
          is_total: true,
          project_name: "Total",
          actual_hrs: total_actual,
          billed_hrs: total_billed,
          non_billed_hrs: total_actual - total_billed,
        });
        return result;
      })
  );

  let projectWorkHourGroupData = {};
  if (isError) {
    return <span>Error: {error.message}</span>;
  } else {
      projectWorkHourGroupData = groupBy(data, "stream_name");
  }

  
  return (
    <div className="py-2">
      <Table size="sm" bordered responsive>
        <thead className="lisColor1">
          <tr>
            <th style={headerStyles}>Stream</th>
            <th style={headerStyles}>Project Name</th>
            <th style={headerStyles} className="text-right">Actual Hours</th>
            <th style={headerStyles} className="text-right">Billed Hours</th>
            <th style={headerStyles} className="text-right">Non Billed Hours</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {Object.keys(projectWorkHourGroupData).map((stream) =>
            projectWorkHourGroupData[stream].map((row, idx) => (
              <tr key={`${idx}`}>
                {idx === 0 && <td rowSpan={Object.keys(projectWorkHourGroupData[stream]).length}>{stream === "undefined" ? "" : stream}</td>}
                <td>{row.project_name}</td>
                <td className="text-right">{row.actual_hrs%1 !=0 ? `${Math.floor(row.actual_hrs)}.00`: row.actual_hrs}</td>
                <td className="text-right">{row.billed_hrs%1 !=0 ? `${Math.floor(row.billed_hrs)}.00`: row.billed_hrs}</td>
                <td className="text-right">
                  {row.non_billed_hrs%1 !=0 ? `${Math.floor(row.non_billed_hrs)}.00`: row.non_billed_hrs}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export const ProjectWorkHrsTable2 = ({ workHourRef1, workHourRef2, selectedDate }) => {
  const { isLoading, data, isError, error } = useQuery(
    ["projectWorkHoursReport", selectedDate],
    () =>
      getProjectWorkHoursReport(selectedDate).then(({ data }) => {
        let total_actual = 0;
        let total_billed = 0;
        let result = data?.map(({ actual_hrs, billed_hrs, ...rest }) => {
          let actual = parseFloat(actual_hrs) || 0;
          let billed = parseFloat(billed_hrs) || 0;
          total_actual += actual;
          total_billed += billed;
          let non_billed_hrs = actual - billed;
          return {
            actual_hrs: actual,
            billed_hrs: billed,
            non_billed_hrs,
            ...rest
          };
        });
        result.push({
          is_total: true,
          project_name: "Total",
          actual_hrs: total_actual,
          billed_hrs: total_billed,
          non_billed_hrs: total_actual - total_billed,
        });
        return result;
      })
  );

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  let table1Data = data?.slice(0, Math.floor(data.length/2));
  let table2Data = data?.slice(Math.ceil(data.length/2), data.length);

  table1Data = groupBy(table1Data, "stream_name");
  table2Data = groupBy(table2Data, "stream_name")
  

  return (
    <div>
      <div ref={workHourRef1}>
      <Table size="sm" bordered responsive>
        <thead className="lisColor1">
          <tr>
            <th style={headerStyles}>Stream</th>
            <th style={headerStyles}>Project Name</th>
            <th style={headerStyles} className="text-right">Actual Hours</th>
            <th style={headerStyles} className="text-right">Billed Hours</th>
            <th style={headerStyles} className="text-right">Non Billed Hours</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {Object.keys(table1Data).map((stream) =>
            table1Data[stream].map((row, idx) => (
              <tr key={`${idx}`}>
                {idx === 0 && <td rowSpan={Object.keys(table1Data[stream]).length}>{stream === "undefined" ? "" : stream}</td>}
                <td>{row.project_name}</td>
                <td className="text-right">{row.actual_hrs%1 !=0 ? `${Math.floor(row.actual_hrs)}.00`: row.actual_hrs}</td>
                <td className="text-right">{row.billed_hrs%1 !=0 ? `${Math.floor(row.billed_hrs)}.00`: row.billed_hrs}</td>
                <td className="text-right">
                  {row.non_billed_hrs%1 !=0 ? `${Math.floor(row.non_billed_hrs)}.00`: row.non_billed_hrs}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
        {/* <MuiThemeProvider theme={theme}>
          <MaterialTable
            isLoading={isLoading}
            title={<h3 style={{ color: "#00948B" }}>Project Work Hours</h3>}
            options={{
              sorting: false,
              paging: false,
              search: false,
              style: { borderColor: "#D4AF37" },
              headerStyle: {
                color: "#00948B",
                fontWeight: "bold",
                paddingTop: "0px",
                paddingBottom: "5px",
                whiteSpace: "nowrap",
              },
            }}
            columns={[
              {
                title: "Project Name",
                field: "project_name",
                cellStyle: {
                  maxWidth: 250,
                },
              },
              {
                title: "Actual Hrs",
                field: "actual_hrs",
                type: "numeric",
                cellStyle: {
                  maxWidth: 100,
                },
              },
              {
                title: "Billed Hrs",
                field: "billed_hrs",
                type: "numeric",
                cellStyle: {
                  maxWidth: 100,
                },
              },
              {
                title: "Non-Billed Hrs",
                field: "non_billed_hrs",
                type: "numeric",
                cellStyle: {
                  maxWidth: 100,
                },
              },
            ]}
            data={table1Data || []}
          />
        </MuiThemeProvider> */}
      </div>
      <div ref={workHourRef2}>
        <Table size="sm" bordered responsive>
        <thead className="lisColor1">
          <tr>
            <th style={headerStyles}>Stream</th>
            <th style={headerStyles}>Project Name</th>
            <th style={headerStyles} className="text-right">Actual Hours</th>
            <th style={headerStyles} className="text-right">Billed Hours</th>
            <th style={headerStyles} className="text-right">Non Billed Hours</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {Object.keys(table2Data).map((stream) =>
            table2Data[stream].map((row, idx) => (
              <tr key={`${idx}`}>
                {idx === 0 && <td rowSpan={Object.keys(table2Data[stream]).length}>{stream === "undefined" ? "" : stream}</td>}
                <td>{row.project_name}</td>
                <td className="text-right">{row.actual_hrs%1 !=0 ? `${Math.floor(row.actual_hrs)}.00`: row.actual_hrs}</td>
                <td className="text-right">{row.billed_hrs%1 !=0 ? `${Math.floor(row.billed_hrs)}.00`: row.billed_hrs}</td>
                <td className="text-right">
                  {row.non_billed_hrs}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      </div>
    </div>
  );
}