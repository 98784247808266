import React, { Fragment } from 'react'
import ActionFeedback from '../ActionFeedbackAlert'
import _ from 'lodash'

import Container from '@material-ui/core/Container'
import MaterialTable from 'material-table'
import classnames from 'classnames'
import { Cancel, CheckCircle } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../utils/Theme'
import Loading from '../Loading';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Col,
  Form,
  FormGroup,
  CardHeader,
  CardBody,
} from 'reactstrap'

const formatCurrentTabName = (activeTab, label) => {
  return activeTab === label.toLowerCase() ? <b>{label}</b> : `${label}`
}

const materialTableOptions = {
  headerStyle: {
    color: '#00948B',
    fontWeight: 'bold',
    paddingTop: '0px',
    paddingBottom: '5px',
  },
  rowStyle: {
    hover: true,
  },
  actionsColumnIndex: -1,
  detailPanelColumnAlignment: 'right',
}

const formHeaderStyle = {
  fontSize: 20,
  color: '#00948B',
  textAlign: 'center',
  padding: 10,
}

// this component serves as a common component for Permission, Role and User group views
const ConfigComponent = (props) => {
  const activeTab = props.currentTab
  let listData = _.cloneDeep(props.data)
  listData = listData.map((row) => {
    const rowString = JSON.stringify(row)
    row['status_icon'] = (
      <IconButton onClick={props.onStatusIconClick} data-config={rowString}
        disabled={!props.isActionAllowed('status')}>
        {row['status'] === 'I' ? (
          <Tooltip title="Inactive">
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title="Active">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    )
    row['approved_icon'] = (
      <IconButton onClick={props.onApprovedIconClick} data-config={rowString}
        disabled={!props.isActionAllowed('approve')}>
        {row['approved'] === 'N' ? (
          <Tooltip title="Unapproved">
            <Cancel style={{ color: 'red' }} />
          </Tooltip>
        ) : (
          <Tooltip title="Approved">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )}
      </IconButton>
    )
    return row
  })

  return (
    <Fragment>
      {props.message && <ActionFeedback message={props.message} />}
      <Container
        className="mt-3"
        style={{ maxWidth: '100%', margin: 'auto auto' }}
      >
        {/* <Nav tabs className="border-bottom-0">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'list' })}
              onClick={() => {
                props.resetState();
                props.changeTab('list');
              }}
              style={{backgroundColor: activeTab === 'list' ? 'white' : null}}
            >
              {formatCurrentTabName(activeTab, 'List')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'add' })}
              onClick={() => {
                props.resetState();
                props.changeTab('add');
              }}
              style={{backgroundColor: activeTab === 'add' ? 'white' : null}}
            >
              {props.editMode ? formatCurrentTabName(activeTab, 'Edit') : formatCurrentTabName(activeTab, 'Add')}
            </NavLink>
          </NavItem>
        </Nav> */}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="list">
            <Card className="border-top-0 shadow-none">
              <MuiThemeProvider theme={theme}>
                <MaterialTable
                  components={{
                    OverlayLoading: props => (<Loading />)
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: 
                        props.isFetchComplete ? 'No records to display' : '',
                    }
                  }}
                  isLoading={!props.isFetchComplete}
                  title={
                    <div style={{ width: '500px' }}>
                      <Button
                        type="button"
                        title="Add"
                        color="primary"
                        style={{
                          margin: '0px 10px 5px 0px',
                          borderRadius: '50%',
                          padding: '0px',
                          width: '30px',
                          height: '30px',
                          float: 'left',
                        }}
                        onClick={() => {
                          props.resetState()
                          props.changeTab('add')
                        }}
                        hidden={!props.isActionAllowed('add')}
                      >
                        <i class="fas fa-plus fa-sm"></i>
                        {/* {props.editMode
                          ? formatCurrentTabName(activeTab, 'Edit')
                          : formatCurrentTabName(activeTab, 'Add')} */}
                      </Button>

                      <h2 style={{ color: '#00948B' }}>{props.tableTitle}</h2>
                    </div>
                  }
                  columns={props.columns}
                  data={listData}
                  options={materialTableOptions}
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Edit',
                      fontSize: 'small',
                      disabled: !props.isActionAllowed('edit'),
                      onClick: (event, rowData) => {
                        props.handleEdit(rowData)
                      },
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Delete',
                      fontSize: 'small',
                      disabled: !props.isActionAllowed('delete'),
                      onClick: (event, rowData) => {
                        props.handleDelete(rowData)
                      },
                    },
                  ]}
                  detailPanel={props.getDetailPanelView()}
                />
              </MuiThemeProvider>
            </Card>
          </TabPane>

          <TabPane tabId="add">
            <Card className="border-top-0 shadow-none">
              <CardHeader style={formHeaderStyle}>{props.formTitle}</CardHeader>

              <CardBody>
                <Form onSubmit={props.handleSubmit}>
                  {props.formGroup()}
                  <FormGroup row>
                    <Col xs={{ offset: 5 }} className="text-right">
                      <Button
                        type="submit"
                        color="primary"
                        className="fixedWidthButton"
                      >
                        Save
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="fixedWidthButton"
                        color="danger"
                        onClick={() => {
                          props.resetState()
                          props.changeTab('list')
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  )
}

export default ConfigComponent
