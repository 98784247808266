import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getUserId, getUserToken } from './UtilFunctions'

export const listEfforttype = (cancelToken) => {
  const token = getUserToken()
  return axios
    .get('/efforttype/listEfforttype', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}
export const activeEfforttype = (cancelToken) => {
  const token = getUserToken()
  return axios
    .get('/efforttype/activeEfforttype', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createEfforttype = (fields) => {
  const token = getUserToken()
  const id = getUserId()
  fields['created_by'] = id // TODO: big todo: find some users or create new ones
  fields['created_dt'] = new Date().toISOString()
  return axios
    .post('/efforttype/createEfforttype', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server', response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteEfforttype = (efforttypeId) => {
  // TODO: manage this token at a central store
  const token = getUserToken()
  return axios
    .delete(`/efforttype/deleteEfforttype/${efforttypeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server: ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while deleting effort type with id ${efforttypeId}`,
        error,
      )
    })
}

export const updateEfforttype = (efforttypeId, rowData) => {
  const token = getUserToken()
  const id = getUserId()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/efforttype/updateEfforttype/${efforttypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Response from server(Updating Effort type): ', response)
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Effort type with id ${efforttypeId}`,
        error,
      )
    })
}

export const updateApproved = (efforttypeId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  // TODO: do we need to changed the updated_by field as well here ?
  rowData['approved_by'] = id // TODO: big todo: find some users or create new ones
  rowData['approved_dt'] = new Date().toISOString()
  return axios
    .put(`/efforttype/updateApproved/${efforttypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating approved status of Effort type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Effort type's approved status with id ${efforttypeId}`,
        error,
      )
    })
}

export const updateStatus = (efforttypeId, rowData) => {
  const id = getUserId()
  const token = getUserToken()
  rowData['updated_by'] = id // TODO: big todo: find some users or create new ones
  rowData['updated_dt'] = new Date().toISOString()
  return axios
    .put(`/efforttype/updateStatus/${efforttypeId}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Updating status field of Effort type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while updating Effort type's status with Id ${efforttypeId}`,
        error,
      )
    })
}

export const getDetail = (efforttypeId) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/efforttype/getDetail/${efforttypeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of Effort type): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting Effort type's detail with Id ${efforttypeId}`,
        error,
      )
    })
}

export const listProjectEffort = (projectId, cancelToken) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/projecteffort/listEffort/${projectId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of project effort): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting effort's detail with Id ${projectId}`,
        error,
      )
    })
}

export const getProjectEffortDetail = (projectId, cancelToken) => {
  const id = getUserId()
  const token = getUserToken()
  return axios
    .get(`/projecteffort/getDetail/${projectId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken,
    })
    .then((response) => {
      console.log(
        'Response from server(Getting detail of project effort): ',
        response,
      )
      return response.data
    })
    .catch((error) => {
      console.log(
        `Error while getting effort's detail with Id ${projectId}`,
        error,
      )
    })
}
