import axios from 'axios'
import { getUserId, getUserToken } from './UtilFunctions'

export const listHealthCalculation = () => {
  const token = getUserToken();
  return axios
    .get('/healthCalculation/list', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('List health calculation', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error list health calculation', error);
    })
}

export const createHealthCalculation = (fields) => {
  const token = getUserToken();
  fields['created_by'] = getUserId();
  fields['created_dt'] = new Date().toISOString();
  return axios
    .post('/healthCalculation/create', fields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Create health calculation', response);
      return response.data
    })
    .catch((error) => {
      console.log('Error create health calculation', error);
    })
}

export const deleteHealthCalculation = (id) => {
  const token = getUserToken();
  return axios
    .delete(`/healthCalculation/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Delete health calculation', response);
      return response.data;
    })
    .catch((error) => {
      console.log(`Error delete health calculation`, error);
      throw error;
    })
}

export const updateHealthCalculation = (id, rowData) => {
  const token = getUserToken();
  rowData['updated_by'] = getUserId();
  rowData['updated_dt'] = new Date().toISOString();
  return axios
    .put(`/healthCalculation/update/${id}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Update health calculation', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error update health calculation', error);
    })
}

export const updateApproved = (id, rowData) => {
  const token = getUserToken();
  rowData['approved_by'] = getUserId();
  rowData['approved_dt'] = new Date().toISOString();
  return axios
    .put(`/healthCalculation/approve/${id}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Approve health calculation', response);
      return response.data
    })
    .catch((error) => {
      console.log('Error update health calculation', error);
    })
}

export const updateStatus = (id, rowData) => {
  const token = getUserToken();
  rowData['updated_by'] = getUserId();
  rowData['updated_dt'] = new Date().toISOString();
  return axios
    .put(`/healthCalculation/status/${id}`, rowData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Status health calculation', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error status health calculation', error);
    })
}

export const getDetail = (id) => {
  const token = getUserToken();
  return axios
    .get(`/healthCalculation/detail/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log('Detail health calculation', response);
      return response.data;
    })
    .catch((error) => {
      console.log('Error detail health calculation', error);
    })
}