import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../../../utils/Theme";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  label: {
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  root: {
    height: "2rem",
  },
}))(ToggleButton);

const CustomToggleButton = ({ selection, setSelection }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <StyledToggleButtonGroup
        value={selection}
        exclusive
        size="small"
        onChange={setSelection}
        aria-label="health toggle"
      >
        <StyledToggleButton value="projectSummary">{`Project Summary`}</StyledToggleButton>
        <StyledToggleButton value="resourceSummary">{`Resource Summary`}</StyledToggleButton>
        <StyledToggleButton value="projectStatus">{`Project Status`}</StyledToggleButton>
        <StyledToggleButton value="risksAndIssues">{`Risks And Issues`}</StyledToggleButton>
      </StyledToggleButtonGroup>
    </MuiThemeProvider>
  );
};

export default CustomToggleButton;
