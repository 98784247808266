import React, {Fragment, useEffect, useState} from "react"
import {getPermissionsFor} from "../../actions/UtilFunctions"
import {
    createMenu,
    deleteMenu,
    getDetail,
    listMenu,
    updateApproved,
    updateMenu,
    updateStatus
} from "../../actions/MenuFunctions"
import ActionFeedback from "../../components/ActionFeedbackAlert"
import {Container, TabContent, TabPane} from "reactstrap"
import MenuTable from "./MenuTable"
import MenuForm from "./MenuForm"
import {confirmAlert} from "react-confirm-alert"

const MenuContainer = () => {

    const fields = {
        'menu_name': '',
        'upper_menu_id': '',
        'link_url': '',
        'icon_url': ''
    }
    const [initialValues, setInitialValues] = useState({})

    const [menuList, setMenuList] = useState([])

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [activeTab, setActiveTab] = useState('list')
    const [actionFeedbackMsg, setActionFeedbackMsg] = useState('')

    const [allowedPermissions] = useState(getPermissionsFor('menu'))

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                await refreshProject()
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const refreshProject = async () => {
        let menu = await listMenu()
        if (menu) {
            setMenuList(menu)
        }
    }

    const changeTab = (tabName) => setActiveTab(tabName)

    const populateFormData = async (rowData) => {
        const menuId = rowData.menu_id
        try {
            if (menuId > 0) {
                setLoading(true)
                const detail = (await getDetail(menuId))[0]
                setInitialValues(detail)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleEdit = async (rowData) => {
        setEditMode(true)
        setActiveTab('add')
        await populateFormData(rowData)
    }

    const handleDelete = (rowData) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const menuId = rowData.menu_id
                        const menuListData = menuList.filter((menu) => menu['menu_id'] !== menuId)
                        setMenuList(menuListData)
                        deleteMenu(menuId).then((response) => {
                            console.log(`Menu successfully deleted with menu id: ${menuId} `, response)
                            if (response) {
                                showActionFeedback(response.message)
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        })
    }

    const handleStatus = (rowData) => {
        const menuId = rowData.menu_id
        const status = rowData['status']
        rowData['status'] = status === 'A' ? 'I' : 'A'
        updateStatus(menuId, rowData).then((response) => {
            console.log(`Status successfully updated for menu with menu id: ${menuId} `, response)
            if (response) {
                refreshProject()
                showActionFeedback(response.message)
            }
        })
    }

    const handleApproved = (rowData) => {
        const menuId = rowData.menu_id
        const isApproved = rowData['approved']
        rowData['approved'] = isApproved === 'Y' ? 'N' : 'Y'
        updateApproved(menuId, rowData).then((response) => {
            console.log(`Menu successfully approved with menu id: ${menuId} `, response)
            if (response) {
                refreshProject()
                showActionFeedback(response.message)
            }
        })
    }

    const onStatusIconClick = (e) => {
        e.preventDefault()
        const menuData = e.currentTarget.getAttribute('data-menu')
        handleStatus(JSON.parse(menuData))
    }

    const onApprovedIconClick = (e) => {
        e.preventDefault()
        const menuData = e.currentTarget.getAttribute('data-menu')
        handleApproved(JSON.parse(menuData))
    }

    const handleSubmit = (values) => {
        confirmAlert({
            title: 'Confirm Submission',
            message: 'Are you sure you want to submit?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let response

                        if (!values['upper_menu_id']) {
                            values['upper_menu_id'] = '0'
                            setInitialValues(values)
                        }

                        if (editMode) {
                            if (values?.menu_id && values?.menu_id > 0) {
                                for (const key of Object.keys(fields)) {
                                    fields[key] = values[key]
                                }
                                response = await updateMenu(values.menu_id, fields)
                            }
                        } else {
                            response = await createMenu(values)
                        }

                        if (response) {
                            showActionFeedback(response.message)
                            changeTab('list')
                            refreshProject()
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        })
    }

    const isActionAllowed = (action) => {
        for (let permission of allowedPermissions) {
            if (permission.perm_name.toLowerCase().includes(action.toLowerCase())) return true
        }
        return false
    }

    const showActionFeedback = (message) => {
        setActionFeedbackMsg(message)
        setTimeout(() => setActionFeedbackMsg(''), 5000)
    }

    const resetForm = () => {
        setInitialValues({})
        setEditMode(false)
    }

    return <div className="ipp-page">
        <Fragment>
            {actionFeedbackMsg && <ActionFeedback message={actionFeedbackMsg} />}
            <Container className="mt-3" fluid style={{maxWidth: '100%', margin: 'auto'}}>
                <TabContent activeTab={activeTab}>

                    <TabPane tabId="list">
                        <MenuTable
                            menuListData={menuList}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            onStatusIconClick={onStatusIconClick}
                            onApprovedIconClick={onApprovedIconClick}
                            isLoading={loading}
                            isActionAllowed={isActionAllowed}
                            changeTab={changeTab}
                        />
                    </TabPane>

                    <TabPane tabId="add">
                        <MenuForm
                            initValues={initialValues}
                            menuListData={menuList}
                            handleSubmit={handleSubmit}
                            isLoading={loading}
                            changeTab={changeTab}
                            resetForm={resetForm}
                        />
                    </TabPane>

                </TabContent>
            </Container>
        </Fragment>
    </div>

}

export default MenuContainer