import React, { Fragment } from 'react'
import { getDetail } from '../../actions/ProjectFunctions'
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardColumns,
} from 'reactstrap'

class ProjectDetailList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: {},
    }
  }

  componentDidMount() {
    getDetail(this.props.projectId).then((detail) => {
      this.setState({
        detail: detail,
      })
    })
  }

  notEmpty = (value) => {
    return value ? value : '';
  }

  getFormattedValue = (key) => {
    const detail = this.state.detail
    if (key === 'velocity_per_resource') {
      return `${this.notEmpty(detail['capacity_hrs'])} hrs ${this.notEmpty(detail['capacity_points'])} points`
    } 
    else if (key === 'work_plan') {
      return `${this.notEmpty(detail['work_plan'])} ${this.notEmpty(detail['work_days'])} working days`
    } else {
      return this.notEmpty(detail[key])
    }
  }

  render() {
    const { detail } = this.state
    const map = {
      project_cd: 'Project Code',
      project_name: 'Project Name',
      project_abbr: 'Project Abbreviation',
      project_type: 'Project Type',
      project_category: 'Project Category',
      stream_name: 'Stream',
      lead_name: 'Lead',
      client_name: 'Client',
      email: 'Email',
      project_st_dt: 'Start Date',
      project_end_dt: 'End date',
      velocity_per_resource: 'Velocity per resource',
      // capacity_hrs: 'Velocity',
      // capacity_points: 'Points',
      model_name: 'Project Model',
      client_type_name: 'Client type',
      work_plan: 'Work Plan',
      // work_days: 'Work Days',
      status: 'Status',
      approved: 'Approved',
      state_project: 'State',
      state_remarks: 'State Remarks',
      state_date: 'State Date',
      approved_by: 'Approved By',
      approved_dt: 'Approved Date',
      updated_by: 'Updated By',
      updated_dt: 'Updated Date',
      created_by: 'Created By',
      created_dt: 'Created Date',
      remarks: 'Remarks',
    }

    return (
      <CardColumns className="lisColor1 pt-2">
        {Object.keys(map).map((key) => (
          <Card className="border-0 lisColor1">
            <CardBody className="p-0 pl-3">
              <CardTitle>{`${map[key]}`}</CardTitle>
              <CardSubtitle className="font-weight-bold">{`${this.getFormattedValue(
                key,
              )}`}</CardSubtitle>
            </CardBody>
          </Card>
        ))}
      </CardColumns>
    )
  }
}

export default ProjectDetailList
